import styled from 'styled-components';

interface InputProps {
    width: string | number
}

export const Container = styled.div<InputProps>`
    width: ${props => props.width};
    @media (min-resolution: 1.50dppx){
        label, input {
                font-size: 0.75rem;
                // line-height: 1.7;
            }

        input {
            height: 2.5rem;
            padding: 0.8rem 1rem;
        }

        div div {
            font-size: 0.55rem;
        }
    }
`