import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { EXPORT_LIST, changeExportBundle } from '../../store/stock/ExportReducer';
import StyledAlert from '../Alert/Alert';
import { Link, useNavigate } from 'react-router-dom';

interface props {
  bundle: string;
  campaignId: string;
  refresh?: boolean;
}
const ExportStatus: React.FC<props> = (props) => {
  const { bundle, isQueued } = useAppSelector((state) => state.export);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(changeExportBundle(props.bundle));
  }, [props.bundle]);

  useEffect(() => {
    bundle === props.bundle && handleSearch();
  }, [bundle]);

  const handleSearch = () => {
    dispatch(EXPORT_LIST({ ...props }));
  };

  const navigate = useNavigate();

  return (
    <>
      {isQueued && bundle === props.bundle && (
        <StyledAlert
          className='exportAlert'
          css={{ width: '100%', margin: '0.5rem 0' }}
          message="The export run in a background process."
          actions={[
            {
              action: () => navigate(`/bees-games/manage-assets/campaign-details/${props.campaignId}/export/${bundle}`),
              name: 'Click here to follow up the process and download the file.',
            }
          ]}
          type="info"
        />
      )}
    </>
  );
};

export default ExportStatus;
