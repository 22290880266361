import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface TotalScoreInterface {
    getRanking: (id) => Promise<any>,
}

export default function useTotalScoreHandler(): TotalScoreInterface {
    const getRanking = async (id) => {
        return await apiDrupal.get(`${base_url}/competition/v1/campaigns/${id}/bee-run/ranking`)
            .then(response => response.data)
            .catch(error => error);
    }

    return { getRanking }
}
