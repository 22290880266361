import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface ValidationHandleInterface {
  getValidation: (campaignId) => Promise<any>;
}

export default function useValidationHandler(): ValidationHandleInterface {
  const getValidation = async (campaignId) => {
    return await apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaignId}/validations`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  return { getValidation };
}
