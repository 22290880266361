import styled from 'styled-components';

interface ContainerProps {
    padding?: string
}

export const Container = styled.div<ContainerProps>`
    div {
        padding: ${props => props.padding};
    }

    @media (min-resolution: 1.50dppx){
        h5, p {
                font-size: 0.8rem;
                line-height: 1.5;
        }

        img {
            height: 150px;
        }

        h3 {
            font-size: 1.25rem;
            line-height: 1.5;
        }

        h4 {
            font-size: 1rem;
            line-height: 1.5;
        }

        button {
            font-size: 0.75rem;
        }

        svg {
            height: 1.4rem;
            width: 1.4rem;
        }
    }
`