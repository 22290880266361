import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable from '../../../components/Table/Table';
import handleFilter from '../../campaignDetails/extra/filter';
import QuizHeaders from '../../json/columnHeaders/QuizQuestionsHeaders.json';
import mockData from '../../json/mock/mockingQuizQuestionsData.json';
import PageTitle from '../../../components/PageTitle/PageTitle';

const QuestionsTab: React.FC = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(mockData);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState(mockData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const updateData = InsertActions(filteredData);
    setData(updateData);
  }, [filteredData]);

  const handleSearch = (e) => {
    setSearch(e.currentTarget.value);
    const currentData = mockData;
    if (e.currentTarget.value) {
      const searchKeys = ['id', 'title', 'brand', 'published', 'actions'];
      handleFilter(e.currentTarget.value, currentData, searchKeys, setFilteredData);
    } else {
      setFilteredData(currentData);
    }
  };

  const handleDelete = () => {

  }

  const InsertActions = (data) => {
    let aux = [];

    const actions = (data) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(
                `/bees-games/quiz/quiz-details/${data.id}/edit-question/${data.id}`,
                {
                  state: {
                    data
                  },
                }
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleDelete()}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete question?
            </Heading>
            <Paragraph>{'Do you really wish to delete this question?'}</Paragraph>
            <Paragraph>{"This action can't be undone."}</Paragraph>
          </Modal.Root>
        </>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <>
      <PageTitle marginBottom="1rem" size="H2" title="Questions and Answers" />
      <Card elevated={'medium'} border={'large'} >
        <FlexContainer
          width="100%"
          display={'inline-flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e) => handleSearch(e)}
              onClear={(e) => handleSearch(e)}
            />
          </div>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() => navigate(`/bees-games/quiz/quiz-details/${data[0].id}/add-question`)}
          >
            Add Question
          </StyledButton>
        </FlexContainer>
        <StyledTable
          columns={QuizHeaders}
          search={false}
          data={data || []}
          loading={loading}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10, 20],
            showPageSizeSelector: true,
          }}
        />
      </Card>
    </>
  );
};

export default QuestionsTab;
