import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    '@media (min-resolution: 1.50dppx)': {
        button: {
            fontSize: '0.75rem',
            lineHeight: '1.7'
        },

        svg: {
            height: '14px',
            width: '14px'
        }
    }
})
