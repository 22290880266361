import { Checkbox, Chip, Divider, Paragraph } from '@hexa-ui/components';
import { ChevronDown, ChevronUp, Filter } from '@hexa-ui/icons';
import { useEffect, useState } from 'react';
import StyledButton from '../Button/Button';
import { ButtonText, Container, ContainerActions, DisplayFilters, FilterMenu, FilterOptions, GroupChips, List, ShowCheckboxes, StyledListItem } from './CheckboxFilter.styles';
import { CheckboxFilterType, CheckboxListFilterType } from './CheckboxFilter.types';

const VerifyFilters = (filters) => {
    const tabs = Object.keys(filters)
    if (tabs.length > 0) {
        let checkFilters = false
        tabs.forEach(item => {
            if (filters[item]?.length > 0) {
                checkFilters = true
            }
        })
        return checkFilters
    } else return false

}

export const DisplayFilter = ({ checkFilters, deleteFilter }: CheckboxListFilterType): JSX.Element => {
    const [filters, setFilters] = useState(checkFilters)

    useEffect(() => {
        setFilters(checkFilters)
    }, [checkFilters])

    const deleteChip = (tab: string, acessor: string) => {
        let list = { ...filters }
        list[tab] = list[tab].filter((item: string) => item !== acessor)
        if (!VerifyFilters(list)) list = {}
        deleteFilter(list)
    }

    return VerifyFilters(filters) ? <DisplayFilters>
        <Paragraph size='small' type='body'>
            Filtered by
        </Paragraph>
        {Object.keys(filters).map((tab, i) => {
            return filters[tab]?.map((item: string) => {
                return <GroupChips key={i} >
                    <Chip.Label size='small'>{`${(tab[0].toUpperCase() + tab.substring(1)).replace('_', ' ')} = ${(item[0].toUpperCase() + item.substring(1)).replace('_', ' ')}`}</Chip.Label>
                    <Chip.DeleteIcon onDelete={() => deleteChip(tab, item)} />
                </GroupChips>
            })
        })
        }
    </DisplayFilters>
        : null
}

const CheckboxFilter = ({ menuTabs, tabItems, onChange, selectedCheckboxes, onCancel }: CheckboxFilterType): JSX.Element => {
    const [open, setOpen] = useState(false)
    const [selectedTab, setSelectedTab] = useState<string>(menuTabs[0]?.acessor)
    let initialState = {}
    menuTabs?.forEach(tab => {
        initialState = { ...initialState, [tab?.key]: [] }
    })

    const [filters, setFilters] = useState(initialState)

    useEffect(() => {
        if (Object.keys(selectedCheckboxes).length > 0) setFilters(selectedCheckboxes)
        else setFilters(initialState)
        console.log({ filters })
    }, [selectedCheckboxes])

    const handleClick = (acessor: string) => {
        setSelectedTab(acessor)
    }

    const handleCheck = (check: boolean | string, tab: string, acessor: string) => {
        if (check) setFilters(prevState => ({
            ...prevState, [tab]: [
                ...prevState[tab],
                acessor
            ]
        }))
        else
            setFilters(prevState => {
                let removedItem = [
                    ...prevState[tab]
                ].filter(item => item !== acessor)
                return ({
                    ...prevState, [tab]: removedItem
                })
            })
    }

    const handleCancel = () => {
        setFilters({})
        setOpen(false)
    }

    const handleApplyFilters = () => {
        onChange(filters)
        setOpen(false)
    }

    return (
        <Container>
            <StyledButton
                size="medium"
                variant="secondary"
                onClick={() => setOpen(prevState => !prevState)}
            >
                <Filter size='medium' /><ButtonText>Filters</ButtonText>{open ? <ChevronUp size='medium' /> : <ChevronDown size='medium' />}
            </StyledButton>
            {open ? <FilterMenu border='medium' elevated='large'>
                <FilterOptions>
                    <List>
                        {menuTabs.map((tab, i) =>
                            <StyledListItem onClick={() => handleClick(tab?.acessor)} key={tab?.acessor} isCurrentTab={tab?.acessor === selectedTab} spacing='large' variant="navigation">
                                {tab?.label}
                            </StyledListItem>
                        )}
                    </List>
                    <Divider orientation="vertical" />
                    <ShowCheckboxes>
                        {
                            tabItems[selectedTab]?.map((checkbox, i) =>
                                <Checkbox
                                    key={i}
                                    id={checkbox?.acessor}
                                    label={checkbox?.label}
                                    onCheckedChange={(check) => handleCheck(check, selectedTab, checkbox?.acessor)}
                                    checked={filters[selectedTab]?.indexOf(checkbox?.acessor) >= 0}
                                />
                            )
                        }
                    </ShowCheckboxes>
                </FilterOptions>
                <Divider orientation="horizontal" />
                <ContainerActions>
                    <StyledButton variant="secondary" onClick={handleCancel}>Cancel</StyledButton>
                    <StyledButton onClick={handleApplyFilters} >Apply</StyledButton>
                </ContainerActions>
            </FilterMenu>
                : null}
        </Container>
    )
}

export default CheckboxFilter