import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    @media (min-resolution: 1.50dppx){
        padding: 0px;
        [role=tablist]{
            font-size: 0.75rem;
            padding: 0px;
            button {
                font-size: 0.75rem;
            }
        }

        [role=tabpanel] {
            padding-left: 0px;
            padding-right: 0px;
        }
    }
`