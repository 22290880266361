import { IconButton, Modal, Select, Tooltip } from '@hexa-ui/components';
import { DocumentSigned, Download } from '@hexa-ui/icons';
import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import StyledHeading, { StyledParagraph } from '../../../components/Heading/Typography';
import StyledInput from '../../../components/Input/Input';
import StyledSelect from '../../../components/Select/Select';
import { countryType } from '../../../components/layout/Layout';
import { AlertContext } from '../../../contexts/alert.context';
import { AppDispatch, useAppSelector } from '../../../store';
import { EXPORT_CREATE, EXPORT_CREATE_CAMPAIGN } from '../../../store/stock/ExportReducer';

// import { Container } from './styles';
interface props {
  bundle: string;
  campaignId: string;
  disabled?: boolean;
}
const ExportModule: React.FC<props> = ({ bundle, campaignId, disabled }) => {
  const exportState = useAppSelector((state) => state.export);

  const { addToast } = useContext(AlertContext);
  const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
  const [isModelOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formExport, setFormExport] = useState({
    start: 0,
    length: 1000,
    country: localStorage.getItem('country').toString()
  });

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const handleCreateExportCampaign = async () => {
    setLoading(true);
    dispatch(EXPORT_CREATE_CAMPAIGN({ bundle, campaignId, ...formExport })).then((res) => console.log({ res })).finally(() => {
      setLoading(false);
      setIsModalOpen(false);
      if (exportState.error) {
        addToast({ message: 'Error creating data export.', type: 'error' });
      } else {
        addToast({ message: 'Data export successfully created.', type: 'success' });
      }
    });
  };

  const handleCreateExport = async () => {
    setLoading(true);
    dispatch(EXPORT_CREATE({ bundle, ...formExport })).then((res) => console.log({ res })).finally(() => {
      setLoading(false);
      setIsModalOpen(false);
      if (exportState.error) {
        addToast({ message: 'Error creating data export.', type: 'error' });
      } else {
        addToast({ message: 'Data export successfully created.', type: 'success' });
      }
    });
  }

  return (
    <FlexContainer display="flex" flexDirection="row" gap="0.5rem" margin="0.5rem 0">
      <Tooltip placement="top" text="Generate CSV Report">
        <Modal.Root
          actions={
            <FlexContainer
              width="100%"
              display="inline-flex"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Modal.Action>
                <StyledButton variant="primary" onClick={() => campaignId !== null ? handleCreateExportCampaign() : handleCreateExport()} isLoading={loading}>
                  Export
                </StyledButton>
              </Modal.Action>
              <Modal.Action>
                <StyledButton variant="secondary" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </StyledButton>
              </Modal.Action>
            </FlexContainer>
          }
          open={isModelOpen}
          trigger={
            <IconButton
              disabled={disabled}
              icon={DocumentSigned}
              size="large"
              variant="secondary"
              onClick={() => setIsModalOpen(true)}
            />
          }
          variant="overlay"
          destructive="off"
        >
          <FlexContainer
            display="flex"
            gap="0.5rem"
            justifyContent="space-evenly"
            flexDirection="column"
            minHeight="350px"
          >
            <StyledHeading size='H3' title='Do you want to export?' />
            <StyledParagraph type='body' title='This action cannot be undone.' />
            <StyledInput
              label="Start position"
              value={formExport.start}
              hint="The start position to export."
              placeholder=""
              required
              type="number"
              min={0}
              onChange={(e) => {
                let val = Number(e.currentTarget.value);
                val = val < 0 ? 0 : val;

                setFormExport({
                  ...formExport,
                  start: val,
                });
              }}
            />
            <StyledInput
              label="Quantity records"
              value={formExport.length}
              hint="The quantity records to export."
              placeholder=""
              type="number"
              min={1}
              onChange={(e) => {
                let val = Number(e.currentTarget.value);
                val = val < 1 ? 1 : val;

                setFormExport({
                  ...formExport,
                  length: val,
                });
              }}
            />
            {campaignId === null && <StyledSelect
              width={'auto'}
              value={formExport.country}
              size="large"
              label='Country'
              onChange={(value) => {
                setFormExport({
                  ...formExport,
                  country: value,
                });
              }}
            >
              {countriesPromo &&
                countriesPromo.map((country: countryType, index: number) => {
                  return (
                    <Select.Option key={country.name + index} value={String(country.id)}>
                      {country.name}
                    </Select.Option>
                  );
                })}
            </StyledSelect>}
          </FlexContainer>
        </Modal.Root>
      </Tooltip>
      <Tooltip placement="top" text="Download ready CSV">
        <IconButton
          disabled={disabled}
          icon={Download}
          size="large"
          variant="secondary"
          onClick={() => {
            if (campaignId === null) navigate(`/bees-games/export/${bundle}`)
            else navigate(`/bees-games/manage-assets/campaign-details/${campaignId}/export/${bundle}`)
          }}
        />
      </Tooltip>
    </FlexContainer>
  );
};

export default ExportModule;
