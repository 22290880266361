
export const checkValidityCSV = (file: File, validHeaders: string[], delimiter: string = ',') => new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function () {
        let headers = ((<string>reader?.result)?.replace('\r', '').split('\n')[0]).split(delimiter)
        let isValid = true
        validHeaders.map(item => {
            if (!headers.includes(item) && isValid) {
                isValid = false
            }
        })
        resolve(isValid)
    }
    reader.readAsText(file);
})

export default checkValidityCSV
