import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { countryType } from '../../components/layout/Layout';

//ASYNC THUNKS
export const FETCH_COUNTRIES = createAsyncThunk('fetch/countries', async (thunkAPI) => {
  const response = await apiDrupal.get(
    `/countries?page=0&limit=0&_format=json&XDEBUG_SESSION_START=VSCODE`
  );
  return response.data;
});

interface ICountryState {
  data: countryType[];
  error: any;
}

const initialState: ICountryState = {
  data: [],
  error: null,
};

export const Slice = createSlice({
  name: 'Country',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //FETCH_COUNTRIES
    builder.addCase(FETCH_COUNTRIES.pending, (state, action) => { });
    builder.addCase(FETCH_COUNTRIES.fulfilled, (state, action) => {
      state.data = Object.values(action.payload.data.countries);
    });
    builder.addCase(FETCH_COUNTRIES.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
