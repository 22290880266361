import { useEffect } from 'react';

const WindowFocusHandler = (onFocus: VoidFunction, onBlur?: VoidFunction, dependency?: any) => {
  useEffect(() => {
    window.addEventListener('blur', onBlur);
    // window.addEventListener('focus', onFocus);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('blur', onBlur);
      // window.removeEventListener('focus', onBlur);
    };
  }, [dependency]);

  return <></>;
};

export default WindowFocusHandler;
