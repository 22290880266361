// import { Container } from './styles';

import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface RankingHandleInterface {
  /* getRankingStatus: (campaignId) => Promise<any>; */
  getAllRanking: (campaignId) => Promise<any>;
  getCSVData: (
    campaignId: string | number,
    start: string | number,
    length: string | number
  ) => Promise<any>;
}

export default function useRankingHandler(): RankingHandleInterface {
  const getAllRanking = async (campaignId) => {
    return await apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaignId}/player-ranking`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  const getCSVData = async (campaignId, start, length) => {
    return await apiDrupal
      .get(
        `${base_url}/hub/v2/competition/${campaignId}/mix-and-match/ranking/export?start=${start}&length=${length}`
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  };

  return { getAllRanking, getCSVData };
}
