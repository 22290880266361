import React, { SetStateAction } from 'react';
import EditCampaignModule from '../../../modules/EditCampaign.module';
import { CampaignType } from '../../../store/dataTypes';
import StepGameSelection from '../step/GameSelection/StepGameSelection';
import StepGameSettings from '../step/GameSettings/StepGameSettings';
import ReviewCampaign from '../step/ReviewCampaign/ReviewCampaign';
import StepSelectPrize from '../step/SelectPrize/StepSelectPrize';
import StepTournamentRules from '../step/TournamentRules/StepTournamentRules';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
  step: number;
  game: string;
  stepIncompleted: boolean;
}

const CustomStepSelect: React.FC<props> = ({ form, setForm, step, game, stepIncompleted }) => {
  const screens = {
    spinning_wheel: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepSelectPrize form={form} setForm={setForm} />,
      3: <StepGameSettings form={form} setForm={setForm} />,
      4: <ReviewCampaign form={form} setForm={setForm} />,
    },
    penalty_kick: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepSelectPrize form={form} setForm={setForm} />,
      3: <StepGameSettings form={form} setForm={setForm} />,
      4: <ReviewCampaign form={form} setForm={setForm} />,
    },
    bottle_crush: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepGameSettings form={form} setForm={setForm} />,
      3: <ReviewCampaign form={form} setForm={setForm} />,
    },
    instant_card: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepSelectPrize form={form} setForm={setForm} />,
      3: <StepGameSettings form={form} setForm={setForm} />,
      4: <ReviewCampaign form={form} setForm={setForm} />,
    },
    virtual_cup_mug: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepSelectPrize form={form} setForm={setForm} />,
      3: <StepGameSettings form={form} setForm={setForm} />,
      4: <ReviewCampaign form={form} setForm={setForm} />,
    },
    soccer_competition: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm />,
      2: <StepTournamentRules form={form} setForm={setForm} />,
      3: <StepSelectPrize form={form} setForm={setForm} />,
      4: <StepGameSettings form={form} setForm={setForm} />,
      5: <ReviewCampaign form={form} setForm={setForm} />,
    },
    bee_run: {
      0: <StepGameSelection form={form} setForm={setForm} />,
      1: <EditCampaignModule form={form} setForm={setForm} stepIncompleted={stepIncompleted} isCreateForm  />,
      2: /* form?.bee_run_prizes ? <StepSelectPrize form={form} setForm={setForm} /> : */ form?.rewards_integration ? <StepGameSettings form={form} setForm={setForm} /> : <ReviewCampaign form={form} setForm={setForm} />,
      3: /* form?.bee_run_prizes ? <StepGameSettings form={form} setForm={setForm} /> : */ form?.rewards_integration && <ReviewCampaign form={form} setForm={setForm} />,
      /* 4: form?.bee_run_prizes && <ReviewCampaign form={form} setForm={setForm} />, */
    },
  };
  return screens[game || 'spinning_wheel'][step];
};

export default CustomStepSelect;
