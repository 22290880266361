import { useEffect, useState } from 'react';

export default function useWindowDimensions() {
      const hasWindow = typeof window !== 'undefined';

      //Obtém as dimensões da página e retorna estes valores
      function getWindowDimensions() {
            const width = hasWindow ? window.innerWidth : null;
            const height = hasWindow ? window.innerHeight : null;
            return {
                  width,
                  height
            }
      }

      //estado que contém as dimensões
      const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

      useEffect(() => {
            //Observa sempre que a tela for redimensionada e armazena os novos valores ao estado
            //em seguida encerra o observador para poupar desempenho
            function handleResize() {
                  if (!hasWindow) { return }
                  setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize)
            return () => window.removeEventListener('resize', handleResize)
      }, [hasWindow])

      return windowDimensions
}