import styled from 'styled-components';

interface SelectProps {
    width: string | number
}

export const Container = styled.div<SelectProps>`
    width: ${props => props.width};
    @media (min-resolution: 1.50dppx){
        button, div, label {
                font-size: 0.75rem;
        }

        button {
            height: 2.5rem;
        }

        li > div > div, li > div {
            min-height: 38px;
        }
    }
`