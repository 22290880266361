import { Card, FileValidated, Grid, Image, Paragraph } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import FlexContainer from '../../components/FlexContainer';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import useCustomSettingsHandler from '../../hook/useCustomSettingsHandler';
import { base_url } from '../../utils/constants';
import verifyImgType from '../../utils/verifyImgType';
import pdfImage from '../../assets/pdfSettings.jpeg';

interface Settings {
  settings: {
    field_text_before_digital_prize: string;
    field_text_before_prize_name: string;
    field_title: string;
    name: string;
    field_logo_header: FileValidated[] | any;
    field_logo_footer: FileValidated[] | any;
    field_logo_header_id?: string;
    field_logo_footer_id?: string;
  };
}

const EditCustomSettings: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(AlertContext);
  const [headerFileTypeError, setHeaderFileTypeError] = useState(false);
  const [footerFileTypeError, setFooterFileTypeError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Settings>();
  const { state } = useLocation();
  const { editCustomSettings, uploadHeader, uploadFooter } = useCustomSettingsHandler();

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  useEffect(() => {
    setForm({
      settings: {
        field_text_before_digital_prize: state.customSettings.settings.field_text_before_digital_prize,
        field_text_before_prize_name: state.customSettings.settings.field_text_before_prize_name,
        field_title: state.customSettings.settings.field_title,
        name: state.customSettings.settings.name,
        field_logo_header_id: state.customSettings.settings.field_logo_header.id,
        field_logo_footer_id: state.customSettings.settings.field_logo_footer.id,
        field_logo_header: [state.customSettings.settings.field_logo_header],
        field_logo_footer: [state.customSettings.settings.field_logo_footer],
      },
    });
  }, []);

  async function save() {
    setLoading(true);
    if (
      !form.settings.field_logo_header ||
      !form.settings.field_logo_footer ||
      !form.settings.field_title ||
      !form.settings.field_text_before_prize_name ||
      !form.settings.field_text_before_digital_prize
    ) {
      addToast({ message: 'Please fill in all fields to continue', type: 'error' });
      setLoading(false);
      setHasEmptyField(true);
      return;
    }
    await editCustomSettings({
      id: state.customSettings.settings.id,
      name: form.settings.name,
      field_logo_header: form.settings.field_logo_header_id,
      field_logo_footer: form.settings.field_logo_footer_id,
      field_title: form.settings.field_title,
      field_text_before_prize_name: form.settings.field_text_before_prize_name,
      field_text_before_digital_prize: form.settings.field_text_before_digital_prize
    })
      .then((res) => {
        if (res?.data?.errors) {
          addToast({
            message: 'Error saving data.',
            type: 'error',
          });
        } else {
          addToast({ message: `Campaign settings updated successfully.`, type: 'success' })
          navigate(-1);
        }
      })
      .catch(() => addToast({ message: 'Failed to update campaign settings.', type: 'error' })).finally(() => setLoading(false));
  }

  const handleOnDropHeader = async (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return
    }
    if (!verifyImgType(typelist, file)) {
      setHeaderFileTypeError(true);
      return;
    }
    setLoading(true);
    uploadHeader({ file: file[0].file, fileName: file[0].file.name }).then((res) => {
      setForm((prevSettings) => ({
        ...prevSettings,
        settings: {
          ...prevSettings.settings,
          field_logo_header: file,
          field_logo_header_id: res.data.id,
        },
      }));
    }).finally(() => setLoading(false));
  }

  const handleOnCleanHeader = () => {
    setHeaderFileTypeError(false);
    setForm((prevSettings) => ({
      ...prevSettings,
      settings: {
        ...prevSettings.settings,
        field_logo_header: [],
        field_logo_header_id: state.customSettings.settings.field_logo_header.id,
      },
    }));
  }

  const handleOnDropFooter = async (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return
    }
    if (!verifyImgType(typelist, file)) {
      setFooterFileTypeError(true);
      return;
    }
    setLoading(true);
    uploadFooter({ file: file[0].file, fileName: file[0].file.name }).then((res) => {
      setForm((prevSettings) => ({
        ...prevSettings,
        settings: {
          ...prevSettings.settings,
          field_logo_footer: file,
          field_logo_footer_id: res.data.id,
        },
      }));
    }).finally(() => setLoading(false));
  }

  const handleOnCleanFooter = () => {
    setFooterFileTypeError(false);
    setForm((prevSettings) => ({
      ...prevSettings,
      settings: {
        ...prevSettings.settings,
        field_logo_footer: [],
        field_logo_footer_id: state.customSettings.settings.field_logo_footer.id,
      },
    }));
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PageTitle size="H2" marginBottom="1rem" title="Edit PDF Settings" />
      </Grid.Item>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '1.5rem' }}>
        <Card elevated={'medium'} border={'medium'} css={{ width: '100%' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              padding: '1.5rem 0 1rem 0',
            }}
          >
            <Grid.Item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              style={{ paddingLeft: '0px', paddingRight: '24px' }}
            >
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <FlexContainer display="flex" flexDirection="column" gap="1rem">
                <ImageUploader
                    id="field-logo-header"
                    title="Header image"
                    url={base_url + form?.settings.field_logo_header[0]?.url}
                    accept=".png,.jpg,.gif,.jpeg"
                    maxFileSize={1000 * 1000}
                    message="Allowed PNG, GIF, JPG, JPEG format and must be 1MB or less."
                    error={
                      (headerFileTypeError ||
                        (hasEmptyField && form?.settings.field_logo_header.length < 1)) && {
                        message: 'The selected file cannot be uploaded or is missing.',
                      }
                    }
                    value={form?.settings.field_logo_header}
                    onDrop={handleOnDropHeader}
                    onClean={handleOnCleanHeader}
                  />
                  <StyledInput
                    label="Award message"
                    placeholder="award message"
                    size="large"
                    width="100%"
                    value={form?.settings.field_title}
                    onChange={({ currentTarget: { value } }) =>
                      setForm((prevSettings) => ({
                        ...prevSettings,
                        settings: { ...prevSettings.settings, field_title: value },
                      }))
                    }
                    required
                    hasError={hasEmptyField && !form?.settings.field_title}
                    errorText="Title is required."
                  />
                  <StyledInput
                    label="You win message"
                    placeholder="award message"
                    size="large"
                    width="100%"
                    value={form?.settings.field_text_before_prize_name}
                    onChange={({ currentTarget: { value } }) =>
                      setForm((prevSettings) => ({
                        ...prevSettings,
                        settings: { ...prevSettings.settings, field_text_before_prize_name: value },
                      }))
                    }
                    required
                    hasError={
                      hasEmptyField && !form?.settings.field_text_before_prize_name
                    }
                    errorText="Text before prize name is required."
                  />
                  <StyledInput
                    label="Voucher"
                    placeholder="Voucher message"
                    size="large"
                    width="100%"
                    value={form?.settings.field_text_before_digital_prize}
                    onChange={({ currentTarget: { value } }) =>
                      setForm((prevSettings) => ({
                        ...prevSettings,
                        settings: {
                          ...prevSettings.settings,
                          field_text_before_digital_prize: value,
                        },
                      }))
                    }
                    required
                    hasError={
                      hasEmptyField && !form?.settings.field_text_before_digital_prize
                    }
                    errorText="Text before digital prize code is required."
                  />
                  <ImageUploader
                    title="Footer image"
                    accept=".png,.jpg,.gif,.jpeg"
                    maxFileSize={1000 * 1000}
                    message="Allowed PNG, GIF, JPG, JPEG, format and must be less than 256MB"
                    error={
                      (footerFileTypeError ||
                        (hasEmptyField && form?.settings.field_logo_footer.length < 1)) && {
                        message: 'The selected file cannot be uploaded or is missing.',
                      }
                    }
                    value={form?.settings.field_logo_footer}
                    onDrop={handleOnDropFooter}
                    onClean={handleOnCleanFooter}
                    id="field-logo-footer"
                    url={base_url + form?.settings.field_logo_footer[0]?.url}
                  />
                </FlexContainer>
              </Grid.Container>
            </Grid.Item>
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                }}
              >
                <FlexContainer display="flex" flexDirection="column" gap="1rem">
                  <Paragraph weight='semibold' size='small'>
                    PDF Template
                  </Paragraph>
                  <Image
                    src={pdfImage}
                    height={'444px'}
                    width={'523px'}
                    aspectRatio={1}
                    variant="fill"
                  />
                </FlexContainer>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '20px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => save()}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </Grid.Item>
    </>
  );
};

export default EditCustomSettings;
