import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
  position: 'relative',
  '@media (min-resolution: 1.50dppx)': {
    'p, label, input': {
      fontSize: '$1 !important',
    },

    input: {
      height: '2rem',
    },

    svg: {
      height: '1rem',
      width: '1rem',
    },

    'svg:nth-child(2)': {
      height: '0.813px',
      width: '0.813px',
    },

    '> p:nth-child(2)': {
      fontSize: '0.55rem',
      lineHeight: '1.7',
    },
  },
});
