import { Card, Grid } from '@hexa-ui/components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import ExportStatus from '../../../components/ExportStatus';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import usePlayerProgressHandler from '../../../hook/usePlayerProgressHandler';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { PlayerProgressType } from '../../../store/dataTypes/playerProgress.type';
import { changeState } from '../../../utils/functions';
import playerProgressHeaders from '../../json/columnHeaders/PlayerProgressHeaders.json';
import handleFilter from '../extra/filter';
import ExportModule from '../modules/ExportModal';

const PlayerProgressTab: React.FC = () => {
  const params = useParams();
  const [playerProgressData, setPlayerProgressData] = useState<PlayerProgressType[]>([]);

  const { getAllPlayerProgress } = usePlayerProgressHandler();
  const [filteredData, setFilteredData] = useState<PlayerProgressType[]>();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);

  WindowFocusHandler(async () => {
    setLoading(true);
    await getAllPlayerProgress(String(params?.id))
      .then((val) => {
        setPlayerProgressData(dataProcessing(val.data));
      })
      .finally(() => setLoading(false));
  });

  const dataProcessing = (data) => {
    let newObj = [...data];
    newObj.map((arrItem, index) => {
      Object.keys(arrItem).map((item) => {
        if (item === 'player') {
          newObj[index] = {
            ...newObj[index],
            poc: newObj[index].player.poc,
            quantity: newObj[index].player.quantity,
            name: newObj[index].player.name,
          };
          newObj[index].player = '';
        }

        if (item === 'current_level') {
          newObj[index].current_level = newObj[index].current_level.target_id;
        }
      });
    });
    return newObj;
  };

  useEffect(() => {
    const data = playerProgressData;
    setFilteredData(data);
  }, [playerProgressData]);

  useEffect(() => {
    const data = [...playerProgressData];
    if (!search) {
      setFilteredData(data);
    }

    const fields = [
      'id',
      'name',
      'poc',
      'quantity',
      'total_levels',
      'current_level',
      'score',
      'completed_game_in',
      'lost_attempt',
      'new_game',
      'expires',
      'created',
      'changed',
    ];

    handleFilter(search, data, fields, setFilteredData);
  }, [search]);

  return (
    <>
      <ExportStatus bundle="bottle_crush_player_progress" campaignId={params?.id} />
      <Card elevated={'medium'} border={'large'}>
        <Grid.Item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            justifyContent: 'space-between',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              width={'100%'}
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e) => changeState(setSearch, e.currentTarget.value)}
              onClear={() => changeState(setSearch, '')}
            />
          </div>
        </Grid.Item>
        {filteredData !== undefined ? (
          <StyledTable
            loading={loading}
            columns={playerProgressHeaders}
            data={filteredData || []}
            pagination={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
              showPageSizeSelector: true,
            }}
          />
        ) : (
          <Spinner color={'inherit'} show dataTest="transition-spinner" />
        )}
      </Card>
      <ExportModule
        bundle="bottle_crush_player_progress"
        campaignId={params?.id}
        disabled={!!!filteredData?.length}
      />
    </>
  );
};

export default PlayerProgressTab;
