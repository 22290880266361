import { Card, Grid, Paragraph, Select } from '@hexa-ui/components';
import React, { useContext, useReducer, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';
import { apiDrupal } from '../../Api/Api';
import StyledAlert from '../../components/Alert/Alert';
import StyledButton from '../../components/Button/Button';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledSelect from '../../components/Select/Select';
import { AlertContext } from '../../contexts/alert.context';
import useTimeWindowHandler from '../../hook/useTimeWindowHandler';
import { TimeWindowType } from '../../store/dataTypes/timeWindow.type';
import { changeStateForm } from '../../utils/functions';

const defaultValues = {
  first_draw: {
    date: '',
    time: '',
  },
  last_draw: {
    date: '',
    time: '',
  },
  draw_date: {
    date: '',
    time: '',
  },
};

interface Error {
  description: string
  type: {
    first: boolean
    last: boolean
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'changed_field': {
      return {
        ...state,
        [action.field.id]: action.field.value,
      };
    }
  }
}
export default function TimeWindowForm({ ...props }) {
  const { addToast } = useContext(AlertContext);
  const [campaignDate, setCampaignDate] = useState<any>(null);
  const navigate = useNavigate();
  const { state } = useLocation();

  const [search, setSearch] = useState('');

  const timeWindow: TimeWindowType = state;

  const params = useParams();
  const [form, setForm] = useState<TimeWindowType>(undefined);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<Error>(
    {
      description: '',
      type: {
        first: false,
        last: false
      }
    }
  );
  const [loading, setLoading] = useState(false);
  const [campaignForm, dispatch] = useReducer(reducer, defaultValues);

  const { getAllTimeWindow, createTimeWindow, updateTimeWindow } = useTimeWindowHandler();

  const title = params.id ? 'Edit Time Window' : 'Generate time window';

  const [timewindowData, setTimewindowData] = useState<TimeWindowType[]>([]);

  React.useEffect(() => {
    if (!params.campaignId) {
      return;
    }

    getAllTimeWindow(params?.campaignId).then((value) => {
      let obj = value.data.data;

      setTimewindowData(obj);
    });

    apiDrupal.get('/campaigns/' + params.campaignId).then((response) => {
      const campaignDetailData = response.data.data.campaign;

      handleChange(
        {
          date: moment(campaignDetailData?.period.start).format('YYYY-MM-DD'),
          time: moment(campaignDetailData?.period.start).format('HH:mm'),
        },
        'first_draw'
      );

      handleChange(
        {
          date: moment(campaignDetailData?.period.end).format('YYYY-MM-DD'),
          time: moment(campaignDetailData?.period.end).format('HH:mm'),
        },
        'last_draw'
      );

      setCampaignDate({
        start: {
          date: moment(campaignDetailData?.period.start).format('YYYY-MM-DD'),
          time: moment(campaignDetailData?.period.start).format('HH:mm'),
        },
        end: {
          date: moment(campaignDetailData?.period.end).format('YYYY-MM-DD'),
          time: moment(campaignDetailData?.period.end).format('HH:mm'),
        },
      });
    });
  }, [params.campaignId]);

  const create = async () => {
    setLoading(true)
    const now = new Date(Date.now());

    const first_draw_date = new Date(campaignForm.first_draw.date + ' ' + campaignForm.first_draw.time)
    const last_draw_date = new Date(campaignForm.last_draw.date + ' ' + campaignForm.last_draw.time)

    if (
      validateFirstDrawDates(first_draw_date, last_draw_date) &&
      validateLastDrawDates(first_draw_date, last_draw_date)
    ) {
      await createTimeWindow(
        {
          first_draw_date:
            campaignForm.first_draw.date + ' ' + campaignForm.first_draw.time + ':00',
          last_draw_date: campaignForm.last_draw.date + ' ' + campaignForm.last_draw.time + ':00',
        },
        params.campaignId
      ).then((res) => {
        if (res?.data?.error) addToast({
          message: 'All prizes already have time window registred.',
          type: 'error',
        });
        else {
          setErrorMessage({
            description: '',
            type: {
              first: false,
              last: false
            }
          })
          setSuccessMessage('Time Window successfully generated.');
          setTimeout(() => navigate(-1), 2700);
        }

      }).finally(() => {
        setLoading(false)
      });
      return;
    }
    setErrorMessage(prevState =>
    ({
      ...prevState,
      description: 'The dates must be between the beginning and end of the campaign.'
    })
    );
    setLoading(false)
  };

  const update = async () => {
    let data = {
      target_time_window_id: form.target_time_window_id,
    };

    await updateTimeWindow(data, params.campaignId, timeWindow.id).then((res) => {
      setSuccessMessage('Time Window successfully updated.');
      setTimeout(() => navigate(-1), 200);
    });
  };

  React.useEffect(() => {
    console.table(form);
    console.table(timewindowData);
  }, [form, timewindowData]);

  //useEffect(() => {
  //  setCampaignDate({ start: { ...first_draw }, end: { ...last_draw } });
  //}, [first_draw, last_draw]);

  const handleChange = (e, id, validated = true) => {
    if (validated === null) return;
    else if (validated) {
      dispatch({
        type: 'changed_field',
        field: {
          value: e,
          id: id,
        },
      });
    } else
      dispatch({
        type: 'changed_field',
        field: {
          value: {
            time: '',
            date: '',
          },
          id: id,
        },
      });
  };

  const getDate = (dt: Date) => {
    var date = dt;
    var day = date.getUTCDate();
    var month = date.getMonth() + 1;
    var year = date.getFullYear();

    return [day, month, year].join('-');
  };

  const validateFirstDrawDates = (firstDate: Date, lastDate: Date) => {
    let campaignStartDate = new Date(campaignDate.start.date + ' ' + campaignDate.start.time);
    let campaignLastDate = new Date(campaignDate.end.date + ' ' + campaignDate.end.time);

    if ((!campaignForm.first_draw.date && campaignForm.first_draw.time) || (campaignForm.first_draw.date && !campaignForm.first_draw.time)) {
      addToast({
        message: 'The start date cannot have empty fields',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      return false;
    }

    if (firstDate < campaignStartDate) {
      addToast({
        message: 'The start date selected cannot be less than the campaign start date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      handleChange({ date: '', time: '' }, 'first_draw')
      return false;
    }

    if (firstDate >= lastDate || firstDate >= campaignLastDate) {
      addToast({
        message: 'The start date selected cannot be greater than or equal the campaign end date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          first: true
        }
      }))

      handleChange({ date: '', time: '' }, 'first_draw')
      return false;
    }
    setErrorMessage(prevState => ({
      ...prevState,
      type: {
        ...prevState.type,
        first: false
      }
    }))

    return true;
  };

  const validateLastDrawDates = (firstDate: Date, lastDate: Date) => {
    let campaignStartDate = new Date(campaignDate.start.date + ' ' + campaignDate.start.time);
    let campaignLastDate = new Date(campaignDate.end.date + ' ' + campaignDate.end.time);

    console.table([campaignStartDate, campaignLastDate, firstDate, lastDate]);

    if ((!campaignForm.last_draw.date && campaignForm.last_draw.time) || (campaignForm.last_draw.date && !campaignForm.last_draw.time)) {
      addToast({
        message: 'The end date cannot have empty fields',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      return false;
    }

    if (lastDate <= campaignStartDate || lastDate <= firstDate) {
      addToast({
        message: 'The end date cannot be less than or equal to the start date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      handleChange({ date: '', time: '' }, 'last_draw')
      return false;
    }
    if (lastDate > campaignLastDate) {
      addToast({
        message: 'The end date selected cannot be greater than the campaign end date.',
        type: 'error',
      });

      setErrorMessage(prevState => ({
        ...prevState,
        type: {
          ...prevState.type,
          last: true
        }
      }))

      handleChange({ date: '', time: '' }, 'last_draw')
      return false;
    }

    setErrorMessage(prevState => ({
      ...prevState,
      type: {
        ...prevState.type,
        last: false
      }
    }))

    return true;
  };

  return (
    <div>
      <Grid.Container type="fluid" style={{ gap: 20 }}>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle marginBottom="0.5rem" title={title} />
        </Grid.Item>
        {successMessage && (
          <StyledAlert type="success" message={successMessage} style={{ width: '100%' }} />
        )}
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ width: '100%' }}>
          {campaignDate && (
            <Card elevated="small" border="medium" css={{ width: '100%' }}>
              {!params.id ? (
                <>
                  <Grid.Container
                    type="fluid"
                    style={{
                      // display: width <= 1024 ? 'contents' : '',
                      padding: '1.5rem 0 0rem 0',
                    }}
                  >
                    <Grid.Item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ gap: '1rem', flexWrap: 'wrap', padding: '0px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          gap: '0.5rem',
                          alignItems: 'flex-end',
                          flexWrap: 'wrap',
                        }}
                      >
                        <StyledInput
                          required
                          size="large"
                          value={campaignForm.first_draw.date}
                          label="First Draw Date"
                          hint="When the first reward will be drawn"
                          placeholder="Insert the date"
                          hasError={errorMessage.type.first}
                          onChange={(e) =>
                            handleChange(
                              { ...campaignForm.first_draw, date: e.currentTarget.value },
                              'first_draw',
                            )
                          }
                          type="date"
                        />
                        <StyledInput
                          required
                          size="large"
                          value={campaignForm.first_draw.time}
                          placeholder="Insert the date"
                          type="time"
                          hasError={errorMessage.type.first}
                          onChange={(e) =>
                            handleChange(
                              { ...campaignForm.first_draw, time: e.currentTarget.value },
                              'first_draw'
                            )
                          }
                        />
                        <StyledInput
                          required
                          size="large"
                          value={campaignForm.last_draw.date}
                          type="date"
                          label="Last draw date"
                          hint="When the first reward will be drawn"
                          placeholder="Insert the date"
                          hasError={errorMessage.type.last}
                          onChange={(e) =>
                            handleChange(
                              { ...campaignForm.last_draw, date: e.currentTarget.value },
                              'last_draw'
                            )
                          }
                        />
                        <StyledInput
                          required
                          value={campaignForm.last_draw.time}
                          size="large"
                          placeholder="Insert the date"
                          type="time"
                          hasError={errorMessage.type.last}
                          onChange={(e) =>
                            handleChange(
                              { ...campaignForm.last_draw, time: e.currentTarget.value },
                              'last_draw'
                            )
                          }
                        />
                      </div>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-flex',
                        justifyContent: 'flex-end',
                        gap: '0.5rem',
                        padding: '20px',
                      }}
                    >
                      <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                        Cancel
                      </StyledButton>
                      <StyledButton
                        variant="primary"
                        isLoading={loading}
                        onClick={create}
                      >
                        Generate
                      </StyledButton>
                    </div>
                  </Grid.Item>
                </>
              ) : (
                <>
                  <Grid.Container
                    type="fluid"
                    style={{
                      //justifyContent: 'center',
                      padding: '1.5rem 0 1rem 0',
                    }}
                  >
                    <Grid.Item
                      xl={12}
                      lg={8}
                      md={7}
                      sm={12}
                      xs={12}
                      style={{ width: '100%', padding: '0px' }}
                    >
                      <Grid.Container style={{ gap: '1rem', margin: '0px' }}>
                        <Grid.Item>
                          <Paragraph weight="semibold">
                            Here it's possible to update a given time window prize with another time
                            window position.
                          </Paragraph>
                        </Grid.Item>
                        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <StyledInput
                            disabled
                            label="ID"
                            hint="The time window id."
                            value={timeWindow.id}
                          />
                        </Grid.Item>
                        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <StyledInput
                            disabled
                            label="Prize"
                            hint="The time window prize entity."
                            value={timeWindow.name}
                          />
                        </Grid.Item>
                        <Grid.Item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{
                            gap: '1rem',
                            margin: 0,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                          }}
                        >
                          <StyledInput
                            required
                            disabled
                            size="large"
                            type="date"
                            label="Draw Date"
                            defaultValue={moment(timeWindow.draw_date).format('YYYY-MM-DD')}
                            hint="When the first reward will be drawn"
                            placeholder="Insert the date"
                          />
                          <StyledInput
                            disabled
                            required
                            size="large"
                            defaultValue={moment(timeWindow.draw_date).format('hh:mm')}
                            placeholder="Insert the date"
                            type="time"
                          />
                        </Grid.Item>
                        <Grid.Item
                          xl={8}
                          lg={8}
                          md={8}
                          sm={8}
                          xs={8}
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <StyledSelect
                            onChange={(value: string) =>
                              changeStateForm(setForm, form, 'target_time_window_id', Number(value))
                            }
                            disabled={!!!timewindowData}
                            label="Change the prize with time window bellow:"
                            hint="change the prize of this current time window with the time window entity
                            selected at this field. Just not used time window will be loaded here."
                          >
                            {timewindowData
                              ?.filter((v: TimeWindowType) => v.id !== timeWindow.id)
                              .map((timewindow: TimeWindowType, index: number) => {
                                return (
                                  <Select.Option key={timewindow.id} value={timewindow.id}>
                                    {`${timewindow.name} (${timewindow.id})`}
                                  </Select.Option>
                                );
                              })}
                          </StyledSelect>
                        </Grid.Item>
                        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}></Grid.Item>
                      </Grid.Container>
                    </Grid.Item>
                  </Grid.Container>
                  <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div
                      style={{
                        width: '100%',
                        display: 'inline-flex',
                        justifyContent: 'flex-end',
                        gap: '0.5rem',
                        padding: '20px',
                      }}
                    >
                      <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                        Cancel
                      </StyledButton>
                      <StyledButton
                        disabled={
                          !params?.id &&
                            (loading ||
                              !campaignForm.first_draw.date ||
                              !campaignForm.first_draw.time ||
                              !campaignForm.last_draw.date ||
                              !campaignForm.last_draw.time)
                            ? true
                            : params?.id && !form?.target_time_window_id
                              ? true
                              : false
                        }
                        onClick={() => {
                          params?.id && update();
                        }}
                        variant="primary"
                      >
                        {!params.id ? 'Generate' : 'Update'}
                      </StyledButton>
                    </div>
                  </Grid.Item>
                </>
              )}
              {errorMessage.type.first || errorMessage.type.last ? (
                <StyledAlert style={{ margin: '1.8rem 1.5rem' }} type='error' message='The dates must be between the beginning and end of the campaign.' />
              ) : null}
            </Card>
          )}
        </Grid.Item>
        <StyledAlert
          type="warning"
          message={`This action cannot be undone.`}
          style={{ width: '100%' }}
        />
      </Grid.Container>
    </div>
  );
}
