import { Select, SelectProps } from '@hexa-ui/components';
import { Container } from './Select.styles';

const StyledSelect = (
    {
        width,
        ...props
    }: SelectProps & { ref?: React.ForwardedRef<HTMLButtonElement> },
    fowardedRef: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => {
    return (
        <Container width={width}>
            <Select.Root {...props} />
        </Container>
    );
};

export default StyledSelect;