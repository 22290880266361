import { Alert } from '@hexa-ui/components';
import { AlertProps } from '@hexa-ui/components/dist/declarations/src/lib/alert/Alert.types';
import { Container } from './Alert.styles';

const StyledAlert
    = (
        {
            css,
            style,
            width = '100%',
            ...props
        }: AlertProps & {
            width?: string
        }
    ): JSX.Element => {
        return (
            <Container css={{ width: width }}>
                <Alert {...props} css={{ width: '100%', ...css }} />
            </Container>
        );
    };

export default StyledAlert
    ;
