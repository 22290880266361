import { Card, Grid } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import useRankingHandler from '../../../hook/useRankingHandler';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { RankingsType } from '../../../store/dataTypes/ranking.type';
import RankingsHeaders from '../../json/columnHeaders/RankingHeaders.json';
import DirectExportModal from '../modules/DirectExportModal';

const RankingsTab: React.FC = () => {
  const params = useParams();
  const [RankingsData, setRankingsData] = useState<RankingsType[]>([]);
  const { getAllRanking, getCSVData } = useRankingHandler();

  const [filteredData, setFilteredData] = useState<RankingsType[]>(RankingsData);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [filteredRankingData, setFilteredRankingData] = useState<RankingsType[]>(RankingsData);

  WindowFocusHandler(async () => {
    setLoading(true);
    await getAllRanking(String(params?.id))
      .then((val) => {
        setRankingsData(val.data);
      })
      .finally(() => setLoading(false));
  });

  const prepareData = (data: RankingsType[]) => {
    return Object.values(data).map((item) => ({ ...item }));
  };

  const filterData = (data: RankingsType[], searchText: string) => {
    if (!searchText) return data;
    const lowercasedFilter = searchText.toLowerCase();
    return data.filter((item) => {
      return Object.values(item).some((value) =>
        String(value).toLowerCase().includes(lowercasedFilter)
      );
    });
  };

  const prepareAndFilterData = (data: RankingsType[], searchText: string) => {
    const preparedData = prepareData(data);
    return filterData(preparedData, searchText);
  };

  React.useEffect(() => {
    setFilteredRankingData(prepareAndFilterData(RankingsData, search));
  }, [RankingsData, search]);

  return (
    <>
      <Card elevated={'medium'} border={'large'}>
        <Grid.Item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            justifyContent: 'space-between',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              width={'100%'}
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e: any) => setSearch(e.currentTarget.value)}
              onClear={() => setSearch('')}
            />
          </div>
        </Grid.Item>
        {filteredData !== undefined ? (
          <StyledTable
            loading={loading}
            columns={RankingsHeaders}
            data={filteredRankingData || []}
            pagination={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
              showPageSizeSelector: true,
            }}
          />
        ) : (
          <Spinner color={'inherit'} show dataTest="transition-spinner" />
        )}
      </Card>
      <DirectExportModal
        campaignId={params?.id}
        disabled={Object.keys(RankingsData).length === 0}
        fetchData={getCSVData}
      />
    </>
  );
};

export default RankingsTab;
