import { Button, Card, Grid, Heading, Input } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../hook/useWindowDimensions';
import { AppDispatch, useAppSelector } from '../store';

import AutoComplete from '../components/AutoComplete/AutoComplete';
import { AlertContext } from '../contexts/alert.context';
import { CampaignType } from '../store/dataTypes';
import { BottleCrushType } from '../store/dataTypes/bottleCrush.type';
import { FETCH_CAMPAIGN_DETAILS } from '../store/stock/CampaignDetailsReducer';
import { FETCH_LEVEL, PATCH_LEVEL } from '../store/stock/LevelsReducer';

export default function EditLevel() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const { id, idLevel } = useParams();
  const { addToast } = useContext(AlertContext);
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const bottleCrushDetails: BottleCrushType = useAppSelector(
    (state) => state.campaignDetails.bottleCrush
  );
  const levelDetails: any = useAppSelector((state) => state.levels.details);

  const { width } = useWindowDimensions();
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_CAMPAIGN_DETAILS({ id: id }));
      await dispatch(FETCH_LEVEL({ campaign_id: id, id: idLevel }));
    }
    init();
  }, []);

  useEffect(() => {
    setForm(levelDetails);
  }, [levelDetails]);

  const handleSave = async () => {
    if (!form?.moves || !form?.images_quantity || !form.name) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      return;
    }

    setLoading(true);

    await dispatch(
      PATCH_LEVEL({
        name: form.name,
        moves: form.moves,
        images_quantity: form.images_quantity,
        id: idLevel,
        campaignId: id,
      })
    ).then(() => {
      navigate(-1);
      addToast({
        message: 'Level successfully updated.',
        type: 'success',
      });
    });
  };

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Edit Level
          </Heading>
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="small" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={9} lg={8} md={7} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    rowGap: '1rem',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 10,
                      padding: 10,
                      marginTop: 10,
                    }}
                  >
                    <Input
                      value={form?.name}
                      required
                      size="small"
                      placeholder=""
                      label="Name*"
                      width="400px"
                      onChange={(e) => {
                        let val = e.currentTarget.value;

                        setForm({
                          ...form,
                          name: val,
                        });
                      }}
                      hint="The name of the Mix and Match Level entity"
                    />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      gap: 10,
                      padding: 10,
                      flexDirection: 'column',
                    }}
                  >
                    <AutoComplete
                      onChange={(e) => null}
                      onClear={() => null}
                      value={campaignDetails?.name}
                      required
                      size="large"
                      label="Campaign"
                      hint="The campaing that the level will be associated with."
                      disabled
                    />
                    <AutoComplete
                      onChange={(e) => null}
                      onClear={() => null}
                      value={bottleCrushDetails?.name}
                      required
                      size="large"
                      label="Mix and Match"
                      hint="The Mix and Match that level will be associated with."
                      disabled
                    />
                    <Input
                      value={form?.level}
                      required
                      size="small"
                      label="Level*"
                      placeholder=""
                      disabled
                      hint="The level."
                    />
                    <Input
                      value={form?.moves}
                      required
                      size="small"
                      placeholder=""
                      label="Moves*"
                      hint="The moves."
                      onChange={(e) => {
                        let val = Number(e.currentTarget.value);
                        val = val < 0 ? 0 : val;
                        setForm({
                          ...form,
                          moves: val,
                        });
                      }}
                    />
                    <Input
                      value={form?.images_quantity}
                      required
                      size="small"
                      placeholder=""
                      label="Images quantity*"
                      width="100px"
                      hint="The images quantity."
                      onChange={(e) => {
                        let val = Number(e.currentTarget.value);
                        val = val < 0 ? 0 : val;

                        setForm({
                          ...form,
                          images_quantity: val,
                        });
                      }}
                    />

                    {/* <AutoComplete
                      onChange={(e) => null}
                      onClear={() => null}
                      onClickResult={(prize) => {
                        null;
                      }}
                      value="gabriel.sobreira@justdigital.com.br (446)"
                      editValue={'search'}
                      required
                      size="large"
                      label="Authored by"
                      hint="The user ID of author of the Mix and Match Level entity."
                    /> */}
                  </div>
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '10px',
                }}
              >
                <Button isLoading={loading} variant="primary" onClick={() => handleSave()}>
                  Save
                </Button>
                <Button
                  isLoading={loading}
                  variant="secondary"
                  onClick={() =>
                    navigate('/bees-games/manage-assets/campaign-details/' + id + '#levels')
                  }
                >
                  Cancel
                </Button>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
