import { Grid } from '@hexa-ui/components';
import moment from 'moment';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import InfoField from '../../../../components/InfoField/InfoField';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import WindowFocusHandler from '../../../../hook/windowFocusHandler';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { CampaignType } from '../../../../store/dataTypes';
import { FETCH_COUNTRIES } from '../../../../store/stock/CountryReducer';
import { GameSettingsContext } from '../../../../contexts/gameSettings.context';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}
const ReviewCampaign: React.FC<props> = ({ form }) => {
  const newCampaignData = useAppSelector((state) => state.campaignDetails.newCampaign);
  const countriesPromo = useAppSelector((state) => state.country.data);
  const [country, setCountry] = useState<string>('');
  const dispatch = useAppDispatch();

  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);
  const tournamentSelected = gameSettingsState.tournamentSelected;

  WindowFocusHandler(() => {
    async function init() {
      await dispatch(FETCH_COUNTRIES());
    }
    init();
  });

  useEffect(() => {
    setCountry(countriesPromo.find((c) => c.id === Number(form?.country))?.name || '');
  }, [countriesPromo]);

  return (
    <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <PageTitle
          title="Review campaign"
          hint="Before creating the campaign, double-check its information."
        />
      </Grid.Item>
      <Grid.Item
        xl={3}
        lg={3}
        md={3}
        sm={3}
        xs={3}
        style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
      >
        <InfoField title="Name" text={form?.name} />
        <InfoField
          title="Game"
          text={
            form?.type?.target_id == 'bottle_crush'
              ? 'mix and match'
              : form?.type?.target_id.replace('_', ' ')
          }
        />
        <InfoField title="Brand" text={form?.brand?.name} />
      </Grid.Item>
      <Grid.Item
        xl={3}
        lg={3}
        md={3}
        sm={3}
        xs={3}
        style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
      >
        <InfoField title="Country" text={newCampaignData?.country} />
        <InfoField title="Campaign type" text={form?.category?.name} />
        <InfoField
          title="Period"
          text={`${moment(form?.period?.start).format('DD/MM/YYYY')} - ${moment(
            form?.period?.end
          ).format('DD/MM/YYYY')}`}
        />
      </Grid.Item>
      {form?.type.target_id === 'soccer_competition' ? (
        <>
          <Grid.Item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
          >
            <InfoField title="Tournament Name" text={form?.tournament_name} />
            <InfoField
              title="Max minutes to guess before match starts"
              text={`${form?.max_minutes_to_guess} minutes`}
            />
            <InfoField title="Rank type" text={form?.rank_type} />
          </Grid.Item>
          <Grid.Item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
          >
            <InfoField title="Guess by" text={form?.guess_by} />
            <InfoField title="Guess type" text={form?.guess_type} />
          </Grid.Item>
        </>
      ) : null}
    </Grid.Container>
  );
};
export default ReviewCampaign;
