import { Paragraph } from '@hexa-ui/components';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { DigitalPrizeCodeType } from '../dataTypes/digitalPrizeCode.type';

//ASYNC THUNKS
export const FETCH_DIGITAL_PRIZE_CODE = createAsyncThunk(
  'fetch/digital_prize_code',
  async (data: any, thunkAPI) => {
    const response = await apiDrupal.get(
      `/digital-prize-codes/${data.campaign_id}?page=${data.page}&limit=${data.limit}&search=${data.search}`
    );
    return response.data.data;
  }
);

export const IMPORT_DIGITAL_PRIZE_CODE_CHECK = createAsyncThunk(
  'fetch/import_digital_prize_code_check',
  async (
    data: {
      id: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `${base_url}/hub/v2/competition/${data.id}/import/cm_base_digital_prize_code/status?format=json`
    );
    return response.data;
  }
);

export const IMPORT_DIGITAL_PRIZE_CODE = createAsyncThunk(
  'fetch/import_digital_prize_code',
  async (
    data: {
      idCampaign: string;
      fileName: string;
      file: any;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      base_url +
      '/file/upload/cm_import/cm_base_digital_prize_code/field_file?_format=json&campaign=' +
      data.idCampaign,
      data.file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': 'aittachment; filename="' + data.fileName + '"',
        },
      }
    );
    return response.data;
  }
);

export const IMPORT_DIGITAL_PRIZE_CODE_ENTITY = createAsyncThunk(
  'fetch/import_digital_prize_code_entity',
  async (
    data: {
      name: string;
      bundle: string;
      field_added_to_queue: number;
      field_base_campaign: number;
      field_file: number;
      field_file_delimiter: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      `${base_url}/hub/v2/competition/${data.field_base_campaign}/import/cm_base_digital_prize_code?_format=json`,
      data
    );
    return response.data;
  }
);

interface IDigitalPrizeCodeState {
  data: DigitalPrizeCodeType[];
  check: boolean;
  total: number;
  error: any;
}

const initialState: IDigitalPrizeCodeState = {
  data: [],
  check: false,
  total: 0,
  error: undefined,
};

export const Slice = createSlice({
  name: 'DigitalPrizeCode',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_DIGITAL_PRIZE_CODE
    builder.addCase(FETCH_DIGITAL_PRIZE_CODE.pending, (state, action) => { });
    builder.addCase(FETCH_DIGITAL_PRIZE_CODE.fulfilled, (state, action) => {
      let codes = action.payload;

      //mount the status icon with type of status...
      const statusComponent = (text, color) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Paragraph
              style={{ borderRadius: '50%', backgroundColor: color, width: 10, height: 10 }}
            ></Paragraph>
            {text}
          </div>
        );
      };

      //Add HTML IMG element with url and add it to the status field.
      codes.results.map((a) => {
        a.status = statusComponent(
          a.status == 1 ? 'Avaliable' : 'Used',
          a.status == 1 ? '#00a838' : '#e4423f'
        );
      });

      state.data = codes.results;
      state.total = codes.total;
    });
    builder.addCase(FETCH_DIGITAL_PRIZE_CODE.rejected, (state, action) => {
      state.error = action.error;
    });

    // IMPORT_DIGITAL_PRIZE_CODE_CHECK
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_CHECK.pending, (state, action) => {
      state.check = true;
    });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_CHECK.fulfilled, (state, action) => {
      state.check = true;
    });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_CHECK.rejected, (state, action) => {
      state.error = action.error;
      state.check = false;
    });

    // IMPORT_DIGITAL_PRIZE_CODE
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE.pending, (state, action) => { });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE.rejected, (state, action) => {
      state.error = action.error;
    });

    // IMPORT_DIGITAL_PRIZE_CODE_ENTITY
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_ENTITY.pending, (state, action) => { });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_ENTITY.fulfilled, (state, action) => {
      // state.data = action.payload;
    });
    builder.addCase(IMPORT_DIGITAL_PRIZE_CODE_ENTITY.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
