import { Pagination, PaginationProps, Table, TableProps } from '@hexa-ui/components';
import { Container, ContainerPagination } from './Table.styles';

interface ContainerProps {
    margin?: string
    maxWidth?: string
    className?: string
}

const StyledTable = <T,>(
    {
        margin,
        maxWidth,
        className,
        ...props
    }: TableProps<T> & ContainerProps & { ref?: React.ForwardedRef<HTMLButtonElement> },
    fowardedRef: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => {
    return (
        <Container margin={margin} maxWidth={maxWidth} className={className}>
            <Table {...props} />
        </Container>
    );
};

export const StyledPagination = (
    {
        ...props
    }: PaginationProps
): JSX.Element => {
    return (
        <ContainerPagination>
            <Pagination {...props} />
        </ContainerPagination>
    );
};

export default StyledTable;
