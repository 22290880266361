import { IconButton, Modal, Tooltip } from '@hexa-ui/components';
import { Download } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import StyledHeading, { StyledParagraph } from '../../../components/Heading/Typography';
import StyledInput from '../../../components/Input/Input';

interface props {
  campaignId: string;
  disabled?: boolean;
  fetchData: (id: string, start: number, length: number) => Promise<string>;
}
const DirectExportModal: React.FC<props> = ({ campaignId, disabled, fetchData }) => {
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formExport, setFormExport] = useState({
    start: 0,
    length: 600,
  });

  const handleExport = async () => {
    setLoading(true);

    const csvData = await fetchData(String(params?.id), formExport.start, formExport.length);
    const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    const link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', 'rankings.csv');
    document.body.appendChild(link);
    link.click();

    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <FlexContainer display="flex" flexDirection="row" gap="0.5rem" margin="0.5rem 0">
      <Tooltip placement="top" text="Generate CSV Report">
        <Modal.Root
          actions={
            <FlexContainer
              width="100%"
              display="inline-flex"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Modal.Action>
                <StyledButton variant="primary" onClick={handleExport} isLoading={loading}>
                  Export
                </StyledButton>
              </Modal.Action>
              <Modal.Action>
                <StyledButton variant="secondary" onClick={() => setIsModalOpen(false)}>
                  Cancel
                </StyledButton>
              </Modal.Action>
            </FlexContainer>
          }
          open={isModalOpen}
          trigger={
            <IconButton
              disabled={disabled}
              icon={Download}
              size="large"
              variant="secondary"
              onClick={() => setIsModalOpen(true)}
            />
          }
          variant="overlay"
          destructive="off"
        >
          <FlexContainer
            display="flex"
            gap="0.5rem"
            justifyContent="space-evenly"
            flexDirection="column"
            minHeight="350px"
          >
            <StyledHeading size="H3" title="Do you want to export?" />
            <StyledParagraph type="body" title="This action cannot be undone." />
            <StyledInput
              label="Start position"
              value={formExport.start}
              hint="The start position to export."
              placeholder=""
              required
              type="number"
              min={0}
              onChange={(e) => {
                let val = Number(e.currentTarget.value);
                val = val < 0 ? 0 : val;

                setFormExport({
                  ...formExport,
                  start: val,
                });
              }}
            />
            <StyledInput
              label="Quantity records"
              value={formExport.length}
              hint="The quantity records to export."
              placeholder=""
              type="number"
              min={1}
              onChange={(e) => {
                let val = Number(e.currentTarget.value);
                val = val < 1 ? 1 : val;

                setFormExport({
                  ...formExport,
                  length: val,
                });
              }}
            />
          </FlexContainer>
        </Modal.Root>
      </Tooltip>
    </FlexContainer>
  );
};

export default DirectExportModal;
