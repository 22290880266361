import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { TimeWindowType } from '../dataTypes/timeWindow.type';

//ASYNC THUNKS
export const FETCH_TIME_WINDOW = createAsyncThunk('fetch/timewindow', async (thunkAPI) => {
  const response = await apiDrupal.get('/time-window?page=0&page_size=50000');
  return response.data.data;
});

interface ITimeWindowState {
  data: TimeWindowType[];
  error: any;
}

const initialState: ITimeWindowState = {
  data: [],
  error: undefined,
};

export const Slice = createSlice({
  name: 'TimeWindow',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_TIME_WINDOW
    builder.addCase(FETCH_TIME_WINDOW.pending, (state, action) => { });
    builder.addCase(FETCH_TIME_WINDOW.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(FETCH_TIME_WINDOW.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
