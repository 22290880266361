import { Button, ButtonProps } from '@hexa-ui/components';
import { Container } from './Button.styles';

const StyledButton = (
    {
        children,
        ...props
    }: ButtonProps & { ref?: React.ForwardedRef<HTMLButtonElement> },
    fowardedRef: React.ForwardedRef<HTMLButtonElement>
): JSX.Element => {
    return (
        <Container>
            <Button {...props}>
                {children}
            </Button>
        </Container>
    );
};

export default StyledButton;