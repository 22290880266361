import styled from 'styled-components';

interface FIleUploaderProps {
    maxHeight?: string | number
    minHeight?: string | number
    width: string | number
}

export const Container = styled.div<FIleUploaderProps>`
    min-width: 316px;
    max-height: ${props => props.maxHeight};
    min-height: ${props => props.minHeight};
    width: ${props => props.width};

    section > h2 {
        font-size: 1rem;
        word-wrap: break-word;
    }

    p { 
        margin-bottom: 0.25rem;
    }

    @media (min-resolution: 1.50dppx){
        min-width: 260px;
        p, button {
                font-size: 0.75rem;
                line-height: 1.7;
        }

        section > h2 {
            font-size: 1rem;
        }
    }
`