import { Card, Grid, Image } from '@hexa-ui/components';
import { Edit2, Gift, Megaphone, Plus, Trash2 } from '@hexa-ui/icons';
import { Box } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { apiDrupal } from '../../Api/Api';
import StyledAlert from '../../components/Alert/Alert';
import StyledButton from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown';
import InfoField, { InfoButtonText, InfoCardTitle } from '../../components/InfoField/InfoField';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AppDispatch } from '../../store';
import { PrizeType } from '../../store/dataTypes/prize.type';
import { base_url } from '../../utils/constants';
import '../manageassets/ManageAssets.css';

export const PrizeDetails = (): JSX.Element => {
  const [prize, setPrize] = useState<PrizeType>(null);
  const [dropdown, setDropdown] = useState(false);
  const [valueDropdown, setValueDropdown] = useState('');
  const [imgUrl, setImgUrl] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const divRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleDropdownVisible = () => {
    setDropdown(true);
  };

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      apiDrupal.get('/prizes/' + params.id).then((response) => {
        setPrize(response.data?.data?.prize);
        setImgUrl(base_url + response.data?.data?.prize?.field_image?.url);
      });
    }
  }, [params]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  const handleDelete = async () => {
    await apiDrupal.delete('/prizes/' + params.id).then((response) => {
      setSuccessMessage('Prize successfully deleted.');
      setTimeout(() => navigate('/bees-games/manage-assets#prizes'), 2700);
    });
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '8px',
        }}
      >
        <PageTitle title="Prize Details" />
        <div>
          <StyledButton
            icon={Plus}
            leading
            size="medium"
            variant="primary"
            onClick={() => {
              handleDropdownVisible();
            }}
          >
            Add Asset
          </StyledButton>

          {dropdown && (
            <div
              ref={divRef}
              className="manage-assets-dropdown"
              onClick={() => {
                setDropdown(false);
              }}
            >
              <Dropdown
                optionArray={[
                  {
                    path: '/bees-games/manage-assets/create-campaign',
                    label: 'Create campaign',
                    value: 'campaign',
                    icon: <Megaphone />,
                  },
                  {
                    path: '/bees-games/manage-assets/create-prize',
                    label: 'Create prize',
                    value: 'prize',
                    icon: <Gift />,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      {successMessage && (
        <Box mb={2}>
          {' '}
          <StyledAlert type="success" message={successMessage} style={{ width: '100%' }} />
        </Box>
      )}
      <Card elevated={'small'} border={'small'} css={{ padding: '1rem 0' }}>
        <Grid.Container type="fluid">
          <Grid.Item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: '1rem' }}
          >
            <InfoCardTitle title={prize?.name} />
            <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
              <InfoButtonText
                size="small"
                css={{ backgroundColor: '#E9F1FF', borderRadius: '24px', padding: '0.2rem 0.8rem' }}
              >
                {prize ? (prize.status ? 'Publish' : 'Ready to publish') : ''}
              </InfoButtonText>
              <InfoButtonText
                icon={Edit2}
                iconPosition="leading"
                onClick={() =>
                  navigate('/bees-games/manage-assets/prize-details/edit/' + params.id)
                }
              >
                Edit
              </InfoButtonText>
              <ModalDelete
                handleDelete={handleDelete}
                trigger={
                  <InfoButtonText
                    style={{ marginTop: '0.25rem' }}
                    icon={Trash2}
                    iconPosition="leading"
                  >
                    Delete
                  </InfoButtonText>
                }
                title="Delete prize?"
                description="In case you do, you will need to manually delete any time window that is currently
              using this prize."
              />
            </div>
          </Grid.Item>
        </Grid.Container>
        <br />
        <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
          <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
            <InfoField title="Voucher ID" text={prize?.sku} />
          </Grid.Item>
          <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
            <InfoField title="Category" text={prize?.field_type} />
          </Grid.Item>
        </Grid.Container>
        <br />
        <Grid.Container type="fluid" style={{ gap: '1rem' }}>
          <Grid.Item xl={4} lg={4} md={4} sm={4} xs={12}>
            <InfoField title="Description" text={prize?.description} />
          </Grid.Item>
          <Grid.Item xl={6} lg={6} md={6} sm={6} xs={12} style={{ padding: '0px' }}>
            <InfoField
              title="Prize Thumbnail"
              padding="0px"
              text={
                <div
                  style={{
                    border: '2px dashed #367DE8',
                    borderRadius: '8px',
                    padding: '1rem',
                    width: '100%',
                  }}
                >
                  {imgUrl && (
                    <Image
                      src={imgUrl}
                      height={'200px'}
                      width={'auto'}
                      aspectRatio={1}
                      variant="fill"
                    />
                  )}
                </div>
              }
            />
          </Grid.Item>
        </Grid.Container>
      </Card>
    </div>
  );
};
