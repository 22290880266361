type responseJsonApi = {
    data: any[]
    included: any[]
    jsonapi: any
    links: any
    meta: {
        count: number
    }
    uuidEntity: string
}

export const getSelectedAttributes = (props: string[], item: any) => {
    const attributesKeys = Object?.keys(item)
    let newObj = {}
    if (props.length > 0) {
        props?.forEach((prop, i) => {
            if (attributesKeys.indexOf(prop) >= 0) {
                newObj = {
                    ...newObj,
                    [prop]: item[prop]
                }
            }
        })
        return newObj
    }
    else return item
}

export const getJsonApiData = (response: responseJsonApi, included = false, includedFields: string[] = []) => {
    let newArr = response.data.map(item => ({ ...item?.attributes, uuidEntity: item.id }))
    if (included) {
        newArr = newArr.map((item, i) => {
            const selectedItems = response?.included[i] && getSelectedAttributes(includedFields, response?.included[i]?.attributes)
            return {
                ...item,
                ...selectedItems
            }
        }
        )
    }

    return {
        data: newArr,
        total: response.meta.count,
        pagination: response.links
    }
}