import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { ExportType } from '../dataTypes/export.type';

//ASYNC THUNKS
export const EXPORT_LIST = createAsyncThunk(
  'index/export',
  async (
    data: {
      bundle: string;
      campaignId: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `${base_url}/hub/v2/competition/${data.campaignId}/exporters/${data.bundle}`
    );
    return response.data.data;
  }
);

export const EXPORT_LIST_NO_CAMPAIGN = createAsyncThunk(
  'index/export',
  async (
    data: {
      bundle: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `${base_url}/hub/v2/exporters/${data.bundle}`
    );
    return response.data.data;
  }
);

export const EXPORT_CREATE_CAMPAIGN = createAsyncThunk(
  'create/export',
  async (
    data: {
      bundle: string;
      campaignId: string;
      start: number;
      length: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      `${base_url}/hub/v2/competition/${data.campaignId}/exporters/${data.bundle}?start=${data.start}&length=${data.length}`,
      {}
    );
    return response.data.data;
  }
);

export const EXPORT_CREATE = createAsyncThunk(
  'create/export',
  async (
    data: {
      bundle: string;
      start: number;
      length: number;
      country: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      `${base_url}/hub/v2/exporters/${data.bundle}?start=${data.start}&length=${data.length}&field_country=${data.country}`,
      {
      }
    );
    return response.data.data;
  }
);

export const EXPORT_DOWNLOAD = createAsyncThunk(
  'download/export',
  async (data: { file_id: number; name: string }, thunkAPI) => {
    const response = await apiDrupal.get(`${base_url}/api/v1/file/${data.file_id}/download`, {
      responseType: 'blob',
    });
    return response.data;
  }
);

export const EXPORT_DELETE = createAsyncThunk(
  'delete/export',
  async (
    data: {
      bundle: string;
      campaignId: string;
      exportId: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.delete(
      `${base_url}/hub/v2/competition/${data.campaignId}/exporters/${data.bundle}`,
      {
        data: { id: data.exportId },
      }
    );
    return response.data.data;
  }
);

export const EXPORT_DELETE_NO_CAMPAIGN = createAsyncThunk(
  'delete/export',
  async (
    data: {
      bundle: string;
      exportId: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.delete(
      `${base_url}/hub/v2/exporters/${data.bundle}`,
      {
        data: { id: data.exportId },
      }
    );
    return response.data.data;
  }
);

interface IExportState {
  data: ExportType[];
  total: number;
  error: any;
  isQueued: boolean;
  bundle: string;
}

const initialState: IExportState = {
  data: [],
  total: 0,
  error: null,
  isQueued: false,
  bundle: '',
};

export const Slice = createSlice({
  name: 'Exports',
  initialState,
  reducers: {
    changeExportBundle: (state, action) => {
      state.bundle = action.payload;
    },
  },

  extraReducers: (builder) => {
    //EXPORT_LIST
    builder.addCase(EXPORT_LIST.pending, (state, action) => {
      state.isQueued = false;
    });
    builder.addCase(EXPORT_LIST.fulfilled, (state, action) => {
      let aux: ExportType[] = action.payload;

      aux.forEach((element: ExportType, index: number) => {
        aux[index] = {
          ...aux[index],
          created: moment(element.created).format('DD/MM/YYYY - HH:mm'),
          changed: moment(element.changed).format('DD/MM/YYYY - HH:mm'),
        };
      });

      state.isQueued = !!aux.find((v: ExportType) => v.export_status === 'queued');

      state.data = aux;
    });
    builder.addCase(EXPORT_LIST.rejected, (state, action) => {
      state.error = action.error;
    });

    //EXPORT_CREATE
    builder.addCase(EXPORT_CREATE.pending, (state, action) => { });
    builder.addCase(EXPORT_CREATE.fulfilled, (state, action) => {
      state.isQueued = true;
    });
    builder.addCase(EXPORT_CREATE.rejected, (state, action) => {
      state.error = action.error;
    });

    //EXPORT_DELETE
    builder.addCase(EXPORT_DELETE.pending, (state, action) => { });
    builder.addCase(EXPORT_DELETE.fulfilled, (state, action) => {
      state.error = undefined;
    });
    builder.addCase(EXPORT_DELETE.rejected, (state, action) => {
      state.error = action.error;
      state.error = true;
    });
  },
});

export const { changeExportBundle } = Slice.actions;
export default Slice.reducer;
