import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DisplayFilter } from '../../../components/CheckboxFilter/CheckboxFilter';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { AppDispatch, useAppSelector } from '../../../store';
import { PrizeType } from '../../../store/dataTypes';
import { FETCH_MANAGEASSETS_PRIZE } from '../../../store/stock/manageAssetsReducer';
import { FilterByCheckbox } from '../../../utils/filterByCheckbox';
import { changeState } from '../../../utils/functions';
import PrizeHeaders from '../../json/columnHeaders/PrizeHeadersManage.json';

function ManageAssetsPrizesTab() {
  const prizeData: PrizeType[] = useAppSelector((state) => state.manageAssets.prizeData);
  const totalPages: number = useAppSelector((state) => state.manageAssets.totalPrizeData);
  const [filteredData, setFilteredData] = useState(prizeData)
  const [total, setTotal] = useState(totalPages);
  const [checkboxListFilters, setCheckboxListFilters] = useState({});
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [requestData, setRequestData] = useState({
    page: 0,
    limit: 25,
    search: ''
  });

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  WindowFocusHandler(async () => {
    const requestData = {
      page,
      limit,
      search,
    };
    setRequestData(requestData);
    handleSearchAll(requestData);
  });

  useEffect(() => {
    if (prizeData?.length > 0) {
      setFilteredData(prizeData)
      setTotal(totalPages)
    }
  }, [prizeData]);

  useEffect(() => {
    const data = FilterByCheckbox(prizeData, checkboxListFilters, { category: 'field_type', status: 'status' })
    setFilteredData(data)
    setTotal(data?.length)
  }, [checkboxListFilters]);

  const handlePagination = (page, limit) => {
    requestData.page = page - 1;
    requestData.limit = limit;
    requestData.search = search;
    setRequestData(requestData);
    setLimit(limit);
    handleSearchAll(requestData);
  };

  const handleSearch = (value) => {
    setSearch(value);

    if (value.length == 0 || value.length >= 2) {
      setPage(1);
      requestData.page = 0;
      setRequestData(requestData);
      requestData.search = value;
      handleSearchAll(requestData);
    }
  };

  const handleSearchAll = async (requestData) => {
    setLoading(true);
    await dispatch(FETCH_MANAGEASSETS_PRIZE(requestData)).finally(() => setLoading(false));
  };
  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 250),
    []
  );

  const handleChange = (value) => {
    changeState(setSearch, value);
    debouncedSearch(value);
  };

  const handleCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks)
  }

  const deleteCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks)
  }

  const FiltersContainer = {
    width: "100%",
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem',
    gap: '0.5rem'
  }

  const ContainerFilterTags = styled('div', {
    padding: '0px $space$6 $space$6'

  })

  return (
    <>
      <div style={FiltersContainer}>
        {/*<CheckboxFilter
          selectedCheckboxes={checkboxListFilters}
          onChange={handleCheckboxFilter}
          menuTabs={[
            { acessor: 'category', label: 'Category', key: 'category' },
            { acessor: 'status', label: 'Status', key: 'status' }
          ]}
          tabItems={{
            category: categoryOptions,
            status: statusOptions
          }}
        />*/}
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={e => handleChange(e.currentTarget.value)}
            onClear={(e) => {
              setSearch('')
              handleSearch('');
            }}
          />
        </div>
      </div>
      {Object.keys(checkboxListFilters).length > 0 ? <ContainerFilterTags>
        <DisplayFilter checkFilters={checkboxListFilters} deleteFilter={deleteCheckboxFilter} />
      </ContainerFilterTags> : null}
      <StyledTable
        loading={loading}
        columns={PrizeHeaders}
        data={filteredData || []}
        pagination={false}
        onRow={(data, index) => ({
          onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
            navigate('/bees-games/manage-assets/prize-details/' + data.id);
          },
        })}
      />
      <FlexContainer
        border=""
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'0.5rem 1rem'}
        gap={'0.5rem'}
        width="100%"
        flexWrap="wrap"
      >
        <StyledPagination
          pageSize={limit}
          total={total}
          current={page}
          onChange={handlePagination}
        />
      </FlexContainer>
    </>
  );
}

export default ManageAssetsPrizesTab;
