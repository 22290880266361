import { Select } from '@hexa-ui/components';
import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

type optionType = {
  path: string;
  label: string;
  value: string;
  icon: JSX.Element;
};
const Dropdown = ({ optionArray }: { optionArray: optionType[] }) => {
  const [dropdown, setDropdown] = useState(false);
  const handleDropdownVisible = () => {
    setDropdown(true);
  };

  const { Option } = Select;

  const divRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div>
      {optionArray.map((optionData: optionType, index: number) => {
        return (
          <Link to={optionData.path} key={index + optionData.path}>
            <Option value={optionData.value}>
              {optionData.icon}
              {optionData.label}
            </Option>
          </Link>
        );
      })}
    </div>
  );
};

export default Dropdown;
