import styled from 'styled-components';

interface ContainerProps {
    margin?: string
    maxWidth?: string
}

export const Container = styled.div<ContainerProps>`
    margin: ${props => props.margin};
    max-width: ${props => props.maxWidth};

    &.deleteAll {
      div:not(td div):nth-last-of-type(3):not(:has(> li)) {
        height: 15px;
        margin-top: -25px;
        margin-bottom: 10px;
      }

      div:not(td div):nth-last-of-type(3):empty {
        display: none;
      }

    }

    &.deleteAllWizard {
      div:not(td div):nth-last-of-type(3):not(:has(> li)) {
        height: 15px;
        margin-top: -40px;
        margin-bottom: 10px;
      }

      div:not(td div):nth-last-of-type(3):empty {
        display: none;
      }

    }

    div:has(> table) {
        max-height: 60vh;
        height: 100%;
    }

    @media (min-resolution: 1.50dppx){
        th > div > h5 {
            font-size: 0.75rem;
        }

        td {
            font-size: 0.69rem;
        }

        p, button, nav, li {
            font-size: 0.75rem;
            height: 2.2rem;
        }

        div {
            font-size: 0.75rem;
        }

        div > p {
            margin-top: 0.30rem;
        }

        svg {
            height: 1.1rem;
            width: 1.1rem;
        }

        div:has(> table) {
            max-height: 40vh;
            height: 100%;
        }
    }
`

export const ContainerPagination = styled.div`
    @media (min-resolution: 1.50dppx){
        p, button, nav, li {
            font-size: 0.75rem;
            height: 2.2rem;
        }

        div {
            font-size: 0.75rem;
        }

        div > p {
            margin-top: 0.30rem;
        }

        svg {
            height: 1.1rem;
            width: 1.1rem;
        }
    }
`
