import { Card, Grid, Input, Paragraph, Quantifier } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import { changeStateForm } from '../../utils/functions';
import StyledToggle from '../../components/Toggle/Toggle';
import useChancesResetHandler from '../../hook/useChancesResetHandler';
import moment from 'moment';

export default function ChancesReset() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { addToast } = useContext(AlertContext);
  const { addChances } = useChancesResetHandler();
  const [form, setForm] = useState({
    campaignId: state.id,
    chances: 0,
    reset_date: "",
    repeat: 1,
    intervalHours: 24,
    intervalMinutes: 0,
  });
  const [repeat, setRepeat] = useState(false)
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSend = async () => {
    if (!form?.reset_date) {
      setError(true);
      addToast({
        message: 'Please fill in all fields to continue!',
        type: 'error',
      });
    } else {
      setError(false);
      setLoading(true);

      try {
        let resetDate = moment.utc(form.reset_date);
        let totalRepeats = repeat ? 1 + form.repeat : 1;

        for (let i = 0; i < totalRepeats; i++) {
          if (i > 0) {
            resetDate.add(form.intervalHours, 'hours').add(form.intervalMinutes, 'minutes');
          }

          const response = await addChances({
            ...form,
            reset_date: resetDate.format(),
          });

          if (response?.data?.errors) {
            addToast({
              message: 'An error occurred while creating the chance.',
              type: 'error',
            });
            break;
          } else {
            addToast({
              message: `Chance successfully created.`,
              type: 'success',
            });
          }
        }

        navigate(-1);
      } catch (error) {
        addToast({
          message: 'An error occurred while creating the player.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <PageTitle marginBottom="0.5rem" title="Chances Reset" hint="" />
      </Grid.Item>
      <Card elevated="medium" border="medium" css={{ width: '100%' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'start',
            padding: '1.5rem 0 1rem 0',
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container
              type="fluid"
              style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: 'column' }}
            >
              <StyledInput
                required
                size="large"
                label="Campaign"
                hint="The current campaign. "
                width="50%"
                disabled={true}
                defaultValue={state?.campaignName}
              />
              <Input
                value={form.reset_date}
                type="datetime-local"
                size="large"
                label="Chances reset date (start)*"
                hint="Chances reset date (start date). Default timezone is GMT 00:00"
                placeholder="Insert the date"
                required
                hasError={error && !form?.reset_date}
                errorText="Reset date is required."
                onChange={(e) => {
                  setForm({
                    ...form,
                    reset_date: e.currentTarget.value,
                  })
                }}
              />
              <div>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Chances*
                </Paragraph>
                <Paragraph
                  type=""
                  size="xsmall"
                  css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}
                >
                  The number of times players can still play after reset.
                </Paragraph>
                <div style={{ width: '25%' }}>
                  <Quantifier
                    onValueChange={(e) => changeStateForm(setForm, form, 'chances', e)}
                    value={form?.chances}
                    min={0}
                    size="medium"
                  />
                </div>
              </div>
              <div>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Repeat settings
                </Paragraph>
                <StyledToggle
                  title="Enable repeat interval."
                  description="Check this option to create a repeat interval."
                  defaultChecked={false}
                  onChange={() => setRepeat(!repeat)}
                  value={repeat}
                />
              </div>
            { repeat && <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem'}}>
              <div>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Number of times to repeat*
                </Paragraph>
                <Paragraph
                  type=""
                  size="xsmall"
                  css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}
                >
                  Number of times to repeat.
                </Paragraph>
                <div style={{ width: '25%' }}>
                  <Quantifier
                    onValueChange={(e) => changeStateForm(setForm, form, 'repeat', e)}
                    value={form.repeat}
                    min={0}
                    defaultValue={form.repeat}
                    size="medium"
                  />
                </div>
              </div>
              <div>
              <Paragraph type="label" size="basis" weight="semibold">
                Interval settings
              </Paragraph>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Hours*
                </Paragraph>
                <Paragraph
                  type=""
                  size="xsmall"
                  css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}
                >
                  Hours.
                </Paragraph>
                <div style={{ width: '25%' }}>
                  <Quantifier
                    onValueChange={(e) => changeStateForm(setForm, form, 'intervalHours', e)}
                    value={form.intervalHours}
                    min={0}
                    defaultValue={form.intervalHours}
                    size="medium"
                  />
                </div>
              </div>
              <div>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Minutes*
                </Paragraph>
                <Paragraph
                  type=""
                  size="xsmall"
                  css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}
                >
                  Minutes.
                </Paragraph>
                <div style={{ width: '25%' }}>
                  <Quantifier
                    onValueChange={(e) => changeStateForm(setForm, form, 'intervalMinutes', e)}
                    value={form?.intervalMinutes}
                    min={0}
                    size="medium"
                  />
                </div>
              </div>
            </div>}
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Card>
    </>
  );
}
