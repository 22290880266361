import { Grid } from '@hexa-ui/components';
import { SetStateAction } from 'react';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { CampaignType } from '../../../../store/dataTypes';
import SettingsTabSelect from './extra/SettingsTabSelect';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}
const StepGameSettings: React.FC<props> = ({ form, setForm }) => {
  return (
    <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <PageTitle
          title="Customize game"
          hint="Users can win prizes for playing the game in your campaign."
        />
      </Grid.Item>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
        <SettingsTabSelect game={form?.type?.target_id} form={form} setForm={setForm} />
      </Grid.Item>
    </Grid.Container>
  );
};
export default StepGameSettings;
