import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface PlayerProgressHandleInterface {
  getAllPlayerProgress: (campaignId) => Promise<any>,
}

export default function usePlayerProgressHandler(): PlayerProgressHandleInterface {
  const getAllPlayerProgress = async (campaignId) => {
    return await apiDrupal.get(`${base_url}/hub/v2/competition/${campaignId}/mix-and-match/player-progress`)
      .then(response => response.data)
      .catch(error => error.response);

  }
  return { getAllPlayerProgress }
}
