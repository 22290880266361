import { Card, LoadingDots } from '@hexa-ui/components';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import FlexContainer from '../../components/FlexContainer';
import PageTitle from '../../components/PageTitle/PageTitle';
import { countryType } from '../../components/layout/Layout';
import { AlertContext } from '../../contexts/alert.context';
import EditCampaignModule from '../../modules/EditCampaign.module';
import { AppDispatch, useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes/campaign.type';
import {
  FETCH_CAMPAIGN_DETAILS,
  PATCH_CAMPAIGN_DETAILS,
} from '../../store/stock/CampaignDetailsReducer';

const EditCampaign: React.FC = () => {
  const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
  const { addToast } = useContext(AlertContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [form, setForm] = useState<CampaignType>(null);
  const campaignData = useAppSelector((state) => state.campaignDetails.data);
  const bottleCrush = useAppSelector((state) => state.campaignDetails.bottleCrush);

  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!state) {
      return;
    }

    dispatch(FETCH_CAMPAIGN_DETAILS({ id: state?.campaignDetailData?.id })).finally(() =>
      setLoading(false)
    );
  }, [state]);

  async function handleSaveForm() {
    if (
      !form ||
      !form?.name ||
      !form?.brand?.name ||
      !form?.brand?.target_id ||
      !form?.category?.name ||
      !form?.category?.target_id ||
      !form?.period?.start ||
      !form?.period?.end ||
      (form?.type?.target_id === 'bottle_crush' &&
        (!form?.name_mix_and_match || !form?.player_progress_expiration_time))
    ) {
      addToast({
        message: 'Please fill in all fields to continue.',
        type: 'error',
      });
      return;
    }

    setLoading(true);

    await dispatch(PATCH_CAMPAIGN_DETAILS(form))
      .then((res: any) => {
        if (res?.error) {
          addToast({
            message: 'Error saving data.',
            type: 'error',
          });
        } else {
          addToast({
            message: 'Data successfully saved.',
            type: 'success',
          });
          navigate(
            `/bees-games/manage-assets/campaign-details/${state?.campaignDetailData?.id}#campaign`,
            {
              state: {
                toast: {
                  message: 'Data successfully saved.',
                  type: 'success',
                },
              },
            }
          );
        }
      })
      .catch(() => {
        addToast({
          message: 'Error saving data.',
          type: 'warning',
        });
      }).finally(() => setLoading(false));
  }

  useEffect(() => {
    setForm({
      ...campaignData,
      country: countriesPromo?.find((v) => v.name === campaignData?.country)?.id?.toString(),
      player_progress_expiration_time: bottleCrush?.player_progress_expiration_time,
      newgame: bottleCrush?.new_game,
      name_mix_and_match: bottleCrush?.name,
    });
  }, [campaignData]);

  return (
    <div>
      <PageTitle size="H2" title="Edit campaign" />
      <br />
      <Card elevated="small" border="medium" css={{ padding: '1rem 0px' }}>
        <br />
        {!loading ? (
          <>
            <EditCampaignModule form={form} setForm={setForm} isEdit />
            <div
              style={{
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                width: '100%',
                padding: '1rem',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton
                variant="primary"
                type="submit"
                isLoading={loading}
                onClick={() => handleSaveForm()}
              >
                Save
              </StyledButton>
            </div>
          </>
        ) : (
          <FlexContainer display="flex" justifyContent="center" margin="4rem">
            <LoadingDots />
          </FlexContainer>
        )}
      </Card>
    </div>
  );
};

export default EditCampaign;
