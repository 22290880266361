import { Grid, IconButton, Select } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutoComplete from '../../../../../components/AutoComplete/AutoComplete';
import StyledButton from '../../../../../components/Button/Button';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import StyledTable from '../../../../../components/Table/Table';
import { AlertContext } from '../../../../../contexts/alert.context';
import { DeleteModalContext } from '../../../../../contexts/deleteModal.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import useWindowDimensions from '../../../../../hook/useWindowDimensions';
import useWindowScale from '../../../../../hook/useWindowScale';
import WindowFocusHandler from '../../../../../hook/windowFocusHandler';
import { AppDispatch, useAppSelector } from '../../../../../store';
import { CampaignType, PrizeType } from '../../../../../store/dataTypes';
import {
  FETCH_PRIZES_CAMPAIGN,
  PATCH_PRIZES_CAMPAIGN,
  STORE_PRIZES_CAMPAIGN,
} from '../../../../../store/stock/PrizeCampaignReducer';
import StyledRadio from '../../../../../components/Radio/StyledRadio';
import SearchDropdown from '../../../../../components/SearchDropdown/SearchDropdown';
import StyledSelect from '../../../../../components/Select/Select';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
  setDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedPrize: Dispatch<SetStateAction<PrizeType>>;
}
const TabInstantPrize: React.FC<props> = ({
  form,
  setForm,
  setDeleteModalOpen,
  setSelectedPrize,
}) => {
  const { addToast } = useContext(AlertContext);
  const isAugmentedScale = useWindowScale();
  const { width } = useWindowDimensions();

  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);
  const { deleteModalState, setDeleteModalState } = useContext(DeleteModalContext);
  const tournamentSelected = gameSettingsState.tournamentSelected;

  const [radioValue, setRadioValue] = useState('match');
  const [selectedValue, setSelectedValue] = useState('');
  const [filteredPrizeData, setFilteredPrizeData] = useState<PrizeType[]>();
  const [loading, setLoading] = useState(false);
  const [searchDropdownValue, setSearchDropdownValue] = useState('');

  const dispatch = useDispatch<AppDispatch>();

  const prizesData = useAppSelector((state) => state.prizeCampaign.data);

  const handleSavePrize = async () => {
    if (
      !gameSettingsState?.prizeList.addPrize ||
      !gameSettingsState?.prizeList.addPrize?.quantity
    ) {
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      return;
    }

    if (!gameSettingsState?.prizeList.addPrize?.prize) {
      addToast({
        message: 'Please, select a prize to continue.',
        type: 'error',
      });
      return;
    }

    setLoading(true);
    gameSettingsState.prizeList.editMode
      ? await dispatch(
          PATCH_PRIZES_CAMPAIGN({
            campaignId: String(form.id),
            id: gameSettingsState?.prizeList.addPrize.id,
            prizeData: gameSettingsState?.prizeList.addPrize,
          })
        )
          .then(() => {
            addToast({
              message: 'prize successfuly updated.',
              type: 'success',
            });
            handleClear();
          })
          .catch(() => {
            addToast({
              message: 'An error occurred while editing the prize.',
              type: 'error',
            });
          })
          .finally(() => setLoading(false))
      : await dispatch(
          STORE_PRIZES_CAMPAIGN({
            id: String(form?.id),
            prizeData: { ...gameSettingsState?.prizeList.addPrize, type: form.type.target_id },
          })
        )
          .then(() => {
            addToast({
              message: 'prize successfuly added.',
              type: 'success',
            });
            handleClear();
          })
          .catch(() =>
            addToast({
              message: 'An error occurred while adding the prize.',
              type: 'error',
            })
          )
          .finally(() => setLoading(false));
  };

  const handleClear = () => {
    setGameSettingsState({
      ...gameSettingsState,
      prizeList: {
        search: null,
        addPrize: {
          id: null,
          campaign: null,
          prize: null,
          field_spin_prize_name: null,
          quantity: 1,
          field_spin_prize_bg_color: null,
          field_spin_prize_text_color: null,
          winner_redirect_link: null,
          prize_description: null,
        },
        editMode: false,
      },
    });
    handleSearchAll();
  };

  const handleSearchAll = () => {
    setLoading(true);
    dispatch(
      FETCH_PRIZES_CAMPAIGN({
        page: 0,
        limit: 25,
        id: String(form?.id),
        search: '',
      })
    ).finally(() => setLoading(false));
  };

  const changeGameSettingsState = (key: string, value: any) => {
    setGameSettingsState({
      ...gameSettingsState,
      prizeList: {
        ...gameSettingsState?.prizeList,
        addPrize: {
          ...gameSettingsState?.prizeList?.addPrize,
          [key]: value,
        },
      },
    });
  };

  WindowFocusHandler(() => {
    if (!form?.id) {
      addToast({
        message: 'Please create a campaign to continue.',
        type: 'error',
      });
      return;
    }

    setLoading(true);

    dispatch(
      FETCH_PRIZES_CAMPAIGN({
        id: String(form?.id),
        page: 0,
        limit: 0,
        search: '',
      })
    ).finally(() => setLoading(false)),
      null,
      [];
  });

  useEffect(() => {
    let data: PrizeType[] = InsertActions(prizesData);
    setFilteredPrizeData(data);
  }, [prizesData]);

  const InsertActions = (data: PrizeType[]) => {
    let aux: PrizeType[] = [];

    //Iserir botoes aqui

    return aux;
  };

  useEffect(() => {
    setLoading(deleteModalState.loading);
  }, [deleteModalState.loading]);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const handleSearch = (searchTerm, type) => {
    const dataList = tournamentSelected[type] || [];
    const filteredData = dataList.filter((item) => item.name.includes(searchTerm));
    return Promise.resolve(filteredData);
  };

  return (
    <Grid.Item
      xl={12}
      lg={12}
      md={12}
      sm={12}
      xs={12}
      style={{ rowGap: '1rem', flexDirection: 'column', padding: '0px' }}
    >
      <Grid.Item
        xl={8}
        lg={8}
        md={12}
        sm={12}
        xs={12}
        style={{
          width: '100%',
          gap: '1rem',
          margin: '0 0 2rem 0',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          position: 'relative',
          display: 'flex',
        }}
      >
        <StyledInput
          value={form?.name}
          size="large"
          label="Campaign"
          hint="The current campaign. "
          disabled={true}
        />
        <StyledInput
          value={tournamentSelected.tournament}
          size="large"
          label="Tournament"
          hint="The tournament the matches and groups will be loaded."
          disabled={true}
        />
        <StyledSelect
          value={selectedValue}
          onChange={handleSelectChange}
          size="large"
          width={'200px'}
          label="Release method*"
          hint="The release method. "
          placeholder="select"
        >
          <Select.Option value="automatic">Automatic</Select.Option>
          <Select.Option value="manual">Manual</Select.Option>
        </StyledSelect>
        <StyledRadio
          label="Generate Instant Prizes By"
          hint="How the instant prizes will be generated?"
          defaultValue="match"
          options={[
            { id: 'match', label: 'Match', value: 'match' },
            { id: 'group', label: 'Group', value: 'group' },
          ]}
          onChange={(e) => {
            setRadioValue(e);
            setSearchDropdownValue('');
          }}
        />
        {radioValue === 'match' ? (
          <SearchDropdown
            onSearch={(term) => handleSearch(term, 'matches')}
            onChange={(e) => setSearchDropdownValue(e.currentTarget.value)}
            value={searchDropdownValue}
            required
            size="large"
            label="Match*"
            hint="The instant prize match entity."
            placeholder="Start typing to search"
          />
        ) : (
          <SearchDropdown
            onSearch={(term) => handleSearch(term, 'groups')}
            onChange={(e) => setSearchDropdownValue(e.currentTarget.value)}
            value={searchDropdownValue}
            required
            size="large"
            label="Group*"
            hint="The tournament's group in which the matches of the instant prizes will go."
            placeholder="Start typing to search"
          />
        )}
        <AutoComplete
          onChange={(e) => {
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                search: e.currentTarget.value,
              },
            });
          }}
          onClear={() => {
            changeGameSettingsState('prize', undefined);
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                search: '',
                addPrize: {
                  ...gameSettingsState.prizeList.addPrize,
                  prize: null,
                },
              },
            });
          }}
          onClickResult={(prize) => {
            setGameSettingsState({
              ...gameSettingsState,
              prizeList: {
                ...gameSettingsState.prizeList,
                addPrize: {
                  ...gameSettingsState.prizeList.addPrize,
                  prize: prize?.id,
                },
                search: prize.name,
              },
            });
          }}
          value={gameSettingsState?.prizeList?.search || ''}
          required
          size="large"
          label="Prize*"
          hint="Prizes are registered in the Prizes page"
          placeholder="Start typing to search"
        />

        <StyledInput
          value={gameSettingsState?.prizeList.addPrize?.quantity || ''}
          type="number"
          required
          size="large"
          label="Quantity*"
          hint="How many units of this prize are available"
          placeholder="Insert your text here"
          min={0}
          onKeyDown={(e) => {
            ['-', '+', '.', ','].includes(e.key) && e.preventDefault();
          }}
          onChange={(e) => {
            let v = e.currentTarget.value;
            v = v.includes('-') || v === '0' ? '1' : v;
            changeGameSettingsState('quantity', v);
          }}
        />
        <FlexContainer
          display="flex"
          justifySelf="flex-end"
          flexDirection="row"
          gap="0.5rem"
          position="absolute"
          bottom={0}
          right={'8px'}
        >
          <StyledButton
            leading
            disabled={loading}
            icon={Plus}
            css={{
              justifySelf: 'flex-end',
            }}
            size="medium"
            onClick={() => handleSavePrize()}
          >
            {gameSettingsState.prizeList.editMode ? 'Save' : 'Add prize'}
          </StyledButton>
          {gameSettingsState.prizeList.editMode && (
            <StyledButton
              variant="secondary"
              onClick={() => {
                setGameSettingsState({
                  ...gameSettingsState,
                  prizeList: {
                    ...gameSettingsState.prizeList,
                    editMode: false,
                  },
                });
                handleClear();
              }}
            >
              Cancel
            </StyledButton>
          )}
        </FlexContainer>
      </Grid.Item>
      <StyledTable
        maxWidth={width < 1200 ? '' : isAugmentedScale ? '58.3vw' : ''}
        data={filteredPrizeData || []}
        tableWidth="100%"
        loading={loading || deleteModalState.loading ? true : false}
        columns={[
          {
            Header: 'Group',
            accessor: 'group',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
          {
            Header: 'Match',
            accessor: 'match',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
          {
            Header: 'Prize',
            accessor: 'prize',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
          {
            Header: 'Quantity',
            accessor: 'quantity',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
          {
            Header: 'Release method',
            accessor: 'release_method',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
          {
            Header: 'Operations links',
            accessor: 'operationsLinks',
            disableSortBy: false,
            style: {
              width: '5%',
            },
          },
        ]}
      />
    </Grid.Item>
  );
};
export default TabInstantPrize;
