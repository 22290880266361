import { Card, Grid, Heading, Select } from '@hexa-ui/components';
import { useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledInput from '../../components/Input/Input';
import StyledButton from '../../components/Button/Button';
import { CampaignType } from '../../store/dataTypes';
import { useAppSelector } from '../../store';
import StyledCheckbox from '../../components/Checkbox/StyledCheckbox';
import StyledSelect from '../../components/Select/Select';
import useWindowScale from '../../hook/useWindowScale';
import StyledToggle from '../../components/Toggle/Toggle';

export default function EditRanking() {
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);

  const navigate = useNavigate();
  const { id } = useParams();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');

  const StyledPaddding = styled('div', {
    paddingBottom: '1rem',
  });

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const matches = ['Group 1', 'Group 2', 'Group 3'];

  return (
    <>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Edit Ranking Config
          </Heading>
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="small" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={9} lg={8} md={7} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <StyledPaddding>
                    <StyledInput
                      size="large"
                      label="Name*"
                      width={'300px'}
                      hint="The name of the winners settings."
                      placeholder="Insert you text here"
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledInput
                      value={campaignDetails?.name}
                      size="large"
                      width={'300px'}
                      label="Campaign*"
                      hint=" The campaign that the settings will be associated with."
                      disabled
                    />
                  </StyledPaddding>
                  <StyledPaddding>
                    {matches.map((match, index) => (
                      <StyledCheckbox
                        key={match}
                        id={match}
                        title={match}
                        label={index === 0 ? 'Groups' : undefined}
                        hint={
                          index === 0
                            ? 'The groups to be used in generating the ranking.'
                            : undefined
                        }
                      />
                    ))}
                  </StyledPaddding>
                  <StyledPaddding>
                    <StyledSelect
                      width={'300px'}
                      label="Languages"
                      value={selectedLanguage}
                      onChange={handleLanguageChange}
                    >
                      <Select.Option value="en">English</Select.Option>
                      <Select.Option value="br">Portuguese</Select.Option>
                      <Select.Option value="fr">French</Select.Option>
                      <Select.Option value="de">German</Select.Option>
                    </StyledSelect>
                  </StyledPaddding>
                  <StyledToggle
                    title="Ranking is published ?"
                    description="Indicates whether this ranking is published."
                    value={false}
                    onChange={() => null}
                  />
                  <StyledToggle
                    title="Winners is published ?"
                    description="Indicates whether the winners related to this ranking is published."
                    value={false}
                    onChange={() => null}
                  />
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  gap: '0.5rem',
                  padding: '10px',
                }}
              >
                <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                  Save
                </StyledButton>
                <StyledButton
                  variant="secondary"
                  isLoading={loading}
                  onClick={() =>
                    navigate(`/bees-games/manage-assets/campaign-details/${id}#rankingsbolao`)
                  }
                >
                  Cancel
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </>
  );
}
