import { useState } from 'react';

export default function useWindowScale() {
    const hasWindow = typeof window !== 'undefined';

    function isWindowOSScale() {
        if (hasWindow) return window.devicePixelRatio >= 1.50;
        return false
    }

    const [isAugmentedScale] = useState(isWindowOSScale());

    return isAugmentedScale
}