import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    '@media (min-resolution: 1.50dppx)': {
        input: {
            height: '2rem'
        },

        svg: {
            height: '1rem',
            width: '1rem'
        },

        'svg:nth-child(2)': {
            height: '0.813rem',
            width: '0.813rem'
        }
    }
})