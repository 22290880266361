import { Checkbox, Paragraph, TextLink } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

type LinkedCheckboxProps = {
  id: string;
  labelText: string;
  linkText: string;
  linkHref: string;
  onCheckedChange?: (checked: boolean) => void;
};

const CheckBoxContainer = styled('div', {
  display: 'flex',
});

const LinkedCheckbox = ({id, labelText, linkText, linkHref, onCheckedChange}: LinkedCheckboxProps) => {
  return (
    <CheckBoxContainer>
        <Checkbox id={id} onCheckedChange={onCheckedChange}/>
        <Paragraph htmlFor={id}>
            {labelText}
            <TextLink
                colorOption="blue"
                hasUnderline={false}
                size="basis"
                href={linkHref}
                target="_blank"
            >
                {linkText}
            </TextLink>
        </Paragraph>
    </CheckBoxContainer>
  );
}

export default LinkedCheckbox
