import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { CampaignType, ManageAssetsType, PrizeType } from '../dataTypes';

//ASYNC THUNKS
export const FETCH_MANAGEASSETS_CAMPAIGN = createAsyncThunk(
  'fetch/manageasset_campaign',
  async (
    data: {
      page?: number;
      limit?: number;
      search?: string;
      country?: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `/campaigns?page=${data.page}&limit=${data.limit}&country=${data.country}&search${data.search && '=' + data.search
      }`
    );

    return response.data;
  }
);

export const FETCH_MANAGEASSETS_PRIZE = createAsyncThunk(
  'fetch/manageasset_prize',
  async (
    data: {
      page?: number;
      limit?: number;
      search?: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `/prizes?page=${data.page}&limit=${data.limit}&search=${data.search}`
    );

    return response.data;
  }
);

interface IManageAssetsState {
  data: ManageAssetsType;
  prizeData: PrizeType[];
  campaignData: CampaignType[];
  totalCampaignData: number;
  totalPrizeData: number;
  error: any;
}

const initialState: IManageAssetsState = {
  data: undefined,
  prizeData: undefined,
  campaignData: undefined,
  totalCampaignData: 0,
  totalPrizeData: 0,
  error: undefined,
};

export const Slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_MANAGEASSETS_CAMPAIGN
    builder.addCase(FETCH_MANAGEASSETS_CAMPAIGN.pending, (state, action) => {
      state.campaignData = [];
    });
    builder.addCase(FETCH_MANAGEASSETS_CAMPAIGN.fulfilled, (state, action) => {
      state.totalCampaignData = Number(action.payload.data.total_items);
      let aux: CampaignType[] = Object.values(action.payload.data.campaigns);

      for (let index = 0; index < aux.length; index++) {
        const element: CampaignType = aux[index];

        aux[index].statusDetail = { text: element.status ? 'published' : 'unpublished', color: '' }
        aux[index].status = (
          <div
            style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', flexDirection: 'row' }}
          >
            <div
              style={{
                width: '8px',
                height: '8px',
                borderRadius: '10rem',
                backgroundColor: element.status ? '#047AF1' : '#414141',
              }}
            />
            <div>{element.status ? 'Published' : 'Unpublished'}</div>
          </div>
        );
        aux[index].fieldbrand = element.brand.name;
        aux[index].fieldperiod = `Start: ${moment(element.period?.start).format(
          'DD/MM/YYYY'
        )}\nEnd: ${moment(element.period?.end).format('DD/MM/YYYY')}`;
        aux[index].field_author = element.author.email;
        aux[index].game = element?.type?.target_id
          ?.replace(/_/g, ' ')
          .replace('bottle crush', 'mix and match');
      }

      aux = aux
        .sort(
          (a, b) => Number(moment(a.changed).format('X')) - Number(moment(b.changed).format('X'))
        )
        .reverse()
        //RETIRAR JOGOS AINDA NAO IMPLEMENTADOS
        .filter(
          (campaign: CampaignType) =>
            campaign.type.target_id !== 'soccer_competition'
        );

      state.campaignData = aux;
    });
    builder.addCase(FETCH_MANAGEASSETS_CAMPAIGN.rejected, (state, action) => {
      state.error = action.error;
    });

    //FETCH_MANAGEASSETS_PRIZE
    builder.addCase(FETCH_MANAGEASSETS_PRIZE.pending, (state, action) => { });
    builder.addCase(FETCH_MANAGEASSETS_PRIZE.fulfilled, (state, action) => {
      state.totalPrizeData = Number(action.payload.data.total_items);

      let aux: PrizeType[] = Object.values(action.payload.data.prizes);

      for (let index = 0; index < aux.length; index++) {
        const element: PrizeType = aux[index];

        aux[index].preview = (
          <img src={base_url + element.field_image.url} width={50} height={50} />
        );
        aux[index].status = element.status === true ? 'Published' : 'Unpublished';
      }

      aux = aux
        .sort(
          (a, b) => Number(moment(a.changed).format('X')) - Number(moment(b.changed).format('X'))
        )
        .reverse();

      state.prizeData = aux;
    });
    builder.addCase(FETCH_MANAGEASSETS_PRIZE.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
