import styled from 'styled-components';

interface ContainerProps {
    marginbottom?: string
}

export const Container = styled.div<ContainerProps>`
    margin-bottom: ${props => props.marginbottom};
    @media (min-resolution: 1.50dppx){
        h2 {
                font-size: 1.5rem;
                line-height: 2rem;
        }

        h1 {
            font-size: 1.80rem;
        }

        p {
            font-size: 0.75rem;
            line-height: 1.1rem;
        }
    }
`