import { Card, Grid, Heading, Paragraph } from '@hexa-ui/components';
import { Target } from '@hexa-ui/icons';
import { SetStateAction, useContext, useEffect } from 'react';
import roleta from '../../../../assets/Roleta.png';
import card from '../../../../assets/cards.png';
import penalty from '../../../../assets/game_card_penalty.png';
import MixMatch from '../../../../assets/mixMatch.jpg';
import spinWheelIMG from '../../../../assets/spin_wheel.png';
import cardVirtualCup from '../../../../assets/thumb-Virtual-Cup.jpg';
import beeRun from '../../../../assets/beeRun.jpg';
import Carousel from '../../../../components/Carousel/Carousel';
import FlexContainer from '../../../../components/FlexContainer';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import { GameSettingsContext } from '../../../../contexts/gameSettings.context';
import useWindowScale from '../../../../hook/useWindowScale';
import { CampaignType } from '../../../../store/dataTypes';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}
const StepGameSelection: React.FC<props> = ({ form, setForm }) => {
  const isAugmentedScale = useWindowScale();

  const CardScale = {
    height: isAugmentedScale ? '190px' : '320px',
  };

  const CardInfosScale = {
    transform: isAugmentedScale ? 'scale(0.7)' : 'scale(1)',
    margin: isAugmentedScale ? '10px 0px' : '40px 48px',
  };

  const { reset } = useContext(GameSettingsContext);

  useEffect(() => {
    reset();
  }, [form]);

  return (
    <Grid.Container type="fluid">
      <Grid.Item
        md={12}
        xs={12}
        lg={12}
        sm={12}
        xl={12}
        style={{ height: 'auto', flexDirection: 'column' }}
      >
        <PageTitle
          marginBottom="1rem"
          title="Select a game"
          hint="To participate in the campaign, users will play the game you choose. Each game
          has its own requirements and rules."
        />
      </Grid.Item>
      <Grid.Item
        md={12}
        xs={12}
        lg={12}
        sm={12}
        xl={12}
        style={{
          maxWidth: '1140px',
        }}
      >
        <Carousel show={3}>
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'spinning_wheel',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={spinWheelIMG}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Spinning Wheel
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Luck game in which the player must spin a roulette wheel, containing one or more
                prizes.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${roleta})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'spinning_wheel' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'spinning_wheel'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'penalty_kick',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={penalty}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Penalty Kick
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Penalty is a game of luck where the challenge is to select a goal point and aim to
                convert the penalty.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${penalty})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'penalty_kick' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'penalty_kick'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'bottle_crush',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={MixMatch}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Mix and Match
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Mix & Match is a puzzle based on swapping two adjacent elements on the gameboard to
                combine at least three matching tiles.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${MixMatch})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'bottle_crush' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'bottle_crush'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'instant_card',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={card}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Instant Card
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Instant Card is a game of luck, in which the challenge is to choose the winning card
                among 3 or more options.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${card})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'instant_card' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'instant_card'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'virtual_cup_mug',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={cardVirtualCup}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Virtual Cup / Mug
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Virtual Cup is a game of luck where the consumer will perform an action and wait for
                the Cup to fill (win) or overflow (lose).
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${cardVirtualCup})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'virtual_cup_mug' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'virtual_cup_mug'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>

          {/* <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'soccer_competition',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={card}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Soccer Competition
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Soccer Competition a game of luck, in which the challenge is to choose the winning
                card among 3 or more options.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${card})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'soccer_competition' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'soccer_competition'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card> */}
          <Card
            onClick={() => {
              setForm({
                ...form,
                type: {
                  target_id: 'bee_run',
                },
              });
            }}
            border="medium"
            elevated="minimal"
            css={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              height: '350px',
              textAlign: 'center',
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: '#FFF',
              borderRadius: '24px',
              ...CardScale,
            }}
          >
            <FlexContainer
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="center"
              background={beeRun}
              zIndex={999}
              position="absolute"
              margin="40px 48px"
              {...CardInfosScale}
            >
              <Target size="large" style={{ color: '#FEFE55' }} />
              <Paragraph size="small" css={{ color: '#FFF' }} style={{ opacity: '80%' }}>
                {' '}
                Create{' '}
              </Paragraph>
              <Heading
                size="H3"
                css={{ color: '#FFF' }}
                style={{ fontWeight: '600', whiteSpace: 'nowrap' }}
              >
                Bee Run
              </Heading>
              <br />
              <Paragraph
                size="xsmall"
                alignment="center"
                weight="normal"
                css={{ color: '#C0C0C0' }}
              >
                Bee Run is a skill-based game where players must overcome obstacles to accumulate points on ranking.
              </Paragraph>
            </FlexContainer>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundImage: `url(${beeRun})`,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
                borderRadius: '24px',
                zIndex: 3,
                filter: form?.type?.target_id === 'bee_run' ? 'brightness(55%)' : null,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  background:
                    form?.type?.target_id === 'bee_run'
                      ? 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 35%, rgba(0,0,0,0.5200945626477541) 100%)'
                      : 'rgba(0, 0, 0, 0.8)',
                  borderRadius: '24px',
                }}
              ></div>
            </div>
          </Card>
        </Carousel>
      </Grid.Item>
    </Grid.Container>
  );
};

export default StepGameSelection;
