import { Button, Card, Grid, Heading, Input } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AlertContext } from '../../contexts/alert.context';
import useWindowDimensions from '../../hook/useWindowDimensions';
import { AppDispatch } from '../../store';
import { PlayerType } from '../../store/dataTypes/player.type';
import { FETCH_PLAYERS_UPDATE } from '../../store/stock/PlayersReducer';
import { changeStateForm } from '../../utils/functions';

export default function EditPlayer() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const params = useParams();
  const { state } = useLocation();

  const { width } = useWindowDimensions();
  const [form, setForm] = useState<PlayerType>(undefined);

  const { addToast } = useContext(AlertContext);

  const handleEdit = async () => {
    if (!form?.name || !form?.poc || !form?.quantity) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      return;
    }

    await dispatch(
      FETCH_PLAYERS_UPDATE({
        idCampaign: params?.campaignId,
        id: params?.id,
        name: form?.name,
        poc: form?.poc,
        quantity: form?.quantity,
      })
    ).finally(() => navigate(-1));
  };

  useEffect(() => {
    setForm(state);
  }, []);

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Edit player
          </Heading>
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="small" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'center',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={12} lg={8} md={7} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    gap: '1rem',
                    margin: 0,
                    display: 'flex',
                    flexDirection: 'row',
                    padding: '20px 10px',
                  }}
                >
                  <Input
                    defaultValue={state?.name}
                    value={form?.name}
                    required
                    size="large"
                    label="Player name*"
                    placeholder="Insert your text here"
                    onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                  />
                  <Input
                    defaultValue={state?.campaign}
                    value={form?.campaign}
                    required
                    size="large"
                    label="Campaign*"
                    placeholder="Insert your text here"
                    onChange={(e) =>
                      changeStateForm(setForm, form, 'campaign', e.currentTarget.value)
                    }
                    disabled
                  />
                  <Input
                    defaultValue={state?.poc}
                    value={form?.poc}
                    required
                    size="large"
                    label="POC*"
                    placeholder="Insert your text here"
                    onChange={(e) => changeStateForm(setForm, form, 'poc', e.currentTarget.value)}
                  />
                  <Input
                    defaultValue={state?.quantity}
                    value={form?.quantity}
                    required
                    size="large"
                    label="Quantity*"
                    placeholder="Insert your text here"
                    type="number"
                    onKeyDown={(e) => ['-', '+', '.', ','].includes(e.key) && e.preventDefault()}
                    onChange={(e) => {
                      let v =
                        Number(e.currentTarget.value) <= 0 ? 1 : Number(e.currentTarget.value);
                      changeStateForm(setForm, form, 'quantity', v);
                    }}
                  />
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                }}
              >
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigate(
                      `/bees-games/manage-assets/campaign-details/${params?.campaignId}/#players`
                    )
                  }
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => handleEdit()}>
                  Save
                </Button>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
