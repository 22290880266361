import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PrizeType } from '../dataTypes/prize.type';
import { apiDrupal } from '../../Api/Api';

//ASYNC THUNKS
export const FETCH_PRIZE = createAsyncThunk('fetch/prizecreation', async (id: string, thunkAPI) => {
  const response = await apiDrupal.get('/prize-creation/' + id);
  return response.data;
});

export const PATCH_PRIZE = createAsyncThunk(
  'post/prizecreation',
  async (
    data: {
      id: string;
      name: string;
      voucherId: string;
      quantity: number;
      category: string;
      description: string;
      image: any;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.put('/prize-creation' + data.id);
    return response.data;
  }
);

interface IPrizeCreationState {
  data: PrizeType;
  error: any;
}

const initialState: IPrizeCreationState = {
  data: undefined,
  error: undefined,
};

export const Slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_PRIZE
    builder.addCase(FETCH_PRIZE.pending, (state, action) => {});
    builder.addCase(FETCH_PRIZE.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(FETCH_PRIZE.rejected, (state, action) => {
      state.error = action.error;
    });

    //PATCH_PRIZE
    builder.addCase(PATCH_PRIZE.pending, (state, action) => {});
    builder.addCase(PATCH_PRIZE.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(PATCH_PRIZE.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
