import { Card, Grid, LoadingDots, TextButton, Toggle, Tooltip } from '@hexa-ui/components';
import { ArrowRight, Edit2, HelpCircle, Trash2 } from '@hexa-ui/icons';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer';
import InfoField, { InfoButtonText, InfoCardTitle } from '../../../components/InfoField/InfoField';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledToggle from '../../../components/Toggle/Toggle';
import { AlertContext } from '../../../contexts/alert.context';
import { AppDispatch, useAppSelector } from '../../../store';
import { CampaignType } from '../../../store/dataTypes';
import {
  DELETE_CAMPAIGN_DETAILS,
  FETCH_CAMPAIGN_DETAILS,
} from '../../../store/stock/CampaignDetailsReducer';

const CampaignTab: React.FC = () => {
  const { addToast } = useContext(AlertContext);
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const bottleCrush = useAppSelector((state) => state.campaignDetails.bottleCrush);

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_CAMPAIGN_DETAILS({ id: params.id }));
    }
    init();
  }, [id]);

  async function handleDelete() {
    if (!id) {
      return;
    }
    await dispatch(DELETE_CAMPAIGN_DETAILS({ id: Number(id) })).finally(() => {
      addToast({
        message: 'Campaign successfuly deleted',
        type: 'success',
      });
      navigate('/bees-games/manage-assets');
    });
  }

  return campaignDetailData ? (
    <Card elevated={'medium'} border={'large'} css={{ padding: '2rem 0' }}>
      <FlexContainer
        width="100%"
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem"
        flexWrap="wrap"
      >
        <InfoCardTitle title={campaignDetailData.name} />
        <FlexContainer display="inline-flex" gap="0.5rem">
          <InfoButtonText
            size="small"
            css={{ backgroundColor: '#E9F1FF', borderRadius: '24px', padding: '0.25rem 0.5rem' }}
          >
            {campaignDetailData?.status}
          </InfoButtonText>
          <InfoButtonText
            icon={Edit2}
            iconPosition="leading"
            onClick={() =>
              navigate(
                '/bees-games/manage-assets/campaign-details/' + campaignDetailData?.id + '/edit',
                {
                  state: { campaignDetailData },
                }
              )
            }
          >
            Edit
          </InfoButtonText>
          <ModalDelete
            handleDelete={() => handleDelete()}
            trigger={
              <InfoButtonText icon={Trash2} iconPosition="leading">
                Delete
              </InfoButtonText>
            }
            title="Delete campaign?"
            description={
              <>
                Do you really wish to delete this campaign? <br />
                If the campaign is published, users won’t be able to play anymore and this action
                can’t be undone.
              </>
            }
          />
        </FlexContainer>
      </FlexContainer>
      <br />
      <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Tooltip text={'Use this ID to connect your campaign with the landing pages'}>
          <HelpCircle size="medium" />
        </Tooltip>
          <InfoField title="ID" text={String(campaignDetailData?.id)} />
        </div>
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Game" text={campaignDetailData?.game} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Brand" text={campaignDetailData?.fieldbrand} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Campaign Type" text={campaignDetailData?.category?.name} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Country" text={campaignDetailData?.country} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Period" text={campaignDetailData?.fieldperiod} />
        </Grid.Item>
        {campaignDetailData?.type?.target_id === 'penalty_kick' || campaignDetailData?.type?.target_id === 'instant_card' || campaignDetailData?.type?.target_id === 'spinning_wheel' ? (
          <Grid.Item
            xl={2}
            lg={3}
            md={4}
            sm={4}
            xs={6}
            style={{ margin: '0rem 0px 1rem 0px', flexDirection: 'column', justifyContent: 'center' }}
          >
            <InfoField title="Lucky number integration" text={<StyledToggle checked={campaignDetailData?.lucky_number_status} size={'small'} onChange={() => { }} />} />
          </Grid.Item>
        ) : null}
        {campaignDetailData?.country === 'Brazil' ? <Grid.Item
          xl={2}
          lg={3}
          md={4}
          sm={4}
          xs={6}
          style={{ margin: '0rem 0px 1rem 0px', flexDirection: 'column', justifyContent: 'center' }}
        >
          <InfoField title="Rewards integration" text={<StyledToggle checked={campaignDetailData?.rewards_integration} size={'small'} onChange={() => { }} />} />
        </Grid.Item> : null}
        {bottleCrush && (
          <>
            <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
              <InfoField title="Name" text={bottleCrush?.name} />
            </Grid.Item>
            <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
              <InfoField
                title="Player progress expiration time"
                text={String(bottleCrush?.player_progress_expiration_time)}
              />
            </Grid.Item>
            <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6} style={{ flexDirection: 'column' }}>
              <InfoField title="New game?" text="" />
              <Toggle checked={bottleCrush?.new_game} />
            </Grid.Item>
          </>
        )}
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
        <Tooltip text={'Access here to create the landing pages'}>
          <HelpCircle size="medium" />
        </Tooltip>
          <InfoField
            title="Page Environment"
            text={
              <Link
                style={{ textDecoration: 'none' }}
                to={'https://sitebees.games/user/login'}
                target="_blank"
              >
                <TextButton icon={ArrowRight} iconPosition="leading">
                  Go to Site Studio
                </TextButton>
              </Link>
            }
          />
        </div>
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Last edit" text={campaignDetailData?.changed} />
        </Grid.Item>
      </Grid.Container>
    </Card>
  ) : (
    <Card elevated={'large'} border={'large'} css={{ padding: '1rem 0' }}>
      <FlexContainer display="flex" height="150px" justifyContent="center" alignItems="center">
        <LoadingDots size="large" />
      </FlexContainer>
    </Card>
  );
};

export default CampaignTab;
