import { Card, Grid, Paragraph, Quantifier } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import usePlayerHandler from '../../hook/usePlayerHandler';
import { changeStateForm } from '../../utils/functions';

type NewAccountType = {
    name: string,
    poc: any,
    quantity: number
}

export default function AddPlayer() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { addToast } = useContext(AlertContext);
    const { addPlayer } = usePlayerHandler();
    const [form, setForm] = useState<NewAccountType>({
        name: '',
        poc: '',
        quantity: 0
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState(false)

    const handleSend = async () => {
        if (!form?.name) {
            setError(true)
            addToast({
                message: 'Please fill in all fields to continue!',
                type: 'error',
            });
        } else {
            setError(false)
            setLoading(true);
            await addPlayer(
                {
                    name: form?.name,
                    poc: form?.poc,
                    quantity: Number(form?.quantity),
                    campaignId: state?.campaignId,
                    campaignType: state?.campaignType
                }
            )
                .then((res) => {
                    if (res?.data?.errors) {
                        addToast({
                            message: res?.data?.errors[0].detail,
                            type: 'error',
                        });
                    } else {
                        addToast({
                            message: 'Player successfuly created',
                            type: 'success',
                        });
                        navigate(-1);
                    }
                })
                .catch(() => {
                    addToast({
                        message: 'An error occurred while create the player.',
                        type: 'error',
                    });
                })
                .finally(() => setLoading(false));
        }
    };

    return (
        <>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
                <PageTitle
                    marginBottom="0.5rem"
                    title="Add Player"
                    hint=""
                />
            </Grid.Item>
            <Card elevated="medium" border="medium" css={{ width: '100%' }}>
                <Grid.Container
                    type="fluid"
                    style={{
                        justifyContent: 'start',
                        padding: '1.5rem 0 1rem 0',
                    }}
                >
                    <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                        <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: "column" }}>
                            <StyledInput
                                required
                                size="large"
                                label="Name*"
                                placeholder="Insert Name"
                                hint="The name of the Base player entity."
                                width="50%"
                                onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                                hasError={error}
                                errorText="Name is required."
                            />
                            <StyledInput
                                size="large"
                                label="POC"
                                placeholder="Insert POC"
                                hint="The player POC ID."
                                width="50%"
                                onChange={(e) => changeStateForm(setForm, form, 'poc', e.currentTarget.value)}
                            />
                            <div>
                                <Paragraph type='label' size='small' weight='semibold' css={{ paddingBottom: '$1' }}>Quantity*</Paragraph>
                                <Paragraph type='' size='xsmall' css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}>
                                    The number of times the player can still participate playing
                                </Paragraph>
                                <div style={{ width: '25%' }}>
                                    <Quantifier
                                        onValueChange={(e) => changeStateForm(setForm, form, 'quantity', e)}
                                        value={form?.quantity}
                                        min={0}
                                        size='medium'
                                    />
                                </div>
                            </div>
                        </Grid.Container>
                    </Grid.Item>
                </Grid.Container>
                <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
                    <div
                        style={{
                            width: '100%',
                            display: 'inline-flex',
                            justifyContent: 'flex-end',
                            gap: '0.5rem',
                            padding: '20px',
                        }}
                    >
                        <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                            Cancel
                        </StyledButton>
                        <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
                            Save
                        </StyledButton>
                    </div>
                </Grid.Item>
            </Card>
        </>
    );
}
