// import { Container } from './styles';

import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface TimeWindowHandleInterface {
  createTimeWindow: (requestData, campaignId) => Promise<any>;
  updateTimeWindow: (requestData, campaignId, targetTimewindowId: string) => Promise<any>;
  deleteTimeWindow: (timeWindowid: string[], campaignId: any) => Promise<any>;
  getAllTimeWindow: (campaignId) => Promise<any>;
  getAllTimeWindowAttempt: (campaignId) => Promise<any>;
}

export default function useTimeWindowHandler(): TimeWindowHandleInterface {
  /**
   * List All Time window.
   *
   * @param object $requestData
   *   The draw date.
   */
  const getAllTimeWindow = async (campaignId) => {
    return apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaignId}/time-window?page=0&page_size=50000`)
      .then((response) => response)
      .catch((error) => error.response);
  };

  /**
   * List All Time window attemps.
   *
   * @param object $requestData
   *   The draw date.
   */
  const getAllTimeWindowAttempt = async (campaignId) => {
    return apiDrupal
      .get('/' + campaignId + '/time-window/reserve-attempt?page=0&page_size=50000')
      .then((response) => response)
      .catch((error) => error.response);
  };

  /**
   * Create time window.
   *
   * @param object $requestData
   *   The draw date.
   */
  const createTimeWindow = async (
    requestData: { first_draw_date: string; last_draw_date: string },
    campaignId
  ) => {
    return apiDrupal
      .post(`${base_url}/hub/v2/competition/${campaignId}/time-window/bulk-operation/generate`, {
        ...requestData,
        check_time_window_prize_used: true,
      })
      .then((response) => response)
      .catch((error) => error.response);
  };

  /**
   * Delete time window.
   *
   * @param array $timeWindowIds
   *   The draw date.
   */
  const deleteTimeWindow = async (timeWindowIds: string[], campaignId) => {
    const data = { timewindow_ids: timeWindowIds };

    return apiDrupal
      .delete(`${base_url}/hub/v2/competition/${campaignId}/time-window`, { data: data })
      .then((response) => response)
      .catch((error) => error.response);
  };

  /**
   * Create time window.
   *
   * @param object $requestData
   *   The draw date.
   */
  const updateTimeWindow = async (requestData: { draw_date: string }, campaignId, timwindowId) => {
    return apiDrupal
      .patch(`${base_url}/hub/v2/competition/${campaignId}/time-window/${timwindowId}`, requestData)
      .then((response) => response)
      .catch((error) => error.response);
  };
  return {
    createTimeWindow,
    updateTimeWindow,
    deleteTimeWindow,
    getAllTimeWindow,
    getAllTimeWindowAttempt,
  };
}
