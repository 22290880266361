import { createContext, Dispatch, SetStateAction, useState } from 'react';

export type toastType = {
  message: string;
  type: 'error' | 'info' | 'warning' | 'success';
  delay?: number;
  position?: string;
  index?: number;
};

//Tipagem dos dados
type AlertContextType = {
  toasts: toastType[];
};

//Tipagem das Props do contexto
type AlertContextProps = {
  state: AlertContextType;
  setState: Dispatch<SetStateAction<AlertContextType>>;
  addToast: (toast: toastType) => void;
};

//Valores Default do contexto
const DEFAULT_VALUE = {
  state: {
    toasts: [],
  },
  setState: () => { }, // função de inicialização e mudança de estado
  addToast: (toast: toastType) => { }, // função para adicionar um novo toast
};

//Cria a Context
export const AlertContext = createContext<AlertContextProps>(DEFAULT_VALUE);

//PROVEDOR DO CONTEXTO
const AlertContextProvider = ({ children }: { children: any }) => {
  const [state, setState] = useState(DEFAULT_VALUE.state);

  const addToast = (toast: toastType) => {
    setState({
      toasts: [
        {
          message: toast.message,
          type: toast.type,
        },
      ],
    });
  };

  return (
    <AlertContext.Provider
      value={{
        state,
        setState,
        addToast,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
