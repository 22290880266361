import { Card, Grid, Input, Paragraph, Quantifier } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import { changeState, changeStateForm } from '../../utils/functions';
import useChancesResetHandler from '../../hook/useChancesResetHandler';
import moment from 'moment';

interface Chances {
  id: string;
  campaignId: string;
  chances: string;
  reset_date: string;
}

const EditChances: React.FC = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(AlertContext);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<Chances>();
  const { state } = useLocation();
  const [search, setSearch] = useState('');
  const { editChances } = useChancesResetHandler();

  useEffect(() => {
    setSearch(state.campaignName);
    const resetDate = moment(state.form.reset_date, 'DD/MM/YYYY HH:mm')
    setForm({ ...state.form, campaignId: state.id, reset_date: resetDate.format('YYYY-MM-DDTHH:mm')});
  }, []);

  async function save() {
    setLoading(true);
    if (
      !form.id ||
      !form.campaignId ||
      !form.reset_date
    ) {
      addToast({ message: 'Please fill in all fields to continue', type: 'error' });
      setLoading(false);
      setHasEmptyField(true);
      return;
    }
    await editChances({
      id: form.id,
      campaignId: form.campaignId,
      chances: form.chances,
      reset_date: moment.utc(form.reset_date).format(),
    })
      .then((res) => {
        if (res?.data?.errors) {
          addToast({
            message: 'Error saving data.',
            type: 'error',
          });
        } else {
          addToast({ message: `Chances reset updated successfully.`, type: 'success' })
          navigate(-1);
        }
      })
      .catch(() => addToast({ message: 'Failed to update chances reset.', type: 'error' })).finally(() => setLoading(false));
  }

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <PageTitle marginBottom="0.5rem" title="Chances Reset" hint="" />
      </Grid.Item>
      <Card elevated="medium" border="medium" css={{ width: '100%' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'start',
            padding: '1.5rem 0 1rem 0',
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container
              type="fluid"
              style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: 'column' }}
            >
                <AutoComplete
                  onChange={(e) => {
                    changeState(setSearch, e.currentTarget.value);
                  }}
                  onClear={() => {
                    changeState(setSearch, '');
                    changeStateForm(setForm, form, 'campaignId', undefined);
                  }}
                  onClickResult={(campaign) => {
                    setForm({
                      ...form,
                      campaignId: campaign?.id.toString()
                    });
                  }}
                  campaignMode={true}
                  value={search}
                  required
                  size="large"
                  label="Campaign*"
                  hint="The campaign."
                  placeholder="Start typing to search"
                  hasError={hasEmptyField && !form?.campaignId}
                  errorText='Campaign is required.'
                />
              <div>
                <Paragraph
                  type="label"
                  size="small"
                  weight="semibold"
                  css={{ paddingBottom: '$1' }}
                >
                  Chances*
                </Paragraph>
                <Paragraph
                  type=""
                  size="xsmall"
                  css={{ color: '$colors$interfaceLabelSecondary', paddingBottom: '$1' }}
                >
                  The number of times players can still play after reset.
                </Paragraph>
                <div style={{ width: '25%' }}>
                  <Quantifier
                    defaultValue={form?.chances}
                    value={form?.chances}
                    min={0}
                    size="medium"
                    onChange={(e) => {
                      let v = parseInt(e.currentTarget.value, 10);
                      if (isNaN(v)) {
                        v = 0;
                      }
                      changeStateForm(setForm, form, 'chances', v);
                    }}
                    onClickPlusButton={() => {
                      changeStateForm(setForm, form, 'chances', Number(form?.chances) + 1);
                    }}
                    onClickMinusButton={() => {
                      changeStateForm(setForm, form, 'chances', Number(form?.chances) - 1);
                    }}
                  />
                </div>
              </div>
              <Input
                value={form?.reset_date}
                type="datetime-local"
                size="large"
                label="Reset date*"
                hint="Date from which the chances can be reset."
                placeholder="Insert the date"
                required
                hasError={hasEmptyField && !form?.reset_date}
                errorText="Reset date is required."
                onChange={(e) => {
                  setForm({
                    ...form,
                    reset_date: e.currentTarget.value,
                  })
                }}
              />
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => save()}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Card>
    </>
  );
};

export default EditChances;
