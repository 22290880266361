import { Card, ExpandableSection, FileValidated, Grid, Heading, Paragraph, Select } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledButton from '../../components/Button/Button';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import StyledToggle from '../../components/Toggle/Toggle';
import LinkedCheckbox from '../../components/LinkedCheckbox/LinkedCheckbox';
import FlexContainer from '../../components/FlexContainer';

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

export default function ImportLocalChallenge() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [csvError, setCsvError] = useState<boolean>(false);

  const handleFile = async (file: FileValidated[]) => {
/*     setCsvError(false);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], challengeForm?.delimiter).then((res: boolean) => res);
    if (isValid) {
        const convertedFile = await base64CSVConverter(file[0].file);
        await uploadChallengeCSV(
            {
                fileName: String(file[0].file.name),
                file: convertedFile,
            },
            String(newCampaignData?.id)
        )
            .then((data) => {
                if (data?.message) {
                    addToast({
                        message: "Error uploading CSV",
                        type: 'error',
                    });
                    setCsvError(true)
                } else {
                    addToast({
                        message: "Success uploading CSV",
                        type: 'success',
                    });
                    setCsvError(false)
                    changeStateForm(setChallengeForm, challengeForm, 'field_file', [{ target_id: data.fid[0].value }]);
                }
            })
            .finally(() => setLoading(false));
    } else {
        setCsvError(true);
        setLoading(false);
    } */
};

  return (
    <Grid.Container type="fluid" style={{ margin: 0 }}>
      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '1rem' }}>
          Add Challenge local
        </Heading>
      </StyledGridItem>

      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="large" css={{ width: '100%', paddingBottom: '0.5rem' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '2rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  gap: '1rem',
                }}
              >
                  <StyledTextarea
                    style={{ height: '222px', resize: 'none' }}
                    label="Description*"
                    width={'100%'}
                    hint=' Description of the import that will be performed.'
                    placeholder="Insert you text here"
                  />
                  <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
                    style={{ minWidth: '100% ', maxWidth: '395px'}}
                    options={[
                        {
                            id: 'comma',
                            label: ', (Comma)',
                            value: ','
                        },
                        {
                            id: 'semicolon',
                            label: '; (Semicolon)',
                            value: ';'
                        }
                    ]}
                    onChange={() => console.log('changed') /* (value => changeStateForm(setChallengeForm, challengeForm, 'delimiter', value)) */}
                  />
                  <StyledFileUploader
                    legend={
                        <>
                            The csv must have the <b>challenge_id</b> and <b>challenge_name</b> columns.
                        </>
                    }
                    title="Import .csv file*"
                    accept=".csv"
                    maxFileSize={1024 * 1024 * 5}
                    message="File must be .csv and must be less than 5MB"
                    onDrop={(file) => handleFile(file)}
                    maxFiles={1}
                    type="file"
                    error={
                      csvError
                          ? {
                              message: `The csv is not valid. Columns don't match!`,
                          }
                          : undefined
                    }
                  />
                  <ExpandableSection headerText={'Overwrite fields'}>
                    <FlexContainer gap='1rem' display='flex' flexDirection='column'>
                      <StyledToggle
                        title="Overwrite"
                        description="Update existing challenge."
                        value={false}
                        onChange={() => null}
                      />
                      <Paragraph weight='medium'>
                        Overwrite fields
                      </Paragraph>
                      <LinkedCheckbox
                        id="name"
                        labelText={`Name`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_mix_and_match_levels_played]", checked)} */
                      />
                      <LinkedCheckbox
                        id="description"
                        labelText={`Description`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_mix_and_match_player_progress]", checked)} */
                      />
                      <LinkedCheckbox
                        id="detailed_description"
                        labelText={`Detailed description`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_pocs_acceptance]", checked)} */
                      />
                      <LinkedCheckbox
                        id="start_date"
                        labelText={`Start date`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_pocs_eligible]", checked)} */
                      />
                      <LinkedCheckbox
                        id="end_date"
                        labelText={`End date`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_pocs_remaining_chances]", checked)} */
                      />
                      <LinkedCheckbox
                        id="external_link_url"
                        labelText={`External link url`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_pocs_attempt]", checked)} */
                      />
                      <LinkedCheckbox
                        id="external_link_text"
                        labelText={`External link text`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes_delivered]", checked)} */
                      />
                      <LinkedCheckbox
                        id="category"
                        labelText={`Category`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes]", checked)} */
                      />
                      <LinkedCheckbox
                        id="image_highlighted"
                        labelText={`Image highlighted`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes_delivered]", checked)} */
                      />
                      <LinkedCheckbox
                        id="image_modal"
                        labelText={`Image modal`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes]", checked)} */
                      />
                      <LinkedCheckbox
                        id="image_chance_available"
                        labelText={`Image chance available`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes_delivered]", checked)} */
                      />
                      <LinkedCheckbox
                        id="image_chance_used"
                        labelText={`Image chance used`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes]", checked)} */
                      />
                      <LinkedCheckbox
                        id="image_chance_unavailable"
                        labelText={`Image chance unavailable`}
                        linkText=""
                        linkHref=""
                        /* onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes]", checked)} */
                      />
                    </FlexContainer>
                  </ExpandableSection>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '10px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </StyledGridItem>
    </Grid.Container>
  );
}
