import { Tooltip } from '@hexa-ui/components';
import { Container } from './ShowColor.styles';

const ShowColor = (
    {
        color
    }
        : string | any
): JSX.Element => {
    return (
        <Tooltip placement="top" text={color}>
            <Container css={{ background: color }} />
        </Tooltip>
    );
};

export default ShowColor