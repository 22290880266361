import { Card, FileValidated, Grid, Image, Modal, Tabs } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StyledAlert from '../../components/Alert/Alert';
import StyledButton from '../../components/Button/Button';
import InfoField, { InfoButtonText } from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import { StyledModal } from '../../components/ModalDelete/ModalDelete';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import useChallengeHandler from '../../hook/useChallengeHandler';
import { useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes';
import { getJsonApiData } from '../../utils/formatData';
import StyledTabs from '../../components/Tabs/Tabs';
import StyledTextarea from '../../components/Textarea/Textarea';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { changeStateForm } from '../../utils/functions';
import { base64CSVConverter } from '../../utils/toBase64';
import checkValidityCSV from '../../utils/verifyCSV';
import { Download, Info } from '@hexa-ui/icons';

type campaignDetailsType = {
  description: string
  title: string
  image: string
}

type challengeType = {
  name: string,
  description: string;
  field_base_campaign: string;
  field_file: any[];
  delimiter: string;
}

export default function AddChallenge() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { addToast } = useContext(AlertContext);
  const { getChallengeDetails } = useChallengeHandler();
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const { linkChallenge, getCountries } = useChallengeHandler();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [challengeId, setChallengeId] = useState<string>('');
  const [challengeDetails, setChallengeDetails] = useState<campaignDetailsType | null>(null);
  const [hasEmptyField, setHasEmptyField] = useState(false);

  const [listType, setListType] = useState('add-challenges');
  const params = useParams();
  const { uploadChallengeCSV, importChallenges } = useChallengeHandler();
  const [form, setForm] = useState<challengeType>(null);
  const [csvError, setCsvError] = useState<boolean>(false);
  const csvData = [['challenge']];

  useEffect(() => {
    setChallengeId(state)
  }, [])

  const handleChange = (value: string) => {
    setChallengeId(value);
  };

  const handleSend = async () => {
    setLoading(true)
    return await linkChallenge({
      challenge_id: challengeId,
    },
      String(campaignDetails?.id)).then((res) => {
        if (res === undefined || res.data.length < 1 || res === undefined) {
          setShowModal(false)
          addToast({
            message: 'Error when linking the challenge to campaign',
            type: 'error',
          })
        } else {
          addToast({
            message: 'Challenge successfuly linked',
            type: 'success',
          })
          navigate(-1);
        }
        setLoading(false)
      }).catch((err) => {
        addToast({
          message: 'Error when linking the challenge to campaign',
          type: 'error',
        })
        setLoading(false)
      })
  };

  const showPreview = async () => {
    if (!challengeId) {
      addToast({
          message: 'Please fill in all fields in challenge to continue!',
          type: 'error',
      });
      setHasEmptyField(true);
  } else {
    setHasEmptyField(false);
    setLoading(true)
    let countryName = ''
    await getCountries(campaignDetails?.country, 'name').then((res) => {
      const code = (getJsonApiData(res).data)[0]?.country
      countryName = code
    })
    await getChallengeDetails(challengeId, countryName)
      .then(res => {
        if (res.code === "ERR_NETWORK" || res.data.length < 1 || res === undefined) addToast({
          message: 'An error occurred when calling up challenge details.',
          type: 'error',
        });
        else {
          setChallengeDetails(res.data[0])
          setShowModal(true)
        }
      })
      .catch(() => {
        addToast({
          message: 'An error occurred when calling up challenge details.',
          type: 'error',
        });
      })
      .finally(() => setLoading(false))
    }
  }

  const handleFile = async (file: FileValidated[]) => {
    setCsvError(false);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then((res: boolean) => res);
    if (isValid) {
        const convertedFile = await base64CSVConverter(file[0].file);
        await uploadChallengeCSV(
            {
                fileName: String(file[0].file.name),
                file: convertedFile,
            },
            String(params?.campaignId)
        )
            .then((data) => {
                if (data?.message) {
                    addToast({
                        message: "Error uploading CSV",
                        type: 'error',
                    });
                    setCsvError(true)
                } else {
                    addToast({
                        message: "Success uploading CSV",
                        type: 'success',
                    });
                    setCsvError(false)
                    changeStateForm(setForm, form, 'field_file', [{ target_id: data.fid[0].value }]);
                }
            })
            .finally(() => setLoading(false));
    } else {
        setCsvError(true);
        setLoading(false);
    }
  };

  const handleSendImport = async () => {
    if (!form?.description || form?.field_file === undefined) {
        addToast({
            message: 'Please fill in all fields in challenge to continue!',
            type: 'error',
        });
        setHasEmptyField(true);
    } else {
        setHasEmptyField(false);
        setLoading(true);
        await importChallenges(
            {
                name: campaignDetails?.name,
                field_base_campaign: String(params?.campaignId),
                description: form?.description,
                field_file: form?.field_file,
                field_file_delimiter: form?.delimiter
            },
            Number(params?.campaignId)
        )
            .then((res) => {
                if (res?.error) {
                    if (res?.error?.code === 0) setCsvError(true);
                    addToast({
                        message: res?.error?.message,
                        type: 'error',
                    });
                } else {
                    addToast({
                        message: 'Challenge successfuly updated',
                        type: 'success',
                    });
                    navigate(-1);
                }
            })
            .catch(() => {
                addToast({
                    message: 'An error occurred while updated challenge.',
                    type: 'error',
                });
            })
            .finally(() => setLoading(false));
    }
  };

  const ContainerActions = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    button: {
      marginRight: '10px'
    }
  })

  const ContainerDetails = styled('div', {
    textAlign: 'left',
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    maxHeight: '42vh'
  })

  const TitleDetails = styled('div', {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem',
    div: {
      marginRight: '1rem'
    }
  })

  const BtnContainer = styled('div', {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
    padding: '20px',
})


  return (
    <Grid.Container style={{ width: '100%' }}>
      <StyledModal
        variant='overlay'
        title="Challenge details"
        maxWidth='70rem'
        width='65rem'
        description={
          <ContainerDetails>
            <TitleDetails>
              <InfoField title="Challenge id:" text={challengeId} />
              <InfoField title="Title:" text={challengeDetails?.title} />
            </TitleDetails>
            <TitleDetails>
              <InfoField title="Description:" text={challengeDetails?.description} />
            </TitleDetails>
            <TitleDetails>
              <InfoField title="Detailed description:" />
            </TitleDetails>
            <Image src={challengeDetails?.image} width='100%' containerCss={{ width: '100%', border: '0px', marginTop: '20px', textAlign: 'center' }} />
          </ContainerDetails>
        }
        actions={
          <ContainerActions>
            <Modal.Action>
              <StyledButton variant="secondary" disabled={loading} onClick={() => setShowModal(false)}>
                Cancel
              </StyledButton>
            </Modal.Action>
            <Modal.Action>
              <StyledButton
                variant="primary"
                onClick={handleSend}
                isLoading={loading}
              >
                Confirm
              </StyledButton>
            </Modal.Action>
          </ContainerActions>
        }
        open={showModal}
        destructive='off'
      />
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px', marginBottom: '20px' }}
      >
      {listType === 'add-challenges' ? (
        <PageTitle marginBottom="0.5rem" title="Link the challenge to the campaign" />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
                <PageTitle
                    marginBottom="0.5rem"
                    title="Import challenges"
                    hint="Time to create your next big project! We suggest giving it a meaningful name,
              as well as a custom thumbnail. The future you says thanks!"
                />
            </Grid.Item>
            <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
                <InfoButtonText
                    icon={() => <Download size="large" />}
                    iconPosition="leading"
                    size="large"
                    css={{ gap: 11 }}
                >
                    <a
                        href={
                            'data:application/octet-stream;base64,Y2hhbGxlbmdlCjIxNjM1MgoyNDMyNjQ3MgozNDYzMjQzMjQzMgo0NjM0NjI0Mgo='
                        }
                        download="Template_challenges.csv"
                        style={{
                            color: 'black',
                            fontWeight: '500',
                            lineHeight: '1.5rem',
                            textDecoration: 'none',
                            fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
                        }}
                    >
                        Download template
                    </a>
                </InfoButtonText>
                <a
                    style={{ textDecoration: 'none' }}
                    target={'_blank'}
                    href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
                >
                    <InfoButtonText
                        icon={() => <Info size="xlarge" />}
                        iconPosition="leading"
                        size="large"
                        css={{ gap: 11 }}
                    >
                        See instructions
                    </InfoButtonText>
                </a>
            </Grid.Item>
        </div>
      )}
      </Grid.Item>
      <Card
        elevated="small"
        border="medium"
        css={{ width: '100%', marginBottom: '20px' }}
      >
      <StyledTabs defaultValue={'add-challenges'} value={listType}>
      <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
        <Tabs.Trigger value="add-challenges" onClick={() => setListType('add-challenges')}>
          Add challenges
        </Tabs.Trigger>
        <Tabs.Trigger value="import-challenges" onClick={() => setListType('import-challenges')}>
          Import challenges
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="add-challenges">
        <Grid.Item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ flexDirection: 'column', gap: '1rem' }}
        >
          <StyledInput
            label="Campaign"
            placeholder="Campaign Example"
            size="large"
            width="30%"
            hint="The current campaign."
            value={campaignDetails?.name}
            disabled
          />
          <StyledInput
            defaultValue={''}
            label="Challenge id*"
            placeholder=""
            hasError={hasEmptyField && !challengeId}
            errorText='Challenge id is required.'
            size="large"
            width="30%"
            hint="The challenge id."
            onChange={(e) => handleChange(e.currentTarget.value)}
            value={challengeId || ''}
          />
        </Grid.Item>
        <Grid.Item>
          <StyledAlert
            type="warning"
            message={`This action cannot be undone.`}
            css={{ width: '100%', marginTop: '20px' }}
          />
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px 0px 20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={showPreview}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Tabs.Content>
      <Tabs.Content value='import-challenges'>
        <Grid.Container
            type="fluid"
            style={{
                justifyContent: 'center'
            }}
        >
            <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0 }}>
                    <StyledInput
                        defaultValue={campaignDetails?.name}
                        required
                        disabled
                        size="large"
                        label="Campaign"
                        placeholder="Campaign Example"
                        hint="The current campaign you’re importing to. This cannot be changed"
                        width="100%"
                        onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                    />
                    <StyledTextarea
                        style={{ height: '300px', resize: 'none' }}
                        defaultValue={form?.description}
                        value={form?.description}
                        required
                        hasError={hasEmptyField && !form?.description}
                        errorText='Description is required.'
                        label="Description*"
                        placeholder="Import made on MM/DD/YY - HH:MM"
                        width="100%"
                        type="textarea"
                        height="242px"
                        characterCounter
                        maxLength={150}
                        rows={12}
                        onChange={(e) =>
                            changeStateForm(setForm, form, 'description', e.currentTarget.value)
                        }
                    />
                </Grid.Container>
            </Grid.Item>
            <Grid.Item xl={4} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
                <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
                    style={{ marginBottom: '12px', minWidth: '100% ' }}
                    options={[
                        {
                            id: 'comma',
                            label: ', (Comma)',
                            value: ','
                        },
                        {
                            id: 'semicolon',
                            label: '; (Semicolon)',
                            value: ';'
                        }
                    ]}
                    onChange={(value => changeStateForm(setForm, form, 'delimiter', value))}
                />
                <StyledFileUploader
                    legend={
                        <>
                            The csv must have the <b>challenge</b> column.
                        </>
                    }
                    title="Import .csv file*"
                    accept=".csv"
                    maxFileSize={1024 * 1024 * 5}
                    message="File must be .csv and must be less than 5MB"
                    onDrop={(file) => handleFile(file)}
                    maxFiles={1}
                    type="file"
                    error={
                        csvError
                            ? {
                                message: `The csv is not valid. Columns don't match!`,
                            }
                            : hasEmptyField && !form?.field_file ?
                            {
                                message: 'File is required.'
                            }
                            : undefined
                    }
                />
            </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
            <BtnContainer>
                <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                    Cancel
                </StyledButton>
                <StyledButton variant="primary" isLoading={loading} onClick={() => handleSendImport()}>
                    Save
                </StyledButton>
            </BtnContainer>
        </Grid.Item>
      </Tabs.Content>
      </StyledTabs>
      </Card>
    </Grid.Container>
  );
}
