import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface ChancesResetHandle {
  getChances: (requestData) => Promise<any>,
  addChances: (form) => Promise<any>,
  editChances: (form) => Promise<any>,
  deleteChance: (id) => Promise<any>,
}

export default function useChancesResetHandler(): ChancesResetHandle {
  const getChances = async (requestData) => {
    return await apiDrupal.get(`${base_url}//hub/v1/competition/campaigns/${requestData.id}/chances-reset?_format=json&page=${requestData.page}&limit=${requestData.limit}`)
    .then((response) => response.data)
    .catch((error) => error)
  }

  const addChances = async (form) => {
    return await apiDrupal.post(`${base_url}/entity/cm_player_chances_reset?_format=json`, {
      "campaign": [{"target_id": `${form.campaignId}`}],
      "chances": [{"value": `${form.chances}`}],
      "reset_date": [{"value": `${form.reset_date}`}]
    }).then((response) => response.data)
    .catch((error) => error.response)
  }

  const editChances = async (form) => {
    return await apiDrupal.patch(`${base_url}/admin/structure/cm_player_chances_reset/${form.id}?_format=json`, {
      "campaign": [{"target_id": `${form.campaignId}`}],
      "chances": [{"value": `${form.chances}`}],
      "reset_date": [{"value": `${form.reset_date}`}]
    }).then((response) => response.data)
    .catch((error) => error.response)
  }

  const deleteChance = async (id) => {
    return await apiDrupal.delete(`${base_url}/admin/structure/cm_player_chances_reset/${id}?_format=json`)
    .then((response) => response.data)
    .catch((error) => error)
  }

  return { getChances, addChances, editChances, deleteChance }
}
