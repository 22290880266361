import { ScaleHeading, ScaleParagraph } from './Typography.styles';

export const StyledParagraph = (
    {
        title,
        ...props
    }: any
)
    : JSX.Element => {
    return (
        <ScaleParagraph {...props}>
            {title}
        </ScaleParagraph>
    )
}

const StyledHeading =
    (
        {
            size = "H3",
            title,
            ...props
        }: any
    )
        : JSX.Element => {
        return (
            <ScaleHeading size={size} {...props}>
                {title}
            </ScaleHeading>
        );
    };

export default StyledHeading;