import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    width: `${props => props.width}`,
    '.exportAlert': {
      div: {
        justifyContent: 'flex-start',
      },
    },
    '@media (min-resolution: 1.50dppx)': {
        div: {
            fontSize: '0.75rem',
            lineHeight: '1.7'
        },

        '> div': {
            display: 'flex',
            justifyContent: 'center'
        },

        svg: {
            'height': '1.2rem',
            'width': '1.2rem'
        }
    }
})
