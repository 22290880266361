import styled from 'styled-components';

interface FIleUploaderProps {
    maxHeight?: string | number
    minHeight?: string | number
    width?: string | number
}

export const Container = styled.div<FIleUploaderProps>`
    max-height: ${props => props.maxHeight};
    min-height: ${props => props.minHeight};
    width: ${props => props.width};
    div {
        width: 100%;
    }

    @media (min-resolution: 1.50dppx){
        label, textarea {
                font-size: 0.75rem;
                line-height: 1.7;
        }
    }
`