import { Card, FileValidated, Grid } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';

import { Download, Info } from '@hexa-ui/icons';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { InfoButtonText } from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import useWindowDimensions from '../../hook/useWindowDimensions';
import { AppDispatch, useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes';
import { DigitalPrizeCodeType } from '../../store/dataTypes/digitalPrizeCode.type';
import { FETCH_CAMPAIGN_DETAILS } from '../../store/stock/CampaignDetailsReducer';
import {
  IMPORT_DIGITAL_PRIZE_CODE,
  IMPORT_DIGITAL_PRIZE_CODE_ENTITY,
} from '../../store/stock/DigitalPrizeCodeReducer';
import { changeStateForm } from '../../utils/functions';
import { base64CSVConverter } from '../../utils/toBase64';
import checkValidityCSV from '../../utils/verifyCSV';

export default function ImportDigitalPrizesCodes() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);

  const { width } = useWindowDimensions();
  const { addToast } = useContext(AlertContext);
  const [form, setForm] = useState<DigitalPrizeCodeType>(undefined);
  const [field_file, setField_file] = useState();
  const [csvError, setCsvError] = useState<boolean>(false);
  const [fileEmptyError, setFileEmptyError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const csvData = [['voucher_id', 'voucher_code']];

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_CAMPAIGN_DETAILS({ id: state.idCampaign }));
    }

    init();
  }, []);

  useEffect(() => {
    setCsvError(false);
  }, [field_file]);

  const handleFile = async (file: FileValidated[]) => {
    setField_file(undefined);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then(
      (res: boolean) => res
    );
    if (isValid) {
      const convertedFile = await base64CSVConverter(file[0].file);

      const importDigitalPrizeCode = await dispatch(
        IMPORT_DIGITAL_PRIZE_CODE({
          idCampaign: state.idCampaign,
          fileName: String(file[0].file.name),
          file: convertedFile,
        })
      ).finally(() => setLoading(false));
      setField_file(importDigitalPrizeCode.payload.fid[0].value);
    } else {
      setCsvError(true);
      setLoading(false);
    }
  };

  const [errorDescription, setErrorDescription] = useState<boolean>(false);

  const handleSave = async () => {
    if (form?.description == '' || form?.description == undefined) {
      setErrorDescription(true);
      return;
    }

    if (!field_file) {
      setFileEmptyError(true);
      return;
    }

    setErrorDescription(false);
    setFileEmptyError(false);

    setLoading(true);
    await dispatch(
      IMPORT_DIGITAL_PRIZE_CODE_ENTITY({
        name: form.description,
        bundle: 'cm_base_digital_prize_code',
        field_added_to_queue: 0,
        field_base_campaign: state.idCampaign,
        field_file_delimiter: form?.delimiter,
        field_file,
      })
    ).then((res) => {
      if (res?.payload?.error?.code === 0) {
        setCsvError(true);
        addToast({
          message: res?.payload?.error?.message,
          type: 'error',
        });
      } else {
        addToast({
          message: 'Digital prize code successfuly updated',
          type: 'success',
        });
      }
    }).finally(() => {
      setLoading(false);
      navigate(
        `/bees-games/manage-assets/campaign-details/${campaignDetails.id}#digitalprizecodes`
      );
    });
  };

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle
            marginBottom="0.5rem"
            title="Import digital prize codes"
            hint="Change this text, but make it a max of 2 lines Change this text, but make it a
              max of 2 lines Change this text, but make it a max of 2 lines."
          />
        </Grid.Item>
        <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
          <InfoButtonText
            icon={() => <Download size="large" />}
            iconPosition="leading"
            size="large"
            css={{ gap: 11 }}
          >
            <a
              href="data:application/octet-stream;base64,dm91Y2hlcl9pZCx2b3VjaGVyX2NvZGUKMTIzLDEyMw=="
              download="template_digital_prize_code.csv"
              style={{
                color: 'black',
                fontWeight: '500',
                lineHeight: '1.5rem',
                textDecoration: 'none',
                fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
              }}
            >
              Download template
            </a>
          </InfoButtonText>
          <a
            style={{ textDecoration: 'none' }}
            target={'_blank'}
            href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
          >
            <InfoButtonText
              icon={() => <Info size="xlarge" />}
              iconPosition="leading"
              size="large"
              css={{ gap: 11 }}
            >
              See instructions
            </InfoButtonText>
          </a>
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="medium" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'center',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={9} lg={8} md={7} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{ width: '100%', height: 'max-content', rowGap: '1rem', margin: 0 }}
                >
                  <StyledInput
                    defaultValue={campaignDetails?.name}
                    value={campaignDetails?.name}
                    required
                    size="large"
                    label="Campaign"
                    placeholder="Campaign Example"
                    hint="The current campaign you’re importing to. This cannot be changed"
                    width="100%"
                    onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                    disabled
                  />
                  <StyledTextarea
                    style={{ height: '300px', resize: 'none' }}
                    defaultValue={form?.description}
                    value={form?.description}
                    required
                    label="Description*"
                    placeholder="Import made on MM/DD/YY - HH:MM"
                    width="100%"
                    type="textarea"
                    characterCounter
                    maxLength={150}
                    rows={12}
                    onChange={(e) =>
                      changeStateForm(setForm, form, 'description', e.currentTarget.value)
                    }
                    hasError={errorDescription}
                    errorText="Description required"
                  />
                </Grid.Container>
              </Grid.Item>
              <Grid.Item xl={3} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
                <StyledRadio
                  label="Delimiter"
                  defaultValue=","
                  type="horizontal"
                  hint="Csv delimiter character must be the same as selected in this field."
                  style={{ marginBottom: '12px' }}
                  options={[
                    {
                      id: 'comma',
                      label: ', (Comma)',
                      value: ',',
                    },
                    {
                      id: 'semicolon',
                      label: '; (Semicolon)',
                      value: ';',
                    },
                  ]}
                  onChange={(value) => changeStateForm(setForm, form, 'delimiter', value)}
                />
                <StyledFileUploader
                  title="Import .csv file"
                  legend={
                    <>
                      The csv must have the <b>voucher_id</b> and a <b>voucher_code</b> columns.
                    </>
                  }
                  accept=".csv"
                  maxFileSize={1024 * 1024 * 5}
                  message="File must be .csv and must be less than 5MB"
                  onDrop={(e) => {
                    handleFile(e);
                    changeStateForm(setForm, form, 'file', e);
                    setFileEmptyError(false);
                  }}
                  error={
                    csvError
                      ? {
                          message: `The csv is not valid. Columns don't match!`,
                        }
                      : fileEmptyError
                      ? {
                          message: `This field cannot be empty.`,
                        }
                      : undefined
                  }
                  maxFiles={1}
                  type="file"
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                }}
              >
                <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton variant="primary" isLoading={loading} onClick={() => handleSave()}>
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
