import { Card, Grid } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import StyledAlert from '../../../components/Alert/Alert';
import StyledButton from '../../../components/Button/Button';
import ExportStatus from '../../../components/ExportStatus';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import useLuckyNumberHandler from '../../../hook/useLuckyNumberHandler';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { LuckyNumbersListType } from '../../../store/dataTypes/luckyNumbers.type';
import { changeState } from '../../../utils/functions';
import luckyNumbersHeaders from '../../json/columnHeaders/LuckyNumbersHeaders.json';
import handleFilter from '../extra/filter';
import ExportModule from '../modules/ExportModal';

const LuckyNumbersTab: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [luckyNumbersData, setLuckyNumbersData] = useState<LuckyNumbersListType[]>();
  const { getAllLuckyNumber, getLuckyNumberStatus } = useLuckyNumberHandler();
  const [filteredData, setFilteredData] = useState<LuckyNumbersListType[]>(luckyNumbersData);
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [importLuckyNumberStatus, setImportLuckyNumberStatus] = useState(false);

  WindowFocusHandler(async () => {
    setLoading(true);
    await getAllLuckyNumber(String(params?.id))
      .then((val) => {
        setLuckyNumbersData(val.data);
      })
      .finally(() => setLoading(false));

    await getLuckyNumberStatus(params?.id).then((error) => {
      if (error?.message) setImportLuckyNumberStatus(true);
      else setImportLuckyNumberStatus(false);
    });
  });

  const treatmentOfPlayers = (list: LuckyNumbersListType[]) => {
    return list?.map((item) =>
      Array.isArray(item?.player)
        ? {
          ...item,
          player: '',
        }
        : {
          ...item,
          player: item?.player?.target_id,
        }
    );
  };

  useEffect(() => {
    const data = treatmentOfPlayers(luckyNumbersData);
    setFilteredData(data);
  }, [luckyNumbersData]);

  useEffect(() => {
    const data = treatmentOfPlayers(luckyNumbersData);
    if (!search) {
      setFilteredData(data);
    }

    const fields = ['id', 'player', 'poc', 'prefix', 'serial_number', 'order_number'];

    handleFilter(search, data, fields, setFilteredData);
  }, [search]);

  const ActionsContainer = styled(Grid.Item, {
    justifyContent: 'space-between',
    padding: '$space$4',
    gap: '$space$05',
    flexWrap: 'wrap',
    alignItems: 'center',
  });

  return (
    <>
      {importLuckyNumberStatus && !loading && (
        <StyledAlert
          message={'You already have an import running. Wait for it to finish and try again!'}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
      )}
      <ExportStatus bundle={'cm_base_lucky_number_v2'} campaignId={params?.id} />
      <Card elevated={'medium'} border={'large'}>
        <ActionsContainer xs={12} sm={12} md={12} lg={12} xl={12}>
          <StyledFilter
            maxWidth="496px"
            width="496px"
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => changeState(setSearch, e.currentTarget.value)}
            onClear={() => changeState(setSearch, '')}
          />
          <StyledButton
            variant="primary"
            disabled={importLuckyNumberStatus}
            icon={Plus}
            leading
            onClick={() =>
              navigate(
                '/bees-games/manage-assets/campaign-details/' + params?.id + '/import-lucky-numbers'
              )
            }
          >
            Import lucky numbers
          </StyledButton>
        </ActionsContainer>
        {filteredData !== undefined ? (
          <StyledTable
            loading={loading}
            columns={luckyNumbersHeaders}
            data={filteredData || []}
            pagination={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
              showPageSizeSelector: true,
            }}
          />
        ) : (
          <Spinner color={'inherit'} show dataTest="transition-spinner" />
        )}
      </Card>
      <ExportModule
        bundle={'cm_base_lucky_number_v2'}
        campaignId={String(params?.id)}
        disabled={!!!filteredData?.length}
      />
    </>
  );
};

export default LuckyNumbersTab;
