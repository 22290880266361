import {Heading, Modal, Paragraph } from "@hexa-ui/components";
import FlexContainer from "../FlexContainer";
import StyledButton from "../Button/Button";
import styled from 'styled-components';
import LinkedCheckbox from "../LinkedCheckbox/LinkedCheckbox";
import { useState } from "react";

type OptInModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
};

const StyledPaddding =styled.div`
  padding-bottom: 0.5rem;

  @media (min-resolution: 1.50dppx){
  padding-bottom: .25rem;
  }
`

const Container = styled.div`
  @media (min-resolution: 1.50dppx){
      h2 {
              font-size: 1.75rem;
      }
      h1 {
          font-size: 1.80rem;
      }
      p, a {
          font-size: 0.75rem;
      }
  }
`

const OptInModal = ({ isOpen, onClose, onSave }: OptInModalProps) => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [usageConsent, setUsageConsent] = useState(false);
  const save = termsAccepted && usageConsent;

  const handleSave = () => {
    onSave()
    setTermsAccepted(false)
    setUsageConsent(false)
  }

  const handleCancel = () => {
    onClose()
    setTermsAccepted(false)
    setUsageConsent(false)
  }

  return (
    <Container>
      <Modal.Root
        open={isOpen}
        actions={
          <FlexContainer
            width="100%"
            display="inline-flex"
            justifyContent="flex-end"
            gap="0.5rem"
          >
            <Modal.Action>
              <StyledButton variant="secondary" onClick={() => handleCancel()}>Cancel</StyledButton>
            </Modal.Action>
            <Modal.Action>
              <StyledButton variant='primary' onClick={() => handleSave()} disabled={!save}>Save</StyledButton>
            </Modal.Action>
          </FlexContainer>
        }
        variant="overlay"
        destructive="off"
      >
        <Heading type="H2" css={{ marginBottom: '1rem' }}>
          Digital Comms Terms and Conditions
        </Heading>
        <StyledPaddding>
          <Paragraph>{'You must read and accept the terms to publish your campaign.'}</Paragraph>
        </StyledPaddding>
        <StyledPaddding>
        <LinkedCheckbox
            id="terms"
            labelText="I accept the terms and conditions."
            linkText=""
            linkHref="#"
            onCheckedChange={(check) => (setTermsAccepted(check))}
        />
        </StyledPaddding>
        <LinkedCheckbox
            id="usage"
            labelText="I consent to the usage."
            linkText=""
            linkHref="#"
            onCheckedChange={(check) => setUsageConsent(check)}
        />
      </Modal.Root>
    </Container>
  );
}

export default OptInModal
