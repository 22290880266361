import React, { CSSProperties } from 'react';

interface IFlexContainer {
  minHeight?: string;
  height?: string;
  maxHeight?: string;
  minWidth?: string;
  width?: string;
  maxWidth?: string;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  top?: number | string;
  right?: number | string;
  bottom?: number | string;
  left?: number | string;
  display?:
  | 'none'
  | 'block'
  | 'inline'
  | 'inline-block'
  | 'flex'
  | 'inline-flex'
  | 'grid'
  | 'inline-grid'
  | 'flow-root';
  justifyContent?:
  | 'flex-start'
  | 'center'
  | 'flex-end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
  justifySelf?: 'flex-start' | 'center' | 'flex-end';
  alignItems?: string;
  padding?: string;
  margin?: string;
  gap?: string;
  flexDirection?: 'row' | 'column';
  flexWrap?: 'wrap' | 'nowrap';
  background?: any;
  zIndex?: number;
  textShadow?: string;
  boxShadow?: string;
  border?: string;
  borderRadius?: string | number;
  overflowY?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
  overflowX?: 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';
  cursor?: any;
  transform?: string;
  transformOrigin?: string;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
}
const FlexContainer: React.FC<IFlexContainer> = ({
  minHeight,
  height,
  maxHeight,
  minWidth,
  width,
  maxWidth,
  display,
  justifyContent,
  justifySelf,
  alignItems,
  padding,
  margin,
  gap,
  children,
  flexDirection,
  flexWrap,
  background,
  position,
  top,
  right,
  bottom,
  left,
  zIndex,
  textShadow,
  boxShadow,
  border,
  borderRadius,
  overflowY,
  overflowX,
  cursor,
  transform,
  transformOrigin,
  onBlur,
}) => {
  const css: CSSProperties = {
    minHeight,
    height,
    maxHeight,
    minWidth,
    width,
    maxWidth,
    display,
    justifyContent,
    justifySelf,
    alignItems,
    padding,
    margin,
    gap,
    flexDirection,
    flexWrap,
    background,
    position,
    top,
    right,
    bottom,
    left,
    zIndex,
    textShadow,
    boxShadow,
    border,
    borderRadius,
    overflowY,
    overflowX,
    cursor,
    transform,
    transformOrigin,
  };
  return (
    <div style={css} onBlur={onBlur}>
      {children}
    </div>
  );
};

export default FlexContainer;
