import { Breadcrumb } from '@hexa-ui/components';
import { Link, useLocation, useParams } from 'react-router-dom';
import useWindowScale from '../../hook/useWindowScale';

export const pathstring = (pathname: string) => {
  let str = pathname.replace('-', ' ').replace('-', ' ');

  return str;
};

const createLinks = (index): any => {
  const paths = location.pathname.split('/');
  const links = [];
  paths
    .filter((f) => !new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i).test(f) && f !== '' && f != 'bees-games' && f != 'base_player' && f != 'cm_time_window_v2'
      && f != 'cm_base_lucky_number_v2' && f != 'bottle_crush_player_progress' && f != 'bc_player_score_by_level' &&
      f != 'cm_base_player_acceptance' && f != 'cm_extension_account'
      && f != 'player_attempts_v2' && f != 'bee_run_score_ranking' && f != 'extension_account')
    .map((pathname: string, index) => {
      const isParam = new RegExp('^[0-9]+$').test(pathname);
      if (!isParam) {
        links.push(pathname);
      } else {
        links.slice(-1);
        links.push(links.pop() + '/' + pathname);
      }
    });

  return links[index];
};

const AutoBreadcrumb = () => {
  const location = useLocation();
  const { id } = useParams();
  const isAugmentedScale = useWindowScale();
  const base = location.pathname.split('/');
  const paths = location.pathname
    .split('/')
    .filter(
      (f) =>
        !new RegExp(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i).test(f) &&
        !new RegExp('^[0-9]+$').test(f) &&
        f !== '' &&
        f != 'bees-games' &&
        f != 'bees-games' &&
        f != 'base_player' &&
        f != 'cm_time_window_v2' &&
        f != 'bottle_crush_player_progress' &&
        f != 'bc_player_score_by_level' &&
        f != 'cm_base_lucky_number_v2' &&
        f != 'cm_base_player_acceptance' &&
        f !== 'player_attempts_v2' &&
        f !== 'bee_run_score_ranking' &&
        f != 'extension_account'
    );
  let items = [];

  return (
    <div style={{ transform: isAugmentedScale ? 'scale(0.9)' : '' }}>
      <Breadcrumb.Root>
        <Breadcrumb.HomeItem asChild>
          <Link to="/bees-games" />
        </Breadcrumb.HomeItem>
        {paths?.map((pathname: string, index) => {
          const isParam = new RegExp('^[0-9]+$').test(pathname);

          const last = (isParam ? paths.length - 1 : paths.length) == (isParam ? index : index + 1);
          if (isParam) {
            pathname = undefined;
          }
          const treatingManageAssets = () =>
            items.join('/') === 'manage-assets'
              ? location.pathname.includes('campaign-details')
                ? '#campaign'
                : location.pathname.includes('prize-details')
                ? '#prizes'
                : ''
              : '';

          const links = createLinks(index);
          items.push(links);

          items.forEach((f: string, index: number) => {
            if (f === 'prize-details') {
              items[index] += `/${location.pathname.replace(/\D/g, '')}`;
            }
          });

          return (
            <Breadcrumb.Item
              isCurrentPage={last}
              data-testid="breadcrumb-item"
              key={index + pathname}
            >
              <Link
                style={{ textTransform: 'capitalize' }}
                to={base[1] + '/' + items.join('/') + treatingManageAssets()}
              >
                {pathstring(pathname)}
              </Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb.Root>
    </div>
  );
};

export default AutoBreadcrumb;
