import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
})

export const CarouselContentWrapper = styled('div', {
    overflow: 'hidden',
    width: '100%',
    height: '100%'
})

export const CarouselWrapper = styled('div', {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
})

export const CarouselItem = styled('div', {
    display: 'flex',
    transition: 'all 250ms linear',
    marginRight: '28px',
    '>*': {
        width: '100%',
        flexShrink: 0,
        flexGrow: '1'
    }
})