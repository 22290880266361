import { Card, Grid, Image, LoadingDots, Paragraph } from '@hexa-ui/components';
import { Edit2, Trash2 } from '@hexa-ui/icons';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import InfoField, { InfoButtonText, InfoCardTitle } from '../../../components/InfoField/InfoField';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import { AlertContext } from '../../../contexts/alert.context';
import useCustomSettingsHandler from '../../../hook/useCustomSettingsHandler';
import { AppDispatch, useAppSelector } from '../../../store';
import { CampaignType } from '../../../store/dataTypes';
import { FETCH_CAMPAIGN_DETAILS } from '../../../store/stock/CampaignDetailsReducer';
import { DELETE_CAMPAIGN_SETTINGS } from '../../../store/stock/CampaignSettingsReducer';
import { base_url } from '../../../utils/constants';

interface Settings {
  settings: {
    changed: string;
    field_text_before_digital_prize: string;
    field_text_before_prize_name: string;
    field_title: string;
    name: string;
    field_logo_header_alt: string;
    field_logo_footer_alt: string;
    field_logo_header: {
      url: string;
      id: string;
    };
    field_logo_footer: {
      url: string;
      id: string;
    };
    id: string;
  };
}

const CustomSettingsTab: React.FC = () => {
  const { addToast } = useContext(AlertContext);
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const { getCustomSettings } = useCustomSettingsHandler();
  const [customSettings, setCustomSettings] = useState<Settings>()

  const dispatch = useDispatch<AppDispatch>();
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);

  useEffect(() => {
   campaignDetailData ? getCustomSettings(campaignDetailData.settings?.field_attempt_pdf_settings.uuid).then((res) => {
      setCustomSettings({
        settings: {
          changed: res.data.attributes.changed,
          field_text_before_digital_prize: res.data.attributes.field_text_before_digital_prize,
          field_text_before_prize_name: res.data.attributes.field_text_before_prize_name,
          field_title: res.data.attributes.field_title,
          name: res.data.attributes.name,
          field_logo_header_alt: res.data.relationships.field_logo_header.data.meta.alt,
          field_logo_footer_alt: res.data.relationships.field_logo_footer.data.meta.alt,
          field_logo_header: {
            url: res.included[0].attributes.uri.url,
            id: res.included[0].id,
          },
          field_logo_footer: {
            url: res.included[1].attributes.uri.url,
            id: res.included[1].id,
          },
          id: res.data.id,
        },
      });
    }
  ) : null
  }, []);

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_CAMPAIGN_DETAILS({ id: params.id }));
    }

    init();
  }, [id]);

  async function handleDelete() {
    if (!id) {
      return;
    }
    await dispatch(DELETE_CAMPAIGN_SETTINGS({ campaignId: Number(id) })).finally(() => {
      addToast({
        message: 'PDF successfuly deleted',
        type: 'success',
      });
      navigate('/bees-games/manage-assets');
    });
  }

  return campaignDetailData == null ? (
    <Card elevated={'large'} border={'large'} css={{ padding: '1rem 0' }}>
      <FlexContainer display="flex" height="150px" justifyContent="center" alignItems="center">
        <LoadingDots size="large" />
      </FlexContainer>
    </Card>
  ) : campaignDetailData.settings ? (
    <Card
      elevated={'medium'}
      border={'large'}
      css={{ padding: '2rem 0', display: 'flex', flexDirection: 'row' }}
    >
      <Grid.Container type="fluid" style={{ rowGap: '3rem', width: '100%' }}>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ gap: '1rem' }}>
          <Image
            src={`${base_url}/${customSettings?.settings.field_logo_header.url}`}
            height={'250px'}
            width={'360px'}
            aspectRatio={1}
            variant="fill"
            style={{ borderRadius: '1rem' }}
          />
          <Image
            src={`${base_url}/${customSettings?.settings.field_logo_footer.url}`}
            height={'250px'}
            width={'360px'}
            aspectRatio={1}
            style={{ borderRadius: '1rem' }}
            variant="fill"
          />
        </Grid.Item>
        <Grid.Item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
        >
          <InfoField title="Name" text={customSettings?.settings.name}/>
          <InfoField title="Title" text={String(customSettings?.settings.field_title)} />
        </Grid.Item>
        <Grid.Item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
        >
          <InfoField
            title="Text before prize name"
            text={customSettings?.settings.field_text_before_prize_name}
          />
          <InfoField
            title="Text before digital prize code"
            text={customSettings?.settings.field_text_before_digital_prize}
          />
        </Grid.Item>
        <Grid.Item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{ flexDirection: 'column', flexWrap: 'wrap', gap: '1rem' }}
        >
          <InfoField title="Last edit" text={moment(customSettings?.settings.changed).format('DD/MM/YYYY - HH:mm')} />
        </Grid.Item>
      </Grid.Container>
      <FlexContainer alignItems="top" padding="0 2rem" flexWrap="wrap">
        <FlexContainer display="inline-flex" gap="0.5rem">
          <InfoButtonText
            icon={Edit2}
            iconPosition="leading"
            onClick={() =>
              navigate(
                '/bees-games/manage-assets/campaign-details/' +
                  campaignDetailData?.id +
                  '/edit-custom-settings',
                {
                  state: { customSettings },
                }
              )
            }
          >
            Edit
          </InfoButtonText>
          <ModalDelete
            handleDelete={() => handleDelete()}
            trigger={
              <InfoButtonText icon={Trash2} iconPosition="leading">
                Delete
              </InfoButtonText>
            }
            title="Delete PDF settings?"
            description={<>Do you really wish to delete this PDF settings?</>}
          />
        </FlexContainer>
      </FlexContainer>
      </Card>
  ) : (
    <Card
      elevated={'medium'}
      border={'large'}
      css={{
        padding: '2rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        gap: '0.5rem',
      }}
    >
      <Image
        src="https://www.beesgames.com.br/sites/default/files/2021-08/Logo%20Bees%20Games%20-%20Header%20-%20PNG.png"
        height={'200px'}
        width={'auto'}
        aspectRatio={1}
        variant="fill"
      />
      <InfoCardTitle title={'PDF settings'}></InfoCardTitle>
      <Paragraph>There is no PDF generated for this campaign yet.</Paragraph>
      <StyledButton variant="primary" onClick={() => { }}>
        Create PDF
      </StyledButton>
    </Card>
  );
};

export default CustomSettingsTab;
