import { Card, Tabs } from '@hexa-ui/components';
import { Gift, Megaphone, Plus } from '@hexa-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledTabs from '../../components/Tabs/Tabs';
import useWindowScale from '../../hook/useWindowScale';
import './ManageAssets.css';
import ManageAssetsCampaignTab from './tabs/campaignTab';
import ManageAssetsPrizesTab from './tabs/prizesTab';

const tabs = [
  { component: ManageAssetsCampaignTab, hash: '#campaign', title: 'Campaign' },
  { component: ManageAssetsPrizesTab, hash: '#prizes', title: 'Prizes' },
];

function ManageAssets() {
  const [dropdown, setDropdown] = useState(false);
  const isAugmentedScale = useWindowScale();

  const handleDropdownVisible = () => {
    setDropdown(true);
  };

  const divRef = useRef(null);
  const { hash } = useLocation();

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="manage-assets-container">
      <div className="manage-assets-header-container">
        <PageTitle title="Manage Assets" />
        <div>
          <StyledButton
            icon={Plus}
            leading
            size="medium"
            variant="primary"
            onClick={() => {
              handleDropdownVisible();
            }}
          >
            Add Asset
          </StyledButton>

          {dropdown && (
            <div
              ref={divRef}
              className="manage-assets-dropdown"
              onClick={() => {
                setDropdown(false);
              }}
            >
              <Dropdown
                optionArray={[
                  {
                    path: '/bees-games/manage-assets/create-campaign',
                    label: 'Create campaign',
                    value: 'campaign',
                    icon: <Megaphone />,
                  },
                  {
                    path: '/bees-games/manage-assets/create-prize',
                    label: 'Create prize',
                    value: 'prize',
                    icon: <Gift />,
                  },
                ]}
              />
            </div>
          )}
        </div>
      </div>
      <div className="manage-assets-list-container">
        <Card elevated="medium" border="large">
          <StyledTabs defaultValue={hash || '#campaign'}>
            <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
              {tabs?.map((tab, index) => (
                <a
                  key={index + tab.title}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                  href={tab.hash}
                >
                  <Tabs.Trigger key={index} value={tab.hash}>
                    {tab.title}
                  </Tabs.Trigger>
                </a>
              ))}
            </Tabs.List>
            {tabs?.map((tab, index) => (
              <Tabs.Content key={index} value={tab?.hash} style={{ padding: '0px' }}>
                <tab.component />
              </Tabs.Content>
            ))}
          </StyledTabs>
        </Card>
      </div>
    </div>
  );
}

export default ManageAssets;
