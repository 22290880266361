import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupalV2 } from '../../Api/Api';
import { LevelsType } from '../dataTypes/level.type';

//ASYNC THUNKS
export const FETCH_LEVELS = createAsyncThunk('fetch/levels', async (data: any, thunkAPI) => {
  const response = await apiDrupalV2.get(
    `/${data.campaign_id}/mix-and-match/levels?page=${data.page}&limit=${data.limit}`
  );
  return response.data;
});

export const FETCH_LEVEL = createAsyncThunk(
  'fetch/level',
  async (data: { campaign_id: string; id: string }, thunkAPI) => {
    const response = await apiDrupalV2.get(`/${data.campaign_id}/mix-and-match/level/${data.id}`);
    return response.data;
  }
);

export const DELETE_LEVEL = createAsyncThunk(
  'delete/prizes',
  async (data: { id: string; campaignId: string }, thunkAPI) => {
    const response = await apiDrupalV2.delete(`/${data.campaignId}/mix-and-match/level/${data.id}`);
    return response.data;
  }
);

export const PATCH_LEVEL = createAsyncThunk(
  'patch/prizes',
  async (
    data: { id: string; campaignId: string; moves: string; name: string; images_quantity: string },
    thunkAPI
  ) => {
    const campaignId = data.campaignId;
    const id = data.id;
    delete data.campaignId, data.id;

    const response = await apiDrupalV2.patch(`/${campaignId}/mix-and-match/level/${id}`, data);
    return response.data;
  }
);

export const GENERATE_LEVEL = createAsyncThunk(
  'fetch/import_players_entity',
  async (
    data: {
      levels: number;
      moves: number;
      campaignId: string;
    },
    thunkAPI
  ) => {
    let campaignId = data.campaignId;
    delete data.campaignId;
    const response = await apiDrupalV2.post(`/${campaignId}/mix-and-match/level`, {
      ...data,
      images_quantity: 4,
    });
    return response.data;
  }
);

interface ILevelsType {
  data: LevelsType[];
  details: LevelsType[];
  hasProgress: boolean;
  total: number;
  error: any;
}

const initialState: ILevelsType = {
  data: [],
  details: [],
  hasProgress: false,
  total: 0,
  error: undefined,
};

export const Slice = createSlice({
  name: 'Levels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_LEVELS
    builder.addCase(FETCH_LEVELS.pending, (state, action) => { });
    builder.addCase(FETCH_LEVELS.fulfilled, (state, action) => {
      let levels = action.payload;
      state.data = levels.data;
      state.hasProgress = levels.hasProgress
      state.total = levels.total;
    });
    builder.addCase(FETCH_LEVELS.rejected, (state, action) => {
      state.error = action.error;
    });

    builder.addCase(FETCH_LEVEL.pending, (state, action) => { });
    builder.addCase(FETCH_LEVEL.fulfilled, (state, action) => {
      let level = action.payload;
      state.details = level.data.level;
    });
    builder.addCase(FETCH_LEVEL.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
