import { Checkbox, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import StyledAlert from '../../../../../components/Alert/Alert';
import StyledButton from '../../../../../components/Button/Button';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import StyledTable, { StyledPagination } from '../../../../../components/Table/Table';
import { AlertContext } from '../../../../../contexts/alert.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import { AppDispatch, useAppSelector } from '../../../../../store';
import { LevelsType } from '../../../../../store/dataTypes/level.type';
import {
  DELETE_LEVEL,
  FETCH_LEVELS,
  GENERATE_LEVEL,
} from '../../../../../store/stock/LevelsReducer';
import LevelsHeaders from '../../../../json/columnHeaders/LevelsHeadersCreate.json';
import QuantifierLabeled from '../../../../../components/QuantifierLabeled/QuantifierLabeled';

export type formLevelType = {
  levels: number;
  moves: number;
  images_quantity: number;
};

const TabLevels: React.FC = () => {
  const { addToast } = useContext(AlertContext);
  const { setGameSettingsState, gameSettingsState } = useContext(GameSettingsContext);
  const newCampaignData = useAppSelector((state) => state.campaignDetails.newCampaign);
  const levelsData: LevelsType[] = useAppSelector((state) => state.levels.data);
  const totalPages = useAppSelector((state) => state.levels.total);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(0);
  const [data, setData] = useState(levelsData);
  const [limit, setLimit] = useState(25);
  const [requestData, setRequestData] = useState(null);
  const [erro, setErro] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = {
      campaign_id: newCampaignData.id,
      page: page,
      limit: limit,
    };
    setRequestData(requestData);
    setLoading(true);
    dispatch(FETCH_LEVELS(requestData)).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setData(InsertActions(levelsData));
  }, [levelsData]);

  const handleGenerateLevels = async () => {
    if (
      !gameSettingsState?.levels?.form ||
      !gameSettingsState?.levels?.form?.levels ||
      !gameSettingsState?.levels?.form?.moves
    ) {
      setErro(true);
      addToast({
        message: 'Please, fill in all fields to continue.',
        type: 'error',
      });
      return;
    }

    setLoading(true);
    await dispatch(
      GENERATE_LEVEL({
        campaignId: newCampaignData.id.toString(),
        ...gameSettingsState?.levels?.form,
      })
    )
      .then(async (a: any) => {
        addToast({
          message: 'Levels generated successfuly.',
          type: 'success',
        });
      })
      .catch((e) => {
        addToast({
          message: 'An error occurred while generating levels.',
          type: 'error',
        });
      })
      .finally(async () => {
        await dispatch(FETCH_LEVELS(requestData)).finally(() => {
          setGameSettingsState({
            ...gameSettingsState,
            levels: {
              form: {
                levels: 100,
                moves: 20,
              },
              check: false,
            },
          });
          setLoading(false);
        });
      });
  };

  const handlePagination = (page, limit) => {
    requestData.page = page;
    requestData.limit = limit;
    setRequestData(requestData);
    setLimit(limit);
    dispatch(FETCH_LEVELS(requestData));
  };

  const handleRemove = async (id) => {
    await dispatch(DELETE_LEVEL({ campaignId: params.id, id: id })).finally(async () => {
      await dispatch(FETCH_LEVELS(requestData));
    });
  };

  const InsertActions = (data: LevelsType[]) => {
    let aux: LevelsType[] = [];

    const actions = (level: LevelsType) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => {
            navigate(
              `/bees-games/manage-assets/campaign-details/${params.id}/edit-level/${level.id}`
            );
          }}
        />
        <Modal.Root
          actions={
            <FlexContainer
              width="100%"
              display="inline-flex"
              justifyContent="flex-end"
              gap="0.5rem"
            >
              <Modal.Action>
                <StyledButton variant="secondary">Cancel</StyledButton>
              </Modal.Action>
              <Modal.Action>
                <StyledButton variant="destructive" onClick={() => handleRemove(level.id)}>
                  Delete
                </StyledButton>
              </Modal.Action>
            </FlexContainer>
          }
          trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
          variant="overlay"
          destructive="critical"
        >
          <Heading type="H3" css={{ marginBottom: '1rem' }}>
            Delete level?
          </Heading>
          <Paragraph>{'Do you really wish to delete this level?'}</Paragraph>
          <Paragraph>{'This action can’t be undone.'}</Paragraph>
        </Modal.Root>
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item: LevelsType = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <div>
      <FlexContainer
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexWrap="wrap"
        gap="0.5rem"
      >
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-start'}
          alignItems={'flex-end'}
          padding={'0px 0 1rem 0'}
          gap={'0.5rem'}
          flexWrap="wrap"
          position="relative"
        >
          <QuantifierLabeled
              title="Levels quantity*"
              labelText="Number of levels to be generated."
              defaultValue={gameSettingsState?.levels?.form?.levels}
              value={gameSettingsState?.levels?.form?.levels}
              min={1}
              onChange={(e) => {
                let v = parseInt(e.currentTarget.value, 10);
                if (isNaN(v)) {
                  v = 1;
                }
                v = v <= 0 ? 1 : v;
                setGameSettingsState({...gameSettingsState,
                  levels: {
                    ...gameSettingsState.levels,
                    form: {
                      ...gameSettingsState.levels.form, levels: v
                    }
                  }
                });
              }}
              onClickPlusButton={() => {
                setGameSettingsState({
                  ...gameSettingsState,
                  levels: {
                    ...gameSettingsState.levels,
                    form: {
                      ...gameSettingsState.levels.form,
                      levels:  gameSettingsState.levels.form.levels + 1,
                    },
                  },
                });
              }}
              onClickMinusButton={() => {
                setGameSettingsState({
                  ...gameSettingsState,
                  levels: {
                    ...gameSettingsState.levels,
                    form: {
                      ...gameSettingsState.levels.form,
                      levels: gameSettingsState.levels.form.levels - 1,
                    },
                  },
                });
              }}
            />
          <StyledInput
            required
            value={gameSettingsState?.levels?.form?.moves || ''}
            size="large"
            label="Moves*"
            hint="Moves allowed per level."
            placeholder="Digite um número."
            width="300px"
            onKeyDown={(e) => [',', '.', '-', '+', '*'].includes(e.key) && e.preventDefault()}
            onChange={(e) => {
              let val = e.currentTarget.value;
              val = val === '0' ? '1' : val;
              setGameSettingsState({
                ...gameSettingsState,
                levels: {
                  ...gameSettingsState.levels,
                  form: {
                    ...gameSettingsState.levels.form,
                    moves: Number(val),
                  },
                },
              });
              setErro(false);
            }}
            hasError={erro && !gameSettingsState?.levels?.form?.moves}
            errorText="Moves is required."
          />
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        flexWrap="wrap"
        gap="0.5rem"
      >
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-start'}
          alignItems={'flex-center'}
          padding={'1rem 0'}
          gap={'0.5rem'}
          flexWrap="wrap"
          position="relative"
        >
          <Checkbox
            checked={gameSettingsState?.levels?.check}
            id="example"
            label="I am aware of the action that will be performed."
            onClick={() =>
              setGameSettingsState({
                ...gameSettingsState,
                levels: {
                  ...gameSettingsState.levels,
                  check: !gameSettingsState.levels.check,
                },
              })
            }
          />
        </FlexContainer>
        <StyledButton
          variant="primary"
          icon={Plus}
          isLoading={loading}
          leading
          style={{
            display: gameSettingsState?.levels?.check ? 'flex' : 'none',
            marginBottom: '20px'
          }}
          onClick={() => handleGenerateLevels()}
        >
          Generate levels
        </StyledButton>
      </FlexContainer>
      <StyledAlert
        message={'This action cannot be undone.'}
        type={'warning'}
        style={{ width: '100%', margin: '20px 0' }}
      />
      <br />
      <StyledTable
        columns={LevelsHeaders}
        search={false}
        data={levelsData || []}
        loading={loading}
        pagination={false}
      />
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'20px 20px 20px 0'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <StyledPagination
          pageSize={limit}
          total={totalPages}
          current={page}
          onChange={handlePagination}
        />
      </FlexContainer>
    </div>
  );
};

export default TabLevels;
