import { Card, Checkbox, Grid, Heading, Paragraph } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useWindowDimensions from '../hook/useWindowDimensions';
import { AppDispatch } from '../store';

import StyledAlert from '../components/Alert/Alert';
import StyledButton from '../components/Button/Button';
import StyledInput from '../components/Input/Input';
import { AlertContext } from '../contexts/alert.context';
import { LevelsType } from '../store/dataTypes/level.type';
import { GENERATE_LEVEL } from '../store/stock/LevelsReducer';

export default function GenerateLevels() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  const { width } = useWindowDimensions();
  const [form, setForm] = useState<LevelsType>(undefined);
  const { addToast } = useContext(AlertContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      setForm({
        ...form,
        levels: 100,
        moves: 20,
        images_quantity: 4,
      });
    }

    init();
  }, []);

  const handleSave = async () => {
    if (!form?.moves || !form?.levels) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    await dispatch(GENERATE_LEVEL({ campaignId: id, ...form }))
      .then(async (a: any) => {
        addToast({
          message: 'Levels generated successfuly',
          type: 'success',
        });

        navigate(-1);
      })
      .catch((e) => {
        addToast({
          message: e.message,
          type: 'error',
        });
      });
    setLoading(false);
  };

  const [check, setCheck] = useState(false);

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Heading size="H2" css={{ marginBottom: '1rem' }}>
            Do you want to generate new levels?
          </Heading>
        </Grid.Item>

        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="small" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'flex-start',
                display: width <= 1024 ? 'contents' : '',
                padding: '2rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={9} lg={8} md={7} sm={12} xs={12}>
                <Grid.Container
                  type="fluid"
                  style={{
                    width: '100%',
                    height: 'max-content',
                    rowGap: '1rem',
                    margin: 0,
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 10,
                      marginTop: 10,
                    }}
                  >
                    <StyledInput
                      value={form?.levels}
                      required
                      size="small"
                      label="Levels quantity*"
                      hint="Number of levels to be generated."
                      width="200px"
                      onChange={(e) => {
                        let val = Number(e.currentTarget.value);
                        val = val < 0 ? 0 : val;

                        setForm({
                          ...form,
                          levels: val,
                        });
                      }}
                    // disabled
                    />
                    <Paragraph style={{ color: '#757575', marginBottom: 10 }}>levels</Paragraph>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      gap: 10,
                    }}
                  >
                    <StyledInput
                      value={form?.moves}
                      required
                      size="small"
                      label="Moves*"
                      hint="Moves allowed per level."
                      width="200px"
                      onChange={(e) => {
                        let val = Number(e.currentTarget.value);
                        val = val < 0 ? 0 : val;

                        setForm({
                          ...form,
                          moves: val,
                        });
                      }}
                    // disabled
                    />
                    <Paragraph style={{ color: '#757575', marginBottom: 10 }}>moves</Paragraph>
                  </div>
                  <Checkbox
                    style={{
                      marginLeft: 10,
                    }}
                    id="example"
                    label="I am aware of the action that will be performed."
                    onClick={() => setCheck(!check)}
                  />
                  <StyledAlert
                    message={'This action cannot be undone.'}
                    type={'warning'}
                    style={{ width: '100%', margin: '20px 0' }}
                  />
                </Grid.Container>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-start',
                  gap: '0.5rem',
                  padding: '10px',
                }}
              >
                {check && (
                  <StyledButton isLoading={loading} variant="primary" onClick={() => handleSave()}>
                    Generate
                  </StyledButton>
                )}
                <StyledButton
                  variant="secondary"
                  onClick={() =>
                    navigate('/bees-games/manage-assets/campaign-details/' + id + '#levels')
                  }
                  isLoading={loading}
                >
                  Cancel
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
