import { FileUploader, FileUploaderType, Paragraph } from '@hexa-ui/components';
import { Container } from './FileUploader.styles';

interface ContainerProps {
    width?: string | number
    title?: string
    legend?: any
    id?: any
}

const StyledFileUploader = (
    {
        maxHeight,
        minHeight,
        width,
        title,
        legend,
        id,
        ...props
    }: FileUploaderType & ContainerProps,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
): JSX.Element => {
    return (
        <Container width={width} maxHeight={maxHeight} id={id} minHeight={minHeight}>
            <Paragraph size="small" weight="semibold">
                {title}
            </Paragraph>
            <Paragraph size='xsmall' type='body' style={{ color: '#757575', marginBottom: '0.25rem' }}>
                {legend}
            </Paragraph>
            <FileUploader {...props} />
        </Container>
    );
};

export default StyledFileUploader
    ;