import { Card, FileValidated, Grid, Heading, IconButton, Modal, Paragraph, Select } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';
import { styled } from '@hexa-ui/theme';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '../../hook/useWindowDimensions';
import StyledInput from '../../components/Input/Input';
import StyledButton from '../../components/Button/Button';
import StyledSelect from '../../components/Select/Select';
import StyledTextarea from '../../components/Textarea/Textarea';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import verifyImgType from '../../utils/verifyImgType';
import { AlertContext } from '../../contexts/alert.context';
import StyledTable from '../../components/Table/Table';
import editChallengeHeaders from "../json/columnHeaders/editChallengesLocalHeaders.json"
import mockData from "../json/mock/mockingEditChallengesLocalData.json"
import { Edit2, Trash2 } from '@hexa-ui/icons';
import FlexContainer from '../../components/FlexContainer';

const StyledGridItem = styled(Grid.Item, {
  paddingLeft: '0',
});

export default function EditLocalChallenge() {
  const [data, setData] = useState(mockData);
  const [filteredData, setFilteredData] = useState(mockData);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { addToast } = useContext(AlertContext);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [fileError, setFileError] = useState(false);
  const typelist = ['png', 'jpg', 'jpeg', 'gif'];
  const [imageFileSizeError, setImageFileSizeError] = useState(false);
  const items = [{ name: 'Item 1' }, { name: 'Item 2' }, { name: 'Item 3' }];

  useEffect(() => {
    setData(InsertActions(filteredData));
  }, [filteredData]);

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const search = (searchTerm) => {
    const filteredItems = items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  const handleSelectChange = (e, id) => {
/*     dispatch({
      type: 'changed_field',
      field: {
        value: e,
        id: id,
      },
    }); */
  };

  const sizeValidation = (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      setImageFileSizeError(true);
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      handleSelectChange([], 'field_image');
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      handleSelectChange(e, 'field_image');
    }
  };

  const InsertActions = (data) => {
    return data.map((item) => {

      return {
        ...item,
        image: <img src={item.image.url} width={50} height={50} alt="Football Leagues Logo"/>,
        actions: (
          <div style={{ display: 'inline-flex', gap: '1rem' }}>
            <>
              <Modal.Root
                actions={
                  <FlexContainer
                    width="100%"
                    display="inline-flex"
                    justifyContent="flex-end"
                    gap="0.5rem"
                  >
                    <Modal.Action>
                      <StyledButton variant="secondary">Cancel</StyledButton>
                    </Modal.Action>
                    <Modal.Action>
                      <StyledButton variant="primary" onClick={() => ''}>
                        Save
                      </StyledButton>
                    </Modal.Action>
                  </FlexContainer>
                }
                trigger={<IconButton icon={Edit2} variant="inherit" onClick={() => { }}/>}
                variant="overlay"
              >
                <FlexContainer display='flex' flexDirection='column' gap='1rem'>
                  <Heading type="H3" css={{ marginBottom: '0.5rem' }}>
                    Update challenge media?
                  </Heading>
                  <StyledSelect
                    width={'45%'}
                    label="Type*"
                    hint='The type displayed in the campaign.'
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <Select.Option value="highlighted">Highlighted</Select.Option>
                    <Select.Option value="modal">Modal</Select.Option>
                    <Select.Option value="chance_used">Chance used</Select.Option>
                    <Select.Option value="chance_available">Chance available</Select.Option>
                    <Select.Option value="chance_unavailable">Chance unavailable</Select.Option>
                  </StyledSelect>
                  <ImageUploader
                    id=""
                    title="Image"
                    url={''}
                    error={fileError && { message: 'The selected file cannot be uploaded.' }}
                    accept=".png,.jpg,.gif,.jpeg"
                    maxFileSize={1000 * 1000}
                    message="The image of the challenge. One file only. 256 MB limit. Allowed types: png gif jpg jpeg."
                    value={item.image.url}
                    onDrop={(e) => {
                      verifiedFile(e);
                    }}
                    onClean={() => handleSelectChange('', 'field_image')}
                  />
                </FlexContainer>
              </Modal.Root>
              <Modal.Root
                actions={
                  <FlexContainer
                    width="100%"
                    display="inline-flex"
                    justifyContent="flex-end"
                    gap="0.5rem"
                  >
                    <Modal.Action>
                      <StyledButton variant="secondary">Cancel</StyledButton>
                    </Modal.Action>
                    <Modal.Action>
                      <StyledButton variant="destructive" onClick={() => ''}>
                        Delete
                      </StyledButton>
                    </Modal.Action>
                  </FlexContainer>
                }
                trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => {}} />}
                variant="overlay"
                destructive="critical"
              >
                <Heading type="H3" css={{ marginBottom: '0.5rem' }}>
                  Delete media?
                </Heading>
                <Paragraph>{"Do you really wish to delete this media?"}</Paragraph>
                <Paragraph>{"This action can't be undone."}</Paragraph>
              </Modal.Root>
            </>
          </div>
        )
      };
    });
  };

  return (
    <Grid.Container type="fluid" style={{ margin: 0 }}>
      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Heading size="H2" css={{ marginBottom: '1rem' }}>
          Edit {''}
        </Heading>
      </StyledGridItem>

      <StyledGridItem xl={12} lg={12} md={12} sm={12} xs={12}>
        <Card elevated="medium" border="large" css={{ width: '100%', paddingBottom: '0.5rem' }}>
          <Grid.Container
            type="fluid"
            style={{
              justifyContent: 'flex-start',
              display: width <= 1024 ? 'contents' : '',
              padding: '2rem 0 1rem 0',
            }}
          >
            <Grid.Item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Grid.Container
                type="fluid"
                style={{
                  width: '100%',
                  height: 'max-content',
                  margin: 0,
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  gap: '1rem',
                }}
              >
                  <StyledSelect
                    width={'30%'}
                    label="Category"
                    hint='The challenge category.'
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <Select.Option value="none">- None -</Select.Option>
                  </StyledSelect>
                  <StyledInput
                    size="large"
                    label="Name*"
                    width={'100%'}
                    placeholder=''
                    hint="The name of the Rewards challenge entity."
                  />
                  <StyledSelect
                    width={'70%'}
                    label="Challenge type*"
                    hint='The type of the challenge. Indicates whether the challenge is conducted locally, without integration to an API (Local), or remotely, where validation occurs through API integration (Remote).'
                    placeholder='Local'
                    disabled
                  >
                  </StyledSelect>
                  <StyledInput
                    size="large"
                    label="Challenge id*"
                    width={'100%'}
                    placeholder=''
                    hint="Challenge unique identifier."
                  />
                  <ImageUploader
                    url={''}
                    title="Image"
                    value={''}
                    error={fileError && { message: 'The selected file cannot be uploaded.' }}
                    onDrop={(e) => {
                      verifiedFile(e);
                    }}
                    id='image'
                    onClean={() => handleSelectChange('', 'field_image')}
                    message="The image of the challenge. One file only. 256 MB limit. Allowed types: png gif jpg jpeg."
                  />
                  <StyledTextarea
                    style={{ height: '222px', resize: 'none' }}
                    label="Description"
                    width={'100%'}
                    hint='The description of the Campaign challenge entity.'
                    placeholder="Insert you text here"
                  />
                  <StyledTextarea
                    style={{ height: '222px', resize: 'none' }}
                    label="Detailed description"
                    width={'100%'}
                    hint='The description detailed of the Campaign challenge entity.'
                    placeholder="Insert you text here"
                  />
                  <Heading size='H4'>
                    External link
                  </Heading>
                  <SearchDropdown
                    width=""
                    label="URL"
                    hint="Start typing the title of a piece of content to select it. You can also enter an internal path such as /node/add or an external URL such as http://example.com. Enter <front> to link to the front page. Enter <nolink> to display link text only. Enter <button> to display keyboard-accessible link text only."
                    placeholder=""
                    onSearch={search}
                    onChange={(e) => {
                      e.currentTarget.value;
                    }}
                    onClickResult={(item) => {
                      console.log(`Evento selecionado: ${item.name}`);
                    }}
                  />
                  <StyledInput
                    size="large"
                    label="Link text"
                    width={'100%'}
                    placeholder=''
                    hint="The external link."
                  />
                  <StyledInput
                    value={''}
                    type="datetime-local"
                    size="large"
                    label="Start date"
                    hint='Date and hour the challenge will begin.'
                    placeholder="Insert the date"
                    width={'100%'}
                    required
                    className="calendars"
                    onChange={(e) => {
/*                       checkStartDate(e.currentTarget.value, form?.period?.end) === true
                        ? setForm({
                            ...form,
                            period: {
                              ...form?.period,
                              start: e.currentTarget.value,
                            },
                          })
                        : setForm({
                            ...form,
                            period: {
                              ...form?.period,
                              start: null,
                            },
                          }); */
                    }}
                    /* hasError={stepIncompleted && !form?.period?.start} */
                    errorText="Start is required."
                  />
                  <StyledInput
                    value={''}
                    type="datetime-local"
                    size="large"
                    label="End date"
                    hint='Date and hour the challenge will end.'
                    placeholder="Insert the date"
                    width={'100%'}
                    required
                    onChange={(e) => {
/*                       checkEndDate(form?.period?.start, e.currentTarget.value) === true
                        ? setForm({
                            ...form,
                            period: {
                              ...form?.period,
                              end: e.currentTarget.value,
                            },
                          })
                        : setForm({
                            ...form,
                            period: {
                              ...form?.period,
                              end: null,
                            },
                          }); */
                    }}
                    /* hasError={stepIncompleted && !form?.period?.end} */
                    errorText="End is required."
                  />
                  <StyledSelect
                    width={'30%'}
                    label="Mode"
                    hint='The challenge mode.'
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    <Select.Option value="published">PUBLISHED</Select.Option>
                    <Select.Option value="cancelled">CANCELLED</Select.Option>
                  </StyledSelect>
                  <Card border={'medium'} elevated={'medium'} style={{ gap: '1rem', padding: '1rem' }}>
                    <Heading size='H4'>
                      Media
                    </Heading>
                    <StyledTable
                      columns={editChallengeHeaders}
                      search={false}
                      data={data || []}
                      loading={loading}
                      pagination={false}
                    />
                  </Card>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div
              style={{
                width: '100%',
                display: 'inline-flex',
                justifyContent: 'flex-end',
                gap: '0.5rem',
                padding: '10px',
              }}
            >
              <StyledButton variant="secondary" isLoading={loading} onClick={() => navigate(-1)}>
                Cancel
              </StyledButton>
              <StyledButton isLoading={loading} variant="primary" onClick={() => null}>
                Save
              </StyledButton>
            </div>
          </Grid.Item>
        </Card>
      </StyledGridItem>
    </Grid.Container>
  );
}
