import { TextArea, TextAreaProps } from '@hexa-ui/components';
import { Container } from './Textarea.styles';

const StyledTextarea = (
    {
        width,
        ...props
    }: TextAreaProps & { ref?: React.ForwardedRef<HTMLTextAreaElement> },
    fowardedRef: React.ForwardedRef<HTMLTextAreaElement>
): JSX.Element => {
    return (
        <Container width={width}>
            <TextArea {...props} />
        </Container>
    );
};

export default StyledTextarea;