import { SearchField } from '@hexa-ui/components';
import React, { HTMLProps } from 'react';
import { Container } from './Filters.styles';

type HTMLInputPropsSizeOmitted = Omit<HTMLProps<HTMLInputElement>, 'size'>;
export interface SearchFieldProps extends HTMLInputPropsSizeOmitted {
    size?: 'medium' | 'large';
    children?: JSX.Element;
    width?: string | number;
    onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    onSearch?: (value: string, event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const StyledFilter =
    (
        {
            width,
            maxWidth,
            ...props
        }: any
    ): JSX.Element => {
        return (
            <Container css={{ maxWidth: maxWidth, width: width }} >
                <SearchField.Root {...props} width={'100%'} />
            </Container>
        );
    };

export default StyledFilter;