import QuestionsTab from "../tabs/QuestionsTab";
import QuizTab from "../tabs/QuizTab";
import ResultsTab from "../tabs/ResultsTab";

const QuizDetailTabs = {
  tabs: [
    { component: QuizTab, hash: '#quiz', title: 'Quiz' },
    { component: ResultsTab, hash: '#results', title: 'Results' },
    { component: QuestionsTab, hash: '#questions', title: 'Questions' },
  ],
}

export default QuizDetailTabs;
