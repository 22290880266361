import { Paragraph, Radio } from '@hexa-ui/components';
import React from 'react';
import { RadioContainer } from './StyledRadio.styles';

interface RadioComponentProps {
  style?: any;
  label?: string;
  hint?: string;
  value?: string;
  defaultValue?: string;
  options: Array<{ id: string; label: string; value: string }>;
  type?: string;
  onChange?: (value: string) => void;
}

const StyledRadio: React.FC<RadioComponentProps> = ({
  style,
  label,
  hint,
  value,
  defaultValue,
  options,
  type,
  onChange,
}) => {
  const handleRadioClick = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <RadioContainer style={style}>
      {label && <Paragraph size='small' weight="semibold">{label}</Paragraph>}
      {hint && (
        <Paragraph size="xsmall" css={{ color: '#757575' }}>
          {hint}
        </Paragraph>
      )}
      <Radio.Root defaultValue={defaultValue} value={value} onValueChange={handleRadioClick} css={{ display: type === 'horizontal' ? 'flex' : null }}>
        {options.map((option) => (
          <Radio.Item
            key={option.id}
            id={option.id}
            label={option.label}
            value={option.value}
          />
        ))}
      </Radio.Root>
    </RadioContainer>
  );
};

export default StyledRadio;
