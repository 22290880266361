import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DisplayFilter } from '../../../components/CheckboxFilter/CheckboxFilter';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { AppDispatch, useAppSelector } from '../../../store';
import { CampaignType } from '../../../store/dataTypes';
import { FETCH_MANAGEASSETS_CAMPAIGN } from '../../../store/stock/manageAssetsReducer';
import { FilterByCheckbox } from '../../../utils/filterByCheckbox';
import { changeState } from '../../../utils/functions';
import CampaignHeaders from '../../json/columnHeaders/CampaignHeadersManage.json';

function ManageAssetsCampaignTab() {
  const campaignData: CampaignType[] = useAppSelector((state) => state.manageAssets.campaignData);
  const totalPages: number = useAppSelector((state) => state.manageAssets.totalCampaignData);
  const [checkboxListFilters, setCheckboxListFilters] = useState({});
  const [filteredData, setFilteredData] = useState(campaignData)
  const [total, setTotal] = useState(totalPages);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('');
  const [requestData, setRequestData] = useState({
    page: 0,
    limit: 0,
    search: null,
    country: localStorage.getItem('country'),
  });

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  WindowFocusHandler(async () => {
    setLoading(true);
    const requestData = {
      page,
      limit,
      search,
      country: localStorage.getItem('country'),
    };
    setRequestData(requestData);
    await dispatch(FETCH_MANAGEASSETS_CAMPAIGN(requestData)).finally(() => setLoading(false));
  });

  useEffect(() => {
    setRequestData({ ...requestData, country: localStorage.getItem('country') });
    setLoading(true);
    handleSearch(search);
  }, [localStorage.getItem('country')]);

  useEffect(() => {
    setFilteredData(campaignData)
    setTotal(totalPages)
  }, [campaignData])

  useEffect(() => {
    const data = FilterByCheckbox(campaignData, checkboxListFilters, { game: 'type.target_id', status: 'statusDetail.text' })
    setFilteredData(data)
    setTotal(data?.length)
  }, [checkboxListFilters]);

  const handlePagination = (page, limit) => {
    if (Object.keys(checkboxListFilters).length < 1) {
      setLoading(true);
      requestData.page = page - 1;
      requestData.limit = limit;
      requestData.search = search;
      requestData.country = localStorage.getItem('country');
      setRequestData(requestData);
      setLimit(limit);
      dispatch(FETCH_MANAGEASSETS_CAMPAIGN(requestData)).finally(() => setLoading(false));
    }

  };

  const handleSearch = (value) => {
    if (value.length == 0 || value.length >= 2) {
      setPage(1);
      requestData.search = value;
      requestData.country = localStorage.getItem('country');
      setRequestData(requestData);
      setLoading(true);
      dispatch(FETCH_MANAGEASSETS_CAMPAIGN(requestData)).finally(() => setLoading(false));
    }
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 250),
    []
  );

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    changeState(setSearch, event.currentTarget.value);
    debouncedSearch(event.currentTarget.value);
  };

  const handleCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks)
  }

  const deleteCheckboxFilter = (checks) => {
    setCheckboxListFilters(checks)
  }

  const FiltersContainer = {
    width: "100%",
    display: 'inline-flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1rem',
    gap: '0.5rem'
  }

  const ContainerFilterTags = styled('div', {
    padding: '0px $space$6 $space$6'

  })

  return (
    <>
      <div style={FiltersContainer}>
        {/*<CheckboxFilter
          selectedCheckboxes={checkboxListFilters}
          onChange={handleCheckboxFilter}
          menuTabs={[
            { acessor: 'game', label: 'Game', key: 'game' },
            { acessor: 'status', label: 'Status', key: 'status' }
          ]}
          tabItems={{
            game: gameOptions,
            status: statusOptions
          }}
        />*/}
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={handleChange}
            onClear={(e) => {
              setSearch('')
              handleSearch('');
            }}
          />
        </div>
      </div>
      {Object.keys(checkboxListFilters).length > 0 ? <ContainerFilterTags>
        <DisplayFilter checkFilters={checkboxListFilters} deleteFilter={deleteCheckboxFilter} />
      </ContainerFilterTags> : null}
      <StyledTable
        loading={loading}
        columns={CampaignHeaders}
        data={filteredData || []}
        pagination={Object.keys(checkboxListFilters).length < 1 ? false : {
          pageSize: 20,
          pageSizeOptions: [5, 10, 20],
          showPageSizeSelector: true,
        }}
        onRow={(data, index) => ({
          onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
            const game = data.game.replace(/ /g, '_');

            navigate('/bees-games/manage-assets/campaign-details/' + data.id + '#campaign', {
              state: {
                game,
              },
            });
          },
        })}
      />
      {Object.keys(checkboxListFilters).length < 1 ? <FlexContainer
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'0.5rem 1rem'}
        width="100%"
        flexWrap="wrap"
      >
        <StyledPagination
          pageSize={limit}
          total={total}
          current={page}
          onChange={handlePagination}
        />
      </FlexContainer> : null}
    </>
  );
}

export default ManageAssetsCampaignTab;
