import { Tabs, Toast } from '@hexa-ui/components';
import { CSSProperties, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledTabs from '../../components/Tabs/Tabs';
import { AlertContext } from '../../contexts/alert.context';
import { AppDispatch, useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes';
import { FETCH_CAMPAIGN_DETAILS } from '../../store/stock/CampaignDetailsReducer';
import CampaignDetailTabs from './extra/campaignDetailTabs';

const CampaignDetails = () => {
  const { addToast } = useContext(AlertContext);
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const selectedGame: string = useAppSelector((state) => state.campaignDetails.selectedGame);
  const dispatch = useDispatch<AppDispatch>();

  const { state } = useLocation();
  const params = useParams();
  const [showToast, setShowToast] = useState(false);
  const [toastParams, setToastParams] = useState<{
    message: string;
    type: 'info' | 'error' | 'warning' | 'success';
  }>({
    message: '',
    type: 'info',
  });
  const { hash } = useLocation();

  useEffect(() => {
    if (state?.toast) {
      addToast({
        message: state?.toast?.message,
        type: state?.toast?.type || 'info',
      });
    }

    dispatch(FETCH_CAMPAIGN_DETAILS({ id: params?.id }));
  }, []);

  const tabsContentCSS: CSSProperties = {
    backgroundColor: 'transparent',
    padding: '1.6rem 0 0 0',
  };

  return (
    <div>
      <PageTitle marginBottom="1.2rem" title="Campaign details" />
      <StyledTabs defaultValue={hash || '#campaign'}>
        <Tabs.List>
          {CampaignDetailTabs[selectedGame]?.map((tab, index) => {
            return tab.title !== 'Challenges' && tab.title!== 'PDF settings' ||
              (tab.title === 'Challenges' && campaignDetailData?.rewards_integration || tab.title == 'PDF settings' && campaignDetailData?.settings) ? (
              <a
                key={index + tab.title}
                style={{ textDecoration: 'none', color: 'inherit' }}
                href={tab.hash}
              >
                <Tabs.Trigger key={index} value={tab.hash}>
                  {tab.title}
                </Tabs.Trigger>
              </a>
            ) : (
              <></>
            );
          })}
        </Tabs.List>
        {CampaignDetailTabs[selectedGame]?.map((tab, index) => (
          <Tabs.Content key={index} value={tab?.hash} style={tabsContentCSS}>
            <tab.component game={campaignDetailData?.type?.target_id} />
          </Tabs.Content>
        ))}
      </StyledTabs>
      {/* TOAST */}
      <Toast.Provider duration={3000} swipeDirection="right">
        <Toast.Root
          open={showToast}
          message={toastParams.message}
          type={toastParams.type || 'info'}
          position={'top-right'}
          showCloseButton
        />
      </Toast.Provider>
    </div>
  );
};
export default CampaignDetails;
