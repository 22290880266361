import { Card, Grid, Heading, IconButton, LoadingDots, Paragraph } from '@hexa-ui/components';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store';
import { useParams } from 'react-router-dom';
import ExportStatus from '../../../components/ExportStatus';
import StyledInput from '../../../components/Input/Input';
import { Download } from '@hexa-ui/icons';
import LinkedCheckbox from '../../../components/LinkedCheckbox/LinkedCheckbox';
import { InfoCardTitle } from '../../../components/InfoField/InfoField';
import { CampaignType } from '../../../store/dataTypes';
import useAnalyticsHandler from '../../../hook/useAnalyticsHandler';
import FlexContainer from '../../../components/FlexContainer';
import { changeStateForm } from '../../../utils/functions';

interface analytics {
  active_campaigns: number;
  total_mix_and_match_levels_played: number;
  total_mix_and_match_player_progress: number;
  total_pocs_acceptance: number;
  total_pocs_chances: number;
  total_pocs_eligible: number;
  total_pocs_remaining_chances: number;
  total_predictor_guess: number;
  total_predictor_ranking_score_prizes_delivered: number;
  total_time_window_pocs_attempt: number;
  total_time_window_prizes: number;
  total_time_window_prizes_delivered: number;
}

type AnalyticsDownloadForm = {
  period_start: string;
  period_end: string;
  fields: string;
  status: string;
  game: string;
}

const AnalyticsTab: React.FC = () => {
  const campaignDetailData: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const { getAnalytics, downloadAnalytics } = useAnalyticsHandler();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState<analytics>();
  const [form, setForm] = useState<AnalyticsDownloadForm>({
    period_start: campaignDetailData.period.start,
    period_end: campaignDetailData.period.end,
    game: campaignDetailData.type.target_id,
    status: campaignDetailData.status === 'Unpublished' ? '&status=0' : '&status=1',
    fields: '',
  });

  const fetchData = async () => {
    setLoading(true)
    await getAnalytics(id, form)
      .then(res => {
        setAnalytics(res.data)
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    fetchData();
  }, []);

  const download = async (id, form) => {
    const data = await downloadAnalytics(id, form);

    const href = window.URL.createObjectURL(data);
    const el = document.createElement('a');
    el.href = href;
    el.download = 'download.csv';
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
    window.URL.revokeObjectURL(href);
  };

  const updateFormFields = (field: string, checked: boolean) => {
    const currentFields = form.fields.split('&').filter(f => f);
    if (checked) {
        currentFields.push(`${field}=1`);
    } else {
        const index = currentFields.indexOf(`${field}=1`);
        if (index > -1) {
            currentFields.splice(index, 1);
        }
    }
    const newFields = currentFields.length > 0 ? '&' + currentFields.join('&') : '';
    setForm({ ...form, fields: newFields });
};

  return (
    <>
      <ExportStatus bundle="cm_base_player_acceptance" campaignId={id} />
      <Card elevated={'medium'} border={'large'}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'start',
            padding: '1.5rem 0 1rem 0',
          }}
        >
          <Grid.Container
            type="fluid"
            style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: 'column' }}
          >
            {loading ? (
              <FlexContainer
                width="100%"
                height="50px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <LoadingDots />
              </FlexContainer>
            ) : (
            <>
            <InfoCardTitle title={'Download any metric you want from this campaign.'} />
            <Heading size="H5">Metrics Available for {campaignDetailData.game}</Heading>
            {campaignDetailData.game === 'mix and match' &&
            <>
            <LinkedCheckbox
              id="total_mix_and_match_levels_played"
              labelText={`Total Mix and Match Levels Played. ${analytics?.total_mix_and_match_levels_played}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_mix_and_match_levels_played]", checked)}
            />
            <LinkedCheckbox
              id="total_mix_and_match_player_progress"
              labelText={`Total Mix and Match Player Progress. ${analytics?.total_mix_and_match_player_progress}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_mix_and_match_player_progress]", checked)}
            />
            </>
            }
            <LinkedCheckbox
              id="total_pocs_acceptance"
              labelText={`Total Pocs Acceptance. ${analytics?.total_pocs_acceptance}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_pocs_acceptance]", checked)}
            />
            <LinkedCheckbox
              id="total_pocs_eligible"
              labelText={`Total Pocs Eligible. ${analytics?.total_pocs_eligible}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_pocs_eligible]", checked)}
            />
            <LinkedCheckbox
              id="total_pocs_remaining_chances"
              labelText={`Total Pocs Remaining Chances. ${analytics?.total_pocs_remaining_chances}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_pocs_remaining_chances]", checked)}
            />
            {['spinning wheel', 'penalty kick', 'instant card'].includes(campaignDetailData.game) &&
            <>
            <LinkedCheckbox
              id="total_time_window_pocs_attempt"
              labelText={`Total Time Window Pocs Attempt. ${analytics?.total_time_window_pocs_attempt}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_time_window_pocs_attempt]", checked)}
            />
            <LinkedCheckbox
              id="total_time_window_prizes_delivered"
              labelText={`Total Time Window Prizes Delivered. ${analytics?.total_time_window_prizes_delivered}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes_delivered]", checked)}
            />
            <LinkedCheckbox
              id="total_time_window_prizes"
              labelText={`Total Time Window Prizes. ${analytics?.total_time_window_prizes}`}
              linkText=""
              linkHref=""
              onCheckedChange={(checked) => updateFormFields("only[total_time_window_prizes]", checked)}
            />
            </>
            }
            {analytics?.total_pocs_chances &&
            <LinkedCheckbox
            id="total_pocs_chances"
            labelText={`Total Pocs Chances. ${analytics?.total_pocs_chances}`}
            linkText=""
            linkHref=""
            onCheckedChange={(checked) => updateFormFields("only[total_pocs_chances]", checked)}
            />}
            <Paragraph size="small" colortype="disabled">
              Select the metrics available to download.
            </Paragraph>
            <StyledInput
              value={form.period_start}
              type="datetime-local"
              size="large"
              label="Start metric date*"
              hint="Date of the first draw from the start of the campaign."
              placeholder="Insert the date"
              width={'25%'}
              required
              className="calendars"
              onChange={(e) => changeStateForm(setForm, form, 'period_start', e.currentTarget.value)}
              hasError={false}
              errorText="Start is required."
            />
            <StyledInput
              value={form.period_end}
              type="datetime-local"
              size="large"
              hint="Date of the last draw from the start of the campaign."
              label="End metric date*"
              placeholder="Insert the date"
              width={'25%'}
              required
              onChange={(e) => changeStateForm(setForm, form, 'period_end', e.currentTarget.value)}
              hasError={false}
              errorText="End is required."
            />
            </> )}
          </Grid.Container>
        </Grid.Container>
      </Card>
      <IconButton
        disabled={false}
        icon={Download}
        style={{ marginTop: '0.5rem' }}
        size="large"
        variant="secondary"
        onClick={() => download(id, form)}
      />
    </>
  );
};

export default AnalyticsTab;
