import { Card, Heading, IconButton, Modal, Paragraph, Tooltip } from '@hexa-ui/components';
import { Edit2, HelpCircle, Plus, Trash2 } from '@hexa-ui/icons';
import debounce from 'lodash.debounce';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import StyledFilter from '../../../components/Filter/Filter';
import FlexContainer from '../../../components/FlexContainer';
import ShowColor from '../../../components/ShowColor/ShowColor';
import StyledTable from '../../../components/Table/Table';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { AppDispatch, useAppSelector } from '../../../store';
import { PrizeType } from '../../../store/dataTypes/prize.type';
import {
  DELETE_PRIZE_CAMPAIGN,
  FETCH_PRIZES_CAMPAIGN,
} from '../../../store/stock/PrizeCampaignReducer';
import { changeState } from '../../../utils/functions';
import PrizeHeaders from '../../json/columnHeaders/PrizeHeaders.json';
import PrizeHeadersSpinning from '../../json/columnHeaders/PrizeListHeadersSpinning.json';
import PrizeStaticHeaders from '../../json/columnHeaders/PrizeStaticHeaders.json';
import PrizeStaticHeadersSpinning from '../../json/columnHeaders/PrizeStaticListHeadersSpinning.json';
import useTimeWindowHandler from '../../../hook/useTimeWindowHandler';
import { apiDrupal } from '../../../Api/Api';
import { base_url } from '../../../utils/constants';
import { TimeWindowType } from '../../../store/dataTypes/timeWindow.type';
import StyledAlert from '../../../components/Alert/Alert';
import moment from 'moment';
interface props {
  game;
}
const PrizesTab: React.FC<props> = ({ game }) => {
  const params = useParams();
  const prizesData: PrizeType[] = useAppSelector((state) => state.prizeCampaign.data);
  const campaignDetailStatus: string = useAppSelector((state) => state.campaignDetails.data?.status);
  const campaignUuid: string = useAppSelector((state) => state.campaignDetails.data?.uuid);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [requestData, setRequestData] = useState({
    id: params?.id,
    page,
    limit,
    search,
    game
  });
  const [filteredPrizeData, setFilteredPrizeData] = useState<PrizeType[]>(prizesData);
  const { getAllTimeWindow } = useTimeWindowHandler();
  const { id } = useParams();
  const [timeWindowData, setTimeWindowData] = useState<TimeWindowType[]>();

  const navigate = useNavigate();

  WindowFocusHandler(async () => {
    if (!params.id) {
      return;
    }
    const requestData = {
      id: params.id,
      page: page,
      limit: limit,
      search: search,
      game: game
    };
    setRequestData(requestData);
    setLoading(true);
    await dispatch(FETCH_PRIZES_CAMPAIGN(requestData));

    const [timeWindowResponse] = await Promise.all([
      getAllTimeWindow(id),
      apiDrupal.get(`${base_url}/hub/v2/competition/${id}/prizes`),
    ]);

    const timeWindowData = game === 'bee_run' ? [] : timeWindowResponse.data.data

    setTimeWindowData(timeWindowData);
    setLoading(false);
  });

  const handleRemove = async (prize: PrizeType) => {
    await dispatch(DELETE_PRIZE_CAMPAIGN({ campaignId: params.id, id: prize.id, game: game })).finally(
      async () => {
        await dispatch(FETCH_PRIZES_CAMPAIGN(requestData));
      }
    );
  };

  const debouncedSearch = useCallback(
    debounce((nextValue) => handleSearch(nextValue), 250),
    []
  );

  const handleSearch = (value) => {
    if (value.length == 0 || value.length >= 2) {
      setPage(1);
      requestData.search = value;
      setRequestData(requestData);
      setLoading(true);
      dispatch(FETCH_PRIZES_CAMPAIGN(requestData)).finally(() => setLoading(false));
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    changeState(setSearch, event.currentTarget.value);

    debouncedSearch(event.currentTarget.value);
  };

  React.useEffect(() => {
    let data: PrizeType[] = InsertActions(prizesData);
    setFilteredPrizeData(data);
  }, [prizesData, search, filter, timeWindowData]);

  const InsertBgColor = (data) => {
    let insertBgColor: any[] = [...data];
    insertBgColor = insertBgColor.map((item) => ({
      ...item,
      field_spin_prize_bg_color: <ShowColor color={item?.field_spin_prize_bg_color?.color} />,
      field_spin_prize_text_color: <ShowColor color={item?.field_spin_prize_text_color?.color} />,
    }));
    return insertBgColor;
  };

  const checkTimeWindow = (prize: PrizeType) => {
    timeWindowData?.sort((a, b) => {
      const dateA:any = new Date(a.created);
      const dateB:any = new Date(b.created);
      return dateB - dateA;
    });

    const mostRecentTimeWindow = timeWindowData?.[0];

    if (timeWindowData?.some(timeItem => timeItem.name === prize.name) && moment.utc(prize.created).format('DD/MM/YYYY-HH:mm:ss') < moment(mostRecentTimeWindow?.created).format('DD/MM/YYYY-HH:mm:ss')) {
      return true
    }

    return false
  }

  const InsertActions = (data: PrizeType[]) => {
    if (campaignDetailStatus === 'Unpublished') {
      let aux: PrizeType[] = [];

      const actions = (prize: PrizeType) => (
        <div style={{ display: 'inline-flex', gap: '1rem' }}>
          <IconButton
            icon={Edit2}
            variant="inherit"
            onClick={() => {
              navigate(
                `/bees-games/manage-assets/campaign-details/${params.id}/edit-prize/${prize.id}`,
                {
                  state: {
                    game,
                    checkTimeWindow: checkTimeWindow(prize),
                    timeWindowData,
                    prizesData: data.map(prize => ({
                      id: prize.id,
                      quantity: prize.quantity,
                      name: prize.name,
                      created: prize.created,
                    }))
                  },
                }
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleRemove(prize)}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton disabled={checkTimeWindow(prize)} icon={Trash2} variant="inherit" onClick={() => { }} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete prize?
            </Heading>
            <Paragraph>{'Do you really wish to delete this prize?'}</Paragraph>
            <Paragraph>{'This action can’t be undone.'}</Paragraph>
          </Modal.Root>
        </div>
      );

      for (let i = 0; i < data.length; i++) {
        let item: PrizeType = data[i];
        aux[i] = Object.assign({}, item, {
          actions: actions(item),
        });
      }

      return aux;
    }
    return data
  };

  const getHeaders = () => {
    if (campaignDetailStatus === 'Unpublished') {
      return game === 'spinning_wheel' ? PrizeHeadersSpinning : PrizeHeaders
    } else return game === 'spinning_wheel' ? PrizeStaticHeadersSpinning : PrizeStaticHeaders
  }

  return (
    <>
    {timeWindowData?.length >= 1 ? <StyledAlert type="warning" message={`You can not change or delete the prize with TW generated.`} style={{ width: '50%' }} css={{ marginBottom: '1rem' }} /> : " "}
    <Card elevated={'medium'} border={'large'} css={{ padding: '0px', position: 'relative' }}>
      <FlexContainer
        display={'inline-flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        padding="1rem"
        flexWrap="wrap"
      >
        <div style={{ maxWidth: '496px', width: '496px' }}>
          <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={handleChange}
            onClear={(e) => {
              setSearch('');
              handleSearch('');
            }}
          />
        </div>
        {campaignDetailStatus === 'Unpublished' ?
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <Tooltip text={'Add and verify that the quantity of prizes is correct before generating the time window'}>
            <HelpCircle size="large" />
          </Tooltip>
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            isLoading={loading}
            onClick={() =>
              navigate(`/bees-games/manage-assets/campaign-details/${params.id}/add-prize`, {
                state: {
                  id: params.id,
                  game,
                  timeWindowData,
                  campaignUuid
                },
              })
            }
          >
            Add prize
          </StyledButton>
          </div> : null}
      </FlexContainer>
      <StyledTable
        loading={loading}
        columns={getHeaders()}
        data={
          game === 'spinning_wheel' ? InsertBgColor(filteredPrizeData) : filteredPrizeData || []
        }
        pagination={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20],
          showPageSizeSelector: true,
        }}
      />
    </Card>
    </>
  );
};

export default PrizesTab;
