import { Card, Grid, Image, Modal } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import InfoField from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import { StyledModal } from '../../components/ModalDelete/ModalDelete';
import PageTitle from '../../components/PageTitle/PageTitle';
import { AlertContext } from '../../contexts/alert.context';
import useChallengeHandler from '../../hook/useChallengeHandler';
import { useAppSelector } from '../../store';
import { CampaignType } from '../../store/dataTypes';
import { getJsonApiData } from '../../utils/formatData';
import SearchDropdown from '../../components/SearchDropdown/SearchDropdown';

type campaignDetailsType = {
  description: string
  title: string
  image: string
}

export default function AddChallengeLocal() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { addToast } = useContext(AlertContext);
  const { getChallengeDetails } = useChallengeHandler();
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const { linkChallenge, getCountries } = useChallengeHandler();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [challengeId, setChallengeId] = useState<string>('');
  const [challengeDetails, setChallengeDetails] = useState<campaignDetailsType | null>(null);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const TypeItems = [{ name: '1st option' }, { name: 'Multiple choice' }];

  const search = (searchTerm) => {
    const filteredItems = TypeItems.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return Promise.resolve(filteredItems);
  };

  useEffect(() => {
    setChallengeId(state)
  }, [])

  const handleSend = async () => {
    setLoading(true)
    return await linkChallenge({
      challenge_id: challengeId,
    },
      String(campaignDetails?.id)).then((res) => {
        if (res === undefined || res.data.length < 1 || res === undefined) {
          setShowModal(false)
          addToast({
            message: 'Error when linking the challenge to campaign',
            type: 'error',
          })
        } else {
          addToast({
            message: 'Challenge successfuly linked',
            type: 'success',
          })
          navigate(-1);
        }
        setLoading(false)
      }).catch((err) => {
        addToast({
          message: 'Error when linking the challenge to campaign',
          type: 'error',
        })
        setLoading(false)
      })
  };

  const showPreview = async () => {
    if (!challengeId) {
      addToast({
          message: 'Please fill in all fields in challenge to continue!',
          type: 'error',
      });
      setHasEmptyField(true);
  } else {
    setHasEmptyField(false);
    setLoading(true)
    let countryName = ''
    await getCountries(campaignDetails?.country, 'name').then((res) => {
      const code = (getJsonApiData(res).data)[0]?.country
      countryName = code
    })
    await getChallengeDetails(challengeId, countryName)
      .then(res => {
        if (res.code === "ERR_NETWORK" || res.data.length < 1 || res === undefined) addToast({
          message: 'An error occurred when calling up challenge details.',
          type: 'error',
        });
        else {
          setChallengeDetails(res.data[0])
          setShowModal(true)
        }
      })
      .catch(() => {
        addToast({
          message: 'An error occurred when calling up challenge details.',
          type: 'error',
        });
      })
      .finally(() => setLoading(false))
    }
  }

  const ContainerActions = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    button: {
      marginRight: '10px'
    }
  })

  const ContainerDetails = styled('div', {
    textAlign: 'left',
    overflowY: 'scroll',
    overflowX: 'hidden',
    width: '100%',
    maxHeight: '42vh'
  })

  const TitleDetails = styled('div', {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem',
    div: {
      marginRight: '1rem'
    }
  })

  return (
    <Grid.Container style={{ width: '100%' }}>
      <StyledModal
        variant='overlay'
        title="Challenge details"
        maxWidth='70rem'
        width='65rem'
        description={
          <ContainerDetails>
            <TitleDetails>
              <InfoField title="Challenge id:" text={challengeId} />
              <InfoField title="Title:" text={challengeDetails?.title} />
            </TitleDetails>
            <TitleDetails>
              <InfoField title="Description:" text={challengeDetails?.description} />
            </TitleDetails>
            <TitleDetails>
              <InfoField title="Detailed description:" />
            </TitleDetails>
            <Image src={challengeDetails?.image} width='100%' containerCss={{ width: '100%', border: '0px', marginTop: '20px', textAlign: 'center' }} />
          </ContainerDetails>
        }
        actions={
          <ContainerActions>
            <Modal.Action>
              <StyledButton variant="secondary" disabled={loading} onClick={() => setShowModal(false)}>
                Cancel
              </StyledButton>
            </Modal.Action>
            <Modal.Action>
              <StyledButton
                variant="primary"
                onClick={handleSend}
                isLoading={loading}
              >
                Confirm
              </StyledButton>
            </Modal.Action>
          </ContainerActions>
        }
        open={showModal}
        destructive='off'
      />
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px', marginBottom: '20px' }}
      >
        <PageTitle marginBottom="0.5rem" title="Add Challenge Local" />
      </Grid.Item>
      <Card
        elevated="small"
        border="medium"
        css={{ width: '100%', padding: '1.5rem 1rem 0px', marginBottom: '20px' }}
      >
        <Grid.Item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ flexDirection: 'column', gap: '1rem' }}
        >
          <StyledInput
            label="Campaign"
            placeholder="Campaign Example"
            size="large"
            width="30%"
            hint="The current campaign."
            value={campaignDetails?.name}
            disabled
          />
          <SearchDropdown
            width="30%"
            label="Challenge*"
            hint="The challenge."
            value=''
            placeholder=""
            onSearch={search}
            onChange={(e) => {
              e.currentTarget.value;
            }}
            onClickResult={(item) => {
              console.log(`Evento selecionado: ${item.name}`);
            }}
          />
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px 0px 20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={showPreview}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Card>
    </Grid.Container>
  );
}
