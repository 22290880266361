import { styled } from '@hexa-ui/theme';
import { StyledParagraph } from '../Heading/Typography';

export const Container = styled('div', {
    flexDirection: 'column',
    justifyContent: 'center'
})

export const ContainerTitle = styled('div', {
    display: 'flex'
})

export const StyledText = styled(StyledParagraph, {
    variants: {
        size: {
            xsmall: {
                fontSize: '0.75rem',
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '0.55rem'
                }
            },
            small: {
                fontSize: '0.875rem',
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '0.75rem'
                }
            }
        },
    }
})