const base64FileConverter = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () => {
            resolve(reader.result)
      };
      reader.onerror = error => reject(error);
})

export const base64CSVConverter = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
            resolve(reader.result)
      };
      reader.onerror = error => reject(error);

})

export default base64FileConverter
