import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

//REDUCERS
import AcceptanceReducer from './stock/AcceptanceReducer';
import BrandReducer from './stock/BrandReducer';
import CampaignDetailsReducer from './stock/CampaignDetailsReducer';
import CampaignSettingsReducer from './stock/CampaignSettingsReducer';
import CategoryReducer from './stock/CategoryReducer';
import CountryReducer from './stock/CountryReducer';
import DigitalPrizeCodeReducer from './stock/DigitalPrizeCodeReducer';
import ExportReducer from './stock/ExportReducer';
import FileUploadReducer from './stock/FileUploadReducer';
import LevelsReducer from './stock/LevelsReducer';
import luckyNumbersReducer from './stock/luckyNumbersReducer';
import ManageAssetsReducer from './stock/manageAssetsReducer';
import PlayersReducer from './stock/PlayersReducer';
import PrizeCampaignReducer from './stock/PrizeCampaignReducer';
import prizeEditionReducer from './stock/prizeEditionReducer';
import PrizeReducer from './stock/PrizeReducer';
import TimeWindowReducer from './stock/TimeWindowReducer';

export const store = configureStore({
  reducer: {
    manageAssets: ManageAssetsReducer,
    campaignDetails: CampaignDetailsReducer,
    prizes: PrizeReducer,
    digitalPrizeCode: DigitalPrizeCodeReducer,
    players: PlayersReducer,
    timeWindow: TimeWindowReducer,
    luckyNumber: luckyNumbersReducer,
    acceptance: AcceptanceReducer,
    editPrize: prizeEditionReducer,
    uploadFile: FileUploadReducer,
    brands: BrandReducer,
    categories: CategoryReducer,
    prizeCampaign: PrizeCampaignReducer,
    country: CountryReducer,
    campaignSettings: CampaignSettingsReducer,
    export: ExportReducer,
    levels: LevelsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<any>>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
