import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { categoryType } from '../dataTypes/category.type';

//ASYNC THUNKS
export const FETCH_CATEGORIES = createAsyncThunk('fetch/categories', async (thunkAPI) => {
  const response = await apiDrupal.get('/categories');
  return response.data;
});

interface ICategoryState {
  data: categoryType[];
  error: any;
}

const initialState: ICategoryState = {
  data: [],
  error: undefined,
};

export const Slice = createSlice({
  name: 'Category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_CATEGORIES
    builder.addCase(FETCH_CATEGORIES.pending, (state, action) => {});
    builder.addCase(FETCH_CATEGORIES.fulfilled, (state, action) => {
      state.data = Object.values(action.payload.data.categories);
    });
    builder.addCase(FETCH_CATEGORIES.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
