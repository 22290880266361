import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';

//Pages
import _routes from './_routes';

const Router = (): JSX.Element => {
  return (
    <Routes>
      {_routes.map((_route, index) => {
        return (
          <Route
            key={index}
            path={_route.path}
            element={
              <Suspense fallback={<Spinner show dataTest="transition-spinner" />}>
                <_route.page />
              </Suspense>
            }
          />
        );
      })}
    </Routes>
  );
};
export default Router;
