import { Card, IconButton, Modal } from '@hexa-ui/components';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '../../../store';
import { EXPORT_DELETE_NO_CAMPAIGN, EXPORT_DOWNLOAD, EXPORT_LIST_NO_CAMPAIGN } from '../../../store/stock/ExportReducer';

import { Download, Trash2 } from '@hexa-ui/icons';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import { StyledModal } from '../../../components/ModalDelete/ModalDelete';
import PageTitle from '../../../components/PageTitle/PageTitle';
import StyledTable from '../../../components/Table/Table';
import { AlertContext } from '../../../contexts/alert.context';
import { ExportType } from '../../../store/dataTypes/export.type';
import headers from './../json/export.header.json';

const ExportListNoCampaign: React.FC = () => {
    const { addToast } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);

    const [isOpen, setIsOpen] = useState(false);

    const [selectedExport, setSelectedExport] = useState<ExportType>(undefined);

    const { id, bundle } = useParams();
    const data: ExportType[] = useAppSelector((state) => state.export.data);
    const exportState = useAppSelector((state) => state.export);

    const [exportData, setExportData] = useState([]);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        listAll();
    }, []);

    useEffect(() => {
        const dataActions = InsertActions(data);
        setExportData(dataActions);
    }, [data]);

    const handleDeleteExport = async (exportId: number) => {
        setLoading(true);
        await dispatch(EXPORT_DELETE_NO_CAMPAIGN({ bundle, exportId })).finally(() => {
            setLoading(false);
            if (exportState.error) {
                addToast({ message: 'An error occurred while deleting the export.', type: 'error' });
            } else {
                setIsOpen(false);
                addToast({ message: 'Data export successfully deleted.', type: 'success' });
                listAll();
            }
        });
    };

    const download = (file_id: any, name: any) => {
        dispatch(EXPORT_DOWNLOAD({ file_id, name })).then((a: any) => {
            const href = window.URL.createObjectURL(a.payload);
            const el = document.createElement('a');
            el.href = href;
            el.download = `data-export-${file_id}-${bundle}-campaign-${id}`;
            document.body.appendChild(el);
            el.click();
            document.body.removeChild(el);
            window.URL.revokeObjectURL(href);
        });
    };

    const listAll = async () => {
        setLoading(true);
        await dispatch(
            EXPORT_LIST_NO_CAMPAIGN({
                bundle
            })
        ).finally(() => setLoading(false));

    };

    function InsertActions(data: ExportType[], setState?: SetStateAction<any>) {
        let aux: ExportType[] = [];

        const actions = (data: ExportType) => (
            <FlexContainer display="inline-flex" gap="0.5rem">
                <IconButton
                    icon={Trash2}
                    variant="inherit"
                    onClick={() => {
                        setSelectedExport(data);
                        setIsOpen(true);
                    }}
                />

                {data.export_status !== 'queued' && (
                    <IconButton
                        icon={Download}
                        variant="inherit"
                        onClick={() => download(data.file.target_id, data.name)}
                    />
                )}
            </FlexContainer>
        );

        for (let i = 0; i < data.length; i++) {
            let item: ExportType = data[i];
            aux[i] = Object.assign({}, item, {
                actions: actions(item),
            });
        }

        return aux;
    }

    return (
        <div>
            <PageTitle marginBottom="1.2rem" title="Export list" />
            <Card
                elevated="medium"
                border="large"
                css={{
                    padding: '2rem 0px 0px',
                }}
            >
                <StyledTable loading={loading} columns={headers} data={exportData} />
            </Card>
            <StyledModal
                title="Delete export?"
                description="Do you really wish to delete this export? This action can’t be undone."
                actions={
                    <FlexContainer width="100%" display="inline-flex" justifyContent="flex-end" gap="0.5rem">
                        <Modal.Action>
                            <StyledButton variant="secondary" onClick={() => setIsOpen(false)}>
                                Cancel
                            </StyledButton>
                        </Modal.Action>
                        <Modal.Action>
                            <StyledButton
                                variant="destructive"
                                onClick={() => handleDeleteExport(selectedExport.id)}
                                isLoading={loading}
                            >
                                Delete
                            </StyledButton>
                        </Modal.Action>
                    </FlexContainer>
                }
                open={isOpen}
                variant="overlay"
                destructive="critical"
            />
        </div>
    );
};

export default ExportListNoCampaign;
