import { Card } from '@hexa-ui/components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledTable from '../../components/Table/Table';
import headers from './json/import.header.json';
import useAccountHandler from '../../hook/useAccountHandler';

const ImportList: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const { bundle } = useParams();
  const { getAccountStatus } = useAccountHandler();

  const [importData, setImportData] = useState([]);

  useEffect(() => {
    listAll();
  }, []);

  const listAll = async () => {
    setLoading(true);
    await getAccountStatus().then((response) => {
      setImportData([response.data]);
   }).catch((error) => {
     if (error?.message) console.log(error?.message);
   }).finally(() => setLoading(false));
  };

  console.log(importData);


  return (
    <div>
      <PageTitle marginBottom="1.2rem" title="Import list" />
      <Card
        elevated="medium"
        border="large"
        css={{
          padding: '2rem 0px 0px',
        }}
      >
        <StyledTable loading={loading} columns={headers} data={importData[0] === null ? [] : importData} />
      </Card>
    </div>
  );
};

export default ImportList;
