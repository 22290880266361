import { SetStateAction } from "react";

export const changeState = (setState: SetStateAction<any>, value: any) => {
    setState(value)
}

export const changeStateForm = (setState: SetStateAction<any>, state: any, key: string, value: any) => {
    let aux = state
    aux = {
        ...aux,
        [key]: value
    }

    setState(aux)
};
