import { Heading, Paragraph, TextButton, TextButtonTypes } from '@hexa-ui/components';
import React from 'react';
import { Container } from './InfoField.styles';

interface InfoFieldProps {
    title: string
    text?: string | JSX.Element
    padding?: string
}

interface InfoCardTitleProps {
    title: String
    size?: "H5" | "Hero" | "H1" | "H2" | "H3" | "H4"
}

const InfoField = (
    {
        text,
        title,
        padding

    }: InfoFieldProps
): JSX.Element => {
    return (
        <Container padding={padding}>
            <Heading size="H5" css={{ paddingBottom: '4px' }}>
                {title}
            </Heading>
            {typeof text === 'string' ? <Paragraph>{text}</Paragraph> : <div style={{ padding: '0px' }}>{text}</div>}
        </Container>
    );
};

export const InfoCardTitle = (
    {
        title,
        size = 'H3'
    }: InfoCardTitleProps
): JSX.Element => {
    return (
        <Container>
            <Heading size={size}>
                {title}
            </Heading>
        </Container>
    );
};

export const InfoButtonText =
    (
        {
            ...props
        }: TextButtonTypes & { ref?: React.ForwardedRef<HTMLButtonElement> }
    ): JSX.Element => {
        return (
            <Container style={{ display: 'contents' }}>
                <TextButton {...props} />
            </Container>
        );
    };

export default InfoField;