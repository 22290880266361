
// import { Container } from './styles';

import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface LuckyNumberHandleInterface {
    createLuckyNumber: (requestData, campaignId) => Promise<any>,
    uploadLuckyNumberCSV: (requestData, campaignId) => Promise<any>,
    getLuckyNumberStatus: (campaignId) => Promise<any>,
    getAllLuckyNumber: (campaignId) => Promise<any>,
}

type CSVLuckyNumber = {
    fileName: string;
    file: any;
}

type LuckyNumber = {
    name: string;
    description: string;
    field_base_campaign: number;
    field_file: any[];
}

export default function useLuckyNumberHandler(): LuckyNumberHandleInterface {
    const getAllLuckyNumber = async (campaignId) => {
        return await apiDrupal.get(`${base_url}/hub/v2/competition/${campaignId}/lucky-number`)
            .then(response => response.data)
            .catch(error => error.response);

    }

    const createLuckyNumber = async (requestData: LuckyNumber, campaignId) => {
        return await apiDrupal.post(`${base_url}/hub/v2/competition/${campaignId}/import/base_lucky_number?_format=json`,
            { ...requestData, field_added_to_queue: 0 })
            .then(response => response.data)
            .catch(error => error.response);

    }

    const getLuckyNumberStatus = async (campaignId) => {
        return await apiDrupal.get(`${base_url}/hub/v2/competition/${campaignId}/import/base_lucky_number/status`)
            .then(response => response.data)
            .catch(error => error);
    }

    const uploadLuckyNumberCSV = async (requestData: CSVLuckyNumber, campaignId) => {
        return await apiDrupal.post(`${base_url}/file/upload/cm_import/base_lucky_number/field_file?_format=json&campaign=${campaignId}`,
            requestData.file, {
            headers: {
                'Content-Type': 'application/octet-stream',
                Accept: 'application/vnd.api+json',
                'Content-Disposition': 'aittachment; filename="' + requestData.fileName + '"',
            },
        })
            .then(response => response.data)
            .catch(error => error.response);

    }
    return { createLuckyNumber, getAllLuckyNumber, uploadLuckyNumberCSV, getLuckyNumberStatus }
}