import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { brandType } from '../dataTypes/brand.type';

//ASYNC THUNKS
export const FETCH_BRANDS = createAsyncThunk('fetch/brands', async (thunkAPI) => {
  const response = await apiDrupal.get('/brands');
  return response.data;
});

interface IBrandState {
  data: brandType[];
  error: any;
}

const initialState: IBrandState = {
  data: [],
  error: undefined,
};

export const Slice = createSlice({
  name: 'Brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_BRANDS
    builder.addCase(FETCH_BRANDS.pending, (state, action) => {});
    builder.addCase(FETCH_BRANDS.fulfilled, (state, action) => {
      state.data = Object.values(action.payload.data.brands);
    });
    builder.addCase(FETCH_BRANDS.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
