export const warningMessages = {
  time_window_empty:
    "No time window has been registered yet. To publish your campaign, first you must  register a time window in the 'Time Window' tab.",
  time_window_prize_empty:
    "No prizes have been registered yet. To publish your campaign, first you must register the prizes in the 'Prizes' tab.",
  time_window_total:
    'The number of registered time windows does not match the number of prizes in the campaign.',
  digital_prize_code_total:
    'The number of digital prize codes imported does not match the number of the digital prizes registered in the campaign.',
  time_window_x_prize_quantity_mismatch:
    "Some of the prizes you've created do not match the quantity of time windows assigned for those prizes.",
  ValidationErrors:
    "To publish your campaign, please ensure you have accepted the terms and conditions and consented to the data usage.",
};
