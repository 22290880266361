import { Heading, Modal, Paragraph } from '@hexa-ui/components';
import { ModalProps } from '@hexa-ui/components/dist/declarations/src/lib/dialog/Modal';
import { MouseEventHandler, ReactNode } from 'react';
import StyledButton from '../Button/Button';
import { Container } from './ModalDelete.styles';

interface ModalDeleteProps {
    handleDelete?: MouseEventHandler<HTMLButtonElement>
    trigger?: ReactNode
    title: string
    type?: 'delete' | 'leave'
    description: string | JSX.Element
    variant?: "overlay" | "bottomTray" | "takeOver"
    maxWidth?: string
    width?: string
    height?: string
}

const ModalDelete =
    (
        {
            handleDelete,
            trigger,
            title,
            description,
            type = 'delete',
            height,
            ...props
        }: ModalDeleteProps
    )
        : JSX.Element => {
        return (
            <Container>
                <Modal.Root
                    actions={
                        <div
                            style={{
                                width: "100%",
                                display: "inline-flex",
                                justifyContent: "flex-end",
                                gap: "0.5rem"

                            }}
                        >
                            <Modal.Action>
                                <StyledButton variant="secondary">{type === 'delete' ? 'Cancel' : 'No, keep creating'}</StyledButton>
                            </Modal.Action>
                            <Modal.Action>
                                <StyledButton onClick={handleDelete} variant="destructive">
                                    {type === 'delete' ? 'Delete' : 'Yes, leave'}
                                </StyledButton>
                            </Modal.Action>
                        </div>
                    }
                    trigger={trigger}
                    variant="overlay"
                    destructive={type === 'delete' ? "critical" : 'off'}>
                    <div style={{ maxWidth: '31.25rem', height: height }}>
                        <Heading size="H2" css={{ marginBottom: '1rem' }}>
                            {title}
                        </Heading>
                        <Paragraph type='body'>{description}</Paragraph>
                    </div>
                </Modal.Root>
            </Container>
        );
    };

export const StyledModal =
    (
        {
            title,
            description,
            maxWidth = '31.25rem',
            width,
            ...props
        }: ModalProps & ModalDeleteProps
    )
        : JSX.Element => {
        return (
            <Container>
                <Modal.Root variant='overlay' {...props}>
                    <div style={{ maxWidth: maxWidth, width: width }}>
                        <Heading size="H2" css={{ marginBottom: '1rem' }}>
                            {title}
                        </Heading>
                        <Paragraph type='body'>
                            {description}
                        </Paragraph>
                    </div>
                </Modal.Root>
            </Container>
        );
    };

export default ModalDelete;
