import { FileValidated, IconButton } from '@hexa-ui/components';
import { Edit2, Trash2 } from '@hexa-ui/icons';
import { useEffect, useRef, useState } from 'react';
import StyledFileUploader from '../FileUploader/FileUploader';
import { Container, IconButtonsContainer } from './imageUploader.style';
interface props {
  message?: string;
  accept?: string;
  maxFileSize?: number;
  onChange?: (files: FileValidated[]) => void;
  error?: any;
  onError?: () => void;
  url: any;
  title: string;
  value: FileValidated[] | any;
  onDrop: (files: FileValidated[]) => void;
  onClean: any;
  id: string
  loading?: boolean;
}
const ImageUploader: React.FC<props> = ({
  message,
  accept,
  maxFileSize,
  error,
  onChange,
  onError,
  url,
  title,
  value,
  onDrop,
  onClean,
  id,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setIsEdit((typeof value === 'string' && value !== '') || (Array.isArray(value) && value.length !== 0) || ((typeof value === 'object' && value.constructor === Object) && value[0].file !== undefined))
  }, [value]);

  const handleClear = (e) => {
    e.stopPropagation();
    onClean();
  }


  const handleEdit = () => {
    const sectionDropzone: any = document.querySelector(`#${id} section`);
    if (sectionDropzone) sectionDropzone?.click();
  }

  return <Container ref={inputRef} url={url} showActions={isEdit} background={(typeof value === 'string' && value !== '') || value !== undefined ? url : 'none'}>
    {isEdit ? <IconButtonsContainer>
      <IconButton
        variant="secondary"
        type="button"
        icon={Trash2}
        onClick={handleClear}
        style={{ marginRight: '16px' }}
        aria-label="Delete and clear"
        elevation
      />
      <IconButton
        type="button"
        variant="secondary"
        icon={Edit2}
        onClick={handleEdit}
        aria-label="Edit"
        elevation
      />
    </IconButtonsContainer> : null}
    <StyledFileUploader
      title={title}
      value={value}
      error={error}
      onDrop={onDrop}
      accept=".png,.jpg,.jpeg,.gif"
      maxFileSize={1000000}
      id={id}
      onClean={handleClear}
      message="Allowed PNG, JPG, JPEG, GIF format and must be less than 1MB"
      width="100%"
      {...props}
    />
  </Container>
};

export default ImageUploader;
