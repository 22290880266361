import { SetStateAction } from 'react';
const handleFilter = (value: string, data: any[], keys: string[], setState: SetStateAction<any>, callback?: VoidFunction) => {
      if (!value) {
            return;
      }
      let aux = [];

      data.forEach((item: any) => {
            let result = false;

            keys.forEach((key: string) => {
                  let searchValue = String(value).toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                  let field = String(item[key]).toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
                  if (field.includes(searchValue)) {
                        result = true;
                        return
                  }
            })

            if (result) {
                  aux.push(item);
            }
      })
      setState(aux)

      callback && callback()
};

export default handleFilter