import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface CustomSettingsHndleInterface {
  getCustomSettings: (uuid) => Promise<any>
  editCustomSettings: (data) => Promise<any>
  uploadHeader: (requestData) => Promise<any>
  uploadFooter: (requestData) => Promise<any>
}

export default function useCustomSettingsHandler(): CustomSettingsHndleInterface {
  const getCustomSettings = async (uuid: string) => {
    return await apiDrupal.get(`${base_url}/jsonapi/cm_settings/attempt_pdf_settings/${uuid}?include=field_logo_header,field_logo_footer`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  const uploadHeader = async (requestData) => {
    return await apiDrupal.post(base_url + '/jsonapi/cm_settings/attempt_pdf_settings/field_logo_header', requestData.file, {
      headers: {
        'Content-Type': 'application/octet-stream',
        Accept: 'application/vnd.api+json',
        'Content-Disposition': 'file; filename="' + requestData.fileName + '"',
      },
    })
    .then((res) => res.data)
    .catch(error => error);
}

const uploadFooter = async (requestData) => {
  return await apiDrupal.post(base_url + '/jsonapi/cm_settings/attempt_pdf_settings/field_logo_footer', requestData.file, {
    headers: {
      'Content-Type': 'application/octet-stream',
      Accept: 'application/vnd.api+json',
      'Content-Disposition': 'file; filename="' + requestData.fileName + '"',
    },
  })
  .then((res) => res.data)
  .catch(error => error);
}

  const editCustomSettings = async (data: any) => {
    return await apiDrupal.patch(`${base_url}/jsonapi/cm_settings/attempt_pdf_settings/${data.id}`,
    {
      "data": {
          "type": "cm_settings--attempt_pdf_settings",
          "id": `${data.id}`,
          "attributes": {
              "name": `${data.name}`,
              "field_text_before_digital_prize": `${data.field_text_before_digital_prize}`,
              "field_text_before_prize_name": `${data.field_text_before_prize_name}`,
              "field_title": `${data.field_title}`
          },
          "relationships": {
              "field_logo_footer": {
                  "data": {
                      "type": "file--file",
                      "id": `${data.field_logo_footer}`
                  }
              },
              "field_logo_header": {
                  "data": {
                      "type": "file--file",
                      "id": `${data.field_logo_header}`
                  }
              }
          }
      }
  }, {
        headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: '*/*'
        }
    })
        .then(response => response.data)
        .catch(error => error.response);
}

  return { getCustomSettings, editCustomSettings, uploadHeader, uploadFooter };
}
