import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal, apiDrupalV2 } from '../../Api/Api';
import { base_url } from '../../utils/constants';

//ASYNC THUNKS
export const FETCH_LUCKY_NUMBERS = createAsyncThunk(
  'fetch/lucky_number',
  async (idCampaign: string, thunkAPI) => {
    const response = await apiDrupalV2.get('/' + idCampaign + '/lucky-number');
    return response.data;
  }
);

export const IMPORT_LUCKY_NUMBERS = createAsyncThunk(
  'fetch/base_lucky_number',
  async (
    data: {
      idCampaign: string;
      fileName: string;
      description?: string;
      file: any;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      base_url +
        '/file/upload/cm_import/base_lucky_number/field_file?_format=json&campaign=' +
        data.idCampaign,
      data.file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': 'aittachment; filename="' + data.fileName + '"',
        },
      }
    );
    return response.data;
  }
);

export const IMPORT_LUCKY_NUMBERS_ENTITY = createAsyncThunk(
  'fetch/import_lucky_numbers_entity',
  async (
    data: {
      name: string;
      description: string;
      field_base_campaign: number;
      field_file: any[];
    },
    thunkAPI
  ) => {
    const response = await apiDrupalV2.post(
      '/' + data.field_base_campaign + '/import/base_lucky_number?_format=json',
      data
    );
    return response.data;
  }
);

interface ILuckyNumberState {
  data: any[];
  error: any;
}

const initialState: ILuckyNumberState = {
  data: [],
  error: null,
};

export const Slice = createSlice({
  name: 'LuckyNumber',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // IMPORT_LUCKY_NUMBERS
    builder.addCase(IMPORT_LUCKY_NUMBERS.pending, (state, action) => {});
    builder.addCase(IMPORT_LUCKY_NUMBERS.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(IMPORT_LUCKY_NUMBERS.rejected, (state, action) => {
      state.error = action.error;
    });

    //FETCH_LUCKY_NUMBERS
    builder.addCase(FETCH_LUCKY_NUMBERS.pending, (state, action) => {});
    builder.addCase(FETCH_LUCKY_NUMBERS.fulfilled, (state, action) => {
      state.data = action.payload.data;
    });
    builder.addCase(FETCH_LUCKY_NUMBERS.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
