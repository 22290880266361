import { Toggle } from '@hexa-ui/components';
import { useEffect, useState } from 'react';
import { Container, ContainerTitle, StyledText } from './Toggle.styles';

const StyledToggle = (
    {
        title,
        description,
        value,
        onChange,
        ...props
    }: any
): JSX.Element => {
    const [checked, setChecked] = useState(value)

    useEffect(() => {
        setChecked(value)
    }, [value])

    const handleChange = (val: boolean) => {
        setChecked(val)
        onChange(val)
    }

    return (
        <Container>
            <ContainerTitle>
                <Toggle
                    onCheckedChange={handleChange}
                    checked={checked}
                    {...props}
                />
                {title ?
                    <StyledText
                        size="small"
                        type="body"
                        css={{
                            color: '#141414',
                            marginLeft: '8px'
                        }}
                        title={title}
                    />
                    :
                    null
                }
            </ContainerTitle>
            {description ?
                <StyledText
                    size="xsmall"
                    type="body"
                    css={{
                        color: 'rgb(117, 117, 117)',
                    }}
                    title={description}
                />
                :
                null}
        </Container>
    );
};

export default StyledToggle;