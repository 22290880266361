import { Select, Toast } from '@hexa-ui/components';
import { World } from '@hexa-ui/icons';
import { useAuthenticationService, useRegionService } from 'admin-portal-shared-services';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from '../../Router';
import { AlertContext, toastType } from '../../contexts/alert.context';
import { AppDispatch, useAppSelector } from '../../store';
import { FETCH_COUNTRIES } from '../../store/stock/CountryReducer';
import countriesOBJ from '../../utils/countries.json';
import AutoBreadcrumb from '../AutoBreadcrumb/AutoBreadcrumb';
import StyledSelect from '../Select/Select';
import './Layout.css';

export type countryType = {
  name: string;
  code: string;
  id?: number;
  changed?: string;
  created?: string;
  status?: boolean;
  uuid?: string;
};
const Layout: React.FC = () => {
  const { state } = useContext(AlertContext);

  const countriesPromo = useAppSelector((state) => state.country.data);
  const authentication = useAuthenticationService();

  const { getCountries, getStateByCountry } = useRegionService();
  const [countries, setCountries] = useState<countryType[]>(getCountries());
  const [selectedCountry, setSelectCountry] = useState<string>();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_COUNTRIES());
    }
    init();
  }, []);

  useEffect(() => {
    const _countries: string[] = authentication.getSupportedCountries();
    console.log({ _countries })
    const _countryStorage = localStorage.getItem('country');

    if (!_countries || !countriesPromo) {
      return;
    }

    const filteredData = countriesPromo?.filter((countryPromo: countryType) => {
      return _countries.find((country: string) => countriesOBJ[countryPromo.name] === country);
    });

    const searchStorageCountry = filteredData?.find(
      (country: countryType) => String(country.id) === _countryStorage
    );
    console.log({ searchStorageCountry, filteredData })

    setCountries(filteredData);
    setSelectCountry(
      searchStorageCountry ? _countryStorage : filteredData ? String(filteredData[0]?.id) : ''
    );

    if (!localStorage.getItem('country') && filteredData?.length) {
      localStorage.setItem('country', String(filteredData[0].id));
    }
  }, [countriesPromo]);

  return (
    <BrowserRouter>
      <div className="body-content" data-testid="layout">
        <div className="content-container">
          <div className="content-header" data-testid="content-header-container">
            <div className="home-bread">
              <AutoBreadcrumb />
            </div>
            <div className="home-select-country">
              <StyledSelect
                disabled={!!!countries.length}
                width={'auto'}
                placeholder="Naz"
                value={selectedCountry}
                size="small"
                icon={<World size="medium" />}
                onChange={(value) => {
                  countries && setSelectCountry(value);
                  localStorage.setItem('country', value);
                }}
              >
                {countries &&
                  countries.map((country: countryType, index: number) => {
                    return (
                      <Select.Option key={index + country.id} value={String(country.id)}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
            </div>
          </div>
          {state.toasts &&
            state.toasts.map((toast: toastType, index: number) => {
              return (
                <div key={index + toast.message}>
                  <AutoToast
                    message={toast.message}
                    type={toast.type}
                    index={index}
                    delay={3000 + 1000 * index}
                  />
                </div>
              );
            })}
          <Router />
        </div>
      </div>
    </BrowserRouter>
  );
};

const AutoToast: React.FC<toastType> = ({ message, type, index, delay }) => {
  const { state, setState } = useContext(AlertContext);
  useEffect(() => {
    const timerId = setTimeout(() => {
      setState({
        toasts: state.toasts.filter((_, indexFilter: number) => indexFilter !== index),
      });
    }, delay);

    return () => {
      clearTimeout(timerId);
    };
  }, []);
  return (
    <Toast.Provider swipeDirection="right">
      <Toast.Root
        style={{ marginTop: index * 67.5 }}
        message={message}
        type={type}
        open
        position={'top-right'}
        showCloseButton
      ></Toast.Root>
    </Toast.Provider>
  );
};

export default Layout;
