import { FileValidated } from '@hexa-ui/components';

const verifyImgType = (typeList: String[], files: FileValidated[]) => {
  let res = false;

  files.forEach((file) => {
    typeList.forEach((type: string) => {
      if (file.file.type.includes(type)) {
        res = true;
      }
    });
  });

  return res;
};

export default verifyImgType;
