import { createContext, Dispatch, SetStateAction, useState } from 'react';
import useChallengeHandler from '../hook/useChallengeHandler';
import { getJsonApiData } from '../utils/formatData';

type campaignDetailsType = {
    description: string
    title: string
    image: string
}

type StatusType = {
    type: 'error' | 'success' | null
    message: string
}

type ChallengeModalContextType = {
    isOpen: boolean;
    challengeId?: string;
    challengeData: campaignDetailsType | null;
    loading: boolean;
    status?: StatusType;
};

type ChallengeModalContextProps = {
    challengeModalState: ChallengeModalContextType;
    setChallengeModalState: Dispatch<SetStateAction<ChallengeModalContextType>>;
    handleSend: Function;
    handleOpenPreview?: Function;
};

const DEFAULT_VALUE = {
    challengeModalState: {
        isOpen: false,
        challengeId: '',
        loading: false,
        challengeData: null,
        status: {
            type: null,
            message: ''
        },
    },
    setChallengeModalState: () => { },
    handleSend: () => { },
    handleOpenPreview: () => { }
};

export const ChallengeModalContext = createContext<ChallengeModalContextProps>(DEFAULT_VALUE);

const ChallengeModalContextProvider = ({ children }: { children: any }) => {
    const { getChallengeDetails, linkChallenge, getCountries } = useChallengeHandler();
    const [challengeModalState, setChallengeModalState] = useState(DEFAULT_VALUE.challengeModalState);

    const showPreview = async (id, country) => {
        setChallengeModalState(prevState => ({ ...prevState, loading: true }))
        let countryName = ''
        await getCountries(country, 'name').then((res) => {
            const code = (getJsonApiData(res).data)[0]?.country
            countryName = code
        })
        await getChallengeDetails(id, countryName)
            .then(res => {
                if (res?.data?.length < 1 || res?.data === undefined) {
                    setChallengeModalState(prevState => ({
                        ...prevState, status: {
                            type: 'error',
                            message: 'An error occurred when calling up challenge details.'
                        }
                    }))
                } else {
                    setChallengeModalState(prevState => ({ ...prevState, isOpen: true, challengeData: res.data[0] }))
                }
            })
            .finally(() => setChallengeModalState(prevState => ({ ...prevState, loading: false, hasSend: false })))
    }

    const handleSend = async (campaignId: number) => {
        setChallengeModalState(prevState => ({ ...prevState, loading: true }))
        await linkChallenge({
            challenge_id: challengeModalState?.challengeId,
        },
            String(campaignId)).then((res) => {
                if (res?.data?.length < 1 || res?.data === undefined) {
                    setChallengeModalState(prevState => ({
                        ...prevState, status: {
                            type: 'error',
                            message: 'Error when linking the challenge to campaign.'
                        }, isOpen: false
                    }))
                } else {
                    setChallengeModalState(prevState => ({
                        ...prevState, status: {
                            type: 'success',
                            message: 'Challenge successfuly linked'
                        }
                    }))
                }
            }
            ).finally(() => setChallengeModalState(prevState => ({ ...prevState, loading: false, isOpen: false })))
    }

    const handleOpenPreview = (challengeId: string, country) => {
        showPreview(challengeId, country)
    }

    return (
        <ChallengeModalContext.Provider
            value={{
                challengeModalState,
                setChallengeModalState,
                handleSend,
                handleOpenPreview
            }}
        >
            {children}
        </ChallengeModalContext.Provider>
    );
};

export default ChallengeModalContextProvider;
