import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { PrizeType } from '../dataTypes';

//ASYNC THUNKS
export const POST_PRIZE = createAsyncThunk(
  'post/prizecreation',
  async (
    data: {
      name: string;
      sku: string;
      field_type: string;
      field_image: number;
      description: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post('/prizes', data);
    return response.data;
  }
);

interface IPrizeCreationState {
  data: PrizeType;
  error: any;
}

const initialState: IPrizeCreationState = {
  data: undefined,
  error: undefined,
};

export const Slice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //POST_PRIZE
    builder.addCase(POST_PRIZE.pending, (state, action) => {});
    builder.addCase(POST_PRIZE.fulfilled, (state, action) => {});
    builder.addCase(POST_PRIZE.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
