import { Card, Grid } from '@hexa-ui/components';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'supplier-portal-shared-components';
import StyledFilter from '../../../components/Filter/Filter';
import StyledTable from '../../../components/Table/Table';
import usePlayerScoreBlvlHandler from '../../../hook/usePlayerScoreBlvlHandler';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { PlayerScoreBlvlType } from '../../../store/dataTypes/playerScoreBlvl.type';
import PlayerScoreBlvlHeaders from '../../json/columnHeaders/PlayerScoreBlvlHeaders.json';
import handleFilter from '../extra/filter';
import ExportModule from '../modules/ExportModal';

const PlayerScoreBlvlTab: React.FC = () => {
  const params = useParams();
  const [PlayerScoreBlvlData, setPlayerScoreBlvlData] = useState<PlayerScoreBlvlType[]>([]);
  const { getAllPlayerScoreBlv } = usePlayerScoreBlvlHandler();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [filteredPlayerScoreBlvlData, setFilteredPlayerScoreBlvlData] =
    useState<PlayerScoreBlvlType[]>(PlayerScoreBlvlData);

  WindowFocusHandler(async () => {
    setLoading(true);
    await getAllPlayerScoreBlv(String(params?.id))
      .then((val) => {
        setPlayerScoreBlvlData(val.data);
      })
      .finally(() => setLoading(false));
  });

  const flattenData = (data: PlayerScoreBlvlType[]) => {
    return data.map((item) => ({
      ...item,
      playerId: item.player?.id,
      playerPoc: item.player?.poc,
      playerName: item.player?.name,
      bottleCrushLevelName: item.bottle_crush_level?.name,
    }));
  };

  React.useEffect(() => {
    const flattenedData = flattenData(PlayerScoreBlvlData);

    if (search) {
      const searchKeys = ['playerId', 'bottleCrushLevelName', 'playerName', 'score', 'playerPoc'];
      handleFilter(search, flattenedData, searchKeys, setFilteredPlayerScoreBlvlData);
    } else {
      setFilteredPlayerScoreBlvlData(flattenedData);
    }
  }, [PlayerScoreBlvlData, search]);

  return (
    <>
      {/* <ExportStatus bundle="bc_player_score_by_level" campaignId={params?.id} /> */}
      <Card elevated={'medium'} border={'large'}>
        <Grid.Item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            justifyContent: 'space-between',
            padding: '1rem',
            gap: '0.5rem',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}
        >
          <div style={{ maxWidth: '496px', width: '496px' }}>
            <StyledFilter
              width={'100%'}
              placeholder="Start typing to filter results"
              value={search}
              onChange={(e: any) => setSearch(e.currentTarget.value)}
              onClear={() => setSearch('')}
            />
          </div>
        </Grid.Item>
        {filteredPlayerScoreBlvlData !== undefined ? (
          <StyledTable
            loading={loading}
            columns={PlayerScoreBlvlHeaders}
            data={filteredPlayerScoreBlvlData || []}
            pagination={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20],
              showPageSizeSelector: true,
            }}
          />
        ) : (
          <Spinner color={'inherit'} show dataTest="transition-spinner" />
        )}
      </Card>
      <ExportModule
        bundle={'bc_player_score_by_level'}
        campaignId={String(params?.id)}
        disabled={!!!filteredPlayerScoreBlvlData?.length}
      />
    </>
  );
};

export default PlayerScoreBlvlTab;
