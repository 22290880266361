import { Card } from "@hexa-ui/components";
import { styled } from '@hexa-ui/theme';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import StyledFilter from "../../../components/Filter/Filter";
import FlexContainer from "../../../components/FlexContainer";
import PageTitle from "../../../components/PageTitle/PageTitle";
import StyledTable, { StyledPagination } from "../../../components/Table/Table";
import useAccountHandler from "../../../hook/useAccountHandler";
import headers from '../../json/columnHeaders/challengeStatusHeader.json';
import moment from "moment";

export default function ChallengeStatus() {

  const Container = styled('div', {
    display: 'flex',
    justifyContent: 'space-between'
  })

  const { getChallenge } = useAccountHandler();
  const [challenges, setChallenges] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [requestData, setRequestData] = useState({
    total: 0,
    page: 1,
    limit: 25,
    search: ''
  })
  const { state } = useLocation();

  const fetchData = async () => {
    setLoading(true)

    await getChallenge(state.accountId, requestData)
      .then(async (res) => {
        const challenges = res.data.map(challenge => ({
          ...challenge,
          status: challenge.status.charAt(0) + challenge.status.slice(1).toLowerCase(),
          lastModified: moment(challenge.lastModified).format('DD/MM/YYYY HH:mm'),
          submittedDate: moment(challenge.submittedDate).format('DD/MM/YYYY HH:mm'),
        }));

        setChallenges(challenges);
        setRequestData(prevState => ({ ...prevState, total: res.meta?.count ?? 0 }));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page
    fetchData();
  };

  const handleSearch = (value) => {
    requestData.search = value
    fetchData();
  };

  const debouncedSearch = useCallback(
    debounce((value) => handleSearch(value), 250),
    []
  );

  const handleChange = (el) => {
    setSearch(el.currentTarget.value);
    debouncedSearch(el.currentTarget.value)
  }

  for (let index = 0; index < challenges.length; index++) {
    const element = challenges[index];

    let statusColor = '';
    if (element.status === 'Failed') {
      statusColor = '#E4423F';
    } else if (element.status === 'Completed') {
      statusColor = '#00A838';
    } else if (element.status === 'Accepted') {
      statusColor = '#047AF1';
    } else {
      statusColor = '#FFFF00';
    }

    challenges[index].status = (
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            width: '8px',
            height: '8px',
            borderRadius: '10rem',
            backgroundColor: statusColor,
          }}
        />
        <div>{element.status}</div>
      </div>
    );
  }

  return (
    <>
      <Container>
        <PageTitle marginBottom="0.5rem" title="Challenge status" />
      </Container>
      <Card elevated={'medium'} border={'large'}>
        <div style={{ maxWidth: '496px', width: '496px', padding: '1rem' }}>
          {/* <StyledFilter
            placeholder="Start typing to filter results"
            value={search}
            onChange={(e) => handleChange(e)}
            onClear={(e) => handleChange(e)}
          /> */}
        </div>
        <StyledTable loading={loading} columns={headers} data={challenges} pagination={false} />
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding={'0.5rem 1rem'}
          gap={'0.5rem'}
          flexWrap="wrap"
        >
          <StyledPagination
            pageSize={requestData.limit}
            total={requestData.total}
            current={requestData.page}
            onChange={handlePagination}
          />
        </FlexContainer>
      </Card>
    </>
  );
}
