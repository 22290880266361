const VerifyFilters = (filters) => {
    let checkFilters = false
    Object.keys(filters).forEach(item => {
        if (filters[item]?.length > 0) {
            checkFilters = true
        }
    })
    return checkFilters
}

const getValue = (item, acessor) => {
    const list = acessor.split('.')
    let value = item
    list.forEach((prop) => {
        value = value[prop]
    })
    return value.toLowerCase()
}

export const FilterByCheckbox = (list, tabs, props) => {
    if (VerifyFilters(tabs)) {
        let filteredList = [...list]
        Object.keys(tabs).forEach(acessor => {
            tabs[acessor].forEach(checkbox => {
                filteredList = filteredList.filter(item => checkbox === getValue(item, props[acessor]))
            })
        }
        )
        return filteredList
    } else return list

};