import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable from '../../../components/Table/Table';
import WindowFocusHandler from '../../../hook/windowFocusHandler';
import { useAppSelector } from '../../../store';
import ChancesResetHeaders from '../../json/columnHeaders/ChancesResetHeaders.json';
import useChancesResetHandler from '../../../hook/useChancesResetHandler';
import { ChancesResetType } from '../../../store/dataTypes/chancesReset.type';
import moment from 'moment';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';

const ChancesResetTab: React.FC = () => {
  const params = useParams();
  const campaignUuid: string = useAppSelector((state) => state.campaignDetails.data?.uuid);
  const campaignName: string = useAppSelector((state) => state.campaignDetails.data?.name);
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState({
    id: params?.id,
    page: 0,
    limit: 10,
    total: 0
  });
  const { getChances, deleteChance } = useChancesResetHandler();
  const [chancesData, setChancesData] = useState([]);
  const [selectedChances, setSelectedChances] = useState<any>([]);

  const handleRowSelect = (rowSelectEvent) => {
    const itemId = (rowSelectEvent.data as ChancesResetType).id;

    setSelectedChances((prevSelectedChances) => {
      if(prevSelectedChances.includes(itemId)) {
        return prevSelectedChances.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedChances, itemId];
      }
    })
  }

  const { id } = useParams();

  const navigate = useNavigate();

  WindowFocusHandler(() => {
    fetchData()
  });

  const fetchData = async () => {
    setLoading(true);
    await getChances(requestData)
    .then(response => {
      const chancesData = InsertActions(response.data ? response.data.results.map(chance => ({
        ...chance,
        reset_date: moment(chance.reset_date).format('DD/MM/YYYY HH:mm'),
        reseted_in: chance.reseted_in ? moment(chance.reseted_in).format('DD/MM/YYYY HH:mm') : null
      })) : []);
      setChancesData(chancesData)
      setRequestData(prevState => ({ ...prevState, total: response.meta ? response.meta.count : 0 }))
    })
    .finally(() => setLoading(false));
  };

  const handleRemove = async (chance: ChancesResetType) => {
    setLoading(true)
    await deleteChance(chance.id)
    await fetchData();
  };

  const handleRemoveArray = (value) => {
    /* deleteTimeWindow(value, id).then(() => {
      setTimeWindowFilteredData([]);
      setSelectedChances([]);
      listAll();
    }); */
  }

  const handlePagination = (page, limit) => {
    requestData.limit = limit
    requestData.page = page >= 1 ? page - 1 : page
    fetchData();
  };

  const InsertActions = (data: ChancesResetType[]) => {
      let aux: ChancesResetType[] = [];

      const actions = (chance: ChancesResetType) => (
        <div style={{ display: 'inline-flex', gap: '1rem' }}>
          <IconButton
            icon={Edit2}
            variant="inherit"
            disabled={!!chance.reseted_in}
            onClick={() => {
              navigate(
                `/bees-games/manage-assets/campaign-details/${id}/edit-chances/${chance.id}`,
                {
                  state: {
                    campaignName,
                    id,
                    form: chance
                  },
                }
              );
            }}
          />
          <Modal.Root
            actions={
              <FlexContainer
                width="100%"
                display="inline-flex"
                justifyContent="flex-end"
                gap="0.5rem"
              >
                <Modal.Action>
                  <StyledButton variant="secondary">Cancel</StyledButton>
                </Modal.Action>
                <Modal.Action>
                  <StyledButton variant="destructive" onClick={() => handleRemove(chance)}>
                    Delete
                  </StyledButton>
                </Modal.Action>
              </FlexContainer>
            }
            trigger={<IconButton icon={Trash2} disabled={!!chance.reseted_in} variant="inherit" onClick={() => {}} />}
            variant="overlay"
            destructive="critical"
          >
            <Heading type="H3" css={{ marginBottom: '1rem' }}>
              Delete chance?
            </Heading>
            <Paragraph>{'Do you really wish to delete this chance?'}</Paragraph>
            <Paragraph>{'This action can’t be undone.'}</Paragraph>
          </Modal.Root>
        </div>
      );

      for (let i = 0; i < data.length; i++) {
        let item: ChancesResetType = data[i];
        aux[i] = Object.assign({}, item, {
          actions: actions(item),
        });
      }

      return aux;
  };

  return (
    <>
      <Card elevated={'medium'} border={'large'} css={{ padding: '0px', position: 'relative' }}>
        <FlexContainer
          display={'inline-flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          padding="1rem"
          flexWrap="wrap"
        >
            <StyledButton
              variant="primary"
              icon={Plus}
              leading
              onClick={() =>
                navigate(`/bees-games/manage-assets/campaign-details/${params.id}/chances-reset`, {
                  state: {
                    id: params.id,
                    campaignUuid,
                    campaignName,
                  },
                })
              }
            >
              Chances reset rule generator
            </StyledButton>
        </FlexContainer>
        {selectedChances.length > 0 ?
            <div style={{ position: 'relative', ...(selectedChances.length < 10 ? { left: '220px' } : selectedChances.length >= 100 ? { left: '240px' } : { left: '230px' }) }}>
              <ModalDelete
                height='8rem'
                handleDelete={() => handleRemoveArray(selectedChances)}
                trigger={<IconButton icon={Trash2} variant="inherit" style={{ filter: 'invert(54%) sepia(89%) saturate(5215%) hue-rotate(341deg) brightness(98%) contrast(81%)' }}/>}
                title="Delete chances?"
                description={
                  <>
                    Do you really wish to delete these chances?
                    <br />
                    This action can’t be undone.
                  </>
                }
          />
            </div> : null}
        <StyledTable
          loading={loading}
          columns={ChancesResetHeaders}
          data={ chancesData || [] }
          pagination={{
            onChange: handlePagination,
            pageSize: requestData.limit,
            total: requestData.total,
            pageSizeOptions: [5, 10, 20],
            showPageSizeSelector: true,
          }}
        />
      </Card>
    </>
  );
};

export default ChancesResetTab;
