import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface AnalyticsHandleInterface {
  getAnalytics: (campaignId, form) => Promise<any>;
  downloadAnalytics: (campaignId, form) => Promise<any>;
}

export default function useRankingHandler(): AnalyticsHandleInterface {
  const getAnalytics = async (campaignId, form) => {
    return await apiDrupal
      .get(`${base_url}/api/analytics/v1/campaign/metrics?_format=json&campaign=${campaignId}&game_type=${form.game}${form.status}`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  const downloadAnalytics = async (campaignId, form) => {
    const url = `${base_url}/api/analytics/v1/campaign/metrics/download?_format=json&campaign=${campaignId}&period_start=${form.period_start}&period_end=${form.period_end}${form.fields}${form.status}&game_type=${form.game}`;

    try {
      const response = await apiDrupal.get(url, {
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return { getAnalytics, downloadAnalytics };
}
