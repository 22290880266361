import { Tabs } from '@hexa-ui/components';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { Container } from './Tabs.styles';

export type TabsProps = React.ComponentProps<typeof TabsPrimitive.Root> & {
    defaultValue: string;
};

const StyledTabs = ({ children, ...props }: TabsProps) => {
    return (
        <Container>
            <Tabs.Root {...props}>
                {children}
            </Tabs.Root>
        </Container>
    );
};

export default StyledTabs;