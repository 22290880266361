import styled from 'styled-components';

type ImageUploaderProps = {
    url: string;
    showActions: boolean;
    background: string;
}

export const Container = styled.div<ImageUploaderProps>`
    width: 100%;
    ${props => props.showActions && `
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    position: relative;
        > div > div > section {
            background-image: url(${props.background});
            background-repeat: round;
            background-size: cover;
            border: 0px;

            > p {
                visibility: hidden;
            }

            > button {
                visibility: hidden;
            }

            > section > svg {
                visibility: hidden;
            }

            > section > section {
                visibility: hidden;
            }

            &:hover {
                background-image: url(${props.background});
                background-repeat: round;
                background-size: cover;
            }
        }`
    }
`

export const IconButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  align-items: flex-end;
  margin: 38px 16px;
`;
