import { LoadingDots, Paragraph, SearchField } from '@hexa-ui/components';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../store';
import { CampaignType, PrizeType } from '../../store/dataTypes';
import { FETCH_PRIZES_CAMPAIGN } from '../../store/stock/PrizeCampaignReducer';
import { FETCH_PRIZES } from '../../store/stock/PrizeReducer';
import { changeState } from '../../utils/functions';
import FlexContainer from '../FlexContainer';
import { Container, Error, ErrorIcon, Hint, Label } from './AutoComplete.styles';
import { FETCH_MANAGEASSETS_CAMPAIGN } from '../../store/stock/manageAssetsReducer';

interface props {
  hint?: string;
  value?: string;
  editValue?: string;
  defaultValue?: string;
  required?: boolean;
  size?: 'large' | 'medium';
  title?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  prizeCampaignMode?: boolean;
  campaignMode?: boolean;
  campaignId?: string;
  hasError?: boolean;
  errorText?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onClickResult?: (prize: PrizeType | CampaignType) => void;
}
const AutoComplete: React.FC<props> = ({
  value,
  defaultValue,
  required,
  size,
  hint,
  label,
  placeholder,
  hasError = false,
  disabled = false,
  errorText,
  prizeCampaignMode,
  campaignMode,
  campaignId,
  onChange,
  onClear,
  onClickResult,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const searchData = useAppSelector((state) => state.prizes.data);
  const searchPrizeCampaignData = useAppSelector((state) => state.prizeCampaign.data);
  const searchCampaignData = useAppSelector((state) => state.manageAssets.campaignData);

  const [focused, setFocused] = useState(false);

  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (disabled) {
      setFocused(false);
    }
  }, [disabled]);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  const handleSearchCampaigns = async (value: string) => {
    if (!value) {
      setSearch('');
      return;
    }

    if (value.length == 0 || value.length >= 1) {
      setLoading(true);
      await dispatch(FETCH_MANAGEASSETS_CAMPAIGN({
        page: 0,
        limit: 10,
        country: localStorage.getItem('country'),
        search: value,
      })).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  //BUSCA TODOS OS PRIZES
  const handleSearch = async (value: string) => {
    if (!value) {
      setSearch('');
      return;
    }

    if (value.length == 0 || value.length >= 1) {
      setLoading(true);
      await dispatch(
        FETCH_PRIZES({
          page: 0,
          limit: 10,
          search: value,
        })
      ).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  //BUSCA PRIZES CAMPAIGN
  //Necessita de preenchimento do campo 'campaignId' e do campo prizeCampaignMode
  const handleSearchPrizesCampaign = async (value: string) => {
    if (!value) {
      setSearch('');
      return;
    }

    if (value.length == 0 || value.length >= 1) {
      setLoading(true);
      await dispatch(
        FETCH_PRIZES_CAMPAIGN({
          id: campaignId || '',
          page: 0,
          limit: 10,
          search: value,
        })
      ).finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  };

  const debouncedSave = useCallback(
    debounce(
      (nextValue) =>
      campaignMode ? handleSearchCampaigns(nextValue) :  prizeCampaignMode ? handleSearchPrizesCampaign(nextValue) : handleSearch(nextValue),
      250
    ),
    []
  );

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    changeState(setSearch, event.currentTarget.value)

    debouncedSave(event.currentTarget.value);
  };

  return (
    <Container>
      <Label hasError={hasError}>{label}</Label>
      <Hint>
        {hint}
      </Hint>
      {hasError ? (
        <Error>
          <ErrorIcon size="tiny" />
          {errorText}
        </Error>
      ) : null}
      <SearchField.Root
        defaultValue={defaultValue}
        onClear={() => {
          setSearch('');
          onClear();
        }}
        value={search}
        required={required}
        size={size}
        // hint="Prizes are registered in the Prizes page"
        placeholder={placeholder}
        onChange={(e) => {
          handleChange(e);
          onChange(e);
        }}
        disabled={disabled}
        onFocus={() => setFocused(true)}
      ></SearchField.Root>
      <FlexContainer
        display={
          focused && search && (searchData || searchPrizeCampaignData || loading) ? 'flex' : 'none'
        }
        gap="0.5rem"
        flexDirection="column"
        background={'#FFF'}
        width="300px"
        height="max-content"
        maxHeight="300px"
        position="absolute"
        zIndex={99}
        boxShadow="2px 4px 8px #ddd"
        border="1px solid #ddd"
        borderRadius={8}
        overflowY="auto"
        onBlur={() => setFocused(false)}
      >
        {loading ? (
          <FlexContainer
            width="100%"
            height="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingDots />
          </FlexContainer>
        ) : (
          <FlexContainer padding="4px" height="max-content">
            {!prizeCampaignMode &&
              searchData?.map((prize: PrizeType, index: number) => {
                return (
                  <Paragraph
                    css={{ width: '100%', cursor: 'pointer' }}
                    key={index + prize.name}
                    onClick={() => {
                      onClickResult(prize);
                      changeState(setSearch, prize.name);
                      setFocused(false);
                    }}
                  >
                    {prize.name}
                  </Paragraph>
                );
              })}
            {prizeCampaignMode &&
              searchPrizeCampaignData?.map((prize: PrizeType, index: number) => {
                return (
                  <Paragraph
                    css={{ width: '100%', cursor: 'pointer' }}
                    key={index + prize.name}
                    onClick={() => {
                      onClickResult(prize);
                      changeState(setSearch, prize.name);
                      setFocused(false);
                    }}
                  >
                    {prize.name}
                  </Paragraph>
                );
              })}
              {campaignMode &&
                searchCampaignData?.map((campaign: CampaignType, index: number) => {
                  return (
                    <Paragraph
                      css={{ width: '100%', cursor: 'pointer' }}
                      key={index + campaign.name}
                      onClick={() => {
                        onClickResult(campaign);
                        changeState(setSearch, campaign.name);
                        setFocused(false);
                      }}
                    >
                      {campaign.name}
                    </Paragraph>
                  );
                })}
          </FlexContainer>
        )}
      </FlexContainer>
    </Container>
  );
};

export default AutoComplete;
