import { createContext, Dispatch, SetStateAction, useState } from 'react';
import { CampaignType, PrizeType, TimeWindowType } from '../store/dataTypes';

//Tipagem dos dados do state
type DeleteModalContextType = {
  isOpen: boolean;
  prize?: PrizeType;
  campaign?: CampaignType;
  timewindow?: TimeWindowType;
  campaignChallenge?: string;
  loading: boolean;
  type: string;
};

//Tipagem das Props do contexto
type DeleteModalContextProps = {
  deleteModalState: DeleteModalContextType;
  setDeleteModalState: Dispatch<SetStateAction<DeleteModalContextType>>;
};

//Valores Default do contexto
const DEFAULT_VALUE = {
  deleteModalState: {
    isOpen: false,
    prize: undefined,
    campaign: undefined,
    timewindow: undefined,
    campaignChallenge: '',
    loading: false,
    type: null,
  },
  setDeleteModalState: () => { }, // função de inicialização e mudança de estado
};

//Cria a Context
export const DeleteModalContext = createContext<DeleteModalContextProps>(DEFAULT_VALUE);

//PROVEDOR DO CONTEXTO
const DeleteModalContextProvider = ({ children }: { children: any }) => {
  const [deleteModalState, setDeleteModalState] = useState(DEFAULT_VALUE.deleteModalState);

  return (
    <DeleteModalContext.Provider
      value={{
        deleteModalState,
        setDeleteModalState,
      }}
    >
      {children}
    </DeleteModalContext.Provider>
  );
};

export default DeleteModalContextProvider;
