import { FileValidated, Grid } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import StyledAlert from '../../../../../components/Alert/Alert';
import StyledButton from '../../../../../components/Button/Button';
import { InfoCardTitle } from '../../../../../components/InfoField/InfoField';
import StyledInput from '../../../../../components/Input/Input';
import { ChallengeModalContext } from '../../../../../contexts/challenge.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import useChallengeHandler from '../../../../../hook/useChallengeHandler';
import { useAppSelector } from '../../../../../store';
import { CampaignType } from '../../../../../store/dataTypes';
import StyledTextarea from '../../../../../components/Textarea/Textarea';
import StyledRadio from '../../../../../components/Radio/StyledRadio';
import StyledFileUploader from '../../../../../components/FileUploader/FileUploader';
import FlexContainer from '../../../../../components/FlexContainer';
import { styled } from '@hexa-ui/theme';
import { AlertContext } from '../../../../../contexts/alert.context';
import { changeStateForm } from '../../../../../utils/functions';
import checkValidityCSV from '../../../../../utils/verifyCSV';
import { base64CSVConverter } from '../../../../../utils/toBase64';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}

type challengeType = {
  name: string,
  description: string;
  field_base_campaign: string;
  field_file: any[];
  delimiter: string;
}

const TabChallenge: React.FC<props> = ({ form }) => {
  const { challengeModalState, setChallengeModalState, handleOpenPreview } = useContext(ChallengeModalContext);
  const newCampaignData = useAppSelector((state) => state.campaignDetails.newCampaign);
  const { gameSettingsState, setChallengeState } = useContext(GameSettingsContext);
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [importChallengeStatus, setImportChallengeStatus] = useState(false);
  const [fields, setFields] = useState(false);
  const [challengeForm, setChallengeForm] = useState<challengeType>(null);
  const { addToast } = useContext(AlertContext);
  const { uploadChallengeCSV, importChallenges, getChallengeStatus } = useChallengeHandler();
  const [csvError, setCsvError] = useState<boolean>(false);
  const csvData = [['challenge']];

  useEffect(() => {
    fetchStatus();
  }, [])

  const fetchStatus = async () => {
    setLoading(true)

    await getChallengeStatus(newCampaignData?.id).then((error) => {
      setLoading(false)
      if (error?.message) setImportChallengeStatus(true);
      else setImportChallengeStatus(false);
    });
  };

  useEffect(() => {
    const handleClearFields = async () => {
      setChallengeModalState(prevState => ({ ...prevState, loading: true }))
      setChallengeState({
        challenge: {
          id: '',
          name: '',
          challenge_id: ''
        }
      });
      handleChange('')
      setChallengeModalState(prevState => ({ ...prevState, loading: false }))
    }
    handleClearFields()
  }, [challengeModalState.isOpen])

  const handleChange = (value: string) => {
    setChallengeState({
      ...gameSettingsState?.currentChallenge,
      challenge: {
        ...gameSettingsState?.currentChallenge?.challenge,
        challenge_id: value
      }
    });
  };

  const handleLinkChallenge = () => {
    if (!gameSettingsState?.currentChallenge?.challenge?.challenge_id) setError(true)
    else {
      setError(false)
      setChallengeModalState(() => ({ ...challengeModalState, challengeId: gameSettingsState?.currentChallenge?.challenge?.challenge_id }))
      handleOpenPreview(gameSettingsState?.currentChallenge?.challenge?.challenge_id, newCampaignData?.country)
    }
  }

  const handleFile = async (file: FileValidated[]) => {
    setCsvError(false);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], challengeForm?.delimiter).then((res: boolean) => res);
    if (isValid) {
        const convertedFile = await base64CSVConverter(file[0].file);
        await uploadChallengeCSV(
            {
                fileName: String(file[0].file.name),
                file: convertedFile,
            },
            String(newCampaignData?.id)
        )
            .then((data) => {
                if (data?.message) {
                    addToast({
                        message: "Error uploading CSV",
                        type: 'error',
                    });
                    setCsvError(true)
                } else {
                    addToast({
                        message: "Success uploading CSV",
                        type: 'success',
                    });
                    setCsvError(false)
                    changeStateForm(setChallengeForm, challengeForm, 'field_file', [{ target_id: data.fid[0].value }]);
                }
            })
            .finally(() => setLoading(false));
    } else {
        setCsvError(true);
        setLoading(false);
    }
};

  const handleSend = async () => {
    if (!challengeForm?.description || challengeForm?.field_file === undefined) {
        addToast({
            message: 'Please fill in all fields in challenge to continue!',
            type: 'error',
        });
    } else {
        setLoading(true);
        await importChallenges(
            {
                name: newCampaignData?.name,
                field_base_campaign: String(newCampaignData?.id),
                description: challengeForm?.description,
                field_file: challengeForm?.field_file,
                field_file_delimiter: challengeForm?.delimiter
            },
            Number(newCampaignData?.id)
        )
            .then((res) => {
                if (res?.error) {
                    if (res?.error?.code === 0) setCsvError(true);
                    addToast({
                        message: res?.error?.message,
                        type: 'error',
                    });
                } else {
                    addToast({
                        message: 'Challenge successfuly updated',
                        type: 'success',
                    });
                    setImportChallengeStatus(true);
                    setFields(false);
                }
            })
            .catch(() => {
                addToast({
                    message: 'An error occurred while updated challenge.',
                    type: 'error',
                });
            })
            .finally(() => setLoading(false));
    }
};

  {/*const handleClearFields = async () => {
    setLoading(true)
    setChallengeState({
      challenge: {
        id: '',
        name: '',
        challenge_id: ''
      }
    });
    handleChange('')
    await getAllChallenges()
    setLoading(false)
  */}

  const FormContainer = {
    display: 'flex',
    height: 'max-content',
    gap: '1rem',
    alignItems: 'flex-end',
    marginBottom: '0.5rem',
    width: '100%',
  }

  const BtnCss = {
    marginBottom: '0.5rem'
  }

  const BtnContainer = styled('div', {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
})

  return (
    <Grid.Container style={{ width: '100%' }}>
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ flexDirection: 'column', gap: '1rem' }}
      >
        <InfoCardTitle size="H4" title="Do you want to link the challenge to the campaign?" />
        <div style={FormContainer}>
          <StyledInput
            defaultValue={gameSettingsState?.currentChallenge?.challenge?.challenge_id}
            label="Challenge id*"
            placeholder=""
            size="large"
            width="30%"
            hint="The challenge id."
            onChange={(e) => handleChange(e.currentTarget.value)}
            value={gameSettingsState?.currentChallenge?.challenge?.challenge_id}
            errorText={challengeModalState?.status?.type === 'error' ? 'Error when linking the challenge to campaign' : 'Challenge id required.'}
            hasError={!gameSettingsState?.currentChallenge?.challenge?.challenge_id && error || challengeModalState?.status?.type === 'error'}
            disabled={importChallengeStatus}
          />
          <StyledButton
            leading
            icon={Plus}
            onClick={handleLinkChallenge}
            isLoading={loading || challengeModalState?.loading}
            css={BtnCss}
            disabled={importChallengeStatus}
          >
            Link
          </StyledButton>
          <StyledButton
              variant="primary"
              disabled={loading || importChallengeStatus}
              icon={Plus}
              leading
              style={{ marginBottom: '0.5rem' }}
              onClick={() => setFields(!fields)}
            >
              Import Challenges
            </StyledButton>
        </div>
        {importChallengeStatus && !loading && (
        <StyledAlert
          message={'Your import is running in background, complete your campaign settings and check it on the Challenges tab!'}
          type={'warning'}
          css={{ width: '100%', marginBottom: 20 }}
        />
        )}
        {fields &&
        <>
          <FlexContainer display="flex" justifyContent='space-between'>
            <FlexContainer display="flex" flexDirection="column" gap="1rem">
              <StyledInput
                defaultValue={newCampaignData?.name}
                required
                disabled
                size="large"
                label="Campaign"
                placeholder="Campaign Example"
                hint="The current campaign you’re importing to. This cannot be changed"
                width="100%"
                onChange={(e) => changeStateForm(setChallengeForm, challengeForm, 'name', e.currentTarget.value)}
              />
              <StyledTextarea
                style={{ height: '300px', resize: 'none' }}
                defaultValue={form?.description}
                value={form?.description}
                required
                label="Description*"
                placeholder="Import made on MM/DD/YY - HH:MM"
                width="100%"
                type="textarea"
                height="242px"
                characterCounter
                maxLength={150}
                rows={12}
                onChange={(e) =>
                  changeStateForm(setChallengeForm, challengeForm, 'description', e.currentTarget.value)
                }
              />
            </FlexContainer>
            <FlexContainer display="flex" flexDirection="column" gap="1rem">
              <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
                style={{ minWidth: '100% ', maxWidth: '395px'}}
                options={[
                    {
                        id: 'comma',
                        label: ', (Comma)',
                        value: ','
                    },
                    {
                        id: 'semicolon',
                        label: '; (Semicolon)',
                        value: ';'
                    }
                ]}
                onChange={(value => changeStateForm(setChallengeForm, challengeForm, 'delimiter', value))}
              />
              <StyledFileUploader
                legend={
                    <>
                        The csv must have the <b>challenge</b> column.
                    </>
                }
                title="Import .csv file"
                accept=".csv"
                maxFileSize={1024 * 1024 * 5}
                message="File must be .csv and must be less than 5MB"
                onDrop={(file) => handleFile(file)}
                maxFiles={1}
                type="file"
                error={
                  csvError
                      ? {
                          message: `The csv is not valid. Columns don't match!`,
                      }
                      : undefined
                }
              />
            </FlexContainer>
          </FlexContainer>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
            <BtnContainer>
              <StyledButton variant="secondary" onClick={() => setFields(false)}>
                  Cancel
              </StyledButton>
              <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
                  Save
              </StyledButton>
            </BtnContainer>
          </Grid.Item>
        </>
        }
      </Grid.Item>
    </Grid.Container>
  );
};

export default TabChallenge;
