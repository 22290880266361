// import { Container } from './styles';

import { apiDrupal } from '../Api/Api';
import { base_url } from '../utils/constants';

interface PlayerScoreBlvlHandleInterface {
  getAllPlayerScoreBlv: (campaignId) => Promise<any>;
}

export default function usePlayerScoreBlvlHandler(): PlayerScoreBlvlHandleInterface {
  const getAllPlayerScoreBlv = async (campaignId) => {
    return await apiDrupal
      .get(`${base_url}/hub/v2/competition/${campaignId}/mix-and-match/player-score-level`)
      .then((response) => response.data)
      .catch((error) => error.response);
  };

  return { getAllPlayerScoreBlv };
}
