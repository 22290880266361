import { AlertOctagon } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    position: 'relative',
    '@media (min-resolution: 1.50dppx)': {
        'p, input': {
            fontSize: '$1 !important',
        },

        input: {
            height: '2rem',
        },

        svg: {
            height: '1rem',
            width: '1rem'
        },

        'svg:nth-child(2)': {
            height: '0.813px',
            width: '0.813px'
        },

        '> p:nth-child(2)': {
            fontSize: '0.55rem',
            lineHeight: '1.7'
        }
    }
})

export const Label = styled('label', {
    fontSize: '$2',
    lineHeight: '$3',
    fontWeight: '$semibold',
    color: '$interfaceLabelPrimary',
    fontFamily: '$paragraph',
    paddingBottom: '$1',
    variants: {
        hasError: {
            true: {
                color: '$semanticErrorText',
            },
        }
    },
    '@media (min-resolution: 1.50dppx)': {
        fontSize: '$1'
    }
})

export const Hint = styled('div', {
    lineHeight: '$4',
    fontSize: '$1',
    fontWeight: '$normal',
    color: '$interfaceLabelSecondary',
    fontFamily: '$paragraph',
    paddingBottom: '$1',
    '@media (min-resolution: 1.50dppx)': {
        fontSize: '0.55rem'
    }
})

export const Error = styled('div', {
    fontFamily: '$paragraph',
    fontSize: '$2',
    lineHeight: '$4',
    fontWeight: '$normal',
    color: '$semanticErrorText',
    paddingBottom: '$1',
    display: 'flex',
    alignItems: 'center',
});

export const ErrorIcon = styled(AlertOctagon, {
    '&>svg': {
        fill: '$semanticErrorText',
    },
    marginRight: '$1',
    verticalAlign: 'top',
});

