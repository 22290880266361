import { Card, Grid, IconButton, FileValidated } from '@hexa-ui/components';
import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import AutoComplete from '../../components/AutoComplete/AutoComplete';
import StyledButton from '../../components/Button/Button';
import FlexContainer from '../../components/FlexContainer';
import StyledInput from '../../components/Input/Input';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import PageTitle from '../../components/PageTitle/PageTitle';
import ShowColor from '../../components/ShowColor/ShowColor';
import StyledTable from '../../components/Table/Table';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import { AppDispatch, useAppSelector } from '../../store';
import { PrizeType } from '../../store/dataTypes';
import {
  DELETE_PRIZE_CAMPAIGN,
  FETCH_PRIZES_CAMPAIGN,
  PATCH_PRIZES_CAMPAIGN,
  STORE_PRIZES_CAMPAIGN,
} from '../../store/stock/PrizeCampaignReducer';
import { changeState, changeStateForm } from '../../utils/functions';
import PrizeHeaders from './json/PrizeHeaders.json';
import PrizeHeadersSpinning from './json/PrizeHeadersSpinning.json';
import moment from 'moment';
import QuantifierLabeled from '../../components/QuantifierLabeled/QuantifierLabeled';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import verifyImgType from '../../utils/verifyImgType';

export default function AddPrize() {
  const { state } = useLocation();
  const prizesData: PrizeType[] = useAppSelector((state) => state.prizeCampaign.data);
  const [filteredPrizeData, setFilteredPrizeData] = useState<PrizeType[]>(prizesData);

  const { addToast } = useContext(AlertContext);
  const [requestData, setRequestData] = useState(null);
 
  const navigate = useNavigate();
  const params = useParams();
  const [winnerURLError, setWinnerURLError] = useState(false);
  const [search, setSearch] = useState('');
  const [formPrize, setFormPrize] = useState<PrizeType>();
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [fileError, setFileError] = useState(false);
  const typelist = ['png', 'jpg', 'jpeg'];

  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const regexHTTP = /^(http:\/\/|https:\/\/).{1,}$/i;

  const sizeValidation = (file: FileValidated[]) => {
    if (file[0].file.size > 102400) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return true;
    }
    return false;
  };

  const verifiedFile = (e) => {
    if (!verifyImgType(typelist, e)) {
      setFileError(true);
      return;
    }
    if (!sizeValidation(e)) {
      setFileError(false);
      setFormPrize({ ...formPrize, image: e, field_image: e });
    }
  };

  const handleSavePrize = async () => {
    if (
      formPrize?.winner_redirect_link?.uri &&
      !regexHTTP.test(formPrize?.winner_redirect_link?.uri)
    ) {
      addToast({
        message: 'Please insert URL with http:// or https://.',
        type: 'error',
      });
      setWinnerURLError(true);
      return;
    } else {
      setWinnerURLError(false);
    }

    if (
      state?.game === 'spinning_wheel' &&
      (!formPrize?.field_spin_prize_bg_color || !formPrize?.field_spin_prize_text_color)
    ) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      return;
    }

    if (
      (state?.game === 'spinning_wheel' ? !formPrize?.field_spin_prize_name : false) ||
      !formPrize?.quantity ||
      !formPrize?.prize
    ) {
      addToast({
        message: 'Please enter all fields to continue.',
        type: 'error',
      });
      setHasEmptyField(true);
      return;
    }

    if (state?.game === 'spinning_wheel' && prizesData.length >= 11) {
      addToast({
        message: "Spinning Wheel game has a maximum limit of 11 types of prizes.",
        type: 'error',
      });
      return;
    }

    if (checkTimeWindow(formPrize)) {
      const matchingTimeWindows = state?.timeWindowData.filter(window => window.name === formPrize.name);

      const latestTimeWindowCreated = state?.timeWindowData.reduce((latest, window) => {
        const windowCreatedDate = new Date(window.created);
        windowCreatedDate.setHours(windowCreatedDate.getHours() - 3);
        return latest > windowCreatedDate ? latest : windowCreatedDate;
      }, new Date(0));

      const filteredPrizesData = prizesData.filter(prize => {
        const prizeCreatedDate = new Date(prize.created);
        return prize.name === formPrize.name && prize.id !== formPrize.id && prizeCreatedDate < latestTimeWindowCreated;
      });

      const totalQuantity = filteredPrizesData.reduce((sum, prize) => sum + prize.quantity, 0) + formPrize.quantity;

      if (totalQuantity < matchingTimeWindows.length) {
        addToast({
          message: "Some of the prizes you've created do not match the quantity of time windows assigned for those prizes.",
          type: 'error',
        });
        return;
      }
    }

    setLoading(true);
    setHasEmptyField(false);

    editMode
      ? await dispatch(
        PATCH_PRIZES_CAMPAIGN({
          campaignId: params?.id,
          id: formPrize?.id,
          prizeData: { ...formPrize, type: state?.game },
        })
      )
        .then(() => {
          setEditMode(false);
          setLoading(false);
          handleSearchAll();
          addToast({
            message: 'prize successfuly updated.',
            type: 'success',
          });
        })
        .catch(() => {
          setLoading(false);
          addToast({
            message: 'An error occurred while editing the prize.',
            type: 'error',
          });
        })
        .finally(() => handleClear())
      : await dispatch(
        STORE_PRIZES_CAMPAIGN({
          id: params?.id,
          campaignUuid: state.campaignUuid,
          prizeData: { ...formPrize, type: state?.game },
        })
      )
        .then(() => {
          setLoading(false);
          handleSearchAll();
          addToast({
            message: 'prize successfuly added.',
            type: 'success',
          });
        })
        .catch(() => {
          setLoading(false);
          addToast({
            message: 'An error occurred while adding the prize.',
            type: 'error',
          });
        })
        .finally(() => handleClear());
  };

  const handleClear = () => {
    setSearch('');
    setFormPrize({
      ...formPrize,
      field_spin_prize_name: '',
      winner_redirect_link: {
        options: [],
        title: '',
        uri: '',
      },
      type: state?.game,
      prize: undefined,
      id: undefined,
      name: '',
      created: '',
      prize_description: '',
      quantity: 1,
      field_spin_prize_bg_color: {
        color: '',
        opacity: 1,
      },
      field_spin_prize_text_color: {
        color: '',
        opacity: 1,
      },
      image: [],
      field_image: null,
    });
  };

  const handleSearchAll = () => {
    setLoading(true);
    dispatch(
      FETCH_PRIZES_CAMPAIGN({
        page: 0,
        limit: 25,
        id: params.id,
        search: undefined,
        game: state?.game,
      })
    ).finally(() => setLoading(false));
  };

  const handleRemove = async (prize: PrizeType) => {
    await dispatch(DELETE_PRIZE_CAMPAIGN({ campaignId: params.id, id: prize.id, game: state?.game}))
      .then(() => {
        addToast({
          message: 'prize removed successfully',
          type: 'success',
        });
        setEditMode(false);
        handleClear();
        handleSearchAll();
      })
      .catch(() => {
        addToast({
          message: 'An error occurred while remove the prize.',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    setFormPrize({ ...formPrize, type: state?.game, quantity: 1, winner_redirect_link: {
      uri: '', title: '',
      options: []
    }, prize_description: ''});
    async function init() {
      const requestData = {
        id: params.id,
        page: 0,
        limit: 25,
        game: state?.game,
      };
      setRequestData(requestData);
      setLoading(true);
      await dispatch(FETCH_PRIZES_CAMPAIGN(requestData));
    }

    init().finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    let data: PrizeType[] = InsertActions(prizesData);
    setFilteredPrizeData(data);
  }, [prizesData]);

  const InsertBgColor = (data) => {
    let insertBgColor: any[] = [...data];
    insertBgColor = insertBgColor.map((item) => ({
      ...item,
      field_spin_prize_bg_color: <ShowColor color={item?.field_spin_prize_bg_color?.color} />,
      field_spin_prize_text_color: <ShowColor color={item?.field_spin_prize_text_color?.color} />,
    }));
    return insertBgColor;
  };

  const checkTimeWindow = (prize: PrizeType) => {
    state.timeWindowData?.sort((a, b) => {
      const dateA: any = new Date(a.created);
      const dateB: any = new Date(b.created);
      return dateB - dateA;
    });

    const mostRecentTimeWindow = state.timeWindowData?.[0];

    if (state.timeWindowData?.some(timeItem => timeItem.name === prize?.name) && moment.utc(prize?.created).format('DD/MM/YYYY-HH:mm:ss') < moment(mostRecentTimeWindow?.created).format('DD/MM/YYYY-HH:mm:ss')) {
      return true
    }

    return false
  }

  const InsertActions = (data: PrizeType[]) => {
    let aux: PrizeType[] = [];

    const actions = (prize: PrizeType) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        <IconButton
          icon={Edit2}
          variant="inherit"
          onClick={() => {
            setEditMode(true);
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            setSearch(formPrize?.type === 'bee_run' ? prize.name : prize.prize.name);
            // changeStateForm(setForm, form, 'prize', prize?.prize?.id);
            // setForm(prize);
            setFormPrize({
              ...prize,
              campaign: formPrize?.type === 'bee_run' ? state.campaignUuid : prize.campaign,
              prize: formPrize?.type === 'bee_run' ? prize?.attributes.drupal_internal__id : prize?.prize.id,
              prizeId: prize?.prizeId,
              field_spin_prize_name: prize?.field_spin_prize_name,
              quantity: prize?.quantity,
              field_spin_prize_bg_color: prize?.field_spin_prize_bg_color,
              field_spin_prize_text_color: prize?.field_spin_prize_text_color,
              winner_redirect_link: formPrize?.type === 'bee_run' ? {
                options: [],
                title: prize?.labelURL || '',
                uri: prize?.winnerURL || ''
              } : prize.winner_redirect_link,
              prize_description: prize?.description || ''
            });
          }}
        />
        <ModalDelete
          handleDelete={() => handleRemove(prize)}
          trigger={<IconButton disabled={checkTimeWindow(prize)} icon={Trash2} variant="inherit" />}
          title="Delete prize?"
          height='6.5rem'
          description={
            <>
              Do you really wish to delete this prize?
              <br />
              This action can’t be undone.
            </>
          }
        />
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item: PrizeType = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }

    return aux;
  };

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle marginBottom="1rem" title="Add prize" />
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="medium" border="medium" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'center',
                padding: '1.5rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
                <FlexContainer
                  display="flex"
                  flexWrap="wrap"
                  flexDirection="row"
                  gap="1rem"
                >
                  <AutoComplete
                    onChange={(e) => {
                      changeState(setSearch, e.currentTarget.value);
                    }}
                    onClear={() => {
                      changeState(setSearch, '');
                      changeStateForm(setFormPrize, formPrize, 'prize', undefined);
                    }}
                    onClickResult={(prize) => {
                      setFormPrize({
                        ...formPrize,
                        prize: prize?.id,
                        prizeId: prize?.uuid
                      });
                    }}
                    value={search}
                    editValue={search}
                    required
                    hasError={hasEmptyField && !formPrize?.prize}
                    errorText='Prize is required.'
                    disabled={checkTimeWindow(formPrize)}
                    size="large"
                    label="Prize*"
                    hint="Prizes are registered in the Prizes page"
                    placeholder="Start typing to search"
                  />
                  {state?.game === 'spinning_wheel' ? (
                    <StyledInput
                      defaultValue={formPrize?.field_spin_prize_name}
                      value={formPrize?.field_spin_prize_name}
                      required
                      size="large"
                      label="Prize name*"
                      hint="Prizes are registered in the Prizes page"
                      placeholder="Start typing to search"
                      disabled={checkTimeWindow(formPrize)}
                      onChange={(e) =>
                        changeStateForm(
                          setFormPrize,
                          formPrize,
                          'field_spin_prize_name',
                          e.currentTarget.value
                        )
                      }
                      hasError={hasEmptyField && !formPrize?.field_spin_prize_name}
                      errorText="Field spin prize name is required."
                    />
                  ) : null}
                  <QuantifierLabeled
                    title='Quantity'
                    labelText="How many units of this prize are available"
                    defaultValue={formPrize?.quantity}
                    value={formPrize?.quantity}
                    min={1}
                    onChange={(e) => {
                      let v = parseInt(e.currentTarget.value, 10);
                      if (isNaN(v)) {
                        v = 1;
                      }
                      v = v <= 0 ? 1 : v;
                      changeStateForm(setFormPrize, formPrize, 'quantity', v);
                    }}
                    onClickPlusButton={() => {
                      changeStateForm(setFormPrize, formPrize, 'quantity', formPrize?.quantity + 1);
                    }}
                    onClickMinusButton={() => {
                      changeStateForm(setFormPrize, formPrize, 'quantity', formPrize?.quantity - 1);
                    }}
                  />
                  <StyledInput
                    defaultValue={formPrize?.winner_redirect_link?.uri}
                    value={formPrize?.winner_redirect_link?.uri}
                    required
                    size="large"
                    hasError={winnerURLError}
                    hint="Please insert URL with http:// or https://"
                    label="Winner’s Redirect URL"
                    // hint="How many units of this prize are available"
                    placeholder="Insert your text here"
                    disabled={checkTimeWindow(formPrize)}
                    onChange={(e) => {
                      if (e.currentTarget.value && !regexHTTP.test(e.currentTarget.value)) {
                        setWinnerURLError(true);
                      } else {
                        setWinnerURLError(false);
                      }
                      setFormPrize({
                        ...formPrize,
                        winner_redirect_link: {
                          options: [],
                          uri: e.currentTarget.value,
                          title: formPrize?.winner_redirect_link?.title,
                        },
                      });
                    }}
                  />
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <StyledInput
                      defaultValue={formPrize?.winner_redirect_link?.title}
                      value={formPrize?.winner_redirect_link?.title}
                      required
                      size="large"
                      label="Link Text"
                      hint="The winner redirect link."
                      placeholder="Insert your text here"
                      disabled={checkTimeWindow(formPrize)}
                      onChange={(e) =>
                        setFormPrize({
                          ...formPrize,
                          winner_redirect_link: {
                            options: [],
                            uri: formPrize?.winner_redirect_link?.uri,
                            title: e.currentTarget.value,
                          },
                        })
                      }
                    />
                  </div>


                    {state?.game === 'spinning_wheel' || state?.game === 'virtual_cup_mug' ? '' : (
                      <div style={{ marginRight: '1rem', width: '55%' }}>
                        <StyledTextarea
                          label="Description"
                          placeholder="Insert your text here"
                          value={formPrize?.prize_description}
                          width={'100%'}
                          maxLength={150}
                          disabled={checkTimeWindow(formPrize)}
                          characterCounter
                          style={{
                            height: '90px',
                            resize: 'none',
                          }}
                          onChange={(e) =>
                            changeStateForm(
                              setFormPrize,
                              formPrize,
                              'prize_description',
                              e.currentTarget.value
                            )
                          }
                        />
                      </div>
                    )}
                    {state?.game === 'spinning_wheel' && (
                      <>
                        <div style={{ width: '53%', display: 'flex', gap: '1rem'}}>
                          <StyledInput
                            width={'250px'}
                            type="color"
                            hint='&nbsp;'
                            value={formPrize?.field_spin_prize_bg_color?.color}
                            required
                            size="large"
                            label="Prize background color*"
                            disabled={checkTimeWindow(formPrize)}
                            onChange={(e) =>
                              setFormPrize({
                                ...formPrize,
                                field_spin_prize_bg_color: {
                                  color: e.currentTarget.value,
                                  opacity: 1,
                                },
                              })
                            }
                            hasError={hasEmptyField && !formPrize?.field_spin_prize_bg_color?.color}
                            errorText="Prize background color is required."
                          />
                          <StyledInput
                            width={'250px'}
                            type="color"
                            hint='&nbsp;'
                            value={formPrize?.field_spin_prize_text_color?.color}
                            required
                            size="large"
                            label="Prize text color*"
                            disabled={checkTimeWindow(formPrize)}
                            onChange={(e) =>
                              setFormPrize({
                                ...formPrize,
                                field_spin_prize_text_color: {
                                  color: e.currentTarget.value,
                                  opacity: 1,
                                },
                              })
                            }
                            hasError={hasEmptyField && !formPrize?.field_spin_prize_text_color?.color}
                            errorText="Prize text color is required."
                          />
                        </div>
                        <StyledFileUploader
                          value={formPrize?.image}
                          width="1%"
                          title="Prize thumbnail"
                          accept=".png,.jpg,.jpeg"
                          maxFileSize={102400}
                          message="One file only.
                          100 KB limit.
                          Allowed types: png jpg jpeg.
                          Images must be exactly 50x50 pixels."
                          error={
                            fileError && {
                              message: 'The selected file cannot be uploaded.',
                            }
                          }
                          onChange={(file) => {}}
                          onDrop={(e) => {
                            verifiedFile(e);
                          }}
                          onClean={(file) => {
                            setFileError(false);
                            setFormPrize({
                              ...formPrize,
                                  image: [],
                                  field_image: null,
                            });
                          }}
                          type="image"
                        />
                      </>
                    )}

                  {/* <div style={{ width: 200 }}>
                    <Select.Root
                      defaultValue={form?.type}
                      value={form?.type}
                      size="large"
                      label="Category type"
                      // hint="hint text"
                      placeholder="value"
                      onChange={(value) => changeStateForm(setForm, form, 'category', value)}
                    >
                      <Select.Option value="physical">Physical</Select.Option>
                      <Select.Option value="digital">Digital</Select.Option>
                    </Select.Root>
                  </div> */}
                </FlexContainer>
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <FlexContainer
                width={'100%'}
                display={'inline-flex'}
                justifyContent={'flex-end'}
                gap="0.5rem"
                padding={'0px 1rem 1rem 0px'}
              >
                <StyledButton
                  variant="primary"
                  disabled={loading}
                  isLoading={loading}
                  icon={Plus}
                  leading
                  onClick={() => handleSavePrize()}
                >
                  {editMode ? 'Save' : 'Add prize'}
                </StyledButton>
                {editMode && (
                  <StyledButton
                    variant="secondary"
                    onClick={() => {
                      setEditMode(false);
                      handleClear();
                    }}
                  >
                    Cancel
                  </StyledButton>
                )}
              </FlexContainer>
            </Grid.Item>
            <StyledTable
              margin="0px 1.5rem"
              loading={loading}
              columns={state?.game === 'spinning_wheel' ? PrizeHeadersSpinning : PrizeHeaders}
              onRow={(data, index) => ({
                onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
                  // alert(index);
                },
              })}
              data={
                state?.game === 'spinning_wheel'
                  ? InsertBgColor(filteredPrizeData)
                  : filteredPrizeData
              }
              pagination={{
                pageSize: 10,
                pageSizeOptions: [5, 10, 20],
                showPageSizeSelector: true,
                onChange: (page, pageSize) => {
                  setRequestData({
                    ...requestData,
                    page,
                    limit: pageSize,
                  });
                },
              }}
            />
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '20px',
                }}
              >
                <StyledButton variant="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="primary"
                  onClick={() => {
                    addToast({
                      message: 'Prize successfully created.',
                      type: 'success',
                    });
                    navigate(-1);
                  }}
                >
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
