import { Card, FileValidated, Grid, Select, Tabs } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import { InfoButtonText } from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledTabs from '../../components/Tabs/Tabs';
import StyledTextarea from '../../components/Textarea/Textarea';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { changeStateForm } from '../../utils/functions';
import { Download, Info } from '@hexa-ui/icons';
import StyledSelect from '../../components/Select/Select';
import { AlertContext } from '../../contexts/alert.context';
import useAccountHandler from '../../hook/useAccountHandler';
import { countryType } from '../../components/layout/Layout';
import { useAppSelector } from '../../store';
import checkValidityCSV from '../../utils/verifyCSV';
import { base64CSVConverter } from '../../utils/toBase64';

type NewAccountType = {
  name: string,
  poc: any,
  country: string
}

type importAccountType = {
  description: string,
  field_file: any,
  delimiter: string,
  country: string
}

export default function AddExtensionAccount() {
  const navigate = useNavigate();
  const { addToast } = useContext(AlertContext);
  const { addExtension } = useAccountHandler();
  const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
  const [formExtension, setFormExtension] = useState<NewAccountType>({
    name: '',
    poc: '',
    country: localStorage.getItem('country')
  });
  const [loading, setLoading] = useState(false);
  const [listType, setListType] = useState('add-extension-account');

  const [csvError, setCsvError] = useState<boolean>(false);
  const [form, setForm] = useState<importAccountType>({
    description: '',
    field_file: '',
    delimiter: ',',
    country: localStorage.getItem('country')
  });
  const csvData = [['name', 'poc']];
  const { uploadAccountsCSV, importAccounts } = useAccountHandler();

  const handleSendExtension = async () => {
    if (!formExtension?.name || formExtension?.poc === undefined) {
      addToast({
        message: 'Please fill in all fields to continue!',
        type: 'error',
      });
    } else {
      setLoading(true);
      await addExtension(
        {
          name: formExtension?.name,
          poc: formExtension?.poc,
          country: (countriesPromo?.filter(item => String(item.id) === formExtension?.country)[0]).uuid
        }
      )
        .then((res) => {
          if (res?.data?.errors) {
            addToast({
              message: 'An error occurred while create the extension account.',
              type: 'error',
            });
          } else {
            addToast({
              message: 'Extension account successfuly created',
              type: 'success',
            });
            navigate(-1);
          }
        })
        .catch(() => {
          addToast({
            message: 'An error occurred while create the extension account.',
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleFile = async (file: FileValidated[]) => {
    setCsvError(false);
    setLoading(true);
    let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then((res: boolean) => res);
    if (isValid) {
      const convertedFile = await base64CSVConverter(file[0].file);
      await uploadAccountsCSV({
        fileName: String(file[0].file.name),
        file: convertedFile,
      },)
        .then((data) => {
          if (data?.data?.errors || data?.response?.data?.errors) {
            throw new Error()
          } else {
            addToast({
              message: "Success uploading CSV",
              type: 'success',
            });
            setCsvError(false)
            changeStateForm(setForm, form, 'field_file', data?.uuid[0]?.value);
          }
        }).catch(() => {
          setCsvError(true)
          addToast({
            message: 'An error occurred while uploading CSV.',
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    } else {
      setCsvError(true);
      setLoading(false);
    }
  };

  const handleSend = async () => {
    if (!form?.description || form?.field_file === undefined) {
      addToast({
        message: 'Please fill in all fields to continue!',
        type: 'error',
      });
    } else {
      setLoading(true);
      await importAccounts(
        {
          description: form?.description,
          field_file: form?.field_file,
          delimiter: form?.delimiter ? form?.delimiter : ',',
          countryUuid: (countriesPromo?.filter(item => String(item.id) === form?.country)[0]).uuid
        }
      )
        .then((res) => {
          if (res?.data?.errors || res?.response?.data?.errors) {
            throw new Error()
          } else {
            addToast({
              message: 'Account list successfuly updated',
              type: 'success',
            });
            navigate(-1);
          }
        })
        .catch(() => {
          addToast({
            message: 'An error occurred while updated account list.',
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  };

  const handleChangeCountry = (value) => {
    changeStateForm(setForm, form, 'country', value)
  }

  return (
    <Grid.Container style={{ width: '100%' }}>
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{ padding: '0px', marginBottom: '20px' }}
      >
      {listType === 'add-extension-account' ? (
        <PageTitle
          marginBottom="0.5rem"
          title="Extension account"
          hint=""
        />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
          <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
            <PageTitle
              marginBottom="0.5rem"
              title="Import accounts"
              hint=""
            />
          </Grid.Item>
          <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
            <InfoButtonText
              icon={() => <Download size="large" />}
              iconPosition="leading"
              size="large"
              css={{ gap: 11 }}
            >
              <a
                href={
                  'data:application/octet-stream;base64,bmFtZSxwb2MKdGVzdGUsMQp0ZXN0ZTIsMgo='
                }
                download="Template_account.csv"
                style={{
                  color: 'black',
                  fontWeight: '500',
                  lineHeight: '1.5rem',
                  textDecoration: 'none',
                  fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
                }}
              >
                Download template
              </a>
            </InfoButtonText>
            <a
              style={{ textDecoration: 'none' }}
              href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
            >
              <InfoButtonText
                icon={() => <Info size="xlarge" />}
                iconPosition="leading"
                size="large"
                css={{ gap: 11 }}
              >
                See instructions
              </InfoButtonText>
            </a>
          </Grid.Item>
        </div>
      )}
      </Grid.Item>
      <Card
        elevated="small"
        border="medium"
        css={{ width: '100%', marginBottom: '20px' }}
      >
      <StyledTabs defaultValue={'add-extension-account'} value={listType}>
      <Tabs.List style={{ borderRadius: '24px 24px 0px 0px' }}>
        <Tabs.Trigger value="add-extension-account" onClick={() => setListType('add-extension-account')}>
          Extension account
        </Tabs.Trigger>
        <Tabs.Trigger value="import-accounts" onClick={() => setListType('import-accounts')}>
          Import accounts
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content value="add-extension-account">
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'start'
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: "column" }}>
              <StyledSelect
                size="large"
                label="Country"
                width="50%"
                value={formExtension?.country}
                defaultValue={localStorage.getItem('country')}
                onChange={(value) => changeStateForm(setFormExtension, formExtension, 'country', value)}
              >
                {countriesPromo &&
                  countriesPromo.map((country: countryType, index: number) => {
                    return (
                      <Select.Option key={country.name + index} value={String(country.id)}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
              <StyledInput
                required
                size="large"
                label="Name*"
                placeholder="Insert Name"
                hint="The name of the Extension account entity."
                width="50%"
                onChange={(e) => changeStateForm(setFormExtension, formExtension, 'name', e.currentTarget.value)}
              />
              <StyledInput
                required
                size="large"
                label="POC*"
                placeholder="Insert POC"
                width="50%"
                onChange={(e) => changeStateForm(setFormExtension, formExtension, 'poc', e.currentTarget.value)}
              />
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => handleSendExtension()}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Tabs.Content>
      <Tabs.Content value='import-accounts'>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'center'
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0 }}>
              <StyledSelect
                size="large"
                label="Country"
                width="100%"
                hint='&nbsp;'
                value={form?.country}
                defaultValue={localStorage.getItem('country')}
                onChange={(value) => handleChangeCountry(value)}
              >
                {countriesPromo &&
                  countriesPromo.map((country: countryType, index: number) => {
                    return (
                      <Select.Option key={country.name + index} value={`${country.id}`}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
              <StyledTextarea
                style={{ height: '280px', resize: 'none' }}
                defaultValue={form?.description}
                value={form?.description}
                required
                label="Description"
                hint="Description of the import that will be performed."
                placeholder="Import made on MM/DD/YY - HH:MM"
                width="100%"
                type="textarea"
                height="242px"
                characterCounter
                maxLength={150}
                rows={12}
                onChange={(e) =>
                  changeStateForm(setForm, form, 'description', e.currentTarget.value)
                }
              />
            </Grid.Container>
          </Grid.Item>
          <Grid.Item xl={4} lg={4} md={5} sm={12} xs={12} style={{ flexDirection: 'column' }}>
            <StyledRadio label='Delimiter' defaultValue=',' type='horizontal' hint='Csv delimiter character must be the same as selected in this field.'
              style={{ marginBottom: '12px', minWidth: '100% ' }}
              options={[
                {
                  id: 'comma',
                  label: ', (Comma)',
                  value: ','
                },
                {
                  id: 'semicolon',
                  label: '; (Semicolon)',
                  value: ';'
                }
              ]}
              onChange={(value => changeStateForm(setForm, form, 'delimiter', value))}
            />
            <StyledFileUploader
              legend={
                <>
                  The csv must have the <b>name</b> and <b>poc</b>{' '}
                  columns.
                </>
              }
              title="Import .csv file"
              accept=".csv"
              maxFileSize={1024 * 1024 * 5}
              message="File must be .csv and must be less than 5MB"
              onDrop={(file) => handleFile(file)}
              maxFiles={1}
              type="file"
              error={
                csvError
                  ? {
                    message: `The csv is not valid. Columns don't match!`,
                  }
                  : undefined
              }
            />
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              paddingBottom: '20px',
              paddingRight: '40px'
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Tabs.Content>
      </StyledTabs>
      </Card>
    </Grid.Container>
  );
}
