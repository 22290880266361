import { Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const ScaleParagraph = styled(Paragraph, {
    variants: {
        size: {
            xsmall: {
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '0.55rem',
                    lineHeight: '$lineHeights$4'
                }
            },
            small: {
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '0.75rem',
                    lineHeight: '$lineHeights$4'
                }
            }
        },
    }
})

export const ScaleHeading = styled(Heading, {
    variants: {
        size: {
            H1: {
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '1.80rem'
                }
            },
            H2: {
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '1.75rem'
                }
            },
            H3: {
                '@media (min-resolution: 1.50dppx)': {
                    fontSize: '1.70rem'
                }
            }
        },
    },
})