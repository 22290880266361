import { apiDrupal } from "../Api/Api";
import { base_url } from "../utils/constants";

interface PlayerHandleInterface {
    addPlayer: (requestData) => Promise<any>
}

type NewPlayerType = {
    name: string
    poc: any
    quantity: number
    campaignId: string
    campaignType: string
}

export default function usePlayerHandler(): PlayerHandleInterface {
    const addPlayer = async (requestData: NewPlayerType) => {
        return await apiDrupal.post(`${base_url}/jsonapi/cm_base_player/cm_base_player`,
            {
                "data": {
                    "type": "cm_base_player--cm_base_player",
                    "attributes": {
                        "name": `${requestData?.name}`,
                        "quantity": `${requestData?.quantity}`,
                        "poc": `${requestData?.poc}`
                    },
                    "relationships": {
                        "campaign": {
                            "data": {
                                "type": `cm_base_campaign--${requestData?.campaignType}`,
                                "id": `${requestData?.campaignId}`
                            }
                        }
                    },
                }
            }, {
            headers: {
                'Content-Type': 'application/vnd.api+json',
                Accept: '*/*'
            }
        })
            .then(response => response.data)
            .catch(error => error.response);
    }
    return { addPlayer }
}
