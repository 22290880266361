import { Card, Chip, ListItem } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('div', {
    position: 'relative',
    zIndex: '$1',
})

export const FilterMenu = styled(Card, {
    position: 'absolute',
    margin: '$space$1',
    width: '500px',
    height: '400px',
    '@media (min-resolution: 1.50dppx)': {
        width: '350px',
        height: '250px',
    },
    '@media (max-width: 490px)': {
        width: '350px',
        height: '300px',
    }
})

export const ButtonText = styled('p', {
    margin: '0px $space$2',
})

export const ContainerActions = styled('div', {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: '$space$6',
    '> div': {
        paddingLeft: '$space$4',
    },
    'div > button': {
        margin: '0px'
    },
    '@media (min-resolution: 1.50dppx)': {
        padding: '$space$4',
        '> div': {
            paddingLeft: '$space$2',
        }
    }
})

export const List = styled('div', {
    width: '50%',
    height: '100%',
    listStyle: 'none',
    '>li:first-child': {
        borderRadius: '$4 0px 0px',
    },
})

export const ShowCheckboxes = styled('div', {
    width: '50%',
    height: '100%',
    listStyle: 'none',
    padding: '$space$6',
    '>div': {
        marginBottom: '$space$4'
    },
    '@media (min-resolution: 1.50dppx)': {
        padding: '$space$4',
        label: {
            fontSize: '$1'
        },
        button: {
            width: '13px',
            height: '13px',
            borderRadius: '4px',
            marginRight: '0px'
        }
    }
})

export const FilterOptions = styled('div', {
    width: '100%',
    display: 'flex',
    height: '350px'
})

export const StyledListItem = styled(ListItem, {
    position: 'relative',
    variants: {
        isCurrentTab: {
            true: {
                borderRight: '2px solid $interfaceLabelPrimary',
                backgroundColor: '$interfaceSurfacePrimary'
            },
        },
    },
    '@media (min-resolution: 1.50dppx)': {
        div: {
            fontSize: '$1'
        }
    }
})

export const DisplayFilters = styled('div', {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '> p': {
        marginRight: '$space$6'
    }
})

export const GroupChips = styled(Chip.Root, {
    margin: '0px $space$1',
    p: {
        fontWeight: '$semibold'
    },
    '@media (min-resolution: 1.50dppx)': {
        p: {
            fontSize: '$fontSizes$1'
        }
    }
})