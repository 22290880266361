import { styled } from '@hexa-ui/theme';

export const CheckboxContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  maxWidth: '100%',
  '@media (min-resolution: 1.50dppx)': {
    'p, input': {
      fontSize: '$1',
    },

    label: {
      fontSize: '$1',
    },

    svg: {
      height: '1rem',
      width: '1rem',
    },

    'svg:nth-child(2)': {
      height: '0.813px',
      width: '0.813px',
    },

    '> p:nth-child(2)': {
      fontSize: '0.55rem',
      lineHeight: '1.7',
    },
  },
});

export const CheckboxStyle = styled('div', {
  margin: '0.5rem 0',
});
