import { Card, FileValidated, Grid } from '@hexa-ui/components';
import { useContext, useEffect, useState } from 'react';

import { Download, Info } from '@hexa-ui/icons';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppDispatch, useAppSelector } from '../../store';
import { PlayerType } from '../../store/dataTypes/player.type';
import { IMPORT_PLAYERS, IMPORT_PLAYERS_ENTITY } from '../../store/stock/PlayersReducer';
import { changeStateForm } from '../../utils/functions';
import { base64CSVConverter } from '../../utils/toBase64';

import StyledButton from '../../components/Button/Button';
import StyledFileUploader from '../../components/FileUploader/FileUploader';
import { InfoButtonText } from '../../components/InfoField/InfoField';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledRadio from '../../components/Radio/StyledRadio';
import StyledTextarea from '../../components/Textarea/Textarea';
import { AlertContext } from '../../contexts/alert.context';
import WindowFocusHandler from '../../hook/windowFocusHandler';
import { CampaignType } from '../../store/dataTypes';
import { FETCH_CAMPAIGN_DETAILS } from '../../store/stock/CampaignDetailsReducer';
import checkValidityCSV from '../../utils/verifyCSV';
import StyledToggle from '../../components/Toggle/Toggle';
import StyledAlert from '../../components/Alert/Alert';

export default function ImportPlayers() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { state } = useLocation();
  const { addToast } = useContext(AlertContext);
  const [csvError, setCsvError] = useState<boolean>(false);
  const [fileEmptyError, setFileEmptyError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const campaignDetails: CampaignType = useAppSelector((state) => state.campaignDetails.data);
  const [errorDescription, setErrorDescription] = useState<boolean>(false);
  const [checkRewardsError, setCheckRewardsError] = useState<boolean>(false);
  const [form, setForm] = useState<PlayerType>(undefined);
  const [field_file, setField_file] = useState();

  const csvData = [['name', 'poc', 'quantity']];

  WindowFocusHandler(async () => {
    await dispatch(FETCH_CAMPAIGN_DETAILS({ id: state.idCampaign }));
  });

  useEffect(() => {
    async function init() {
      await dispatch(FETCH_CAMPAIGN_DETAILS({ id: state.idCampaign }));
    }

    init();
  }, []);

  useEffect(() => {
    setCsvError(false);
  }, [field_file]);

  useEffect(() => {
    if (form?.field_active_rewards_consent) {
      setCheckRewardsError(false)
    }

  }, [form?.field_active_rewards_consent])

  const handleFile = async (file: FileValidated[]) => {
    setField_file(undefined);
    setLoading(true);

    const specialCharPattern = /[çÇáéíóúÁÉÍÓÚâêîôûÂÊÎÔÛãõÃÕàÀ]/;
    if (specialCharPattern.test(file[0].file.name)) {
      setCsvError(true);
      setLoading(false);
      addToast({
        message: `The file name contains special characters. Please rename the file and try again.`,
        type: 'error',
      });
      return;
    }

    let isValid = await checkValidityCSV(file[0].file, csvData[0], form?.delimiter).then(
      (res: boolean) => res
    );
    if (isValid) {
      const convertedFile = await base64CSVConverter(file[0].file);

      const importPlayers = await dispatch(
        IMPORT_PLAYERS({
          idCampaign: state.idCampaign,
          fileName: String(file[0].file.name),
          file: convertedFile,
        })
      ).finally(() => setLoading(false));
      setField_file(importPlayers.payload.fid[0].value);
    } else {
      setCsvError(true);
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (form?.description == '' || form?.description == undefined) {
      setErrorDescription(true);
      return;
    }

    if (!field_file) {
      setFileEmptyError(true);
      return;
    }

    if ((form?.field_active_rewards_consent == false || form?.field_active_rewards_consent == undefined) && campaignDetails?.rewards_integration) {
      setCheckRewardsError(true);
      return;
    }

    setCheckRewardsError(false);
    setErrorDescription(false);
    setFileEmptyError(false);

    setLoading(true);
    await dispatch(
      IMPORT_PLAYERS_ENTITY({
        name: form.description,
        bundle: 'base_player',
        field_added_to_queue: 0,
        field_base_campaign: state.idCampaign,
        field_file,
        field_file_delimiter: form?.delimiter,
        field_active_rewards_consent: form?.field_active_rewards_consent
      })
    ).then((res) => {
      if (res?.payload?.error?.code === 0) {
        setCsvError(true);
        addToast({
          message: res?.payload?.error?.message,
          type: 'error',
        });
        setLoading(false)
      } else {
        addToast({
          message: 'Players successfuly updated',
          type: 'success',
        });
        navigate(-1)
        setLoading(false)
      }
    })
  };

  return (
    <div>
      <Grid.Container type="fluid">
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <PageTitle
            marginBottom="0.5rem"
            title="Import players"
            hint="Time to create your next big project! We suggest giving it a meaningful name,
              as well as a custom thumbnail. The future you says thanks!"
          />
        </Grid.Item>
        <Grid.Item style={{ marginBottom: '1.5rem', gap: 23, alignItems: 'center' }}>
          <InfoButtonText
            icon={() => <Download size="large" />}
            iconPosition="leading"
            size="large"
            css={{ gap: 11 }}
          >
            <a
              href="data:application/octet-stream;base64,bmFtZSxwb2MscXVhbnRpdHkKUG9jIHRlc3RlLDAsMQo="
              download="template_player.csv"
              style={{
                color: 'black',
                fontWeight: '500',
                lineHeight: '1.5rem',
                textDecoration: 'none',
                fontFamily: 'Work Sans, San Francisco, Roboto, Segoe UI, Helvetica, sans-serif',
              }}
            >
              Download template
            </a>
          </InfoButtonText>
          <a
            style={{ textDecoration: 'none' }}
            target={'_blank'}
            href="https://anheuserbuschinbev-my.sharepoint.com/:w:/g/personal/vanessa_venancio_ab-inbev_com/EQKqAk0e62JAsNVMlabUILoBtGwCtQHpna78QBQIZvYneg?e=OKt83g"
          >
            <InfoButtonText
              icon={() => <Info size="xlarge" />}
              iconPosition="leading"
              size="large"
              css={{ gap: 11 }}
            >
              See instructions
            </InfoButtonText>
          </a>
        </Grid.Item>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card elevated="small" border="medium" css={{ width: '100%' }}>
            <Grid.Container
              type="fluid"
              style={{
                justifyContent: 'center',
                padding: '1.5rem 0 1rem 0',
              }}
            >
              <Grid.Item xl={9} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
                <Grid.Container
                  type="fluid"
                  style={{ width: '100%', height: 'max-content', rowGap: '1rem', margin: 0 }}
                >
                  <StyledInput
                    defaultValue={campaignDetails?.name}
                    value={campaignDetails?.name}
                    required
                    size="large"
                    label="Campaign"
                    placeholder="Campaign Example"
                    hint="The current campaign you’re importing to. This cannot be changed"
                    width="100%"
                    onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
                    disabled
                  />
                  <StyledTextarea
                    style={{ height: '300px', resize: 'none' }}
                    defaultValue={form?.description}
                    value={form?.description}
                    required
                    label="Description*"
                    placeholder="Import made on MM/DD/YY - HH:MM"
                    width="100%"
                    type="textarea"
                    characterCounter
                    maxLength={150}
                    rows={12}
                    onChange={(e) =>
                      changeStateForm(setForm, form, 'description', e.currentTarget.value)
                    }
                    hasError={errorDescription}
                    errorText="Description required"
                  />
                  {campaignDetails?.rewards_integration && (
                    <>
                      {checkRewardsError ? (
                        <StyledAlert
                          css={{ width: '45%' }}
                          message="Please check this box if you want to proceed."
                          type="warning"
                        />
                      ) : null}
                      <StyledToggle
                        title="Active rewards consent*"
                        description="The integration with rewards is currently active for the campaign, confirmation is required by checking this option."
                        value={form?.field_active_rewards_consent}
                        onChange={(value) => changeStateForm(setForm, form, 'field_active_rewards_consent', value)}
                      />
                    </>
                  )}
                </Grid.Container>
              </Grid.Item>
              <Grid.Item
                xl={3}
                lg={4}
                md={5}
                sm={12}
                xs={12}
                style={{ flexDirection: 'column', paddingRight: '0px' }}
              >
                <StyledRadio
                  label="Delimiter"
                  defaultValue=","
                  type="horizontal"
                  hint="Csv delimiter character must be the same as selected in this field."
                  style={{ marginBottom: '12px', minWidth: '100% ' }}
                  options={[
                    {
                      id: 'comma',
                      label: ', (Comma)',
                      value: ',',
                    },
                    {
                      id: 'semicolon',
                      label: '; (Semicolon)',
                      value: ';',
                    },
                  ]}
                  onChange={(value) => changeStateForm(setForm, form, 'delimiter', value)}
                />
                <StyledFileUploader
                  title="Import .csv file"
                  legend={
                    <>
                      The csv must have the <b>name</b>, <b>poc</b> and <b>quantity</b> columns.
                    </>
                  }
                  accept=".csv"
                  maxFileSize={1024 * 1024 * 5}
                  message="File must be .csv and must be less than 5MB"
                  onDrop={(e) => {
                    handleFile(e);
                    changeStateForm(setForm, form, 'file', e);
                    setFileEmptyError(false);
                  }}
                  error={
                    csvError
                      ? {
                        message: `The csv is not valid. Columns don't match!`,
                      }
                      : fileEmptyError
                        ? {
                          message: `This field cannot be empty.`,
                        }
                        : undefined
                  }
                  maxFiles={1}
                  type="file"
                />
              </Grid.Item>
            </Grid.Container>
            <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div
                style={{
                  width: '100%',
                  display: 'inline-flex',
                  justifyContent: 'flex-end',
                  gap: '0.5rem',
                  padding: '0px 20px 20px 20px',
                }}
              >
                <StyledButton
                  variant="secondary"
                  onClick={() =>
                    navigate(
                      '/bees-games/manage-assets/campaign-details/' + state.idCampaign + '#players'
                    )
                  }
                >
                  Cancel
                </StyledButton>
                <StyledButton variant="primary" isLoading={loading} onClick={() => handleSave()}>
                  Save
                </StyledButton>
              </div>
            </Grid.Item>
          </Card>
        </Grid.Item>
      </Grid.Container>
    </div>
  );
}
