import { ProgressTracker } from '@hexa-ui/components';
import { CSSProperties, useContext } from 'react';
import useWindowDimensions from '../../hook/useWindowDimensions';
import { GameSettingsContext } from '../../contexts/gameSettings.context';

interface IStep {
  currentStep: number;
  goTo: (index: number) => void;
  items: itemsType[];
  incompleted: boolean[];
  scale?: {
    transform: string;
    transformOrigin: string;
  };
  onClickSelect?: (indexSelected?: number) => void;
  isDisabled?: (index: number) => boolean;
}
export type itemsType = {
  detail: string;
  label: string;
  alertMessage?: string;
};
const StepByStep: React.FC<IStep> = ({
  currentStep,
  scale,
  items,
  incompleted,
  goTo,
  onClickSelect,
  isDisabled,
}) => {
  const { Root, Header, Step, StepLabel } = ProgressTracker;

  const { width } = useWindowDimensions();

  const ContainerStyle: CSSProperties = {
    width: '100%',
    display: 'flex',
  };

  const HeaderStyle: CSSProperties = {
    padding: '2rem 0 2rem 0',
  };

  const { gameSettingsState } = useContext(GameSettingsContext);
  const isPrizeDisabled = gameSettingsState.isPrizeDisabled;

  return (
    <div style={ContainerStyle}>
      <Root
        currentStep={currentStep || 0}
        orientation={width > 1200 ? 'vertical' : 'horizontal'}
        size="large"
      >
        <div style={{ ...scale, ...HeaderStyle }}>
          <Header>
            {items &&
              items.map((item: itemsType, index) => {
                return (
                  <Step
                    error={incompleted[index]}
                    completed={currentStep > index ? true : false}
                    index={index}
                    disabled={isDisabled ? isDisabled(index) : false}
                    key={item.label + index}
                    onSelect={(index) => onClickSelect(index)}
                  >
                    <StepLabel
                      alertMessage={item.alertMessage}
                      detail={item.detail}
                      label={item.label}
                    />
                  </Step>
                );
              })}
          </Header>
        </div>
      </Root>
    </div>
  );
};

export default StepByStep;
