import { Paragraph } from '@hexa-ui/components';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { PrizeType } from '../dataTypes';

//ASYNC THUNKS
export const FETCH_PRIZES = createAsyncThunk(
  'fetch/prizes',
  async (
    data: {
      page: number;
      limit: number;
      search?: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `/prizes?page=${data.page}&limit=${data.limit}&search=${data?.search}`
    );
    return response.data;
  }
);

export const DELETE_PRIZE = createAsyncThunk(
  'delete/prizes',
  async (data: { id: string }, thunkAPI) => {
    const response = await apiDrupal.delete(`/PrizesData/${data.id}`);
    return response.data;
  }
);

interface IPrizeState {
  data: PrizeType[];
  error: any;
}

const initialState: IPrizeState = {
  data: [],
  error: undefined,
};

export const Slice = createSlice({
  name: 'Prize',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_PRIZES
    builder.addCase(FETCH_PRIZES.pending, (state, action) => {});
    builder.addCase(FETCH_PRIZES.fulfilled, (state, action) => {
      state.data = Object.values(action.payload.data.prizes);

      let prizes = state.data;
      let aux = state.data;

      //Add HTML IMG element with url and add it to the status field.
      for (let index = 0; index < prizes.length; index++) {
        const element = prizes[index];
        aux[index].preview = (
          <img
            src={element.field_image.url}
            alt={element.field_image.alt}
            title={element.field_image.title}
            width={50}
            height={50}
          />
        );
        aux[index].status = element.status === true ? 'published' : 'unpublished';
      }

      state.data = aux;
    });
    builder.addCase(FETCH_PRIZES.rejected, (state, action) => {
      state.error = action.error;
    });

    // DELETE_PRIZE
    builder.addCase(DELETE_PRIZE.pending, (state, action) => {});
    builder.addCase(DELETE_PRIZE.fulfilled, (state, action) => {
      let prizes = action.payload;
      let aux = action.payload;

      //mount the status icon with type of status...
      const statusComponent = (text, color) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Paragraph
              style={{ borderRadius: '50%', backgroundColor: color, width: 10, height: 10 }}
            ></Paragraph>{' '}
            {text}
          </div>
        );
      };

      //Add HTML IMG element with url and add it to the status field.
      for (let index = 0; index < prizes.length; index++) {
        const element = prizes[index];
        aux[index].preview = <img src={element.preview} width={50} height={50} />;
        aux[index].status = statusComponent(element.statusDetail.text, element.statusDetail.color);
      }

      state.data = aux;
    });
    builder.addCase(DELETE_PRIZE.rejected, (state, action) => {});
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
