import { Paragraph } from '@hexa-ui/components';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { PlayerAttemptType, PlayerType } from '../dataTypes/player.type';
import { imageUploadType } from '../dataTypes/uploadImage.type';

//ASYNC THUNKS
export const FETCH_PLAYERS = createAsyncThunk(
  'fetch/players',
  async (
    data: {
      campaign_id: string;
      page: number;
      limit: number;
      search: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(`/${data.campaign_id}/players?page=${data.page}&limit=${data.limit}&search=${data.search}`);
    return response.data;
  }
);

export const FETCH_PLAYERS_ATTEMPTS = createAsyncThunk(
  'fetch/player_attempts',
  async (data: any, thunkAPI) => {
    if (data.game === 'bee_run') {
      const response = await apiDrupal.get(
        `${base_url}/jsonapi/bee_run_score/bee_run_score?include=player&page[limit]=${data.limit}&page[offset]=${data.page}&filter[campaign.id][condition][path]=campaign.meta.drupal_internal__target_id&filter[campaign.id][condition][operator]==&filter[campaign.id][condition][value]=${data.campaign_id}`
      );
      return response.data;
    } else {
    const response = await apiDrupal.get(
      `/campaigns/${data.campaign_id}/player-attempts?page=${data.page}&limit=${data.limit}&search=${data.search}`
    );
    return response.data.data;
    }
  }
);

export const FETCH_PLAYER_ByID = createAsyncThunk(
  'fetch_by_id/player',
  async (
    data: {
      campaignId: string;
      id: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get('/' + data.campaignId + '/players/' + data.id);
    return response.data;
  }
);

export const IMPORT_PLAYERS_CHECK = createAsyncThunk(
  'fetch/import_players_check',
  async (
    data: {
      id: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get(
      `${base_url}/hub/v2/competition/${data.id}/import/base_player/status?format=json`
    );
    return response.data;
  }
);

export const IMPORT_PLAYERS = createAsyncThunk(
  'fetch/import_players',
  async (
    data: {
      idCampaign: string;
      fileName: string;
      file: any;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      base_url +
      '/file/upload/cm_import/base_player/field_file?_format=json&campaign=' +
      data.idCampaign,
      data.file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': 'attachment; filename="' + data.fileName + '"',
        },
      }
    );
    return response.data;
  }
);

export const IMPORT_PLAYERS_ENTITY = createAsyncThunk(
  'fetch/import_players_entity',
  async (
    data: {
      name: string;
      bundle: string;
      field_added_to_queue: number;
      field_base_campaign: number;
      field_file: number;
      field_file_delimiter: string;
      field_active_rewards_consent: boolean;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      `${base_url}/hub/v2/competition/${data.field_base_campaign}/import/base_player?_format=json`,
      data
    );
    return response.data;
  }
);

export const IMPORT_PLAYERS_ENTITY_STATUS = createAsyncThunk(
  'fetch/import_players_entity_status',
  async (
    data: {
      id: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.get('/' + data.id + '/import/base_player?_format=json');
    return response.data;
  }
);

export const FETCH_PLAYERS_UPDATE = createAsyncThunk(
  'fetch/players_update',
  async (
    data: {
      id: string;
      idCampaign: string;
      name: string;
      poc: string;
      quantity: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.patch(
      '/' + data.idCampaign + '/players/' + data.id + '?_format=json',
      {
        name: data.name,
        poc: data.poc,
        quantity: data.quantity,
      }
    );
    return response.data;
  }
);

interface IPlayerState {
  data: PlayerType[];
  player: PlayerType;
  totalPlayers: number;
  dataAttempt: PlayerAttemptType[];
  dataFile: imageUploadType;
  check: boolean;
  status: any;
  idFile: number;
  total: number;
  totalAttempt: number;
  error: any;
}

const initialState: IPlayerState = {
  data: [],
  player: undefined,
  totalPlayers: 0,
  dataAttempt: [],
  dataFile: null,
  check: false,
  status: null,
  idFile: null,
  total: 0,
  totalAttempt: 0,
  error: undefined,
};

export const AttemptSlice = createSlice({
  name: 'PlayerAttempts',
  initialState,
  reducers: {},
});

export const Slice = createSlice({
  name: 'Player',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_PLAYERS
    builder.addCase(FETCH_PLAYERS.pending, (state, action) => { });
    builder.addCase(FETCH_PLAYERS.fulfilled, (state, action) => {
      state.data = Object.values(action.payload.data.players);
      state.totalPlayers = action.payload.data.total_items;

      let players = action.payload.data.players;
      let aux: PlayerType[] = state.data;

      //mount the status icon with type of status...
      const statusComponent = (text, color) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Paragraph
              style={{ borderRadius: '50%', backgroundColor: color, width: 10, height: 10 }}
            ></Paragraph>
            {text}
          </div>
        );
      };

      for (let index = 0; index < players.length; index++) {
        const element: PlayerType = players[index];
        aux[index].status = statusComponent(element.statusDetail.text, element.statusDetail.color);
        aux[index].result = statusComponent(element.resultDetail.text, element.resultDetail.color);
      }

      state.data = aux;
    });
    builder.addCase(FETCH_PLAYERS.rejected, (state, action) => {
      state.error = action.error;
    });

    //FETCH_ATTEMPT_PLAYERS
    builder.addCase(FETCH_PLAYERS_ATTEMPTS.pending, (state, action) => { });
    builder.addCase(FETCH_PLAYERS_ATTEMPTS.fulfilled, (state, action) => {
    if (action.meta.arg.game === 'bee_run') {
      console.log(action);
      let attemptData = action.payload;

      state.dataAttempt = attemptData.data;
      state.totalAttempt = attemptData.meta.count;

      for (let i = 0; i < state.totalAttempt; i++) {
        state.dataAttempt[i].id = attemptData.data[i].attributes.drupal_internal__id;
        state.dataAttempt[i].poc = attemptData.data[i].attributes.poc;
        state.dataAttempt[i].player_score = attemptData.data[i].attributes.bee_run_player_score;
        state.dataAttempt[i].seconds = attemptData.data[i].attributes.seconds;
        state.dataAttempt[i].items = attemptData.data[i].attributes.items;
        state.dataAttempt[i].obstacles = attemptData.data[i].attributes.obstacles;
        state.dataAttempt[i].letters = attemptData.data[i].attributes.letters;
        for (let j = 0; j < action.payload.included.length; j++) {
          if (state.dataAttempt[i].relationships.player.data.id === action.payload.included[j].id) {
            state.dataAttempt[i].player = action.payload.included[j].attributes.name;
            state.dataAttempt[i].quantity = action.payload.included[j].attributes.quantity;
            break;
          }
        }
      }
    } else {
      let attemptData = action.payload;

      //mount the status icon with type of status...
      const statusComponent = (text, color) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Paragraph
              style={{ borderRadius: '50%', backgroundColor: color, width: 10, height: 10 }}
            ></Paragraph>
            {text}
          </div>
        );
      };

      attemptData.results.map((a) => {
        a.result = statusComponent(
          a.result == 1 ? 'Lost' : 'Won',
          a.result == 1 ? '#e4423f' : '#00a838'
        );
      });

      state.dataAttempt = attemptData.results;
      state.totalAttempt = attemptData.total;
    }
    });
    builder.addCase(FETCH_PLAYERS_ATTEMPTS.rejected, (state, action) => {
      state.error = action.error;
    });

    // FETCH_PLAYER_ByID
    builder.addCase(FETCH_PLAYER_ByID.pending, (state, action) => { });
    builder.addCase(FETCH_PLAYER_ByID.fulfilled, (state, action) => {
      state.player = action.payload.data.player;
    });
    builder.addCase(FETCH_PLAYER_ByID.rejected, (state, action) => {
      state.error = action.error;
    });

    // IMPORT_PLAYERS_CHECK
    builder.addCase(IMPORT_PLAYERS_CHECK.pending, (state, action) => {
      state.check = true;
    });
    builder.addCase(IMPORT_PLAYERS_CHECK.fulfilled, (state, action) => {
      state.check = true;
      state.status = action.payload;
    });
    builder.addCase(IMPORT_PLAYERS_CHECK.rejected, (state, action) => {
      state.error = action.error;
      state.check = false;
    });

    // IMPORT_PLAYERS
    builder.addCase(IMPORT_PLAYERS.pending, (state, action) => { });
    builder.addCase(IMPORT_PLAYERS.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(IMPORT_PLAYERS.rejected, (state, action) => {
      state.error = action.error;
    });

    // IMPORT_PLAYERS_ENTITY
    builder.addCase(IMPORT_PLAYERS_ENTITY.pending, (state, action) => { });
    builder.addCase(IMPORT_PLAYERS_ENTITY.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(IMPORT_PLAYERS_ENTITY.rejected, (state, action) => {
      state.error = action.error;
    });

    // IMPORT_PLAYERS_ENTITY_STATUS
    builder.addCase(IMPORT_PLAYERS_ENTITY_STATUS.pending, (state, action) => { });
    builder.addCase(IMPORT_PLAYERS_ENTITY_STATUS.fulfilled, (state, action) => {
      state.status = action.payload;
    });
    builder.addCase(IMPORT_PLAYERS_ENTITY_STATUS.rejected, (state, action) => {
      state.error = action.error;
    });

    // FETCH_PLAYERS_UPDATE
    builder.addCase(FETCH_PLAYERS_UPDATE.pending, (state, action) => { });
    builder.addCase(FETCH_PLAYERS_UPDATE.fulfilled, (state, action) => { });
    builder.addCase(FETCH_PLAYERS_UPDATE.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { } = Slice.actions;
export default Slice.reducer;
