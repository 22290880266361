import { Card, Heading, IconButton, Modal, Paragraph } from '@hexa-ui/components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, useAppSelector } from '../../../store';

import { Edit2, Plus, Trash2 } from '@hexa-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import StyledButton from '../../../components/Button/Button';
import FlexContainer from '../../../components/FlexContainer';
import StyledTable, { StyledPagination } from '../../../components/Table/Table';
import { LevelsType } from '../../../store/dataTypes/level.type';
import { DELETE_LEVEL, FETCH_LEVELS } from '../../../store/stock/LevelsReducer';
import LevelsHeaders from '../../json/columnHeaders/LevelsHeaders.json';

const LevelsTab: React.FC = () => {
  const hasProgress: boolean = useAppSelector((state) => state.levels.hasProgress);
  const levelsData: LevelsType[] = useAppSelector((state) => state.levels.data);
  const totalPages = useAppSelector((state) => state.levels.total);
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [page, setPage] = useState(0);
  const [data, setData] = useState(levelsData);
  const [limit, setLimit] = useState(25);
  const [requestData, setRequestData] = useState(null);
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = {
      campaign_id: id,
      page: page,
      limit: limit,
    };
    setRequestData(requestData);
    setLoading(true);
    dispatch(FETCH_LEVELS(requestData)).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    setData(InsertActions(levelsData));
  }, [levelsData]);

  const handlePagination = (page, limit) => {
    requestData.page = page - 1;
    requestData.limit = limit;
    setRequestData(requestData);
    setLimit(limit);
    dispatch(FETCH_LEVELS(requestData));
  };

  const handleRemove = async (id) => {
    await dispatch(DELETE_LEVEL({ campaignId: params.id, id: id })).finally(async () => {
      await dispatch(FETCH_LEVELS(requestData));
    });
  };

  const InsertActions = (data: LevelsType[]) => {
    let aux: LevelsType[] = [];

    const actions = (level: LevelsType) => (
      <div style={{ display: 'inline-flex', gap: '1rem' }}>
        {!hasProgress && (
          <>
            <IconButton
              icon={Edit2}
              variant="inherit"
              onClick={() => {
                navigate(
                  `/bees-games/manage-assets/campaign-details/${params.id}/edit-level/${level.id}`
                );
              }}
            />
            <Modal.Root
              actions={
                <FlexContainer
                  width="100%"
                  display="inline-flex"
                  justifyContent="flex-end"
                  gap="0.5rem"
                >
                  <Modal.Action>
                    <StyledButton variant="secondary">Cancel</StyledButton>
                  </Modal.Action>
                  <Modal.Action>
                    <StyledButton variant="destructive" onClick={() => handleRemove(level.id)}>
                      Delete
                    </StyledButton>
                  </Modal.Action>
                </FlexContainer>
              }
              trigger={<IconButton icon={Trash2} variant="inherit" onClick={() => { }} />}
              variant="overlay"
              destructive="critical"
            >
              <Heading type="H3" css={{ marginBottom: '1rem' }}>
                Delete level?
              </Heading>
              <Paragraph>{'Do you really wish to delete this level?'}</Paragraph>
              <Paragraph>{'This action can’t be undone.'}</Paragraph>
            </Modal.Root>
          </>
        )}
      </div>
    );

    for (let i = 0; i < data.length; i++) {
      let item: LevelsType = data[i];
      aux[i] = Object.assign({}, item, {
        actions: actions(item),
      });
    }
    return aux;
  };

  return (
    <Card elevated={'medium'} border={'large'} css={{ paddingBottom: '2rem' }}>
      <FlexContainer
        width="100%"
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'1rem'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        {!hasProgress && (
          <StyledButton
            variant="primary"
            icon={Plus}
            leading
            onClick={() =>
              navigate('/bees-games/manage-assets/campaign-details/' + id + '/generate-levels')
            }
          >
            Generate levels
          </StyledButton>
        )}
      </FlexContainer>
      <StyledTable
        columns={LevelsHeaders}
        search={false}
        data={data || []}
        loading={loading}
        pagination={false}
      />

      <FlexContainer
        display={'inline-flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        padding={'20px 20px 20px 0'}
        gap={'0.5rem'}
        flexWrap="wrap"
      >
        <StyledPagination
          pageSize={limit}
          total={totalPages}
          current={page}
          onChange={handlePagination}
        />
      </FlexContainer>
    </Card>
  );
};

export default LevelsTab;
