import { FileValidated, Grid, Paragraph, Image } from '@hexa-ui/components';
import { SetStateAction, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { apiDrupal } from '../../../../../Api/Api';
import StyledButton from '../../../../../components/Button/Button';
import StyledFileUploader from '../../../../../components/FileUploader/FileUploader';
import FlexContainer from '../../../../../components/FlexContainer';
import StyledInput from '../../../../../components/Input/Input';
import { AlertContext } from '../../../../../contexts/alert.context';
import { GameSettingsContext } from '../../../../../contexts/gameSettings.context';
import { AppDispatch, useAppSelector } from '../../../../../store';
import { CampaignType } from '../../../../../store/dataTypes';
import { imageUploadType } from '../../../../../store/dataTypes/uploadImage.type';
import pdfImage from '../../../../../assets/pdfSettings.jpeg';

import {
  DELETE_CAMPAIGN_SETTINGS,
  PATCH_CAMPAIGN_SETTINGS,
  STORE_CAMPAIGN_SETTINGS,
} from '../../../../../store/stock/CampaignSettingsReducer';
import { base_url } from '../../../../../utils/constants';
import verifyImgType from '../../../../../utils/verifyImgType';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}

const TabGameSettingsSpinningWheel: React.FC<props> = ({ form, setForm }) => {
  const { gameSettingsState, setGameSettingsState } = useContext(GameSettingsContext);
  const { addToast } = useContext(AlertContext);
  const [headerFileTypeError, setHeaderFileTypeError] = useState(false);
  const [hasEmptyField, setHasEmptyField] = useState(false);
  const [footerFileTypeError, setFooterFileTypeError] = useState(false);
  const [loading, setLoading] = useState(false);

  const newCampaignSettingsData = useAppSelector(
    (state) => state.campaignSettings.newCampaignSettings
  );
  const dispatch = useDispatch<AppDispatch>();

  const typelist = ['png', 'jpg', 'jpeg', 'gif'];

  const changeCustomizePDF = (key: string, value: any) => {
    setGameSettingsState({
      ...gameSettingsState,
      customizeGamePDF: {
        ...gameSettingsState.customizeGamePDF,
        [key]: value,
      },
    });
  };

  const changeMultipleCustomizePDF = (updates: { [key: string]: any }) => {
    let newCustomizeGamePDF = { ...gameSettingsState.customizeGamePDF }
    for (let [key, value] of Object.entries(updates)) {
      newCustomizeGamePDF[key] = value
    }
    setGameSettingsState({
      ...gameSettingsState,
      customizeGamePDF: newCustomizeGamePDF
    })
  }

  const upload = {
    header: async (file: FileValidated[]) => {
      setHeaderFileTypeError(false);
      await apiDrupal
        .post(base_url + '/file/upload/cm_prize/cm_prize/field_image', file[0].file, {
          headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/vnd.api+json',
            'Content-Disposition': 'file; filename="' + file[0].file.name + '"',
          },
        })
        .then((res) => {
          setLoading(false)
          const data: imageUploadType = res.data;
          setGameSettingsState({
            ...gameSettingsState,
            customizeGamePDF: {
              ...gameSettingsState.customizeGamePDF,
              field_logo_header: data?.fid[0]?.value,
              header: file,
            },
          });
        }).finally(() => {
          var objDiv = document.getElementById("create-campaign-scroll");
          objDiv.scrollTop = objDiv.scrollHeight;
        });
    },
    footer: async (file: FileValidated[]) => {
      setFooterFileTypeError(false);
      await apiDrupal
        .post(base_url + '/file/upload/cm_prize/cm_prize/field_image', file[0].file, {
          headers: {
            'Content-Type': 'application/octet-stream',
            Accept: 'application/vnd.api+json',
            'Content-Disposition': 'file; filename="' + file[0].file.name + '"',
          },
        })
        .then((res) => {
          const data: imageUploadType = res.data;
          setLoading(false)

          setGameSettingsState({
            ...gameSettingsState,
            customizeGamePDF: {
              ...gameSettingsState.customizeGamePDF,
              field_logo_footer: data?.fid[0]?.value,
              footer: file,
            },
          });
        }).finally(() => {
          var objDiv = document.getElementById("create-campaign-scroll");
          objDiv.scrollTop = objDiv.scrollHeight;
        });
    },
  };

  async function save() {
    setLoading(true)
    if (
      !gameSettingsState.customizeGamePDF.name_config_campaign ||
      !gameSettingsState.customizeGamePDF.field_logo_header ||
      !gameSettingsState.customizeGamePDF.field_logo_footer ||
      !gameSettingsState.customizeGamePDF.field_title ||
      !gameSettingsState.customizeGamePDF.field_text_before_prize_name ||
      !gameSettingsState.customizeGamePDF.field_text_before_digital_prize
    ) {
      addToast({ message: 'Please fill in all fields to continue', type: 'error' });
      setLoading(false)
      setHasEmptyField(true);
      return;
    }

    !newCampaignSettingsData
      ? await dispatch(
        STORE_CAMPAIGN_SETTINGS({
          campaignId: form.id,
          name: gameSettingsState.customizeGamePDF.name_config_campaign,
          field_logo_header: gameSettingsState.customizeGamePDF.field_logo_header,
          field_logo_footer: gameSettingsState.customizeGamePDF.field_logo_footer,
          field_title: gameSettingsState.customizeGamePDF.field_title,
          field_text_before_prize_name: gameSettingsState.customizeGamePDF.field_text_before_prize_name,
          field_text_before_digital_prize: gameSettingsState.customizeGamePDF.field_text_before_digital_prize,
        })
      )
        .then(() => {
          addToast({ message: `Campaign settings added successfully.`, type: 'success' });
          setLoading(false)
        })
        .catch(() => addToast({ message: 'Failed to update campaign settings.', type: 'error' }))
      : await dispatch(
        PATCH_CAMPAIGN_SETTINGS({
          campaignId: form.id,
          name: gameSettingsState.customizeGamePDF.name_config_campaign,
          field_logo_header: gameSettingsState.customizeGamePDF.field_logo_header,
          field_logo_footer: gameSettingsState.customizeGamePDF.field_logo_footer,
          field_title: gameSettingsState.customizeGamePDF.field_title,
          field_text_before_prize_name: gameSettingsState.customizeGamePDF.field_text_before_prize_name,
          field_text_before_digital_prize: gameSettingsState.customizeGamePDF.field_text_before_digital_prize,
        })
      )
        .then(() => {
          addToast({ message: `Campaign settings updated successfully.`, type: 'success' });
          setLoading(false)
        })
        .catch(() => addToast({ message: 'Failed to update campaign settings.', type: 'error' }))

  }

  function resetCustomizePDF() {
    setGameSettingsState(prevState => ({
      ...prevState,
      customizeGamePDF: {
        ...prevState.customizeGamePDF,
        field_logo_header: null,
        field_logo_footer: null,
        header: [],
        footer: [],
        field_title: '',
        field_text_before_prize_name: '',
        field_text_before_digital_prize: '',
      }
    }))
    setHasEmptyField(false)
  }

  async function remove() {
    setLoading(true);
    newCampaignSettingsData &&
      (await dispatch(DELETE_CAMPAIGN_SETTINGS({ campaignId: form.id }))
        .then(() => {
          resetCustomizePDF()
          addToast({ message: `Campaign settings deleted successfully.`, type: 'success' })
          setLoading(false)
        })
        .catch(() => addToast({ message: 'Failed to delete campaign settings.', type: 'error' })))
  }

  const handleHeaderDrop = async (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' });
      return
    }
    if (!verifyImgType(typelist, file)) {
      setHeaderFileTypeError(true)
      changeCustomizePDF('header', [])
      return
    }
    upload
      .header(file)
      .then(() => addToast({ message: 'Image uploaded successfully.', type: 'success' }))
  };

  const handleFooterDrop = async (file: FileValidated[]) => {
    if (file[0].file.size > 1000 * 1000) {
      addToast({ message: 'The selected file size exceeds the allowed limit.', type: 'error' })
      return;
    }
    if (!verifyImgType(typelist, file)) {
      setFooterFileTypeError(true)
      changeCustomizePDF('footer', [])
      return;
    }
    upload
      .footer(file)
      .then(() => addToast({ message: 'Image uploaded successfully.', type: 'success' }))
  };

  return (
    <>
      <FlexContainer display="flex" justifyContent="space-between">
        <FlexContainer display="flex" flexDirection="column" gap="1rem">
          <StyledInput
            label="Name"
            hint='The name of the Competition settings entity'
            placeholder="name"
            size="large"
            width="90%"
            value={gameSettingsState.customizeGamePDF.name_config_campaign}
            onChange={(e) => {
              changeCustomizePDF('name_config_campaign', e.currentTarget.value);
            }}
            required
            hasError={hasEmptyField && !gameSettingsState.customizeGamePDF.name_config_campaign}
            errorText='Prize Name is required.'
          />
          <StyledFileUploader
            width={'90%'}
            title="Header image"
            accept=".png,.jpg,.gif,.jpeg"
            maxFileSize={1000 * 1000}
            message="Allowed PNG, GIF, JPG, JPEG format and must be 1MB or less."
            error={
              (headerFileTypeError || (hasEmptyField && !gameSettingsState?.customizeGamePDF?.header?.length)) && {
                message: 'The selected file cannot be uploaded or is missing.',
              }
            }
            value={gameSettingsState?.customizeGamePDF?.header}
            onDrop={handleHeaderDrop}
            onClean={() => {
              setHeaderFileTypeError(false);
              changeMultipleCustomizePDF({
                'header': [],
                'field_logo_header': null
              });
            }}
            type="image"
          />
          <StyledInput
            label="Award message"
            placeholder="award message"
            size="large"
            width="90%"
            value={gameSettingsState.customizeGamePDF.field_title}
            onChange={(e) => changeCustomizePDF('field_title', e.currentTarget.value)}
            required
            hasError={hasEmptyField && !gameSettingsState.customizeGamePDF.field_title}
            errorText='Title is required.'
          />
          <StyledInput
            label="You win message"
            placeholder="award message"
            size="large"
            width="90%"
            value={gameSettingsState.customizeGamePDF.field_text_before_prize_name}
            onChange={(e) => changeCustomizePDF('field_text_before_prize_name', e.currentTarget.value)}
            required
            hasError={hasEmptyField && !gameSettingsState.customizeGamePDF.field_text_before_prize_name}
            errorText='Text before prize name is required.'
          />
          <StyledInput
            label="Voucher"
            placeholder="Voucher message"
            size="large"
            width="90%"
            value={gameSettingsState.customizeGamePDF.field_text_before_digital_prize}
            onChange={(e) => changeCustomizePDF('field_text_before_digital_prize', e.currentTarget.value)}
            required
            hasError={hasEmptyField && !gameSettingsState.customizeGamePDF.field_text_before_digital_prize}
            errorText='Text before digital prize code is required.'
          />
          <StyledFileUploader
            width={'90%'}
            title="Footer image"
            accept=".png,.jpg,.gif,.jpeg"
            maxFileSize={1000 * 1000}
            message="Allowed PNG, GIF, JPG, JPEG format and must be 1MB or less"
            error={
              (footerFileTypeError || (hasEmptyField && !gameSettingsState?.customizeGamePDF?.footer?.length)) && {
                message: 'The selected file cannot be uploaded or is missing.',
              }
            }
            value={gameSettingsState?.customizeGamePDF?.footer}
            onChange={async (file) => { }}
            onDrop={handleFooterDrop}
            onClean={() => {
              setFooterFileTypeError(false);
              changeMultipleCustomizePDF({
                'footer': [],
                'field_logo_footer': null
              });
            }}
            type="image"
          />
        </FlexContainer>
        <FlexContainer display="flex" flexDirection="column" gap="1rem">
          <Paragraph weight='semibold' size='small'>
            PDF Template
          </Paragraph>
          <Image
            src={pdfImage}
            height={'394px'}
            width={'473px'}
            aspectRatio={1}
            variant="fill"
          />
        </FlexContainer>
    </FlexContainer>
    <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
      <div
        style={{
          width: '100%',
          display: 'inline-flex',
          justifyContent: 'flex-end',
          gap: '0.5rem',
          padding: '20px',
        }}
      >
        <StyledButton isLoading={loading} variant="primary" onClick={() => save()}>
          {!newCampaignSettingsData ? 'Create PDF Settings' : 'Save PDF Settings'}
        </StyledButton>
        {newCampaignSettingsData && (
          <StyledButton isLoading={loading} variant="destructive" onClick={() => remove()}>
            {'Delete PDF Settings'}
          </StyledButton>
        )}
      </div>
    </Grid.Item>
    </>
  );
};

export default TabGameSettingsSpinningWheel;
