import { Grid } from '@hexa-ui/components';
import { Home, List } from '@hexa-ui/icons';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { useSidebar } from 'admin-portal-shared-services';
import { Provider } from 'react-redux';
import './App.css';
import { EnvConfig, EnvProvider } from './components';
import Layout from './components/layout/Layout';
import GlobalContexts from './contexts';
import { IntlProvider } from './i18n';
import { store } from './store/index';

export default function App(props: EnvConfig) {
  const generateClassName = createGenerateClassName({
    productionPrefix: 'bees-hub-bees-games-mfe',
    seed: 'bees-hub-bees-games-mfe',
  });

  useSidebar({
    items: [
      {
        id: '0',
        title: 'Home',
        icon: () => <Home />,
        path: '/bees-games',
        onPageChange: () => {},
      },
      {
        id: '1',
        title: 'Assets',
        icon: () => <List />,
        path: '/bees-games/manage-assets',
        onPageChange: () => {},
      },
    ],
    utils: [],
  });

  return (
    <EnvProvider env={props}>
      <StylesProvider generateClassName={generateClassName}>
        <IntlProvider>
          <Provider store={store}>
            <GlobalContexts>
              <Grid.Container type="fluid" sidebar style={{ display: 'block' }}>
                <Layout />
              </Grid.Container>
            </GlobalContexts>
          </Provider>
        </IntlProvider>
      </StylesProvider>
    </EnvProvider>
  );
}
