import AlertContextProvider from './alert.context';
import ChallengeModalContext from './challenge.context';
import DeleteModalContextProvider from './deleteModal.context';
import GameSettingsContextProvider from './gameSettings.context';

const GlobalContexts = ({ children }) => {
  return (
    <DeleteModalContextProvider>
      <ChallengeModalContext>
        <GameSettingsContextProvider>
          <AlertContextProvider>{children}</AlertContextProvider>
        </GameSettingsContextProvider>
      </ChallengeModalContext>
    </DeleteModalContextProvider>
  );
};

export default GlobalContexts;
