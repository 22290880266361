import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { CampaignType } from '../dataTypes/campaign.type';

//ASYNC THUNKS
export const STORE_CAMPAIGN_SETTINGS = createAsyncThunk(
  'store/campaignSettings',
  async (
    data: {
      campaignId: number;
      name: string;
      field_logo_footer: number;
      field_logo_header: number;
      field_title: string;
      field_text_before_prize_name: string;
      field_text_before_digital_prize: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(`/campaigns/${data.campaignId}/settings`, {
      type: 'attempt_pdf_settings',
      name: data.name,
      field_logo_footer: {
        target_id: data.field_logo_footer,
      },
      field_logo_header:
      {
        target_id: data.field_logo_header,
      },
      field_title: data.field_title,
      field_text_before_prize_name: data.field_text_before_prize_name,
      field_text_before_digital_prize: data.field_text_before_digital_prize,
    });
    return response.data;
  }
);

export const PATCH_CAMPAIGN_SETTINGS = createAsyncThunk(
  'patch/campaignSettings',
  async (
    data: {
      campaignId: number;
      name: string,
      field_logo_footer: number;
      field_logo_header: number;
      field_title: string;
      field_text_before_prize_name: string;
      field_text_before_digital_prize: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.patch(`/campaigns/${data.campaignId}/settings`, {
      type: 'attempt_pdf_settings',
      name: data.name,
      field_logo_footer: {
        target_id: data.field_logo_footer,
      },
      field_logo_header:
      {
        target_id: data.field_logo_header,
      },
      field_title: data.field_title,
      field_text_before_prize_name: data.field_text_before_prize_name,
      field_text_before_digital_prize: data.field_text_before_digital_prize,
    });
    return response.data;
  }
);

export const DELETE_CAMPAIGN_SETTINGS = createAsyncThunk(
  'delete/campaignSettings',
  async (
    data: {
      campaignId: number;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.delete(`/campaigns/${data.campaignId}/settings`);
    return response.data;
  }
);

interface ICampaignSettingsState {
  data: CampaignType;
  newCampaignSettings: CampaignType;
  error: any;
}

const initialState: ICampaignSettingsState = {
  data: undefined,
  newCampaignSettings: undefined,
  error: undefined,
};

export const Slice = createSlice({
  name: 'CampaignSettings',
  initialState,
  reducers: {
    clearNewCampaignSettings(state) {
      state.newCampaignSettings = initialState.newCampaignSettings;
    },
  },
  extraReducers: (builder) => {
    //STORE_CAMPAIGN_SETTINGS
    builder.addCase(STORE_CAMPAIGN_SETTINGS.pending, (state, action) => { });
    builder.addCase(STORE_CAMPAIGN_SETTINGS.fulfilled, (state, action) => {
      state.newCampaignSettings = action.payload.data.settings;
    });
    builder.addCase(STORE_CAMPAIGN_SETTINGS.rejected, (state, action) => {
      state.error = action.error;
    });

    //PATCH_CAMPAIGN_SETTINGS
    builder.addCase(PATCH_CAMPAIGN_SETTINGS.pending, (state, action) => { });
    builder.addCase(PATCH_CAMPAIGN_SETTINGS.fulfilled, (state, action) => {
      state.newCampaignSettings = action.payload.data.settings;
    });
    builder.addCase(PATCH_CAMPAIGN_SETTINGS.rejected, (state, action) => {
      state.error = action.error;
    });

    //DELETE_CAMPAIGN_SETTINGS
    builder.addCase(DELETE_CAMPAIGN_SETTINGS.pending, (state, action) => { });
    builder.addCase(DELETE_CAMPAIGN_SETTINGS.fulfilled, (state, action) => {
      state.newCampaignSettings = undefined;
    });
    builder.addCase(DELETE_CAMPAIGN_SETTINGS.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { clearNewCampaignSettings } = Slice.actions;
export default Slice.reducer;
