import { IconButton } from '@hexa-ui/components';
import { ArrowLeft, ArrowRight } from '@hexa-ui/icons';
import { Children, useEffect, useState } from 'react';
import { useSwipeable } from "react-swipeable";
import useWindowDimensions from '../../hook/useWindowDimensions';
import useWindowScale from '../../hook/useWindowScale';
import { CarouselContentWrapper, CarouselItem, CarouselWrapper, Container } from './Carousel.styles';
import { CarouselProps } from './Carousel.types';

const Carousel = ({ children, show }: CarouselProps): JSX.Element => {
    const { width } = useWindowDimensions();
    const isAugmentedScale = useWindowScale();

    const [currentIndex, setCurrentIndex] = useState(0)
    const [carouselShow, setCarouselShow] = useState(show)
    const list = children
    const length = Children.count(children)

    useEffect(() => {
        if (width < 750) setCarouselShow(1)
        else if (width < 1400) setCarouselShow(2)
        else if (width < 1570) setCarouselShow(3)
        else setCarouselShow(show)
    }, [width])

    const next = (step: number) => {
        if (currentIndex <= (length - carouselShow)) {
            setCurrentIndex(prevState => prevState + step)
        }
    }

    const prev = (step: number) => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - step)
        }
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => next(1),
        onSwipedRight: () => prev(1),
        //preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    const handleOnClick = (idx: number) => {
        setCurrentIndex(idx);
    }

    const getWidth = () => {
        if (carouselShow === 2) return '45%'
        if (carouselShow === 3) return '27.33%'
        if (carouselShow === 4) return '20.5%'
        else return '100%'
    }

    const getResponsiveTranslate = () => {
        if (carouselShow === 2) return 87
        if (carouselShow === 3) return 80
        if (carouselShow === 4) return 95
        else return 5
    }

    const getResponsiveMarginConst = () => {
        if (carouselShow === 2) return 5
        if (carouselShow === 3) return 3
        if (carouselShow === 4) return 1
        else return 100
    }

    return (
        <Container>
            <CarouselWrapper>
                {currentIndex != 0 && (
                    <IconButton variant='secondary' size={isAugmentedScale ? 'small' : 'large'} icon={ArrowLeft} onClick={() => prev(currentIndex - (carouselShow - 1) <= 0 ? currentIndex : carouselShow)} style={{ position: 'absolute', zIndex: 10, left: '1.9%' }} />
                )}
                <CarouselContentWrapper
                    {...handlers}
                >
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            transition: 'all 370ms linear',
                            scrollbarWidth: 'none',
                            flexGrow: '100%',
                            transform: `translateX(-${(currentIndex * (getResponsiveTranslate() / carouselShow) + (getResponsiveMarginConst() * currentIndex))}%)`,
                        }}
                    >
                        {Children.map(list, (item, idx) => <CarouselItem onClick={() => handleOnClick(idx)} style={{ width: getWidth(), flexShrink: '0' }}>
                            {item}
                        </CarouselItem>)}
                    </div>
                </CarouselContentWrapper>
                {currentIndex < length - carouselShow && (
                    <IconButton icon={ArrowRight} size={isAugmentedScale ? 'small' : 'large'} variant='secondary' onClick={() => next(length - currentIndex < carouselShow ? length - currentIndex : carouselShow)} style={{ position: 'absolute', zIndex: 10, right: '1.9%' }} />
                )}
            </CarouselWrapper>
        </Container>
    )
}

export default Carousel