import { Card, Grid, LoadingDots } from '@hexa-ui/components';
import { Edit2, Trash2 } from '@hexa-ui/icons';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import FlexContainer from '../../../components/FlexContainer';
import InfoField, { InfoButtonText, InfoCardTitle } from '../../../components/InfoField/InfoField';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import StyledToggle from '../../../components/Toggle/Toggle';

const QuizTab: React.FC = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const quizData = {
    name: 'Quiz 1',
    description: 'This is a quiz',
    brand: 'Brand 1',
    coverImage: 'https://via.placeholder.com/150',
    alternativeText: 'Image',
    status: 'Published',
    id: 1,
    published: true,
    showCoverImage: true,
    changed: '2021-09-01',
  }

  useEffect(() => {
  }, [id]);

  async function handleDelete() {
    if (!id) {
      return;
    }
  }

  return quizData ? (
    <Card elevated={'medium'} border={'large'} css={{ padding: '2rem 0' }}>
      <FlexContainer
        width="100%"
        display="inline-flex"
        justifyContent="space-between"
        alignItems="center"
        padding="0 2rem"
        flexWrap="wrap"
      >
        <InfoCardTitle title={quizData.name} />
        <FlexContainer display="inline-flex" gap="0.5rem">
          <InfoButtonText
            size="small"
            css={{ backgroundColor: '#E9F1FF', borderRadius: '24px', padding: '0.25rem 0.5rem' }}
          >
            {quizData?.status}
          </InfoButtonText>
          <InfoButtonText
            icon={Edit2}
            iconPosition="leading"
            onClick={() =>
              navigate(
                '/bees-games/quiz/quiz-details/' + id + '/edit',
                {
                  state: { quizData },
                }
              )
            }
          >
            Edit
          </InfoButtonText>
          <ModalDelete
            handleDelete={() => handleDelete()}
            trigger={
              <InfoButtonText icon={Trash2} iconPosition="leading">
                Delete
              </InfoButtonText>
            }
            title="Delete quiz?"
            description={
              <>
                Do you really wish to delete this quiz? <br />
                If the quiz is published, users won’t be able to play anymore and this action
                can’t be undone.
              </>
            }
          />
        </FlexContainer>
      </FlexContainer>
      <br />
      <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="ID" text={String(quizData?.id)} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Name" text={quizData?.name} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Brand" text={quizData?.brand} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Description" text={quizData?.description} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Cover Image" text={quizData?.coverImage} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Alternative Text" text={quizData?.alternativeText} />
        </Grid.Item>
        <Grid.Item xl={2} lg={3} md={4} sm={4} xs={6}>
          <InfoField title="Last edit" text={quizData?.changed} />
        </Grid.Item>
        <Grid.Item
          xl={2}
          lg={3}
          md={4}
          sm={4}
          xs={6}
          style={{ margin: '0rem 0px 1rem 0px', flexDirection: 'column', justifyContent: 'center' }}
        >
          <InfoField title="Show Cover Image" text={<StyledToggle value={quizData?.showCoverImage} checked={quizData?.showCoverImage} size={'small'} onChange={() => { }} />} />
        </Grid.Item>
      </Grid.Container>
    </Card>
  ) : (
    <Card elevated={'large'} border={'large'} css={{ padding: '1rem 0' }}>
      <FlexContainer display="flex" height="150px" justifyContent="center" alignItems="center">
        <LoadingDots size="large" />
      </FlexContainer>
    </Card>
  );
};

export default QuizTab;
