import React from 'react';
import { Checkbox, Paragraph } from '@hexa-ui/components';
import { CheckboxContainer, CheckboxStyle } from './StyledCheckbox.style';

interface CheckboxComponentProps {
  label?: string;
  title?: string;
  hint?: string;
  id: string;
  defaultChecked?: boolean;
  onChange?: (value: boolean) => void;
}

const StyledCheckbox: React.FC<CheckboxComponentProps> = ({
  label,
  title,
  hint,
  id,
  defaultChecked,
  onChange,
}) => {
  return (
    <CheckboxContainer>
      {label && <Paragraph weight="semibold">{label}</Paragraph>}
      {hint && (
        <Paragraph size="xsmall" css={{ color: '#757575' }}>
          {hint}
        </Paragraph>
      )}
      <CheckboxStyle>
        <Checkbox
          id={id}
          label={title}
          defaultChecked={defaultChecked}
          onCheckedChange={onChange}
        />
      </CheckboxStyle>
    </CheckboxContainer>
  );
};

export default StyledCheckbox;
