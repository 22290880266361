import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiDrupal } from '../../Api/Api';
import { base_url } from '../../utils/constants';
import { imageUploadType } from '../dataTypes/uploadImage.type';
//ASYNC THUNKS
export const UploadImage = createAsyncThunk(
  'upload/image',
  async (
    data: {
      file: any;
      mimetype: string;
      filename: string;
    },
    thunkAPI
  ) => {
    const response = await apiDrupal.post(
      base_url + '/file/upload/cm_prize/cm_prize/field_image',
      data.file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          Accept: 'application/vnd.api+json',
          'Content-Disposition': 'file; filename="' + data.filename + '"',
        },
      }
    );
    return response.data;
  }
);

interface IFileUploadState {
  data: imageUploadType;
  dependency?: string;
  error: any;
}

const initialState: IFileUploadState = {
  data: null,
  dependency: '',
  error: null,
};

export const Slice = createSlice({
  name: 'FileUpload',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UploadImage.pending, (state, action) => {});
    builder.addCase(UploadImage.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(UploadImage.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
