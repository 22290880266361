import { Grid, Tabs, Tooltip } from '@hexa-ui/components';
import React, { SetStateAction, useContext, useRef, useState } from 'react';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import StyledTabs from '../../../../components/Tabs/Tabs';
import { AlertContext } from '../../../../contexts/alert.context';
import useWindowDimensions from '../../../../hook/useWindowDimensions';
import { useAppDispatch } from '../../../../store';
import { CampaignType, PrizeType } from '../../../../store/dataTypes';
import TabCreateNewPrize from './Tabs/TabCreateNewPrize';
import TabPrizeList from './Tabs/TabPrizeList';
import TabInstantPrize from './Tabs/TabInstantPrize';
import { HelpCircle } from '@hexa-ui/icons';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
  scrollTop?: any;
}
const StepSelectPrize: React.FC<props> = ({ form, setForm }) => {
  const { addToast } = useContext(AlertContext);
  const { width } = useWindowDimensions();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedPrize, setSelectedPrize] = useState<PrizeType>();

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const scrollTop = useRef(null);

  return (
    <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
      <Grid.Item
        lg={12}
        md={12}
        sm={12}
        xl={12}
        xs={12}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div ref={scrollTop} style={{ display: 'flex', gap: '0.5rem' }}>
        <Tooltip text={'Adding your campaign prizes in this step is optional'}>
        <HelpCircle style={{ marginTop: '0.4rem' }} size="large" />
        </Tooltip>
        <PageTitle
          title="Select prizes"
          hint="
          Users can win prizes for playing the game in your campaign
        "
        />
        </div>
      </Grid.Item>
      <Grid.Item style={{ padding: '0px' }} lg={12} md={12} sm={12} xl={12} xs={12}>
        <StyledTabs defaultValue={form.type.target_id === 'soccer_competition' ? '3' : '1'}>
          <Tabs.List>
            {form.type.target_id === 'soccer_competition' ? (
              <>
                <Tabs.Trigger value="3">Instant Prize</Tabs.Trigger>
                <Tabs.Trigger value="2">Create New Prize</Tabs.Trigger>
              </>
            ) : (
              <>
                <Tabs.Trigger value="1">Prizes List</Tabs.Trigger>
                <Tabs.Trigger value="2">Create New Prize</Tabs.Trigger>
              </>
            )}
          </Tabs.List>
          <Tabs.Content value="1" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <TabPrizeList
              scrollRef={scrollTop}
              form={form}
              setForm={setForm}
              setDeleteModalOpen={setDeleteModalOpen}
              setSelectedPrize={setSelectedPrize}
            />
          </Tabs.Content>
          <Tabs.Content value="2" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <TabCreateNewPrize form={form} setForm={setForm} />
          </Tabs.Content>
          <Tabs.Content value="3" style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <TabInstantPrize
              form={form}
              setForm={setForm}
              setDeleteModalOpen={setDeleteModalOpen}
              setSelectedPrize={setSelectedPrize}
            />
          </Tabs.Content>
        </StyledTabs>
      </Grid.Item>
    </Grid.Container>
  );
};
export default StepSelectPrize;
