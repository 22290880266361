import { Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import StyledCheckbox from '../../../../components/Checkbox/StyledCheckbox';
import StyledInput from '../../../../components/Input/Input';
import PageTitle from '../../../../components/PageTitle/PageTitle';
import StyledRadio from '../../../../components/Radio/StyledRadio';
import SearchDropdown from '../../../../components/SearchDropdown/SearchDropdown';
import { GameSettingsContext } from '../../../../contexts/gameSettings.context';
import { CampaignType } from '../../../../store/dataTypes';
import tournamentDataMock from '../../../json/mock/mockingTournamentCreateData.json';

interface props {
  form: CampaignType;
  setForm: SetStateAction<any>;
}

interface Tournament {
  id: number;
  name: string;
  groups: {
    id: string;
    name: string;
    matches: {
      id: number;
      name: string;
      team1: string;
      team2: string;
    }[];
  }[];
}

interface TournamentsData {
  tournaments: Tournament[];
}

export const CheckContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const StepTournamentRules: React.FC<props> = ({ form, setForm }) => {
  const [isChecked, setChecked] = useState(true);

  const { gameSettingsState, setGameSettingsState, setPrizeDisabled, reset } =
    useContext(GameSettingsContext);

  const handleCheckboxChange = (checkboxName: string, checked: boolean) => {
    checkboxName === 'For Prize' ? setPrizeDisabled(checked) : null;
    setForm((prev) => {
      let guessTypeArray = prev.guess_type ? prev.guess_type.split(', ').filter(Boolean) : [];

      if (checked && !guessTypeArray.includes(checkboxName)) {
        guessTypeArray.push(checkboxName);
      } else if (!checked) {
        guessTypeArray = guessTypeArray.filter((item) => item !== checkboxName);
      }
      return {
        ...prev,
        guess_type: guessTypeArray.join(', '),
      };
    });
  };

  useEffect(() => {
    if (isChecked) {
      setForm((prev) => ({
        ...prev,
        guess_type: 'For Prize',
      }));
    }
    reset();
  }, [isChecked, setForm]);

  //Dealing with mocking tournament data
  const tournamentData: TournamentsData = tournamentDataMock;
  const fetchTournaments = async (searchTerm: string) => {
    const tournaments = tournamentData.tournaments.map((tournament) => tournament.name);
    return tournaments.filter((tournament) =>
      tournament.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const updateTournamentSelection = (selectedTournamentName: string) => {
    const selectedTournament = tournamentData.tournaments.find(
      (tournament) => tournament.name === selectedTournamentName
    );

    const allMatches = selectedTournament.groups
      .map((group) => group.matches)
      .reduce((acc, matches) => [...acc, ...matches], []);

    setGameSettingsState({
      ...gameSettingsState,
      tournamentSelected: {
        tournament: selectedTournament.name,
        groups: selectedTournament.groups,
        matches: allMatches,
      },
    });
    setForm((prev) => ({ ...prev, tournament_name: selectedTournament.name }));
  };

  return (
    <Grid.Container type="fluid" style={{ rowGap: '1rem' }}>
      <Grid.Item
        lg={12}
        md={12}
        sm={12}
        xl={12}
        xs={12}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <PageTitle
          title="Tournament Rules"
          hint="
          Tournament Rules abstract here.
        "
        />
      </Grid.Item>

      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={6}
        xs={6}
        style={{
          width: '100%',
          gap: '1rem',
          margin: '0 0 2rem 0',
          flexWrap: 'wrap',
          alignItems: 'center',
          position: 'relative',
          display: 'flex',
        }}
      >
        <SearchDropdown
          onSearch={fetchTournaments}
          onClickResult={updateTournamentSelection}
          value={form.tournament_name}
          required
          size="large"
          label="Tournament name*"
          placeholder="Start typing to search"
        />
        <StyledInput
          type="number"
          required
          size="large"
          label="Max minutes to guess before match starts*"
          hint="It should be 60 minutes before the match starts."
          placeholder="Insert your text here"
          min={0}
          onKeyDown={(e) => {
            ['-', '+', '.', ','].includes(e.key) && e.preventDefault();
          }}
          onChange={(e) => {
            setForm((prev) => ({ ...prev, max_minutes_to_guess: e.currentTarget.value }));
          }}
        />
      </Grid.Item>
      <Grid.Item
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        style={{
          width: '100%',
          gap: '1rem',
          margin: '0 0 2rem 0',
          flexWrap: 'wrap',
          alignItems: 'center',
          position: 'relative',
          display: 'flex',
        }}
      >
        <StyledRadio
          label="Rank type*"
          hint="Choose when rank will be generate. Only one for the whole event or one every round of the tournament."
          options={[
            { id: 'whole_event', label: 'Whole Event', value: 'Whole event' },
            { id: 'round', label: 'Round', value: 'Round' },
          ]}
          onChange={(value) => {
            setForm((prev) => ({ ...prev, rank_type: value }));
          }}
        />
        <StyledRadio
          label="Guess by*"
          hint="Choose how the POC will predict the game. Winning team means POC will choose what team will win or both for tie. Exact score means POC will inform the exact score of each team."
          options={[
            { id: 'winning_team', label: 'Winning team (Check marks)', value: 'Winning team' },
            { id: 'exact_score', label: 'Exact score (numbers)', value: 'Exact score' },
          ]}
          onChange={(value) => {
            setForm((prev) => ({ ...prev, guess_by: value }));
          }}
        />
        <CheckContainer>
          <StyledCheckbox
            id="for-fun"
            title="For Fun"
            label="Guess type*"
            hint="It's possible to select one or two options."
            defaultChecked={false}
            onChange={(checked) => handleCheckboxChange('For Fun', checked)}
          />
          <StyledCheckbox
            id="for-prize"
            title="For Prize"
            defaultChecked={isChecked}
            onChange={(checked) => handleCheckboxChange('For Prize', checked)}
          />
        </CheckContainer>
      </Grid.Item>
    </Grid.Container>
  );
};
export default StepTournamentRules;
