import { Card, Grid, Select } from '@hexa-ui/components';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../../components/Button/Button';
import StyledInput from '../../components/Input/Input';
import PageTitle from '../../components/PageTitle/PageTitle';
import StyledSelect from '../../components/Select/Select';
import { countryType } from '../../components/layout/Layout';
import { AlertContext } from '../../contexts/alert.context';
import useAccountHandler from '../../hook/useAccountHandler';
import { useAppSelector } from '../../store';
import { changeStateForm } from '../../utils/functions';

type NewAccountType = {
  name: string,
  poc: any,
  country: string
}

export default function ExtensionAccount() {
  const navigate = useNavigate();
  const { addToast } = useContext(AlertContext);
  const { addExtension } = useAccountHandler();
  const countriesPromo: countryType[] = useAppSelector((state) => state.country.data);
  const [form, setForm] = useState<NewAccountType>({
    name: '',
    poc: '',
    country: localStorage.getItem('country')
  });
  const [loading, setLoading] = useState<boolean>(false);

  const handleSend = async () => {
    if (!form?.name || form?.poc === undefined) {
      addToast({
        message: 'Please fill in all fields to continue!',
        type: 'error',
      });
    } else {
      setLoading(true);
      await addExtension(
        {
          name: form?.name,
          poc: form?.poc,
          country: (countriesPromo?.filter(item => String(item.id) === form?.country)[0]).uuid
        }
      )
        .then((res) => {
          if (res?.data?.errors) {
            addToast({
              message: 'An error occurred while create the extension account.',
              type: 'error',
            });
          } else {
            addToast({
              message: 'Extension account successfuly created',
              type: 'success',
            });
            navigate(-1);
          }
        })
        .catch(() => {
          addToast({
            message: 'An error occurred while create the extension account.',
            type: 'error',
          });
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: '0px' }}>
        <PageTitle
          marginBottom="0.5rem"
          title="Extension account"
          hint=""
        />
      </Grid.Item>
      <Card elevated="medium" border="medium" css={{ width: '100%' }}>
        <Grid.Container
          type="fluid"
          style={{
            justifyContent: 'start',
            padding: '1.5rem 0 1rem 0',
          }}
        >
          <Grid.Item xl={8} lg={8} md={7} sm={12} xs={12} style={{ paddingLeft: '0px' }}>
            <Grid.Container type="fluid" style={{ width: '100%', rowGap: '1rem', margin: 0, flexDirection: "column" }}>
              <StyledSelect
                size="large"
                label="Country"
                width="50%"
                value={form?.country}
                defaultValue={localStorage.getItem('country')}
                onChange={(value) => changeStateForm(setForm, form, 'country', value)}
              >
                {countriesPromo &&
                  countriesPromo.map((country: countryType, index: number) => {
                    return (
                      <Select.Option key={country.name + index} value={String(country.id)}>
                        {country.name}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
              <StyledInput
                required
                size="large"
                label="Name*"
                placeholder="Insert Name"
                hint="The name of the Extension account entity."
                width="50%"
                onChange={(e) => changeStateForm(setForm, form, 'name', e.currentTarget.value)}
              />
              <StyledInput
                required
                size="large"
                label="POC*"
                placeholder="Insert POC"
                width="50%"
                onChange={(e) => changeStateForm(setForm, form, 'poc', e.currentTarget.value)}
              />
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
        <Grid.Item xl={12} lg={12} md={12} sm={12} xs={12} style={{ paddingRight: '0px' }}>
          <div
            style={{
              width: '100%',
              display: 'inline-flex',
              justifyContent: 'flex-end',
              gap: '0.5rem',
              padding: '20px',
            }}
          >
            <StyledButton variant="secondary" onClick={() => navigate(-1)}>
              Cancel
            </StyledButton>
            <StyledButton variant="primary" isLoading={loading} onClick={() => handleSend()}>
              Save
            </StyledButton>
          </div>
        </Grid.Item>
      </Card>
    </>
  );
}
