import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { apiDrupal } from '../../Api/Api';
import { AcceptanceType } from '../dataTypes/acceptance.type';

//ASYNC THUNKS
export const FETCH_ACCEPTANCE = createAsyncThunk(
  'fetch/acceptance',
  async (data: any, thunkAPI) => {
    const response = await apiDrupal.get(
      `/player-acceptances/${data.campaign_id}?page=${data.page}&limit=${data.limit}&search=${data.search}`
    );
    return response.data.data;
  }
);

interface IAcceptanceState {
  data: AcceptanceType[];
  total: number;
  error: any;
}

const initialState: IAcceptanceState = {
  data: [],
  total: 0,
  error: null,
};

export const Slice = createSlice({
  name: 'Acceptance',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    //FETCH_ACCEPTANCE
    builder.addCase(FETCH_ACCEPTANCE.fulfilled, (state, action) => {
      let acceptanceData: any = action.payload;

      acceptanceData.results.map((a) => {
        a.created = moment(a.created).format('DD/MM/YYYY hh:mm');
      });

      state.data = acceptanceData.results;
      state.total = acceptanceData.total;

      console.warn(state.total);
    });
    builder.addCase(FETCH_ACCEPTANCE.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const {} = Slice.actions;
export default Slice.reducer;
